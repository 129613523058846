import {EditorState} from 'draft-js';

export function getFullTextFromEditorState(editorState: EditorState) {
  if (!editorState) {
    return [];
  }

  const blockMap = editorState.getCurrentContent().getBlockMap();

  return blockMap
    .valueSeq()
    .map((block) => (block ? block.getText() : ''))
    .toJS();
}

export function countTextEditorLines(texts: any) {
  if (!texts) {
    return 0;
  }
  if (texts.length < 1) {
    return 0;
  }

  return texts.reduce(
    (previous, curent) => previous + Math.floor(curent.length / 80) + 1,
    0,
  );
}
