import {NewButton} from 'letrus-ui';
import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {connect} from 'react-redux';
import {
  clearRestorePassword,
  isLoadingRestorePassword,
  isRestorePasswordWithError,
  restorePasswordRequest,
} from 'store/reducers/authentication';
import {ApplicationState} from 'store/rootReducer';
import {usePrevious} from 'utils/hooks/usePrevious';
import styles from '../Login.module.scss';

interface DispatchProps {
  isLoadingRestorePassword: boolean;
  restorePasswordError: boolean;
}

interface StateProps {
  restorePasswordRequest: typeof restorePasswordRequest;
  clearRestorePassword: typeof clearRestorePassword;
}

interface FormValues {
  email: string;
}

interface Message {
  content: string;
  type: 'error' | 'success';
  email: string;
}

interface OwnProps {
  setShowRestorePassword: (value: React.SetStateAction<boolean>) => void;
}

type Props = DispatchProps & StateProps & OwnProps;

const RestorePassword: React.FC<Props> = ({
  setShowRestorePassword,
  isLoadingRestorePassword,
  restorePasswordError,
  restorePasswordRequest,
  clearRestorePassword,
}) => {
  const {
    register,
    handleSubmit,
    formState: {errors, isSubmitted},
    watch,
    reset,
  } = useForm<FormValues>({
    mode: 'onChange',
  });
  const email = watch('email');

  const onSubmit = (data: FormValues) => {
    restorePasswordRequest({
      email: data.email,
    });
  };

  const [message, setMessage] = useState<Message | null>(null);
  const previousIsLoadingRestorePassword = usePrevious<boolean>(
    isLoadingRestorePassword,
  );

  useEffect(() => {
    clearRestorePassword();

    return () => {
      clearRestorePassword();
    };
  }, [clearRestorePassword]);

  useEffect(() => {
    if (message && message.email && message.email !== email) {
      setMessage(null);
      reset({email});
      clearRestorePassword();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, message]);

  useEffect(() => {
    if (
      previousIsLoadingRestorePassword &&
      !isLoadingRestorePassword &&
      !restorePasswordError
    ) {
      setMessage({
        content:
          'Um email foi enviado com as informações para gerar uma nova senha',
        type: 'success',
        email,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingRestorePassword]);

  useEffect(() => {
    if (restorePasswordError && isSubmitted) {
      setMessage({
        content: 'Houve um erro, por favor, verifique se o email está correto',
        type: 'error',
        email,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restorePasswordError, isSubmitted]);

  const toggleView = () => setShowRestorePassword((prevState) => !prevState);

  return (
    <div className={styles.formContainer}>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <span className={styles.formBlock}>
          <label>Recuperar Senha</label>
          <input
            type="text"
            name="email"
            placeholder="E-mail"
            aria-label="email"
            ref={register({required: true})}
            className={errors.email && styles.error}
          />
          {errors.email && (
            <span className={styles.errorText}>
              Por favor, preencha o email corretamente.
            </span>
          )}

          {message && message.content && (
            <span className={styles.formBlock}>
              <span
                className={styles.errorText}
                style={{color: message.type === 'success' ? 'green' : 'red'}}
              >
                {message.content}
              </span>
            </span>
          )}
        </span>
        <div className={styles.buttonsRow}>
          <NewButton userRole="anonymous" onClick={toggleView}>
            Voltar
          </NewButton>
          <NewButton userRole="teacher" type="submit">
            Recuperar
          </NewButton>
        </div>
      </form>
    </div>
  );
};

export default connect(
  (state: ApplicationState) => ({
    isLoadingRestorePassword: isLoadingRestorePassword(state),
    restorePasswordError: isRestorePasswordWithError(state),
  }),
  {
    restorePasswordRequest,
    clearRestorePassword,
  },
)(RestorePassword);
