import {LetrusApi} from '@letrustech/letrus-api-interfaces';
import DisplayTextInTooltip from 'components/DisplayTextInTooltip';
import {push} from 'connected-react-router';
import dayjs from 'dayjs';
import {List, Map} from 'immutable';
import {Button, Icon, Table} from 'letrus-ui';
import React, {useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import {Column, usePagination, useSortBy, useTable} from 'react-table';
import {
  fetchFinishedGradesRequest,
  getFinishedGrades,
  getFinishedGradesCount,
  isLoadingFinishedGrades,
} from 'store/reducers/compositionReviews';
import {ApplicationState} from 'store/rootReducer';
import {totalReviewScore} from 'utils/functions/reviewScore';
import styles from './ReviewerFinishGradesTable.module.scss';

export interface FinishedGradesCompositions {
  composition: {
    id: number;
    instruction_id: {
      title: string;
      genre: {
        name: string;
      };
    };
    flag_pre_finished: boolean;
  };
  review_grades: any;
  finished: string;
}

export interface StateProps {
  isLoading: boolean;
  finishedGrades: List<ImmutableMap<LetrusApi.CompositionReviewDetail>>;
  finishedGradesCount: number;
}

export interface DispatchProps {
  fetchFinishedGradesRequest: typeof fetchFinishedGradesRequest;
  push: typeof push;
}

export type Props = StateProps & DispatchProps;

const ReviewerFinishGradesTable: React.FC<Props> = ({
  fetchFinishedGradesRequest,
  push,
  finishedGrades,
  finishedGradesCount,
  isLoading,
}) => {
  const [currentPage, setCurrentPage] = useState(1);

  const handleChangePage = (page) => {
    setCurrentPage(page);
    if (page > 1) {
      fetchFinishedGradesRequest({offset: (page - 1) * 10});
    } else {
      fetchFinishedGradesRequest();
    }
  };

  useEffect(() => {
    fetchFinishedGradesRequest();
  }, [fetchFinishedGradesRequest]);

  const columns: Array<Column<
    LetrusApi.CompositionReviewDetail
  >> = useMemo(() => {
    return [
      {
        id: 'composition__id',
        Header: 'Número',
        accessor: ({composition}) => composition.id,
        Cell: ({value}) => (
          <div className="text-center" style={{minWidth: '100px'}}>
            {value}
          </div>
        ),
      },
      {
        id: 'composition__instruction_id__title',
        Header: 'Tema',
        accessor: ({composition}) =>
          composition.instruction_id.title &&
          composition.instruction_id.title.replace(/ *\[[^)]*\] */g, ''),
        Cell: ({row: {original}, value}) => (
          <div style={{width: '350px'}}>
            <span
              style={{
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                display: 'block',
              }}
            >
              {original.composition.parent_id ? <Icon icon="clone" /> : null}
              <DisplayTextInTooltip text={value} />
            </span>
          </div>
        ),
      },
      {
        id: 'composition__genre__name',
        Header: 'Gênero',
        accessor: ({composition}) =>
          composition.instruction_id?.genre?.name || null,
        Cell: ({value}) => <div style={{minWidth: '150px'}}>{value}</div>,
      },
      {
        id: 'composition__revision_deadline',
        Header: 'Fim da edição',
        accessor: ({finished, flag_pre_finished}) => {
          const finishedDate = finished
            ? dayjs(finished).format('DD/MM/YYYY hh:mm')
            : '';
          const value = flag_pre_finished ? 'Edição aberta' : finishedDate;

          return value;
        },
        Cell: ({value}) => <div style={{minWidth: '200px'}}>{value}</div>,
      },
      {
        id: 'total_score',
        Header: 'Nota',
        accessor: (grade) => {
          return totalReviewScore(grade);
        },
        Cell: ({value}) => <div style={{minWidth: '90px'}}>{value}</div>,
      },
      {
        id: 'editar_correcao',
        Header: 'Edição',
        accessor: ({flag_pre_finished}) => {
          if (flag_pre_finished) return 'Aberta';

          return 'Fechada';
        },
        Cell: ({value}) => {
          return <div style={{minWidth: '140px'}}>{value}</div>;
        },
      },
      {
        id: '_controls',
        Header: 'Ação',
        accessor: () => null,
        Cell: ({row}) => {
          const {original} = row;

          return (
            <div style={{minWidth: '130px'}}>
              {original.flag_pre_finished ? (
                <Button
                  kind="accentPrimary"
                  userRole="reviewer"
                  size="small"
                  onClick={() => push(`/correcao/${original.composition.id}`)}
                  text="Editar Correção"
                />
              ) : (
                <Button
                  kind="secondary"
                  userRole="reviewer"
                  size="small"
                  onClick={() => push(`/correcao/${original.composition.id}`)}
                  text="Ver Correção"
                />
              )}
            </div>
          );
        },
      },
    ];
  }, [push]);

  const data = useMemo(() => {
    const data = finishedGrades
      ?.map((grade) =>
        Map({
          composition: grade.get('composition'),
          review_grades: grade.get('review_grades'),
          finished: grade.get('finished'),
          flag_pre_finished: grade.get('flag_pre_finished'),
        }),
      )
      .toJS();
    return data || [];
  }, [finishedGrades]);

  const {
    getTableBodyProps,
    headerGroups,
    getTableProps,
    prepareRow,
    state,
    page,
  } = useTable({columns, data}, useSortBy, usePagination) as any;

  return (
    <div className={styles.container}>
      <h1>Lista de redações</h1>
      <Table
        dataCount={finishedGradesCount}
        tableData={{
          getTableBodyProps,
          headerGroups,
          getTableProps,
          prepareRow,
          state,
          page,
        }}
        currentPage={currentPage}
        setCurrentPage={handleChangePage}
        isLoading={isLoading}
      />
    </div>
  );
};

export default connect<StateProps, DispatchProps, {}, ApplicationState>(
  (state: ApplicationState) => ({
    finishedGradesCount: getFinishedGradesCount(state),
    finishedGrades: getFinishedGrades(state),
    isLoading: isLoadingFinishedGrades(state),
  }),
  {fetchFinishedGradesRequest, push},
)(ReviewerFinishGradesTable);
