import React, {ReactNode, useEffect, useState} from 'react';
import TabItem, {Size} from './TabItem';
import './TabList.scss';

type Position = 'left' | 'center' | 'right';

interface TabListProps {
  border?: boolean;
  className?: string;
  classNameContainer?: string;
  data: {
    name: string;
    children: any;
  }[];
  header?: ReactNode;
  position?: Position;
  size?: Size;
}

type Props = TabListProps;

const TabList: React.FC<Props> = ({
  border = false,
  children,
  className,
  classNameContainer,
  data,
  header,
  position = 'left',
  size = 'default',
}) => {
  const [active, setActive] = useState(data[0] as any);

  useEffect(() => {
    const activeTab = active.tabIndex;

    setActive(data[activeTab]);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children, data]);

  const handleTabChange = (event: any) => {
    const tabName = event.target.innerText;

    if (tabName === active.name) return;

    const newTab = data.filter((d) => d.name === tabName)[0];

    setActive(newTab);
  };

  return (
    <div className={`tab-list ${className}`}>
      <div
        className={`tab-list__container tab-list--${position} ${classNameContainer}`}
      >
        {header && <div className="tab-list__header">{header}</div>}
        <div className={`tab-list__tabs ${border ? 'tab-list--border' : ''}`}>
          {data.map(({name}) => (
            <TabItem
              active={active.name === name}
              handleClick={handleTabChange}
              key={name}
              name={name}
              size={size}
            />
          ))}
        </div>
      </div>

      <div className={`tab-list__wrapper tab-list--${position}`}>
        {children && <aside className="tab-list__aside">{children}</aside>}
        <div
          className={`tab-list__children tab-list--${position} ${
            children ? 'children-margin' : ''
          }`}
        >
          {active.children}
        </div>
      </div>
    </div>
  );
};

export default TabList;
