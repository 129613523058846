import React from 'react';
import {Link} from 'react-router-dom';
import styles from './PageNotFound.module.scss';
interface DispatchProps {}

type Props = DispatchProps;

const PageNotFound: React.FC<Props> = () => {
  return (
    <div className={styles.wrapper}>
      <h1>Page not found</h1>
      <div className={styles.buttonsRow}>
        <Link to="/">Homepage</Link>
      </div>
    </div>
  );
};

export default PageNotFound;
