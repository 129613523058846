/* eslint-disable consistent-return */
import InputField from 'components/InputField';
import InputSelectField from 'components/InputSelectField';
import InputTextMask from 'components/InputTextMask';
import {Button, Icon} from 'letrus-ui';
import React, {useCallback, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import {InjectedFormProps} from 'redux-form';
import {Field, Form, reduxForm} from 'redux-form/immutable';
import {getIuguInformation} from 'store/reducers/iuguGetOrCreateSubAccount';
import {verifySubAccountRequest} from 'store/reducers/iuguSubAccounts';
import {ApplicationState} from 'store/rootReducer';
import brazilianStates from 'utils/brazilianStates';
import {validateBankInformationForm} from 'utils/forms/validations/bankInformation';
import iuguBankMasks from '../../utils/iuguBankMasks';
import styles from './BankInformationForm.module.scss';

interface DispatchProps {
  verifySubAccountRequest: typeof verifySubAccountRequest;
}

interface StateProps {
  iuguSubAccountResponse: any;
}

interface BankInformationFormProps {
  subAccount: any;
  user: any;
  showAlert: any;
  setLoading: any;
  initialValues: any;
  iuguInformation: any;
}

type Props = DispatchProps & BankInformationFormProps & StateProps;

const BankInformationForm: React.FC<Props & InjectedFormProps<{}, Props>> = ({
  user,
  showAlert,
  setLoading,
  initialValues,
  invalid,
  submitting,
  pristine,
  handleSubmit,
  iuguSubAccountResponse,
  verifySubAccountRequest,
  change,
}) => {
  const [isCNPJ, setIsCNPJ] = useState(false);
  const [bankMask, setBankMask] = useState({
    agency: '',
    account: '',
    operation: {},
  });
  const [selectedBank, setSelectedBank] = useState('');

  const setFormType = (e: any) => {
    setIsCNPJ(e.target.value === 'Pessoa Jurídica');
  };

  useEffect(() => {
    setIsCNPJ(initialValues?.get('person_type') === 'Pessoa Jurídica');

    if (initialValues?.get('bank')) {
      const bank = initialValues?.get('bank');

      if (iuguBankMasks[bank]) {
        const {agency, account, operation} = iuguBankMasks[bank];

        setSelectedBank(bank);

        setBankMask({
          agency,
          account,
          operation,
        });
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const history = useHistory();

  const onChangeSelectedBank = (event: any) => {
    const selectedBank = event.target.value;
    const {agency, account, operation} = iuguBankMasks[selectedBank];

    change('bank_ag', '');
    change('bank_cc', '');

    setBankMask({
      agency,
      account,
      operation,
    });
    setSelectedBank(selectedBank);
  };

  const validateBankInfo = useCallback(
    (value: any, info: any) => {
      if (
        value &&
        iuguBankMasks[selectedBank][info].length !==
          value.replace(/_/g, '').length
      ) {
        return "Complete o campo com 0's a esquerda!";
      }

      return undefined;
    },
    [selectedBank],
  );

  const validateAgency = useCallback(
    (value) => {
      if (!selectedBank || !value) {
        return;
      }

      return validateBankInfo(value, 'agency');
    },
    [selectedBank, validateBankInfo],
  );

  const validateAccount = useCallback(
    (value) => {
      if (!selectedBank || !value) {
        return;
      }

      return validateBankInfo(value, 'account');
    },
    [selectedBank, validateBankInfo],
  );

  const onSubmit = async (values: any) => {
    const account_type = values.get('account_type');
    const person_type = values.get('person_type');
    const bank = values.get('bank');

    let updatedValues = values.update('bank_cc', (value: any) =>
      bankMask.operation
        ? `${bankMask.operation[account_type][person_type]}${value}`
        : value,
    );

    // if bank is "Banco do Brasil" and value last character is "0", change it to "X". This is because of a rule of Banco do Brasil that only accept an agency with X as the last character instead of 0
    updatedValues = updatedValues.update('bank_ag', (value: string) =>
      bank === 'Banco do Brasil' && value.slice(-1) === '0'
        ? value.replace(/0$/, 'X')
        : value,
    );

    // This is because the bank mask is not working properly, so we need to create two masks for Caixa Econômica
    updatedValues = updatedValues.update('bank', (value: string) =>
      bank.includes('Caixa Econômica') ? 'Caixa Econômica' : value,
    );

    setLoading(true);

    try {
      verifySubAccountRequest({
        ...{
          ...(isCNPJ
            ? updatedValues.set('cpf', undefined).toJS()
            : updatedValues.set('cnpj', undefined).toJS()),
          price_range: 'Mais que R$ 500,00',
          physical_products: false,
          name: `${user.get('first_name')}  ${user.get('last_name')}`,
          company_name: `${user.get('first_name')}  ${user.get('last_name')}`,
          automatic_validation: true,
          automatic_transfer: false,
          financial_data_update: iuguSubAccountResponse?.iugu_sub_account
            ? true
            : false,
        },
        sub_account: iuguSubAccountResponse?.id,
      });

      showAlert(
        'Informaçoes adicionadas com sucesso. Estamos avaliando ' +
          'seus dados para futuros pagamentos.',
        'success',
      );

      setLoading(false);
      setTimeout(() => history.go(0), 5000);
    } catch (error) {
      setLoading(false);
      showAlert('Aconteceu algum erro, contate a letrus.', 'error');
    }
  };

  const isBankAccountAccepted = iuguSubAccountResponse?.status === 'accepted';
  const isBankAccountRejected = iuguSubAccountResponse?.status === 'rejected';
  const isBankAccountPending = iuguSubAccountResponse?.status === 'pending';

  return isBankAccountPending && iuguSubAccountResponse?.iugu_sub_account ? (
    <div className={styles['status-pending']}>
      <span>
        <Icon icon="circle" color="#ffd905" /> Sua conta está sendo validada,
        aguarde!
      </span>
    </div>
  ) : (
    <Form
      className={styles['bank-information-form']}
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
    >
      <div className={styles['person-type']}>
        <label className={styles.title}>Tipo de conta</label>
        <div className={styles.ratio}>
          <div>
            <Field
              name="person_type"
              component="input"
              type="radio"
              value="Pessoa Física"
              onChange={setFormType}
            />
            <label>Pessoa física</label>
          </div>

          <div>
            <Field
              name="person_type"
              component="input"
              type="radio"
              value="Pessoa Jurídica"
              onChange={setFormType}
            />
            <label>Pessoa jurídica</label>
          </div>
        </div>
      </div>
      <div className={styles['person-type-number']}>
        <Field
          name="cpf"
          id="cpf"
          type="text"
          component={InputTextMask}
          mask="999.999.999-99"
          label="Número do CPF"
        />

        <div className={styles['sibling']}>
          <Field
            name="cnpj"
            id="cnpj"
            type="text"
            component={InputTextMask}
            mask="99.999.999/9999-99"
            label="Número do CNPJ"
          />
        </div>

        <div className={styles['person-type-number__tooltip']}>
          <ReactTooltip place="top" type="dark" effect="solid" />
          <span
            data-tip={`Você deve colocar o ${
              isCNPJ ? 'CNPJ/' : ''
            } CPF vinculado à conta inserida.`}
          >
            <Icon icon="info-circle" />
          </span>
        </div>
      </div>
      <div className={styles.bank}>
        <Field
          name="bank"
          id="bank"
          type="select"
          component={InputSelectField}
          required
          label="Banco"
          onChange={onChangeSelectedBank}
        >
          <option value="" disabled>
            Selecione
          </option>
          {Object.keys(iuguBankMasks).map((bank) => (
            <option key={bank} value={bank}>
              {bank}
            </option>
          ))}
        </Field>
        <div className={styles.sibling}>
          <Field
            name="account_type"
            id="account_type"
            type="select"
            component={InputSelectField}
            required
            label="Tipo de conta"
          >
            <option value="" disabled>
              Selecione
            </option>
            <option value="Corrente">Corrente</option>
            <option value="Poupança">Poupança</option>
          </Field>
        </div>
      </div>
      <div className={styles.account}>
        <Field
          name="bank_ag"
          id="bank_ag"
          type="text"
          component={InputTextMask}
          required
          label="Agência"
          placeholder={bankMask.agency}
          mask={bankMask.agency}
          validate={validateAgency}
          disabled={!selectedBank}
        />
        <div className={styles.sibling}>
          <Field
            name="bank_cc"
            id="bank_cc"
            type="text"
            component={InputTextMask}
            required
            label="Conta"
            placeholder={bankMask.account.replace(/\*/, 'X')}
            mask={bankMask.account}
            validate={validateAccount}
            disabled={!selectedBank}
          />
        </div>
      </div>
      <div className={styles.address}>
        <Field
          name="address"
          id="address"
          type="text"
          component={InputField}
          required
          label="Endereço"
        />
        <div className={styles['cep-city-state']}>
          <div className={styles.cep}>
            <Field
              name="cep"
              id="cep"
              type="text"
              component={InputTextMask}
              mask="99999-999"
              required
              label="CEP"
            />
          </div>
          <div className={styles.sibling}>
            <Field
              name="city"
              id="city"
              type="text"
              component={InputField}
              required
              label="Cidade"
            />
          </div>
          <div className={styles.sibling}>
            <Field
              name="state"
              id="state"
              type="select"
              component={InputSelectField}
              required
              label="Estado"
            >
              <option>Selecione</option>
              {Object.keys(brazilianStates).map((stateAbbreviation) => (
                <option
                  key={`state-${stateAbbreviation}`}
                  value={stateAbbreviation}
                >
                  {stateAbbreviation}
                </option>
              ))}
            </Field>
          </div>
        </div>
      </div>
      <div className={styles.status}>
        <div>
          <div className={styles['status__info']}>
            {isBankAccountAccepted && (
              <span>
                <Icon icon="circle" color="#0fc78a" /> As informações foram
                validadas com sucesso!
              </span>
            )}
            {isBankAccountRejected && (
              <span>
                <Icon icon="circle" color="#FF3B57" />
                As informações não foram validadas! Envie suas informações
                bancárias novamente.
              </span>
            )}
          </div>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.button}>
          <Button
            userRole="reviewer"
            type="submit"
            disabled={invalid || submitting || pristine}
          >
            Salvar
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default connect<
  StateProps,
  DispatchProps,
  BankInformationFormProps,
  ApplicationState
>(
  (state: ApplicationState) => ({
    iuguSubAccountResponse: getIuguInformation(state),
  }),
  {
    verifySubAccountRequest,
  },
)(
  reduxForm<{}, Props>({
    form: 'BankInformationForm',
    validate: validateBankInformationForm,
  })(BankInformationForm),
);
