import InputField from 'components/InputField';
import InputSelectField from 'components/InputSelectField';
import InputTextMask from 'components/InputTextMask';
import Loading from 'components/Loading';
import ModalInfo from 'components/ModalInfo';
import 'cropperjs/dist/cropper.css';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {Button} from 'letrus-ui';
import React, {FormEvent, useCallback, useEffect, useState} from 'react';
import Cropper from 'react-cropper';
import {connect} from 'react-redux';
import {InjectedFormProps} from 'redux-form';
import {Field, reduxForm} from 'redux-form/immutable';
import {
  getCurrentUserProfile,
  updateCurrentUserRequest,
} from 'store/reducers/authentication';
import {getUploadedImage, uploadToS3Request} from 'store/reducers/s3';
import {updateProfileRequest} from 'store/reducers/userProfile';
import {ApplicationState} from 'store/rootReducer';
import denormalizePhone from 'utils/functions/phone';
import {validateEmail} from 'utils/validateEmail';
import Avatar from '../../../images/avatar_placeholder.png';
import {phoneNumberValidation} from '../../../utils/forms/validations/phoneNumber';
import styles from './ProfileForm.module.scss';

dayjs.extend(customParseFormat);

const fileDomain =
  process.env.NODE_ENV === 'development'
    ? 'http://betamedia.letrus.com.br'
    : 'http://media.letrus.com.br';
const required = (value: any) => (value ? undefined : 'Campo obrigatório');

export interface StateProps {
  profile: ImmutableMap<any>;
  uploadedImage: any;
}

export interface DispatchProps {
  updateProfileRequest: typeof updateProfileRequest;
  updateCurrentUserRequest: typeof updateCurrentUserRequest;
  uploadToS3Request: typeof uploadToS3Request;
}

interface OwnProps {
  handleSubmit?: any;
  submitting?: any;
  onSubmit?: () => void;
  change?: any;
  user?: any;
  initialValues?: any;
}

type Props = StateProps & DispatchProps & OwnProps;

const ProfileForm: React.FC<Props & InjectedFormProps<{}, Props>> = ({
  profile,
  handleSubmit,
  submitting,
  uploadToS3Request,
  updateProfileRequest,
  uploadedImage,
  updateCurrentUserRequest,
  onSubmit,
  pristine,
  initialValues,
}) => {
  const [loading, setLoading] = useState(false);
  const [userImage, setUserImage] = useState<string>();
  const [imagePreviewURL, setImagePreviewURL] = useState<string>();
  const [cropper, setCropper] = useState<Cropper>();

  useEffect(() => {
    setUserImage(uploadedImage);
    setImagePreviewURL(undefined);
    setLoading(false);
  }, [uploadedImage]);

  useEffect(() => {
    const image = initialValues.get('image');
    if (image && !uploadedImage && image !== userImage) {
      setUserImage(image);
    }
  }, [initialValues, uploadedImage, userImage]);

  const onSelectPicture = (event: FormEvent) => {
    event.preventDefault();

    const inputFile = document.createElement('input') as any;

    inputFile.type = 'file';
    inputFile.name = 'file';
    inputFile.id = 'file';
    inputFile.onchange = (event: any) => {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = (event: any) => setImagePreviewURL(event.target.result);
      reader.readAsDataURL(file);
    };
    inputFile.style = {display: 'none'};
    inputFile.click();
  };

  const cancelUpload = () => {
    setImagePreviewURL(undefined);
  };

  const uploadImage = useCallback(() => {
    const dataUrl = cropper
      ?.getCroppedCanvas({
        width: 180,
        height: 180,
        minWidth: 180,
        minHeight: 180,
        maxWidth: 180,
        maxHeight: 180,
        fillColor: '#fff',
        imageSmoothingQuality: 'medium',
      })
      .toDataURL();

    uploadToS3Request(dataUrl);
    setLoading(true);
  }, [cropper, uploadToS3Request]);

  const handleSaveProfileData = (values: ImmutableMap<any>) => {
    const birth = values.get('birth');

    const reviewerData = {
      active: true,
      user: values.get('id'),
      image: uploadedImage,
      id: profile.get('id'),
      phone_number: denormalizePhone(values.get('phone_number')),
      gender: values.get('gender'),
      birth:
        birth !== 'Invalid Date'
          ? dayjs(birth, 'DD/MM/YYYY').format('YYYY-MM-DD')
          : null,
    };

    updateCurrentUserRequest(values.toJS());
    updateProfileRequest(reviewerData);
    onSubmit && onSubmit();
  };

  const onCropperInit = (cropper: Cropper) => {
    setCropper(cropper);
  };

  return (
    <section className={styles.container}>
      <p className={styles.title}>Meus dados</p>
      <form
        onSubmit={handleSubmit(handleSaveProfileData)}
        className={styles.form}
        autoComplete="off"
        noValidate
      >
        <div
          className={styles['form__photo-change']}
          onClick={onSelectPicture}
          style={{
            backgroundImage: `url(${
              userImage ? `${fileDomain}/${userImage}` : Avatar
            })`,
          }}
        />

        <Field
          name="first_name"
          type="text"
          component={InputField}
          label="Nome"
          validate={required}
        />
        <Field
          name="last_name"
          type="text"
          component={InputField}
          label="Sobrenome"
          validate={required}
        />
        <Field
          name="email"
          type="email"
          component={InputField}
          label="E-mail"
        />
        <Field
          name="phone_number"
          type="text"
          component={InputTextMask}
          mask="(99) 99999-9999"
          label="Celular"
          validate={phoneNumberValidation}
        />
        <Field
          name="gender"
          type="select"
          component={InputSelectField}
          label="Gênero"
        >
          <option value="">Prefiro não informar</option>
          <option value="M">Masculino</option>
          <option value="F">Feminino</option>
        </Field>
        <Field
          name="birth"
          type="text"
          component={InputTextMask}
          mask="99/99/9999"
          label="Data de nascimento"
        />

        <div
          className={styles.form__button}
          title={pristine ? 'Nenhum dado foi alterado' : undefined}
        >
          <Button
            type="submit"
            disabled={submitting || pristine}
            userRole="reviewer"
            kind="secondary"
          >
            Salvar
          </Button>
        </div>
      </form>
      {imagePreviewURL && (
        <ModalInfo
          visible
          onCloseClick={() => setImagePreviewURL(undefined)}
          component={
            <div className={styles.cropper}>
              <Cropper
                src={imagePreviewURL}
                style={{height: '100%', width: '100%'}}
                className="cropper"
                aspectRatio={1 / 1}
                dragMode="crop"
                guides={false}
                onInitialized={onCropperInit}
              />
              <div className={styles.cropper__actions}>
                <Button
                  kind="secondary"
                  userRole="reviewer"
                  type="button"
                  onClick={() => cancelUpload()}
                >
                  Cancelar
                </Button>
                <Button
                  userRole="reviewer"
                  type="button"
                  onClick={() => uploadImage()}
                >
                  Fazer Upload
                </Button>
              </div>
            </div>
          }
        />
      )}
      <Loading show={loading} />
    </section>
  );
};

export default connect<StateProps, DispatchProps, OwnProps, ApplicationState>(
  (state: ApplicationState) => ({
    profile: getCurrentUserProfile(state),
    uploadedImage: getUploadedImage(state),
  }),
  {
    uploadToS3Request,
    updateProfileRequest,
    updateCurrentUserRequest,
  },
)(
  reduxForm<{}, Props>({
    form: 'ProfileForm',
    validate: validateEmail,
  })(ProfileForm),
);
