import {Utils} from '@letrustech/letrus-api-interfaces';
import {fromJS} from 'immutable';
import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {
  addLikeInReviewAnnotationService,
  CreateGradeReviewAnnotationData,
  createGradeReviewAnnotationService,
  deleteGradeReviewAnnotationService,
  updateGradeReviewAnnotationService,
} from 'store/services/reviewAnnotationsService';
import {action} from 'typesafe-actions';

//Action types
export enum ReviewAnnotationsTypes {
  CREATE_GRADE_REQUEST = '@reviewAnnotations/CREATE_GRADE_REQUEST',
  CREATE_GRADE_SUCCESS = '@reviewAnnotations/CREATE_GRADE_SUCCESS',
  CREATE_GRADE_FAILURE = '@reviewAnnotations/CREATE_GRADE_FAILURE',

  UPDATE_GRADE_REQUEST = '@reviewAnnotations/UPDATE_GRADE_REQUEST',
  UPDATE_GRADE_SUCCESS = '@reviewAnnotations/UPDATE_GRADE_SUCCESS',
  UPDATE_GRADE_FAILURE = '@reviewAnnotations/UPDATE_GRADE_FAILURE',

  DELETE_GRADE_REQUEST = '@reviewAnnotations/DELETE_GRADE_REQUEST',
  DELETE_GRADE_SUCCESS = '@reviewAnnotations/DELETE_GRADE_SUCCESS',
  DELETE_GRADE_FAILURE = '@reviewAnnotations/DELETE_GRADE_FAILURE',

  ADD_LIKE_REQUEST = '@reviewAnnotations/ADD_LIKE_REQUEST',
  ADD_LIKE_SUCCESS = '@reviewAnnotations/ADD_LIKE_SUCCESS',
  ADD_LIKE_FAILURE = '@reviewAnnotations/ADD_LIKE_FAILURE',

  RESET_REVIEW_ANNOTATIONS_STATE_REQUEST = '@reviewAnnotations/RESET_REVIEW_ANNOTATIONS_STATE_REQUEST',
  RESET_REVIEW_ANNOTATIONS_STATE_SUCCESS = '@reviewAnnotations/RESET_REVIEW_ANNOTATIONS_STATE_SUCCESS',
  RESET_REVIEW_ANNOTATIONS_STATE_FAILURE = '@reviewAnnotations/RESET_REVIEW_ANNOTATIONS_STATE_FAILURE',
}

//Data types
export enum DataStatus {
  added = 'added',
  removed = 'removed',
  updated = 'updated',
}

export interface Data {
  annotation: ImmutableMap<any>;
  status?: DataStatus;
}

//State type
export interface ReviewAnnotationsState
  extends ImmutableMap<{
    data: any;
    loading: boolean;
    error: boolean;
  }> {}

//Create actions
export const createGradeReviewAnnotationRequest = (
  data: CreateGradeReviewAnnotationData,
) => action(ReviewAnnotationsTypes.CREATE_GRADE_REQUEST, data);

export const createGradeReviewAnnotationSuccess = (data: any) =>
  action(ReviewAnnotationsTypes.CREATE_GRADE_SUCCESS, data);

export const createGradeReviewAnnotationFailure = () =>
  action(ReviewAnnotationsTypes.CREATE_GRADE_FAILURE);

//Update actions
export const updateGradeReviewAnnotationRequest = (data: any) =>
  action(ReviewAnnotationsTypes.UPDATE_GRADE_REQUEST, data);

export const updateGradeReviewAnnotationSuccess = (data: any) =>
  action(ReviewAnnotationsTypes.UPDATE_GRADE_SUCCESS, {data});

export const updateGradeReviewAnnotationFailure = () =>
  action(ReviewAnnotationsTypes.UPDATE_GRADE_FAILURE);

export const addLikeInReviewAnnotationRequest = (params?: Utils.GetParams) =>
  action(ReviewAnnotationsTypes.ADD_LIKE_REQUEST, params);

export const addLikeInReviewAnnotationSuccess = (data: any) =>
  action(ReviewAnnotationsTypes.ADD_LIKE_SUCCESS, {data});

export const addLikeInReviewAnnotationFailure = () =>
  action(ReviewAnnotationsTypes.ADD_LIKE_FAILURE);

//Delete actions
export const deleteGradeReviewAnnotationRequest = (data: any) =>
  action(ReviewAnnotationsTypes.DELETE_GRADE_REQUEST, data);

export const deleteGradeReviewAnnotationSuccess = (data: any) =>
  action(ReviewAnnotationsTypes.DELETE_GRADE_SUCCESS, data);

export const deleteGradeReviewAnnotationFailure = () =>
  action(ReviewAnnotationsTypes.DELETE_GRADE_FAILURE);

//reset state actions
export const resetReviewAnnotationRequest = () =>
  action(ReviewAnnotationsTypes.RESET_REVIEW_ANNOTATIONS_STATE_REQUEST);

export const resetReviewAnnotationSuccess = () =>
  action(ReviewAnnotationsTypes.RESET_REVIEW_ANNOTATIONS_STATE_SUCCESS);

export const resetReviewAnnotationFailure = () =>
  action(ReviewAnnotationsTypes.RESET_REVIEW_ANNOTATIONS_STATE_FAILURE);

//Sagas
export function* createReviewGradeReviewAnnotation(action: AnyAction) {
  try {
    const response = yield call(
      createGradeReviewAnnotationService,
      action.payload,
    );
    //this is used to update html markup on EditorState
    const annotation_type = action.payload.annotation_type;
    yield put(
      createGradeReviewAnnotationSuccess({...response.data, annotation_type}),
    );
  } catch (err) {
    yield put(createGradeReviewAnnotationFailure());
  }
}

export function* updateReviewGradeReviewAnnotation(action: AnyAction) {
  try {
    const response = yield call(
      updateGradeReviewAnnotationService,
      action.payload,
    );
    yield put(updateGradeReviewAnnotationSuccess(response.data));
  } catch (err) {
    yield put(updateGradeReviewAnnotationFailure());
  }
}

export function* resetReviewAnnotations() {
  try {
    yield put(resetReviewAnnotationSuccess());
  } catch (err) {
    yield put(resetReviewAnnotationFailure());
  }
}

export function* deleteReviewGradeReviewAnnotation(action: AnyAction) {
  try {
    const response = yield call(
      deleteGradeReviewAnnotationService,
      action.payload,
    );
    yield put(
      deleteGradeReviewAnnotationSuccess({...action.payload, ...response}),
    );
  } catch (err) {
    yield put(deleteGradeReviewAnnotationFailure());
  }
}

export function* addLikeInReviewAnnotation(action: AnyAction) {
  try {
    const response = yield call(
      addLikeInReviewAnnotationService,
      action.payload,
    );
    yield put(addLikeInReviewAnnotationSuccess(response.data));
  } catch (err) {
    yield put(addLikeInReviewAnnotationFailure());
  }
}

//Selectors

const reviewAnnotationsSelector = (state: ApplicationState) =>
  state.get('reviewAnnotations');

export const getReviewAnnotation = createSelector(
  reviewAnnotationsSelector,
  (reviewAnnotation) => reviewAnnotation.getIn(['data', 'annotation']),
);

export const getReviewAnnotationStatus = createSelector(
  reviewAnnotationsSelector,
  (reviewAnnotation) => reviewAnnotation.getIn(['data', 'status']),
);

export const isLoadingReviewAnnotation = createSelector(
  reviewAnnotationsSelector,
  (reviewAnnotation) => reviewAnnotation.get('loading'),
);

//Initial state
export const INITIAL_STATE: ReviewAnnotationsState = fromJS({
  data: fromJS({
    annotation: {},
    status: undefined,
  }),
  error: false,
  loading: false,
});

//Reducer
export const reducer: Reducer<ReviewAnnotationsState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case ReviewAnnotationsTypes.CREATE_GRADE_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('loading', true).setIn(['data', 'status'], undefined),
      );

    case ReviewAnnotationsTypes.CREATE_GRADE_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .setIn(['data', 'status'], DataStatus.added)
          .setIn(['data', 'annotation'], fromJS(action.payload)),
      );

    case ReviewAnnotationsTypes.CREATE_GRADE_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );

    case ReviewAnnotationsTypes.UPDATE_GRADE_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('loading', true).setIn(['data', 'status'], undefined),
      );

    case ReviewAnnotationsTypes.UPDATE_GRADE_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .setIn(['data', 'status'], DataStatus.updated)
          .setIn(['data', 'annotation'], fromJS(action.payload)),
      );

    case ReviewAnnotationsTypes.UPDATE_GRADE_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );

    case ReviewAnnotationsTypes.DELETE_GRADE_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('loading', true).setIn(['data', 'status'], undefined),
      );

    case ReviewAnnotationsTypes.DELETE_GRADE_SUCCESS: {
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .setIn(['data', 'status'], DataStatus.removed)
          .setIn(['data', 'annotation'], fromJS(action.payload)),
      );
    }

    case ReviewAnnotationsTypes.DELETE_GRADE_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );

    case ReviewAnnotationsTypes.ADD_LIKE_REQUEST:
      return state.withMutations((prevState) => prevState.set('loading', true));

    case ReviewAnnotationsTypes.ADD_LIKE_SUCCESS:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', false),
      );

    case ReviewAnnotationsTypes.ADD_LIKE_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );

    case ReviewAnnotationsTypes.RESET_REVIEW_ANNOTATIONS_STATE_REQUEST: {
      return state.withMutations((prevState) => prevState.set('loading', true));
    }

    case ReviewAnnotationsTypes.RESET_REVIEW_ANNOTATIONS_STATE_SUCCESS: {
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .setIn(['data', 'status'], undefined)
          .setIn(['data', 'annotation'], fromJS({})),
      );
    }

    case ReviewAnnotationsTypes.RESET_REVIEW_ANNOTATIONS_STATE_FAILURE: {
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );
    }
    default:
      return state;
  }
};

export default reducer;
