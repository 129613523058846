import {LetrusApi} from '@letrustech/letrus-api-interfaces';
import ModalInfo from 'components/ModalInfo';
import WarningAboutStudent from 'components/WarningAboutStudent';
import {push} from 'connected-react-router';
import ZeroGradeSelectForm from 'containers/ZeroGradeSelectForm';
import dayjs from 'dayjs';
import {fromJS} from 'immutable';
import {Modal, RoundedButton, RoundedButtonGroup} from 'letrus-ui';
import {RoundedButtonProps} from 'letrus-ui/dist/components/infra/RoundedButton';
import React, {useCallback, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import ReactTooltip from 'react-tooltip';
import {fetchAbandonReviewReasonsRequest} from 'store/reducers/abandonReviewReasons';
import {
  getCompositionFromCompositionGrade,
  getCompositionGrade,
  getReviewCompositionStudent,
  isFundII,
  zeroReviewRequest,
} from 'store/reducers/compositionReviews';
import {ApplicationState} from 'store/rootReducer';
import FormAbandonReview from '../../containers/FormAbandonReview';
import {
  countTextEditorLines,
  getFullTextFromEditorState,
} from '../../utils/functions/draft';
import countWords from '../../utils/functions/text';
import styles from './ReviewerEditorWrapper.module.scss';
import StudentInfo, {AboutStudent} from './StudentInfo';

type Modals =
  | 'AbandoReviewModal'
  | 'AboutStudentModal'
  | 'ZeroGradeSelectForm'
  | 'WarningAboutStudent'
  | '';

interface TopTip {
  message?: string;
  timeout?: number;
  show: boolean;
}

export interface StateProps {
  composition: ImmutableMap<any>;
  student: ImmutableMap<any>;
  compositionGrade: ImmutableMap<LetrusApi.CompositionReview>;
  isFundII: boolean;
}

export interface DispatchProps {
  fetchAbandonReviewReasonsRequest: typeof fetchAbandonReviewReasonsRequest;
  push: typeof push;
  zeroReviewRequest: typeof zeroReviewRequest;
}

export interface OwnProps {
  compositionReviewId: number;
  canEditReview: boolean;
  isPlagiarismSelection: boolean;
  toggleIsPlagiarismSelection: () => void;
  activeEditGradeStep: number;
  activeTab: number;
  eEditorState: any;
  children: React.ReactNode;
  handleClickOpenProposal: VoidFunction;
}

export type Props = StateProps & DispatchProps & OwnProps;

const ReviewerEditorWrapper: React.FC<Props> = ({
  fetchAbandonReviewReasonsRequest,
  zeroReviewRequest,
  activeTab,
  activeEditGradeStep,
  canEditReview,
  handleClickOpenProposal,
  isPlagiarismSelection,
  children,
  student,
  compositionReviewId,
  compositionGrade,
  toggleIsPlagiarismSelection,
  push = () => {},
  isFundII,
  composition,
  eEditorState,
}) => {
  const [aboutStudent, setAboutStudent] = useState<ImmutableMap<AboutStudent>>(
    fromJS({}),
  );
  const [actionButtons, setActionButtons] = useState<Array<RoundedButtonProps>>(
    [],
  );
  const [topTip, setTopTip] = useState<TopTip>({message: '', show: false});
  const [clearTopTipTimeout, setClearTopTipTimeout] = useState(false);
  const [modal, setModal] = useState<Modals>('');
  const [isRewritingComposition, setIsRewritingComposition] = useState(
    !!composition.get('child_id'),
  );
  const [
    alreadyClosedModalAboutStudent,
    setAlreadyClosedModalAboutStudent,
  ] = useState(false);

  useEffect(() => {
    if (clearTopTipTimeout) {
      setClearTopTipTimeout(false);
      clearTimeout(topTip.timeout);
    }
  }, [clearTopTipTimeout, topTip]);

  useEffect(() => {
    if (student.count()) {
      const {
        birth,
        accessibility,
        skip_genre_review,
        skip_theme_review,
        skip_words_number_review,
      } = student.get('user_profile').toJS();
      const grade = student.get('school_grade');
      const age = birth && dayjs().diff(birth, 'year', false);

      setAboutStudent(
        fromJS({
          grade,
          accessibility,
          age,
          skip_genre_review,
          skip_theme_review,
          skip_words_number_review,
        }),
      );
    }
  }, [student]);

  const handleSetTopTip = useCallback(
    (message: string, show: boolean, time?: number) => {
      setTopTip({
        message,
        timeout: window.setTimeout(() => {
          setTopTip({show: false, message});
          setClearTopTipTimeout(true);
        }, time || 5000),
        show: show,
      });
    },
    [],
  );

  const handleCompositions = useCallback(() => {
    const parent_id = composition.get('parent_id');
    const child_id = composition.get('child_id');

    push(`/correcao/${child_id ? child_id : parent_id}`);
  }, [composition, push]);

  const onClickPlagiarism = useCallback(() => {
    toggleIsPlagiarismSelection();

    if (!isFundII) {
      const message =
        'Clique e arraste para selecionar o texto todo ou somente as partes que foram plagiadas.';

      const show = !isPlagiarismSelection ? true : false;

      handleSetTopTip(message, show);
    }
  }, [
    handleSetTopTip,
    isFundII,
    isPlagiarismSelection,
    toggleIsPlagiarismSelection,
  ]);

  useEffect(() => {
    fetchAbandonReviewReasonsRequest();
  }, [fetchAbandonReviewReasonsRequest]);

  useEffect(() => {
    if (composition.get('child_id') && !isRewritingComposition) {
      setIsRewritingComposition(true);
    } else if (!composition.get('child_id')) {
      setIsRewritingComposition(false);
    }
  }, [composition, isRewritingComposition]);

  useEffect(() => {
    const skip_genre_review = aboutStudent.get('skip_genre_review');
    const skip_theme_review = aboutStudent.get('skip_theme_review');
    const skip_words_number_review = aboutStudent.get(
      'skip_words_number_review',
    );

    const skipSomeReview =
      skip_genre_review || skip_theme_review || skip_words_number_review;

    if (skipSomeReview && !isFundII) {
      setModal('AboutStudentModal');
    }

    if (skipSomeReview && isFundII && !alreadyClosedModalAboutStudent) {
      setModal('WarningAboutStudent');
    }
  }, [aboutStudent, alreadyClosedModalAboutStudent, isFundII]);

  useEffect(() => {
    if (canEditReview) {
      const showAnnotationMessage =
        activeTab === 1 && !isPlagiarismSelection && activeEditGradeStep === 0;

      if (showAnnotationMessage && !isFundII) {
        const annotationMessage =
          'Clique e arraste para selecionar as partes do texto que você gostaria de  marcar.';

        handleSetTopTip(annotationMessage, true);
      }
    }
  }, [
    activeEditGradeStep,
    activeTab,
    canEditReview,
    handleSetTopTip,
    isFundII,
    isPlagiarismSelection,
  ]);

  useEffect(() => {
    if (isRewritingComposition) {
      const writingRewritingMessage =
        'Essa é a correção da redação feita por esse aluno, para voltar a reescrita aperte novamente o botão.';

      handleSetTopTip(writingRewritingMessage, true);
    }
  }, [handleSetTopTip, isRewritingComposition]);

  useEffect(() => {
    interface Button {
      button: RoundedButtonProps;
      show: boolean;
    }

    const parent_id = composition.get('parent_id');
    const child_id = composition.get('child_id');
    const is_parent_reviewed = composition.get('is_parent_reviewed');

    const isWritingRewritingComposition =
      (!!parent_id && is_parent_reviewed) || !!child_id;

    const buttons: Array<Button> = [
      {
        button: {
          icon: {icon: 'user'},
          onClick: () => {
            setModal('AboutStudentModal');
          },
          text: 'Aluno',
          kind: 'secondary',
        },
        show: isFundII,
      },
      {
        button: {
          icon: {icon: 'file'},
          onClick: handleClickOpenProposal,
          text: 'Proposta',
          kind: 'secondary',
        },
        show: true,
      },
      {
        button: {
          icon: {
            icon: 'clone',
          },
          onClick: handleCompositions,
          text: 'Primeira escrita',
          kind: 'secondary',
        },
        show: isWritingRewritingComposition,
      },
      {
        button: {
          icon: {icon: 'times-hexagon'},
          kind: 'danger',
          text: 'Zerar redação',
          onClick: () => setModal('ZeroGradeSelectForm'),
        },
        show: canEditReview && !isFundII,
      },
    ];

    setActionButtons(
      buttons.filter((button) => button.show).map((button) => button.button),
    );
  }, [
    composition,
    activeEditGradeStep,
    canEditReview,
    isPlagiarismSelection,
    handleCompositions,
    onClickPlagiarism,
    handleClickOpenProposal,
    isFundII,
  ]);
  const wordsNumber = () => {
    if (composition.count()) {
      return countWords(composition.get('composition_raw', ''));
    }

    return 0;
  };

  const linesNumber = () => {
    if (eEditorState) {
      const paragraphs = getFullTextFromEditorState(
        eEditorState && eEditorState.editorState,
      );
      return countTextEditorLines(paragraphs);
    }

    return 0;
  };

  const closeModal = () => {
    setModal('');
  };

  const handleZeroGradeSelectFormSubmit = (zeroGradeReasonId, comments) => {
    const reviewId = compositionGrade.get('id');

    zeroReviewRequest({zeroGradeReasonId, reviewId: parseInt(reviewId, 10)});

    closeModal();
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div
          className={`${styles.header__topTip} ${
            !topTip.show ? styles.hide : ''
          }`}
        >
          {topTip.message}
        </div>
      </div>
      <div className={styles.editor}>
        <div className={styles.editor__metaData}>
          <div className={styles.lineCounter}>
            {linesNumber()} <strong>LINHAS</strong>
          </div>
          <div className={styles.wordCounter}>
            {wordsNumber()} <strong>PALAVRAS</strong>
          </div>
        </div>
        <div className={styles.editor__data}>
          <div className={styles.editor__data__actions}>
            <RoundedButtonGroup buttonsProps={actionButtons} />
            {composition.get('parent_id') &&
              !composition.get('is_parent_reviewed') && (
                <span data-tip="A primeira escrita dessa redação não possui correção, apenas análise feita pela IA, portanto, você não conseguirá acessá-la">
                  <RoundedButton
                    icon={{icon: 'clone'}}
                    disabled
                    text="Escrita"
                    onClick={() => {}}
                  />
                  <ReactTooltip place="top" type="dark" effect="solid" />
                </span>
              )}
          </div>
          <div className={styles.editor__text}>{children}</div>
        </div>
      </div>
      <div className={styles.modals}>
        {modal === 'AbandoReviewModal' && (
          <ModalInfo
            component={
              <FormAbandonReview
                onCancelClick={closeModal}
                compositionReviewId={compositionReviewId}
              />
            }
            visible
            onCloseClick={closeModal}
          />
        )}
        {modal === 'AboutStudentModal' && (
          <ModalInfo
            component={<StudentInfo aboutStudent={aboutStudent} />}
            visible
            onCloseClick={closeModal}
          />
        )}
        {modal === 'ZeroGradeSelectForm' && (
          <ModalInfo
            onCloseClick={closeModal}
            visible={true}
            component={
              <ZeroGradeSelectForm
                handleSubmit={handleZeroGradeSelectFormSubmit}
              />
            }
          />
        )}
        <Modal
          onClose={() => {
            closeModal();
            setAlreadyClosedModalAboutStudent(true);
          }}
          isOpen={modal === 'WarningAboutStudent' && isFundII}
          clickOutsideToClose
          buttonClose={false}
          children={
            <WarningAboutStudent
              aboutStudent={aboutStudent}
              handleCloseWarning={() => {
                closeModal();
                setAlreadyClosedModalAboutStudent(true);
              }}
            />
          }
        />
      </div>
    </div>
  );
};

export default connect<StateProps, DispatchProps, OwnProps, ApplicationState>(
  (state: ApplicationState) => ({
    composition: getCompositionFromCompositionGrade(state),
    student: getReviewCompositionStudent(state),
    compositionGrade: getCompositionGrade(state),
    isFundII: isFundII(state),
  }),
  {
    fetchAbandonReviewReasonsRequest,
    zeroReviewRequest,
    push,
  },
)(ReviewerEditorWrapper);
