import {all, takeEvery, takeLatest} from 'redux-saga/effects';
import {
  AbandonReviewReasonsTypes,
  fetchAbandonReviewReasons,
} from './reducers/abandonReviewReasons';
import {
  AuthenticationTypes,
  fetchCurrentUser,
  loginUser,
  logoutUser,
  resetPassword,
  restorePassword,
  updateCurrentUser,
} from './reducers/authentication';
import {
  CompositionGradesTypes,
  fetchCompositionGrades,
} from './reducers/compositionGrades';
import {
  abandon,
  CompositionReviewsTypes,
  createOrUpdateCompetenceGrade,
  createOrUpdateSubCompetenceGrade,
  fetchCompositionReviewByCompositionId,
  fetchFinishedGrades,
  fetchInProgressGrades,
  finishReview,
  updateCompositionGrade,
  zeroReview,
} from './reducers/compositionReviews';
import {
  CompositionsTypes,
  createCompositionReview,
  resetCompositionReviewState,
} from './reducers/compositions';
import {FeatureFlagsTypes, fetchFeatureFlag} from './reducers/featureFlags';
import {
  addLikeInGeneralAnnotation,
  createGeneralGradeGeneralAnnotation,
  deleteGeneralGradeGeneralAnnotation,
  GeneralAnnotationTypes,
  resetGeneralAnnotationState,
  updateGeneralGradeGeneralAnnotation,
} from './reducers/generalAnnotations';
import {
  fetchServerStatus,
  fetchTerms,
  GlobalConfigurationTypes,
} from './reducers/globalConfiguration';
import {
  getOrCreateSubAccount,
  IuguGetOrCreateSubAccountTypes,
} from './reducers/iuguGetOrCreateSubAccount';
import {
  fetchInProgressPayments,
  IuguReviewerFinancialAccountsTypes,
} from './reducers/iuguReviewerFinancialAccounts';
import {
  confirmPayReviews,
  fetchPayments,
  IuguReviewerTranfersTypes,
} from './reducers/iuguReviewerTransfers';
import {
  disableAndCreateSubAccount,
  disableSubAccount,
  fetchBankInformation,
  IuguSubAccountsTypes,
  verifySubAccount,
} from './reducers/iuguSubAccounts';
import {
  createPresignedUrl,
  createPresignedUrl1,
  deletePresignedUrl,
  fetchPresignedUrl,
  PresignedUrlTypes,
  updatePresignedUrl,
} from './reducers/presignedUrl';
import {
  addLikeInReviewAnnotation,
  createReviewGradeReviewAnnotation,
  deleteReviewGradeReviewAnnotation,
  resetReviewAnnotations,
  ReviewAnnotationsTypes,
  updateReviewGradeReviewAnnotation,
} from './reducers/reviewAnnotations';
import {
  reviewerAvailableCompositions,
  ReviewerCompositionsTypes,
  verifyProgressGradesLimit,
} from './reducers/reviewerCompositions';
import {S3Types, uploadToS3} from './reducers/s3';
import {sendUserOpinionForSurveys, SurveysTypes} from './reducers/surveys';
import {updateProfile, UserProfileTypes} from './reducers/userProfile';
import {
  fetchZeroGradeComment,
  ZeroGradeCommentTypes,
} from './reducers/zeroGradeComment';

export default function* rootSaga() {
  yield all([
    takeLatest(GlobalConfigurationTypes.FETCH_TERMS_REQUEST, fetchTerms),
    takeLatest(
      GlobalConfigurationTypes.FETCH_SERVER_STATUS_REQUEST,
      fetchServerStatus,
    ),
    takeLatest(AuthenticationTypes.LOGIN_REQUEST, loginUser),
    takeLatest(AuthenticationTypes.LOGOUT_REQUEST, logoutUser),
    takeLatest(AuthenticationTypes.UPDATE_REQUEST, updateCurrentUser),
    takeLatest(AuthenticationTypes.RESET_PASSWORD_REQUEST, resetPassword),
    takeLatest(AuthenticationTypes.RESTORE_PASSWORD_REQUEST, restorePassword),
    takeLatest(
      ReviewerCompositionsTypes.AVAILABLE_COMPOSITIONS_REQUEST,
      reviewerAvailableCompositions,
    ),
    takeEvery(FeatureFlagsTypes.FETCH_REQUEST, fetchFeatureFlag),
    takeLatest(
      ReviewerCompositionsTypes.VERIFY_PROGRESS_GRADES_LIMIT_REQUEST,
      verifyProgressGradesLimit,
    ),
    takeLatest(
      PresignedUrlTypes.FETCH_PRESIGNED_URL_REQUEST,
      fetchPresignedUrl,
    ),
    takeLatest(
      PresignedUrlTypes.CREATE_PRESIGNED_URL_REQUEST,
      createPresignedUrl,
    ),
    takeLatest(
      PresignedUrlTypes.CREATE_PRESIGNED_URL_REQUEST1,
      createPresignedUrl1,
    ),
    takeLatest(
      PresignedUrlTypes.UPDATE_PRESIGNED_URL_REQUEST,
      updatePresignedUrl,
    ),
    takeLatest(
      PresignedUrlTypes.DELETE_PRESIGNED_URL_REQUEST,
      deletePresignedUrl,
    ),
    takeLatest(
      CompositionGradesTypes.FETCH_COMPOSITION_GRADES_REQUEST,
      fetchCompositionGrades,
    ),
    takeLatest(
      ZeroGradeCommentTypes.FETCH_ZERO_GRADE_COMMENT_REQUEST,
      fetchZeroGradeComment,
    ),
    takeLatest(
      AbandonReviewReasonsTypes.FETCH_ABANDON_REVIEW_REASONS_REQUEST,
      fetchAbandonReviewReasons,
    ),
    takeLatest(
      ReviewAnnotationsTypes.ADD_LIKE_REQUEST,
      addLikeInReviewAnnotation,
    ),
    takeLatest(
      ReviewAnnotationsTypes.UPDATE_GRADE_REQUEST,
      updateReviewGradeReviewAnnotation,
    ),
    takeLatest(
      ReviewAnnotationsTypes.DELETE_GRADE_REQUEST,
      deleteReviewGradeReviewAnnotation,
    ),
    takeLatest(
      ReviewAnnotationsTypes.CREATE_GRADE_REQUEST,
      createReviewGradeReviewAnnotation,
    ),
    takeLatest(
      CompositionsTypes.RESET_COMPOSITION_REVIEW_STATE_REQUEST,
      resetCompositionReviewState,
    ),
    takeLatest(
      ReviewAnnotationsTypes.RESET_REVIEW_ANNOTATIONS_STATE_REQUEST,
      resetReviewAnnotations,
    ),
    takeLatest(
      GeneralAnnotationTypes.RESET_GENERAL_ANNOTATION_STATE_REQUEST,
      resetGeneralAnnotationState,
    ),
    takeLatest(
      GeneralAnnotationTypes.ADD_LIKE_REQUEST,
      addLikeInReviewAnnotation,
    ),
    takeLatest(
      GeneralAnnotationTypes.UPDATE_GRADE_REQUEST,
      updateGeneralGradeGeneralAnnotation,
    ),
    takeLatest(
      GeneralAnnotationTypes.DELETE_GRADE_REQUEST,
      deleteGeneralGradeGeneralAnnotation,
    ),
    takeLatest(
      GeneralAnnotationTypes.CREATE_GRADE_REQUEST,
      createGeneralGradeGeneralAnnotation,
    ),
    takeLatest(
      GeneralAnnotationTypes.ADD_LIKE_REQUEST,
      addLikeInGeneralAnnotation,
    ),
    takeLatest(CompositionReviewsTypes.ZERO_REQUEST, zeroReview),
    takeLatest(
      CompositionReviewsTypes.FETCH_IN_PROGRESS_GRADES_REQUEST,
      fetchInProgressGrades,
    ),
    takeLatest(
      CompositionReviewsTypes.FETCH_FINISHED_GRADES_REQUEST,
      fetchFinishedGrades,
    ),
    takeLatest(
      CompositionReviewsTypes.FETCH_BY_COMPOSITION_ID_REQUEST,
      fetchCompositionReviewByCompositionId,
    ),
    takeLatest(
      CompositionReviewsTypes.UPDATE_COMPOSITION_GRADE_REQUEST,
      updateCompositionGrade,
    ),
    takeLatest(CompositionReviewsTypes.ABANDON_REQUEST, abandon),
    takeLatest(
      CompositionReviewsTypes.CREATE_OR_UPDATE_COMPETENCE_GRADE_REQUEST,
      createOrUpdateCompetenceGrade,
    ),
    takeLatest(
      CompositionReviewsTypes.CREATE_OR_UPDATE_SUB_COMPETENCE_GRADE_REQUEST,
      createOrUpdateSubCompetenceGrade,
    ),
    takeLatest(CompositionReviewsTypes.FINISH_REVIEW_REQUEST, finishReview),
    takeLatest(
      IuguReviewerTranfersTypes.CONFIRM_PAY_REVIEWS_REQUEST,
      confirmPayReviews,
    ),
    takeLatest(IuguReviewerTranfersTypes.FETCH_PAYMENTS_REQUEST, fetchPayments),
    takeLatest(
      IuguReviewerFinancialAccountsTypes.FETCH_IN_PROGRESS_PAYMENTS_REQUEST,
      fetchInProgressPayments,
    ),
    takeLatest(AuthenticationTypes.FETCH_REQUEST, fetchCurrentUser),
    takeLatest(
      CompositionsTypes.CREATE_COMPOSITION_REVIEW_REQUEST,
      createCompositionReview,
    ),
    takeLatest(S3Types.UPLOAD_REQUEST, uploadToS3),
    takeLatest(
      IuguSubAccountsTypes.FETCH_BANK_INFORMATION_REQUEST,
      fetchBankInformation,
    ),
    takeLatest(
      IuguGetOrCreateSubAccountTypes.GET_OR_CREATE_REQUEST,
      getOrCreateSubAccount,
    ),
    takeLatest(IuguSubAccountsTypes.DISABLE_REQUEST, disableSubAccount),
    takeLatest(IuguSubAccountsTypes.VERIFY_REQUEST, verifySubAccount),
    takeLatest(
      IuguSubAccountsTypes.DISABLE_AND_CREATE_REQUEST,
      disableAndCreateSubAccount,
    ),
    takeLatest(UserProfileTypes.UPDATE_PROFILE_REQUEST, updateProfile),
    takeLatest(
      SurveysTypes.SEND_USER_OPINION_FOR_SURVEYS_REQUEST,
      sendUserOpinionForSurveys,
    ),
  ]);
}
