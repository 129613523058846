import {Utils} from '@letrustech/letrus-api-interfaces';
import api from 'store/services/api';

export const fetchPresignedUrlService = (
  params: Utils.GetParams & {content: string},
) =>
  api('/api/v1/presigned_url/', {
    params,
  });

export const createPresignedUrlService = (requestBody: any) =>
  api('/api/v1/presigned_url/create_presigned_url/', {
    data: requestBody,
    method: 'post',
  });

export const updatePresignedUrlService = (request: {id: string; body: any}) =>
  api(`/api/v1/presigned_url/${request.id}`, {
    data: request.body,
    method: 'put',
  });

export const deletePresignedUrlService = (id: string) =>
  api(`/api/v1/presigned_url/${id}`, {
    method: 'delete',
  });
