import {Utils} from '@letrustech/letrus-api-interfaces';
import {fromJS, List, Map} from 'immutable';
import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {
  reviewerAvailableCompositionsService,
  reviewerVerifyProgressGradesLimitService,
} from 'store/services/reviewerCompositionService';
import {action} from 'typesafe-actions';

//Action types
export enum ReviewerCompositionsTypes {
  AVAILABLE_COMPOSITIONS_REQUEST = '@reviewer_compositions/AVAILABLE_COMPOSITIONS_REQUEST',
  AVAILABLE_COMPOSITIONS_SUCCESS = '@reviewer_compositions/AVAILABLE_COMPOSITIONS_SUCCESS',
  AVAILABLE_COMPOSITIONS_FAILURE = '@reviewer_compositions/AVAILABLE_COMPOSITIONS_FAILURE',

  VERIFY_PROGRESS_GRADES_LIMIT_REQUEST = '@reviewer_compositions/VERIFY_PROGRESS_GRADES_LIMIT_REQUEST',
  VERIFY_PROGRESS_GRADES_LIMIT_SUCCESS = '@reviewer_compositions/VERIFY_PROGRESS_GRADES_LIMIT_SUCCESS',
  VERIFY_PROGRESS_GRADES_LIMIT_FAILURE = '@reviewer_compositions/VERIFY_PROGRESS_GRADES_LIMIT_FAILURE',
}

//Data types
export interface ReviewerCompositions {
  available: Map<string, any>;
  hasInProgressGrades: boolean;
}

//State type
export interface ReviewerCompositionsState
  extends ImmutableMap<{
    data: List<ReviewerCompositions>;
    num_available_compositions: number;
    last_updated: string;
    loading: boolean;
    error: boolean;
  }> {}

//Available Compositions Actions
export const reviewerAvailableCompositionsRequest = (
  data: Utils.GetParams & {minimal?: number} = {
    limit: 10,
    ordering: 'revision_deadline',
    minimal: 1,
  },
) => action(ReviewerCompositionsTypes.AVAILABLE_COMPOSITIONS_REQUEST, data);

export const reviewerAvailableCompositionsSuccess = (data: any) =>
  action(ReviewerCompositionsTypes.AVAILABLE_COMPOSITIONS_SUCCESS, {
    data,
  });

export const reviewerAvailableCompositionsFailure = () =>
  action(ReviewerCompositionsTypes.AVAILABLE_COMPOSITIONS_FAILURE);

//Verify Progress Grades Limit Actions
export const verifyProgressGradesLimitRequest = () =>
  action(ReviewerCompositionsTypes.VERIFY_PROGRESS_GRADES_LIMIT_REQUEST);

export const verifyProgressGradesLimitSuccess = () =>
  action(ReviewerCompositionsTypes.VERIFY_PROGRESS_GRADES_LIMIT_SUCCESS);

export const verifyProgressGradesLimitFailure = () =>
  action(ReviewerCompositionsTypes.VERIFY_PROGRESS_GRADES_LIMIT_FAILURE);

//Sagas
export function* reviewerAvailableCompositions(action: AnyAction) {
  try {
    const response = yield call(
      reviewerAvailableCompositionsService,
      action.payload,
    );
    yield put(reviewerAvailableCompositionsSuccess(response.data));
  } catch (error) {
    yield put(reviewerAvailableCompositionsFailure());
  }
}

export function* verifyProgressGradesLimit(action: AnyAction) {
  try {
    yield call(reviewerVerifyProgressGradesLimitService);
    yield put(verifyProgressGradesLimitSuccess());
  } catch (error) {
    yield put(verifyProgressGradesLimitFailure());
  }
}

//Initial state
export const INITIAL_STATE: ReviewerCompositionsState = fromJS({
  data: fromJS({
    available: [],
    hasInProgressGrades: false,
  }),
  loading: false,
  error: false,
});

//Selectors
const compositionsSelector = (state: ApplicationState) =>
  state.get('reviewerCompositions');

export const getAvailableCompositions = createSelector(
  compositionsSelector,
  (compositions) => compositions.getIn(['data', 'available']),
);

export const getAvailableCompositionsCount = createSelector(
  compositionsSelector,
  (compositions) => compositions.get('num_available_compositions'),
);

export const getAvailableCompositionsCountLastUpdated = createSelector(
  compositionsSelector,
  (compositions) => compositions.get('last_updated'),
);

export const isLoadingReviewerCompositions = createSelector(
  compositionsSelector,
  (compositions: ReviewerCompositionsState) => compositions.get('loading'),
);

export const hasInProgressGradesCompositions = createSelector(
  compositionsSelector,
  (compositions) => compositions.getIn(['data', 'hasInProgressGrades']),
);

//Reducer
const reducer: Reducer<ReviewerCompositionsState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case ReviewerCompositionsTypes.AVAILABLE_COMPOSITIONS_REQUEST: {
      return state.withMutations((prevState) => prevState.set('loading', true));
    }

    case ReviewerCompositionsTypes.AVAILABLE_COMPOSITIONS_SUCCESS: {
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .setIn(['data', 'available'], fromJS(action.payload.data.results))
          .set(
            'num_available_compositions',
            action.payload.data.num_available_compositions,
          )
          .set('last_updated', action.payload.data.last_updated),
      );
    }

    case ReviewerCompositionsTypes.AVAILABLE_COMPOSITIONS_FAILURE: {
      return state.withMutations((prevState) =>
        prevState.set('error', true).set('loading', false),
      );
    }

    case ReviewerCompositionsTypes.VERIFY_PROGRESS_GRADES_LIMIT_REQUEST: {
      return state.withMutations((prevState) => prevState.set('loading', true));
    }

    case ReviewerCompositionsTypes.VERIFY_PROGRESS_GRADES_LIMIT_SUCCESS: {
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .setIn(['data', 'hasInProgressGrades'], false),
      );
    }

    case ReviewerCompositionsTypes.VERIFY_PROGRESS_GRADES_LIMIT_FAILURE: {
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .setIn(['data', 'hasInProgressGrades'], true),
      );
    }

    default: {
      return state;
    }
  }
};

export default reducer;
