export const validateLink = (values: ImmutableMap<{link: string}>) => {
  const errors: any = {};

  if (values.get('link')) {
    const link = String(values.get('link'));
    const url = new RegExp(
      '^(https?://)?(www\\.)?([-a-z0-9]{1,63}\\.)*?[a-z0-9][-a-z0-9]{0,61}[a-z0-9]\\.[a-z]{2,6}(/[-\\w@\\+\\.~#\\?&/=%]*)?$',
    );

    if (!url.test(link)) {
      errors.link = 'Link inválido!';
    }
  } else {
    errors.link = 'Campo Obrigatório!';
  }

  return errors;
};
