//TODO: replace redux-form to react-hooks-form
import {LetrusApi} from '@letrustech/letrus-api-interfaces';
import {convertToRaw} from 'draft-js';
import {Button} from 'letrus-ui';
import {createEditorState} from 'medium-draft';
import mediumDraftExporter from 'medium-draft/lib/exporter';
import mediumDraftImporter from 'medium-draft/lib/importer';
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {InjectedFormProps} from 'redux-form';
import {Field, reduxForm} from 'redux-form/immutable';
import {
  createOrUpdateCompetenceGradeRequest,
  getCompositionGrade,
  isFundII,
} from 'store/reducers/compositionReviews';
import {ApplicationState} from 'store/rootReducer';
import SimpleMediumEditor from '../../components/SimpleMediumEditor';
import Spinner from '../../components/Spinner';
import {Competence, GradeItem} from '../../utils/interfaces/competence';
import styles from './CompetenceGradeSelectForm.module.scss';

export interface IReview {
  id: number;
  grade_item: GradeItem;
  comments: string;
  automatic: boolean;
  like_info?: any;
  created: string;
  modified: string;
  competence: number;
  review: number;
  last_modified_by?: any;
}

interface StateProps {
  isFundII?: boolean;
  compositionGrade: ImmutableMap<LetrusApi.CompositionReview>;
}

interface DispatchProps {
  createOrUpdateCompetenceGradeRequest: typeof createOrUpdateCompetenceGradeRequest;
}

interface OwnProps {
  competence: Competence;
  review?: IReview;
  resetForm?: boolean;
  onCloseForm: CallableFunction;
  editable?: boolean;
}

type Props = OwnProps & DispatchProps & StateProps;
type InjectedProps = Props & InjectedFormProps<{}, Props>;

const importToEditorstate = (text: string) => {
  return createEditorState(convertToRaw(mediumDraftImporter(text)));
};

const CompetenceGradeSelectForm: React.FC<InjectedProps> = ({
  competence,
  review,
  isFundII,
  handleSubmit,
  change,
  pristine,
  resetForm = false,
  reset,
  onCloseForm,
  editable = true,
  createOrUpdateCompetenceGradeRequest,
  compositionGrade,
}) => {
  const [isLoading, setIsloading] = useState(true);
  const [reviewComment, setReviewComment] = useState('');
  const [reviewPoints, setreviewPoints] = useState(isFundII ? 1 : 0);
  const [commentEditorState, setCommentEditorState] = useState(
    importToEditorstate(''),
  );
  const [gradePosition, setGradePosition] = useState(0);
  const [bodyHeight, setBodyHeight] = useState(0);

  useEffect(() => {
    if (review && pristine) {
      change('competenceGrade', review?.grade_item?.points);
      setCommentEditorState(importToEditorstate(review.comments || ''));
      setreviewPoints(review?.grade_item?.points);
    } else if (review && reviewComment !== review.comments) {
      setReviewComment(review.comments);
      setCommentEditorState(importToEditorstate(review.comments || ''));
      setreviewPoints(review?.grade_item?.points);
    } else if (!review) {
      change('competenceGrade', 0);
      setCommentEditorState(importToEditorstate(''));
    }

    setIsloading(false);
  }, [change, pristine, review, reviewComment]);

  useEffect(() => {
    setIsloading(false);
  }, [competence]);

  useEffect(() => {
    if (resetForm) {
      reset();
      if (isFundII) {
        setreviewPoints(1);
      } else {
        setreviewPoints(0);
      }
      setCommentEditorState(importToEditorstate(''));
    }
  }, [isFundII, reset, resetForm]);

  useEffect(() => {
    const inputRange: any = window.document.getElementsByClassName(
      styles.inputCompetenceGrade,
    )[0];

    let {max, step, offsetWidth, valueAsNumber} = inputRange || {};

    if (isFundII) {
      max = max - 1;
      valueAsNumber = valueAsNumber - 1;
    }

    const divGrade: any = window.document.getElementsByClassName(
      styles.inputProgressbar__grade,
    )[0];
    const divGradeOffsetWidth = divGrade?.offsetWidth;

    const numberSteps = max / step;
    const currentStep = valueAsNumber / step;
    const widthGradeSteps = (offsetWidth - divGradeOffsetWidth) / numberSteps;
    const relativeGradePosition = Number(
      (widthGradeSteps * currentStep).toFixed(0),
    );

    if (gradePosition !== relativeGradePosition) {
      setGradePosition(relativeGradePosition);
    }

    const bodyElement: any = window.document.getElementsByClassName(
      styles.body,
    )[0];
    const {offsetHeight} = bodyElement || {};

    setBodyHeight(offsetHeight);
  }, [isFundII, gradePosition]);

  const handleChangeComment = (commentEditorState: any) => {
    setCommentEditorState(commentEditorState);
  };

  const callOnGradeChange = (gradePoints: number) => {
    const grade = competence.grade_items.find((grade) => {
      return grade.points === gradePoints;
    });
    const comments = mediumDraftExporter(
      commentEditorState.getCurrentContent(),
    );

    createOrUpdateCompetenceGradeRequest({
      comments,
      competence_id: competence.id,
      review_id: parseInt(compositionGrade.get('id'), 10),
      grade_item_id: grade?.id,
    });
    setIsloading(true);
    setreviewPoints(gradePoints);
  };

  const handleChangeGrade = (event: React.ChangeEvent<any>) => {
    const gradePoints = Number(event.target.value);

    callOnGradeChange(gradePoints);
  };

  const handleSubmitGradeSelectForm = () => {
    callOnGradeChange(reviewPoints);
    onCloseForm();
  };

  const renderCompetenceName = () => {
    const competenceName = competence.name;

    return isFundII
      ? competenceName.replace('Competência', 'Critério')
      : competenceName;
  };

  return (
    <div className={styles.container}>
      {competence && (
        <form
          className={styles.form}
          onSubmit={handleSubmit(handleSubmitGradeSelectForm)}
        >
          <fieldset>
            <legend
              className={`${styles.header} ${
                bodyHeight === 360 ? styles.scroll : ''
              }`}
            >
              <h1>{renderCompetenceName()}</h1>
              <h2>{competence.description}</h2>
            </legend>

            <div className={styles.body}>
              <div className={styles['body__grade']}>
                <label htmlFor="competenceGradeId">
                  Atribuir {isFundII ? 'Nível' : 'Nota'}
                </label>
                <div className={styles['label-tip']}>
                  Arraste a bolinha para editar{' '}
                  {isFundII ? 'o nível do critério' : 'a nota da competência'}
                </div>
                <Field
                  className={`${styles.inputCompetenceGrade} ${
                    editable ? styles.editable : ''
                  }`}
                  component="input"
                  type="range"
                  min={isFundII ? 1 : 0}
                  max={competence.max_value_range}
                  name="competenceGrade"
                  id="competenceGradeId"
                  step={
                    isFundII
                      ? competence.max_value_range /
                        competence.grade_items.length
                      : competence.max_value_range /
                        (competence.grade_items.length - 1)
                  }
                  onChange={handleChangeGrade}
                  disabled={isLoading || !editable}
                  props={{value: reviewPoints}}
                />
                <div className={styles.inputProgressbar}>
                  <div
                    className={styles.inputProgressbar__grade}
                    style={{
                      left: `${gradePosition}px`,
                    }}
                  >
                    {Number(reviewPoints)}
                  </div>
                  {isFundII ? (
                    <>
                      <div
                        className={styles.inputProgressbar__after}
                        style={{
                          width: `${
                            ((reviewPoints - 1) * 100) /
                            (competence.max_value_range - 1)
                          }%`,
                        }}
                      ></div>
                      <div
                        className={styles.inputProgressbar__before}
                        style={{
                          width: `${
                            ((competence.max_value_range -
                              1 -
                              (reviewPoints - 1)) *
                              100) /
                            (competence.max_value_range - 1)
                          }%`,
                        }}
                      ></div>
                    </>
                  ) : (
                    <>
                      <div
                        className={styles.inputProgressbar__after}
                        style={{
                          width: `${
                            (reviewPoints * 100) / competence.max_value_range
                          }%`,
                        }}
                      ></div>
                      <div
                        className={styles.inputProgressbar__before}
                        style={{
                          width: `${
                            ((competence.max_value_range - reviewPoints) *
                              100) /
                            competence.max_value_range
                          }%`,
                        }}
                      ></div>
                    </>
                  )}
                </div>

                <div>{review?.grade_item?.description || ''}</div>
              </div>
              <div className={styles.body__comment}>
                <label htmlFor="competenceGradeComment">Comentário</label>
                {editable ? (
                  <Field
                    component={SimpleMediumEditor}
                    type="text"
                    name="competenceGradeComment"
                    id="competenceGradeComment"
                    editorState={commentEditorState}
                    placeholder={
                      isFundII
                        ? 'Deixe aqui um comentário para o aluno...'
                        : 'Não há nenhum comentário automático para este nível'
                    }
                    onChange={handleChangeComment}
                  />
                ) : (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: mediumDraftExporter(
                        commentEditorState.getCurrentContent(),
                      ),
                    }}
                  ></div>
                )}
              </div>
              <div
                className={`${styles.body__load} ${
                  isLoading ? styles.loading : ''
                }`}
              >
                <Spinner size={50} />
              </div>
            </div>
            <div
              className={`${styles.footer} ${
                bodyHeight === 360 ? styles.scroll : ''
              }`}
            >
              {editable && (
                <Button
                  kind="primary"
                  size="large"
                  type="submit"
                  userRole="reviewer"
                  isLoading={isLoading}
                >
                  Salvar
                </Button>
              )}
            </div>
          </fieldset>
        </form>
      )}
    </div>
  );
};

export default connect<StateProps, DispatchProps, OwnProps, ApplicationState>(
  (state: ApplicationState) => ({
    compositionGrade: getCompositionGrade(state),
    isFundII: isFundII(state),
  }),
  {
    createOrUpdateCompetenceGradeRequest,
  },
)(
  reduxForm<{}, Props>({
    form: 'competenceGradeSelectForm',
  })(CompetenceGradeSelectForm),
);
