import React from 'react';
import {Link} from 'react-router-dom';
import styles from './Unauthorized.module.scss';
interface DispatchProps {}

type Props = DispatchProps;

const Unauthorized: React.FC<Props> = () => {
  return (
    <div className={styles.wrapper}>
      <h1>
        You're not authorized to access this content. Access as admin to have
        full access.
      </h1>
      <div className={styles.buttonsRow}>
        <Link to="/">Homepage</Link>
      </div>
    </div>
  );
};

export default Unauthorized;
