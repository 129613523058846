import {Modal} from 'letrus-ui';
import React from 'react';
import styles from './TermsModal.module.scss';

export interface TermsModalProps {
  visible: boolean;
  onClose: () => void;
  termsText: string | number;
}

const TermsModal: React.FC<TermsModalProps> = ({
  termsText,
  visible,
  onClose,
}) => {
  return (
    <Modal
      isOpen={visible}
      vw={40}
      maxHeight={800}
      onClose={onClose}
      clickOutsideToClose
      buttonClose
    >
      <div className={styles.termsWrapper}>
        <h3>TERMOS E CONDIÇÕES DE USO DA PLATAFORMA LETRUS</h3>
        <div dangerouslySetInnerHTML={{__html: String(termsText)}} />
      </div>
    </Modal>
  );
};

export default TermsModal;
