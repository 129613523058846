import {LetrusApi} from '@letrustech/letrus-api-interfaces';
import CompetenceGradeSelectForm, {
  IReview,
} from 'containers/CompetenceGradeSelectForm';
import {List} from 'immutable';
import {TabList} from 'letrus-ui';
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {
  getCompositionGrade,
  getCompositionGradeCompetences,
  getReviewGridName,
  isFundII,
  isMultigenre,
} from 'store/reducers/compositionReviews';
import {ApplicationState} from 'store/rootReducer';
import {Competence} from 'utils/interfaces/competence';
import ModalInfo from '../../components/ModalInfo';
import CompetenceGrades from './CompetenceGrades';
import styles from './EditGradeSidebarGrades.module.scss';

export interface StateProps {
  compositionGrade: ImmutableMap<LetrusApi.CompositionReview>;
  competences: List<ImmutableMap<LetrusApi.CompositionCompetence>>;
  isFundII: boolean;
  isMultigenre: boolean;
  compositionGradeGenre: string;
}

export interface OwnProps {
  canEditReview: boolean;
}

export type Props = OwnProps & StateProps;
export type ModalTypes =
  | 'CompetenceGradeSelectForm'
  | 'ZeroGradeSelectForm'
  | '';

const EditGradeSidebarGrades: React.FC<Props> = ({
  canEditReview,
  competences,
  compositionGrade,
  isFundII,
  isMultigenre,
  compositionGradeGenre,
}) => {
  const [selectedCompetence, setSelectedCompetence] = useState<Competence>();
  const [selectedReview, setSelectedReview] = useState<IReview>();
  const [modal, setModal] = useState<ModalTypes>();

  const firstCompetence = competences?.first<
    ImmutableMap<LetrusApi.CompositionCompetence>
  >();

  const [tabActive, setTabActive] = useState(firstCompetence.get('id'));

  const [tabActiveName, setTabActiveName] = useState('C1');

  const handleTabChange = (tabId: number) => {
    setTabActive(tabId);

    const tabName = tabsData.find((tab) => tab.id === tabId);
    if (tabName) {
      setTabActiveName(tabName.name);
    }
  };

  const competencesData = competences.toJS().map((competence) => {
    return {
      id: competence.id,
      name: competence.label,
    };
  });

  const tabsData = [
    ...competencesData,
    {
      id: 0,
      name: 'Total',
    },
  ];

  useEffect(() => {
    if (selectedCompetence) {
      const _selectedReview = compositionGrade
        .get('review_grades')
        .find((grade) => {
          return grade.get('competence') === selectedCompetence.id;
        });

      if (_selectedReview) {
        setSelectedReview(_selectedReview.toJS());
      }
    }
  }, [compositionGrade, selectedCompetence]);

  useEffect(() => {
    if (
      //process.env.NODE_ENV === 'production' &&
      document.getElementById('hs-script-loader') == null
    ) {
      const script = document.createElement('script');

      script.type = 'text/javascript';
      script.id = 'hs-script-loader';
      script.async = true;
      script.defer = true;
      script.src = '//js.hs-scripts.com/7068977.js';

      document.body.appendChild(script);
    }
  }, []);

  const handleCloseModal = () => {
    setModal('');
    setSelectedCompetence(undefined);
    setSelectedReview(undefined);
  };

  return (
    <div className={styles.container}>
      <div className={styles.tabListContainer}>
        <TabList
          data={tabsData}
          handleTabChange={handleTabChange}
          tabActive={tabActive}
          zeroheight
        />
      </div>
      <hr />
      <div className={styles.body}>
        <CompetenceGrades
          canEditReview={canEditReview}
          competences={competences}
          isFundII={isFundII}
          isMultigenre={isMultigenre}
          tabActiveName={tabActiveName}
        />
      </div>
      {modal === 'CompetenceGradeSelectForm' && selectedCompetence && (
        <ModalInfo
          onCloseClick={handleCloseModal}
          visible={true}
          component={
            <CompetenceGradeSelectForm
              competence={selectedCompetence}
              review={selectedReview}
              editable={canEditReview}
              onCloseForm={handleCloseModal}
            />
          }
        />
      )}
    </div>
  );
};

export default connect<StateProps, {}, OwnProps, ApplicationState>(
  (state: ApplicationState) => ({
    compositionGrade: getCompositionGrade(state),
    competences: getCompositionGradeCompetences(state),
    isFundII: isFundII(state),
    isMultigenre: isMultigenre(state),
    compositionGradeGenre: getReviewGridName(state),
  }),
  {},
)(EditGradeSidebarGrades);
