import {LetrusApi} from '@letrustech/letrus-api-interfaces';
import ModalInfo from 'components/ModalInfo';
import SvgIcons from 'containers/Markings/SvgIcons';
import ZeroGradeSelectForm from 'containers/ZeroGradeSelectForm';
import {Icon} from 'letrus-ui';
import React, {useState} from 'react';
import {connect} from 'react-redux';
import ReactTooltip from 'react-tooltip';
import {InjectedFormProps} from 'redux-form';
import {reduxForm} from 'redux-form/immutable';
import {
  getCompositionGrade,
  isFundII,
  updateCompositionGradeRequest,
  zeroReviewRequest,
} from 'store/reducers/compositionReviews';
import {ApplicationState} from 'store/rootReducer';
import {extractTextFromHtml} from 'utils/functions/html';
import {
  maxReviewScore,
  totalReviewLevelScore,
  totalReviewScore,
} from 'utils/functions/reviewScore';
import styles from './ConfirmGrades.module.scss';

export interface ConfirmGradesProps {
  reviewGrade?: ImmutableMap<any>;
  isFundII?: boolean;
  canEditReview: boolean;
}

interface DispatchProps {
  zeroReviewRequest: typeof zeroReviewRequest;
  updateCompositionGradeRequest: typeof updateCompositionGradeRequest;
}

interface StateProps {
  compositionGrade: ImmutableMap<LetrusApi.CompositionReview>;
}

type Props = ConfirmGradesProps & DispatchProps & StateProps;
type InjectedProps = Props & InjectedFormProps<{}, Props>;

const ConfirmGrades: React.FC<InjectedProps> = ({
  isFundII,
  canEditReview,
  updateCompositionGradeRequest,
  zeroReviewRequest,
  compositionGrade,
}) => {
  const [
    isZeroGradeSelectFormModalOpen,
    setIsZeroGradeSelectFormModalOpen,
  ] = useState<boolean>(false);

  const [reviewGeneralComment, setReviewGeneralComment] = useState(
    compositionGrade?.get('general_comments'),
  );

  const totalScore = isFundII
    ? totalReviewLevelScore(compositionGrade)
    : totalReviewScore(compositionGrade);

  const maxScore = isFundII ? 5 : maxReviewScore(compositionGrade);

  const handleZeroGradeSelectFormSubmit = (zeroGradeReasonId, comments) => {
    const reviewId = compositionGrade.get('id');

    zeroReviewRequest({zeroGradeReasonId, reviewId: parseInt(reviewId, 10)});

    setIsZeroGradeSelectFormModalOpen(!isZeroGradeSelectFormModalOpen);
  };

  const handleCloseModalZeroGradeSelectForm = () => {
    setIsZeroGradeSelectFormModalOpen(!isZeroGradeSelectFormModalOpen);
  };

  const handleOpenModal = () => {
    setIsZeroGradeSelectFormModalOpen(!isZeroGradeSelectFormModalOpen);
  };

  const handleChangeGeneralComment = (event) => {
    setReviewGeneralComment(event.target.value);
  };

  const handleSaveGeneralComment = () => {
    const nextCompositionGrade = compositionGrade.set(
      'general_comments',
      reviewGeneralComment,
    );

    updateCompositionGradeRequest(nextCompositionGrade.toJS());
  };

  const gradeScore = (totalScore * 100) / maxScore;

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <span>
          {isFundII ? 'Nível total calculado' : 'Nota total calculada'}
        </span>
        <span
          data-tip={
            'Soma da pontuação atribuída para cada competência avaliada nesta redação.'
          }
          data-for="generalComment"
          className={styles.iconQuestion}
        >
          <Icon icon="question" />
        </span>
        <ReactTooltip
          className={styles.tooltipQuestion}
          id="generalComment"
          type="dark"
          effect="solid"
          place="right"
          multiline={true}
        />
      </div>
      <div className={styles.grades}>
        <span className={styles.totalScore}>{totalScore}</span>
        <span className={styles.maxScore}>/{maxScore}</span>
      </div>
      <div className={styles.gradeScore}>
        <div
          style={{width: `${gradeScore}%`}}
          className={styles.percentScore}
        ></div>
      </div>
      <div className={styles.resetWriting}>
        {canEditReview && !isFundII && (
          <button onClick={handleOpenModal} className={styles.buttonReset}>
            <SvgIcons iconName="resetWriting" /> Zerar redação
          </button>
        )}
      </div>
      {isZeroGradeSelectFormModalOpen && (
        <ModalInfo
          onCloseClick={handleCloseModalZeroGradeSelectForm}
          visible={true}
          component={
            <ZeroGradeSelectForm
              handleSubmit={handleZeroGradeSelectFormSubmit}
            />
          }
        />
      )}
      <div className={styles.comments}>
        <div className={styles.title}>
          <span>Comentário geral</span>
          <span
            data-tip={
              'Os comentários gerais são produzidos pela inteligência artificial da Letrus e variam de acordo com o desempenho do aluno na redação. Eles podem ser editados por você e objetivam evidenciar para o estudante, de forma breve, os pontos de desenvolvimento do texto corrigido.'
            }
            data-for="generalComment"
            className={styles.iconQuestion}
          >
            <Icon icon="question" />
          </span>
          <ReactTooltip
            className={styles.tooltipQuestion}
            id="generalComment"
            type="dark"
            effect="solid"
            place="right"
            multiline={true}
          />
        </div>
        <textarea
          className={styles.generalCommentsInput}
          id="GeneralCommentGrade"
          name="GeneralCommentGrade"
          onBlur={handleSaveGeneralComment}
          onChange={handleChangeGeneralComment}
          placeholder="Acrescente seu comentário."
          disabled={!canEditReview}
          value={
            reviewGeneralComment
              ? extractTextFromHtml(reviewGeneralComment, true)
              : ''
          }
        />
      </div>
      <footer className={styles.footerText}>
        AS EDIÇÕES são salvas automaticamente :)
      </footer>
    </div>
  );
};

export default connect<
  StateProps,
  DispatchProps,
  ConfirmGradesProps,
  ApplicationState
>(
  (state: ApplicationState) => ({
    compositionGrade: getCompositionGrade(state),
    isFundII: isFundII(state),
  }),
  {
    updateCompositionGradeRequest,
    zeroReviewRequest,
  },
)(
  reduxForm<{}, Props>({
    form: 'ConfirmGradesForm',
  })(ConfirmGrades),
);
