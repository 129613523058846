import React from 'react';
import styles from './ThemeSidebarAid.module.scss';

export interface Props {
  aid: ImmutableMap<any>;
}

const ThemeSidebarAid: React.FC<Props> = ({aid}) => {
  return (
    <div className={styles.container} key={aid.get('id')}>
      <h4>{aid.get('title')}</h4>
      <div
        className="HTML"
        dangerouslySetInnerHTML={{
          __html: aid.get('content_html', ''),
        }}
      />
      {aid.has('content_image') && (
        <div>
          {/* TODO - See if `alt` would be read by
                  a screen-reader */}
          <div>
            <img
              className="ThemeSidebar__Image"
              src={aid.get('content_image')}
              alt={aid.get('alt_image')}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ThemeSidebarAid;
