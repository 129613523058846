/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import InputMask from 'react-input-mask';
import './InputTextMask.scss';

interface InputTextMaskProps {
  className?: string;
  mask: any;
  onChange(): void;
  placeholder: string;
  type: string;
  required: boolean;
  id: string;
  input: any;
  label: any;
  meta: any;
  disabled: boolean;
}

const InputTextMask: React.FC<InputTextMaskProps> = ({
  className,
  children,
  mask,
  onChange,
  placeholder,
  type = 'text',
  required,
  id,
  input,
  label,
  meta: {error, touched, active} = {},
  disabled,
}) => {
  const hasError = error && touched;

  return (
    <div className="input-text-mask">
      {label && (
        <label htmlFor={id} className="input-text-mask__label">
          {label}
        </label>
      )}
      <div className="input-text-mask__wrapper">
        <InputMask
          autoFocus
          mask={mask}
          onChange={onChange}
          className={`input-text-mask__input ${className} ${
            hasError ? error : ''
          } ${disabled ? 'disabled' : ''}`}
          {...input}
          placeholder={placeholder}
          type={type}
          required={required}
          id={id}
          autoComplete="off"
          disabled={disabled}
        />
        {children}
        {hasError && !active && (
          <small className="input-text-mask__error">{error}</small>
        )}
      </div>
    </div>
  );
};

export default InputTextMask;
