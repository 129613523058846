import {NewButton} from 'letrus-ui';
import React from 'react';
import styles from './ModalLeaveMarking.module.scss';

interface Props {
  handleClickConfirm: () => void;
  handleClickCancel: () => void;
}

const ModalLeaveMarking: React.FC<Props> = ({
  handleClickCancel,
  handleClickConfirm,
}) => {
  return (
    <div className={styles.container}>
      <figure className={styles.textsContainer}>
        <h3 className={styles.title}>Cancelar marcação?</h3>
        <legend className={styles.description}>
          Ao sair da tela a marcação atual será perdida.
        </legend>
      </figure>
      <footer className={styles.footerButtons}>
        <NewButton
          onClick={handleClickConfirm}
          text="Sair e não salvar"
          userRole="student"
        />
        <NewButton
          onClick={handleClickCancel}
          text="Cancelar"
          userRole="anonymous"
        />
      </footer>
    </div>
  );
};

export default ModalLeaveMarking;
