import React from 'react';

export interface SvgIconsProps {
  iconName: 'addCommentIcon' | 'firstWrite' | 'proposal' | 'resetWriting';
}

const SvgIcons: React.FC<SvgIconsProps> = ({iconName}) => {
  const icons = [
    {
      name: 'addCommentIcon',
      content: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 202.01 150.47">
          <g id="Camada_2" data-name="Camada 2">
            <g id="Camada_1-2" data-name="Camada 1">
              <path d="M181.77,47.4H75c-11.16,0-20.24,8.84-20.24,19.71v63.64c0,10.85,9,19.68,20.17,19.72H181.77c11.16,0,20.24-8.84,20.24-19.71V67.11C202,56.24,192.93,47.4,181.77,47.4Zm-36.72,73.95H94.17V113h50.88Zm17.55-17.93H94.17V95H162.6Zm0-17.92H94.17V77.09H162.6Z" />
              <path d="M58.23,31.06V26.92a4.15,4.15,0,0,0-4.16-4.14H35.36V4.14A4.15,4.15,0,0,0,31.2,0H27a4.15,4.15,0,0,0-4.16,4.14V22.78H4.16A4.15,4.15,0,0,0,0,26.92v4.14A4.15,4.15,0,0,0,4.16,35.2H22.88V53.84A4.15,4.15,0,0,0,27,58H31.2a4.15,4.15,0,0,0,4.16-4.14V35.2H54.07A4.15,4.15,0,0,0,58.23,31.06Z" />
            </g>
          </g>
        </svg>
      ),
    },
    {
      name: 'firstWrite',
      content: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 202.96 145.3">
          <g id="Camada_2" data-name="Camada 2">
            <g id="Camada_1-2" data-name="Camada 1">
              <path d="M121.65,72.65,156,40.47a4.83,4.83,0,0,0,1.56-3.53A4.78,4.78,0,0,0,156,33.43h0l-5-4.72a5.66,5.66,0,0,0-7.58,0L110.27,59.57h0L100,69.13a4.73,4.73,0,0,0,0,7l10.24,9.56h0l33.08,30.86a5.63,5.63,0,0,0,7.57,0l5.06-4.71a4.75,4.75,0,0,0,0-7Z" />
              <path d="M110.26,34.6h0a6.77,6.77,0,0,0-2-4.82L80.15,2A6.66,6.66,0,0,0,77.94.53,7,7,0,0,0,75.26,0H73.51V36.34h36.75Z" />
              <path d="M110.27,98.14,93.84,82.81a13.81,13.81,0,0,1,0-20.33l16.43-15.33V45.4h-39a7.66,7.66,0,0,1-5-2.13,6.16,6.16,0,0,1-1.89-4.67V0H6.9a7.64,7.64,0,0,0-5,2.13A6.09,6.09,0,0,0,0,6.81V138.49a6.09,6.09,0,0,0,1.87,4.68,7.64,7.64,0,0,0,5,2.13h96.48a7.64,7.64,0,0,0,5-2.13,6.06,6.06,0,0,0,1.87-4.68ZM82.7,105.59a3.06,3.06,0,0,1-.94,2.33,3.76,3.76,0,0,1-2.5,1.07H31a3.76,3.76,0,0,1-2.5-1.07,3.06,3.06,0,0,1-.94-2.33v-2.28a3.06,3.06,0,0,1,.94-2.33A3.76,3.76,0,0,1,31,99.91H79.26a3.76,3.76,0,0,1,2.5,1.07,3.06,3.06,0,0,1,.94,2.33Zm0-18.17a3.07,3.07,0,0,1-.94,2.34,3.76,3.76,0,0,1-2.5,1.07H31a3.76,3.76,0,0,1-2.5-1.07,3.07,3.07,0,0,1-.94-2.34V85.15a3,3,0,0,1,.94-2.33A3.76,3.76,0,0,1,31,81.75H79.26a3.76,3.76,0,0,1,2.5,1.07,3,3,0,0,1,.94,2.33Zm0-18.2a3.07,3.07,0,0,1-.94,2.34,3.86,3.86,0,0,1-2.5,1.07H31a3.89,3.89,0,0,1-2.48-1.08,3.09,3.09,0,0,1-.93-2.33V67a3.11,3.11,0,0,1,.94-2.34A3.86,3.86,0,0,1,31,63.58H79.29a3.79,3.79,0,0,1,2.48,1.08A3.08,3.08,0,0,1,82.7,67Z" />
              <path d="M167.06,72.65,201.4,40.47A4.83,4.83,0,0,0,203,36.94a4.78,4.78,0,0,0-1.57-3.51h0l-5.06-4.72a5.65,5.65,0,0,0-7.57,0L145.44,69.13a4.73,4.73,0,0,0,0,7l43.32,40.42a5.62,5.62,0,0,0,7.56,0l5.07-4.71a4.75,4.75,0,0,0,0-7Z" />
            </g>
          </g>
        </svg>
      ),
    },
    {
      name: 'proposal',
      content: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 106.54 144.59">
          <g id="Camada_2" data-name="Camada 2">
            <g id="Camada_1-2" data-name="Camada 1">
              <path d="M79.91,72.3H26.64V90.37H79.91ZM104.6,29.65,77.44,2a6.62,6.62,0,0,0-4.72-2H71V36.15h35.51V34.42A6.82,6.82,0,0,0,104.6,29.65ZM62.15,38.41V0H6.66A6.7,6.7,0,0,0,0,6.78v131a6.7,6.7,0,0,0,6.66,6.78H99.88a6.7,6.7,0,0,0,6.66-6.78V45.18H68.81A6.73,6.73,0,0,1,62.15,38.41ZM17.76,20.33A2.24,2.24,0,0,1,20,18.07H42.17a2.24,2.24,0,0,1,2.22,2.26v4.52a2.24,2.24,0,0,1-2.22,2.26H20a2.24,2.24,0,0,1-2.22-2.26Zm0,18.08A2.24,2.24,0,0,1,20,36.15H42.17a2.24,2.24,0,0,1,2.22,2.26v4.52a2.23,2.23,0,0,1-2.22,2.25H20a2.23,2.23,0,0,1-2.22-2.25Zm71,85.85a2.24,2.24,0,0,1-2.22,2.26H64.37a2.24,2.24,0,0,1-2.22-2.26v-4.52a2.24,2.24,0,0,1,2.22-2.26H86.56a2.24,2.24,0,0,1,2.22,2.26Zm0-56.48V94.89a4.48,4.48,0,0,1-4.44,4.52H22.2a4.48,4.48,0,0,1-4.44-4.52V67.78a4.47,4.47,0,0,1,4.44-4.52H84.34A4.47,4.47,0,0,1,88.78,67.78Z" />
            </g>
          </g>
        </svg>
      ),
    },
    {
      name: 'resetWriting',
      content: (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 132.65 158">
          <g id="Camada_2" data-name="Camada 2">
            <g id="Camada_1-2" data-name="Camada 1">
              <polygon points="76.86 153.25 98.04 153.25 87.45 142.63 76.86 153.25" />
              <path d="M34.16,138.88H17.87a1.91,1.91,0,0,1-2-1.79V35.32a1.91,1.91,0,0,1,2-1.8h13.9V44.3a3.8,3.8,0,0,0,4,3.59H91.33a3.81,3.81,0,0,0,4-3.59V33.52h13.9a1.9,1.9,0,0,1,2,1.8V60.25a17,17,0,0,1,7.69-1.9h0a16.88,16.88,0,0,1,8.19,2.18v-27c0-7.93-7.12-14.36-15.89-14.36H84.71C84.71,8.59,75.21,0,63.53,0S42.36,8.59,42.36,19.16H15.88C7.11,19.16,0,25.59,0,33.52V138.88c0,7.93,7.11,14.37,15.88,14.37h20A17.12,17.12,0,0,1,34.16,138.88ZM63.53,12c4.4,0,7.94,3.21,7.94,7.19s-3.54,7.18-7.94,7.18-7.94-3.2-7.94-7.18S59.13,12,63.53,12Z" />
              <polygon points="127.07 102.91 117.32 112.68 127.07 122.45 127.07 102.91" />
              <path d="M127.07,135.22h0l-15.89-15.93h0l-6.59-6.61,6.59-6.61h0l15.89-15.92h0l3.22-3.24a8.12,8.12,0,0,0,0-11.45l-3.22-3.23h0l-2.5-2.5a8.26,8.26,0,0,0-11.41,0L87.45,95.51,61.75,69.73a8.24,8.24,0,0,0-11.42,0l-5.71,5.72a8.13,8.13,0,0,0,0,11.46l25.7,25.76-25.7,25.78a8.11,8.11,0,0,0-1,10.22,2,2,0,0,0,.17.23c.09.14.19.27.29.4a7.7,7.7,0,0,0,.54.6l5.71,5.72a8.06,8.06,0,0,0,11.43,0l8.73-8.75,8-8h0l9-9,14.6,14.64,8.73,8.75h0l2.37,2.37a8.05,8.05,0,0,0,11.42,0l5.72-5.73a8.12,8.12,0,0,0,0-11.45Z" />
            </g>
          </g>
        </svg>
      ),
    },
  ];

  const selectedIcon = icons.find((icon) => icon.name === iconName);

  return <>{selectedIcon?.content}</>;
};

export default SvgIcons;
