import {fromJS} from 'immutable';
import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {
  updatePresignedUrlContentService,
  uploadToS3Service,
} from 'store/services/s3Service';
import {action} from 'typesafe-actions';

//Action types
export enum S3Types {
  UPLOAD_REQUEST = '@s3/UPLOAD_REQUEST',
  UPLOAD_SUCCESS = '@s3/UPLOAD_SUCCESS',
  UPLOAD_FAILURE = '@s3/UPLOAD_FAILURE',

  UPDATE_PRESIGNED_URL_CONTENT_REQUEST = '@s3/UPDATE_PRESIGNED_URL_CONTENT_REQUEST',
  UPDATE_PRESIGNED_URL_CONTENT_SUCCESS = '@s3/UPDATE_PRESIGNED_URL_CONTENT_SUCCESS',
  UPDATE_PRESIGNED_URL_CONTENT_FAILURE = '@s3/UPDATE_PRESIGNED_URL_CONTENT_FAILURE',
}

//Data types
interface DataObject {
  wasPresignegUrlUpdated: boolean;
  image: any;
}

//State type
export interface S3State
  extends ImmutableMap<{
    data: DataObject;
    loading: boolean;
    error: boolean;
    dataCount: number;
  }> {}

//Create actions
export const uploadToS3Request = (data?: any) =>
  action(S3Types.UPLOAD_REQUEST, data);

export const uploadToS3Success = (data: any) =>
  action(S3Types.UPLOAD_SUCCESS, data);

export const uploadToS3Failure = () => action(S3Types.UPLOAD_FAILURE);

export const updatePresignedUrlContentRequest = (params?: any) =>
  action(S3Types.UPDATE_PRESIGNED_URL_CONTENT_REQUEST, params);

export const updatePresignedUrlContentSuccess = (data: any) =>
  action(S3Types.UPDATE_PRESIGNED_URL_CONTENT_SUCCESS, {data});

export const updatePresignedUrlContentFailure = () =>
  action(S3Types.UPDATE_PRESIGNED_URL_CONTENT_FAILURE);

//Sagas
export function* uploadToS3(action: AnyAction) {
  try {
    const response = yield call(uploadToS3Service, action.payload);
    yield put(uploadToS3Success(response.data));
  } catch (err) {
    yield put(uploadToS3Failure());
  }
}

export function* updatePresignedUrlContent(action: AnyAction) {
  try {
    const response = yield call(
      updatePresignedUrlContentService,
      action.payload.url,
      action.payload.file,
    );
    yield put(updatePresignedUrlContentSuccess(response.data));
  } catch (err) {
    yield put(updatePresignedUrlContentFailure());
  }
}

//Selectors

const s3Selector = (state: ApplicationState) => state.get('S3');

export const getUploadedImage = createSelector(s3Selector, (s3) =>
  s3.getIn(['data', 'image']),
);

//Initial state
export const INITIAL_STATE: S3State = fromJS({
  data: fromJS({}),
  error: false,
  loading: false,
  dataCount: 0,
});

//Reducer
export const reducer: Reducer<S3State> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case S3Types.UPLOAD_REQUEST:
      return state.withMutations((prevState) => prevState.set('loading', true));

    case S3Types.UPLOAD_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .setIn(['data', 'image'], action.payload.fileName),
      );

    case S3Types.UPLOAD_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );

    case S3Types.UPDATE_PRESIGNED_URL_CONTENT_REQUEST:
      return state.withMutations((prevState) => prevState.set('loading', true));

    case S3Types.UPDATE_PRESIGNED_URL_CONTENT_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .setIn(['data', 'wasPresignedUrlUpdated'], true),
      );

    case S3Types.UPDATE_PRESIGNED_URL_CONTENT_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );

    default:
      return state;
  }
};

export default reducer;
