import {AboutStudent} from 'containers/ReviewerEditorWrapper/StudentInfo';
import {NewButton} from 'letrus-ui';
import React from 'react';
import styles from './WarningAboutStudent.module.scss';

interface Props {
  handleCloseWarning: () => void;
  aboutStudent: ImmutableMap<AboutStudent>;
}

const WarningAboutStudent: React.FC<Props> = ({
  handleCloseWarning,
  aboutStudent,
}) => {
  return (
    <div className={styles.container}>
      <h3 className={styles.title}>Aviso:</h3>
      <span className={styles.description}>
        De acordo com as indicações do(a) professor(a), esta redação não deve
        ser zerada por:
      </span>
      <ul className={styles.zeroItems}>
        {aboutStudent.get('skip_words_number_review') && (
          <li className={styles.item}>- Tamanho do texto.</li>
        )}
        {aboutStudent.get('skip_genre_review') && (
          <li className={styles.item}>- Fuga ao gênero.</li>
        )}
        {aboutStudent.get('skip_theme_review') && (
          <li className={styles.item}>- Fuga ao tema.</li>
        )}
      </ul>
      <NewButton
        text="Iniciar correção"
        userRole="student"
        kind="primary"
        onClick={handleCloseWarning}
      />
    </div>
  );
};

export default WarningAboutStudent;
