import {Utils} from '@letrustech/letrus-api-interfaces';
import {fromJS} from 'immutable';
import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {getOrCreateSubAccountService} from 'store/services/iuguGetOrCreateSubAccountService';
import {
  disableSubAccountService,
  fetchBankInformationService,
  verifySubAccountService,
} from 'store/services/iuguSubAccountsService';
import {action} from 'typesafe-actions';

//Action types
export enum IuguSubAccountsTypes {
  FETCH_BANK_INFORMATION_REQUEST = '@iuguSubAccounts/FETCH_BANK_INFORMATION_REQUEST',
  FETCH_BANK_INFORMATION_SUCCESS = '@iuguSubAccounts/FETCH_BANK_INFORMATION_SUCCESS',
  FETCH_BANK_INFORMATION_FAILURE = '@iuguSubAccounts/FETCH_BANK_INFORMATION_FAILURE',

  DISABLE_REQUEST = '@iuguSubAccounts/DISABLE_REQUEST',
  DISABLE_SUCCESS = '@iuguSubAccounts/DISABLE_SUCCESS',
  DISABLE_FAILURE = '@iuguSubAccounts/DISABLE_FAILURE',

  VERIFY_REQUEST = '@iuguSubAccounts/VERIFY_REQUEST',
  VERIFY_SUCCESS = '@iuguSubAccounts/VERIFY_SUCCESS',
  VERIFY_FAILURE = '@iuguSubAccounts/VERIFY_FAILURE',

  DISABLE_AND_CREATE_REQUEST = '@iuguSubAccounts/DISABLE_AND_CREATE_REQUEST',
  DISABLE_AND_CREATE_SUCCESS = '@iuguSubAccounts/DISABLE_AND_CREATE_SUCCESS',
  DISABLE_AND_CREATE_FAILURE = '@iuguSubAccounts/DISABLE_AND_CREATE_FAILURE',
}

//Data types
interface DataObject {}

//State type
export interface IuguSubAccountsState
  extends ImmutableMap<{
    data: any;
    iugu: any;
    loading: boolean;
    error: boolean;
  }> {}

//Update actions
export const fetchBankInformationRequest = (data?: any) =>
  action(IuguSubAccountsTypes.FETCH_BANK_INFORMATION_REQUEST, data);

export const fetchBankInformationSuccess = (data: any) =>
  action(IuguSubAccountsTypes.FETCH_BANK_INFORMATION_SUCCESS, data);

export const fetchBankInformationFailure = () =>
  action(IuguSubAccountsTypes.FETCH_BANK_INFORMATION_FAILURE);

export const disableSubAccountRequest = (params?: Utils.GetParams) =>
  action(IuguSubAccountsTypes.DISABLE_REQUEST, params);

export const disableSubAccountSuccess = (data: any) =>
  action(IuguSubAccountsTypes.DISABLE_SUCCESS, {data});

export const disableSubAccountFailure = () =>
  action(IuguSubAccountsTypes.DISABLE_FAILURE);

export const verifySubAccountRequest = (params?: Utils.GetParams) =>
  action(IuguSubAccountsTypes.VERIFY_REQUEST, params);

export const verifySubAccountSuccess = (data: any) =>
  action(IuguSubAccountsTypes.VERIFY_SUCCESS, {data});

export const verifySubAccountFailure = () =>
  action(IuguSubAccountsTypes.VERIFY_FAILURE);

export const disableAndCreateSubAccountRequest = (
  subAccountId: number | string,
) =>
  action(IuguSubAccountsTypes.DISABLE_AND_CREATE_REQUEST, {
    subAccountId,
  });

export const disableAndCreateSubAccountSuccess = ({
  disableResponse,
  getOrCreateResponse,
}) =>
  action(IuguSubAccountsTypes.DISABLE_AND_CREATE_SUCCESS, {
    disableResponse,
    getOrCreateResponse,
  });

export const disableAndCreateSubAccountFailure = () =>
  action(IuguSubAccountsTypes.DISABLE_AND_CREATE_FAILURE);

//Sagas
export function* fetchBankInformation(action: AnyAction) {
  try {
    const response = yield call(fetchBankInformationService, action.payload);
    yield put(fetchBankInformationSuccess(response.data));
  } catch (err) {
    yield put(fetchBankInformationFailure());
  }
}

export function* disableAndCreateSubAccount(action: AnyAction) {
  try {
    const disableResponse = yield call(
      disableSubAccountService,
      action.payload.subAccountId,
    );

    const getOrCreateResponse = yield call(getOrCreateSubAccountService);

    yield put(
      disableAndCreateSubAccountSuccess({
        disableResponse: disableResponse.data,
        getOrCreateResponse: getOrCreateResponse.data,
      }),
    );
  } catch (err) {
    yield put(disableAndCreateSubAccountFailure());
  }
}

export function* disableSubAccount(action: AnyAction) {
  try {
    const response = yield call(disableSubAccountService, action.payload);
    yield put(disableSubAccountSuccess(response.data));
  } catch (err) {
    yield put(disableSubAccountFailure());
  }
}

export function* verifySubAccount(action: AnyAction) {
  try {
    const response = yield call(verifySubAccountService, action.payload);
    yield put(verifySubAccountSuccess(response.data));
  } catch (err) {
    yield put(verifySubAccountFailure());
  }
}

//Selectors
const iuguSubAccountsSelector = (state: ApplicationState) =>
  state.get('iuguSubAccounts');

export const getBankInformation = createSelector(
  iuguSubAccountsSelector,
  (iuguSubAccounts) => iuguSubAccounts.getIn(['data']),
);

export const getSubAccountUpdate = createSelector(
  iuguSubAccountsSelector,
  (iuguSubAccounts) =>
    iuguSubAccounts.getIn(['dataDisableAndGetOrCreate', 'getOrCreateResponse']),
);

//Initial state
export const INITIAL_STATE: IuguSubAccountsState = fromJS({
  data: fromJS({}),
  error: false,
  loading: false,
  dataDisableAndGetOrCreate: fromJS({
    isLoading: false,
    disableResponse: {},
    getOrCreateResponse: {},
  }),
});

//Reducer
export const reducer: Reducer<IuguSubAccountsState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case IuguSubAccountsTypes.FETCH_BANK_INFORMATION_REQUEST:
      return state.withMutations((prevState) => prevState.set('loading', true));

    case IuguSubAccountsTypes.FETCH_BANK_INFORMATION_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .set('data', fromJS(action.payload)),
      );

    case IuguSubAccountsTypes.FETCH_BANK_INFORMATION_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );

    case IuguSubAccountsTypes.DISABLE_REQUEST:
      return state.withMutations((prevState) => prevState.set('loading', true));

    case IuguSubAccountsTypes.DISABLE_SUCCESS:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', false),
      );

    case IuguSubAccountsTypes.DISABLE_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );

    case IuguSubAccountsTypes.VERIFY_REQUEST:
      return state.withMutations((prevState) => prevState.set('loading', true));

    case IuguSubAccountsTypes.VERIFY_SUCCESS:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', false),
      );

    case IuguSubAccountsTypes.VERIFY_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );

    case IuguSubAccountsTypes.DISABLE_AND_CREATE_REQUEST:
      return state.withMutations((prevState) =>
        prevState.setIn(['dataDisableAndGetOrCreate', 'isLoading'], true),
      );

    case IuguSubAccountsTypes.DISABLE_AND_CREATE_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .setIn(['dataDisableAndGetOrCreate', 'isLoading'], false)
          .setIn(
            ['dataDisableAndGetOrCreate', 'disableResponse'],
            action.payload.disableResponse,
          )
          .setIn(
            ['dataDisableAndGetOrCreate', 'getOrCreateResponse'],
            action.payload.getOrCreateResponse,
          )
          .set('error', false),
      );

    case IuguSubAccountsTypes.DISABLE_AND_CREATE_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .setIn(['dataDisableAndGetOrCreate', 'isLoading'], false)
          .set('error', true),
      );

    default:
      return state;
  }
};

export default reducer;
