import {Utils} from '@letrustech/letrus-api-interfaces';
import {fromJS} from 'immutable';
import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {
  confirmPayReviewsService,
  fetchPaymentsService,
} from 'store/services/iuguReviewerTransfersService';
import {action} from 'typesafe-actions';

//Action types
export enum IuguReviewerTranfersTypes {
  CONFIRM_PAY_REVIEWS_REQUEST = '@iuguReviewerTransfers/CONFIRM_PAY_REVIEWS_REQUEST',
  CONFIRM_PAY_REVIEWS_SUCCESS = '@iuguReviewerTransfers/CONFIRM_PAY_REVIEWS_SUCCESS',
  CONFIRM_PAY_REVIEWS_FAILURE = '@iuguReviewerTransfers/CONFIRM_PAY_REVIEWS_FAILURE',

  FETCH_PAYMENTS_REQUEST = '@iuguReviewerTransfers/FETCH_PAYMENTS_REQUEST',
  FETCH_PAYMENTS_SUCCESS = '@iuguReviewerTransfers/FETCH_PAYMENTS_SUCCESS',
  FETCH_PAYMENTS_FAILURE = '@iuguReviewerTransfers/FETCH_PAYMENTS_FAILURE',
}

//Data types

//State type
export interface IuguReviewerTranfersState
  extends ImmutableMap<{
    data: any;
    loading: boolean;
    error: boolean;
    dataCount: number;
  }> {}

//Create actions
export const confirmPayReviewsRequest = (params?: Utils.GetParams) =>
  action(IuguReviewerTranfersTypes.CONFIRM_PAY_REVIEWS_REQUEST, params);

export const confirmPayReviewsSuccess = (data: any) =>
  action(IuguReviewerTranfersTypes.CONFIRM_PAY_REVIEWS_SUCCESS, {data});

export const confirmPayReviewsFailure = () =>
  action(IuguReviewerTranfersTypes.CONFIRM_PAY_REVIEWS_FAILURE);

//Update actions
export const fetchPaymentsRequest = (params?: Utils.GetParams) =>
  action(IuguReviewerTranfersTypes.FETCH_PAYMENTS_REQUEST, params);

export const fetchPaymentsSuccess = (data: any) =>
  action(IuguReviewerTranfersTypes.FETCH_PAYMENTS_SUCCESS, data);

export const fetchPaymentsFailure = () =>
  action(IuguReviewerTranfersTypes.FETCH_PAYMENTS_FAILURE);

//Sagas
export function* confirmPayReviews() {
  try {
    const response = yield call(confirmPayReviewsService);
    yield put(confirmPayReviewsSuccess(response.data));
  } catch (err) {
    yield put(confirmPayReviewsFailure());
  }
}

export function* fetchPayments(action: AnyAction) {
  try {
    const response = yield call(fetchPaymentsService, action.payload);
    yield put(fetchPaymentsSuccess(response.data));
  } catch (err) {
    yield put(fetchPaymentsFailure());
  }
}

//Selectors
const iuguReviewerTransfersSelector = (state: ApplicationState) =>
  state.get('iuguReviewerTransfers');

const iuguReviewerTransfersDataSelector = (state: ApplicationState) =>
  state.getIn(['iuguReviewerTransfers', 'data']);

export const getPayments = createSelector(
  iuguReviewerTransfersDataSelector,
  (iuguReviewerTransfers) => {
    return iuguReviewerTransfers
      ? iuguReviewerTransfers.map((iuguReviewerTranfer) =>
          fromJS({
            id: iuguReviewerTranfer.get('id'),
            amount_cents: iuguReviewerTranfer.get('amount_cents'),
            payed_mont: iuguReviewerTranfer.get('payed_mont'),
            payed_year: iuguReviewerTranfer.get('payed_year'),
            reviews_number: iuguReviewerTranfer.get('reviews_number'),
            transfer_date:
              iuguReviewerTranfer.get('transfer_date') ??
              iuguReviewerTranfer.get('created'),
            transfer_id: iuguReviewerTranfer.get('transfer_id'),
            withdraw_id: iuguReviewerTranfer.get('withdraw_id'),
            withdraw_status: iuguReviewerTranfer.get('withdraw_status'),
            webhook_feedback: iuguReviewerTranfer.get('webhook_feedback'),
            reviewer_financial: iuguReviewerTranfer.get('reviewer_financial'),
            url: iuguReviewerTranfer.get('url'),
          }),
        )
      : fromJS([]);
  },
);

export const getPaymentsCount = createSelector(
  iuguReviewerTransfersSelector,
  (iuguReviewerTransfers) => iuguReviewerTransfers.getIn(['dataCount']),
);

export const isLoadingPayments = createSelector(
  iuguReviewerTransfersSelector,
  (iuguReviewerTransfers) => iuguReviewerTransfers.getIn(['loading']),
);

//Initial state
export const INITIAL_STATE: IuguReviewerTranfersState = fromJS({
  data: fromJS([]),
  error: false,
  loading: false,
  dataCount: 0,
});

//Reducer
export const reducer: Reducer<IuguReviewerTranfersState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case IuguReviewerTranfersTypes.CONFIRM_PAY_REVIEWS_REQUEST:
      return state.withMutations((prevState) => prevState.set('loading', true));

    case IuguReviewerTranfersTypes.CONFIRM_PAY_REVIEWS_SUCCESS:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', false),
      );

    case IuguReviewerTranfersTypes.CONFIRM_PAY_REVIEWS_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );

    case IuguReviewerTranfersTypes.FETCH_PAYMENTS_REQUEST:
      return state.withMutations((prevState) => prevState.set('loading', true));

    case IuguReviewerTranfersTypes.FETCH_PAYMENTS_SUCCESS: {
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .set('dataCount', action.payload.count)
          .set('data', fromJS(action.payload.results)),
      );
    }

    case IuguReviewerTranfersTypes.FETCH_PAYMENTS_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .set('dataCount', 0)
          .set('data', fromJS([])),
      );

    default:
      return state;
  }
};

export default reducer;
