import api from 'store/services/api';

export interface SendSurveyReviewData {
  comment: string;
  event_name: string;
  user: number;
}

export const sendSurveyReview = (data: SendSurveyReviewData) =>
  api(`/api/v1/surveys/`, {
    method: 'post',
    data,
  });
