import {Utils} from '@letrustech/letrus-api-interfaces';
import {fromJS} from 'immutable';
import {Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {fetchInProgressPaymentsService} from 'store/services/iuguReviewerFinancialAccountsService';
import {action} from 'typesafe-actions';

//Action types
export enum IuguReviewerFinancialAccountsTypes {
  FETCH_IN_PROGRESS_PAYMENTS_REQUEST = '@iuguReviewerFinancialAccounts/FETCH_IN_PROGRESS_PAYMENTS_REQUEST',
  FETCH_IN_PROGRESS_PAYMENTS_SUCCESS = '@iuguReviewerFinancialAccounts/FETCH_IN_PROGRESS_PAYMENTS_SUCCESS',
  FETCH_IN_PROGRESS_PAYMENTS_FAILURE = '@iuguReviewerFinancialAccounts/FETCH_IN_PROGRESS_PAYMENTS_FAILURE',
}

//Data types
export interface InProgressReviewPerUser {
  amount_localized: string;
  reviews_number: number;
}

//State type
export interface IuguReviewerFinancialAccountsState
  extends ImmutableMap<{
    data: ImmutableMap<InProgressReviewPerUser>;
    loading: boolean;
    error: boolean;
  }> {}

//Create actions
export const fetchInProgressPaymentsRequest = (params?: Utils.GetParams) =>
  action(
    IuguReviewerFinancialAccountsTypes.FETCH_IN_PROGRESS_PAYMENTS_REQUEST,
    params,
  );

export const fetchInProgressPaymentsSuccess = (data: any) =>
  action(
    IuguReviewerFinancialAccountsTypes.FETCH_IN_PROGRESS_PAYMENTS_SUCCESS,
    data,
  );

export const fetchInProgressPaymentsFailure = () =>
  action(IuguReviewerFinancialAccountsTypes.FETCH_IN_PROGRESS_PAYMENTS_FAILURE);

//Sagas
export function* fetchInProgressPayments() {
  try {
    const response = yield call(fetchInProgressPaymentsService);
    yield put(fetchInProgressPaymentsSuccess(response));
  } catch (err) {
    yield put(fetchInProgressPaymentsFailure());
  }
}

//Selectors
const iuguReviewerFinancialAccountsSelector = (state: ApplicationState) =>
  state.get('iuguReviewerFinancialAccounts');

export const getInProgressPayments = createSelector(
  iuguReviewerFinancialAccountsSelector,
  (iuguReviewerFinancialAccount) => iuguReviewerFinancialAccount.get('data'),
);

export const isLoadingInProgressPayments = createSelector(
  iuguReviewerFinancialAccountsSelector,
  (iuguReviewerFinancialAccount) => iuguReviewerFinancialAccount.get('loading'),
);

//Initial state
export const INITIAL_STATE: IuguReviewerFinancialAccountsState = fromJS({
  data: fromJS([]),
  error: false,
  loading: false,
});

//Reducer
export const reducer: Reducer<IuguReviewerFinancialAccountsState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case IuguReviewerFinancialAccountsTypes.FETCH_IN_PROGRESS_PAYMENTS_REQUEST:
      return state.withMutations((prevState) => prevState.set('loading', true));

    case IuguReviewerFinancialAccountsTypes.FETCH_IN_PROGRESS_PAYMENTS_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .set('data', fromJS(action.payload.data)),
      );

    case IuguReviewerFinancialAccountsTypes.FETCH_IN_PROGRESS_PAYMENTS_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', false),
      );

    default:
      return state;
  }
};

export default reducer;
