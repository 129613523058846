/* eslint-disable react/jsx-props-no-spreading */
import {LetrusApi} from '@letrustech/letrus-api-interfaces';
import GradeEditor from 'components/GradeEditor';
import {convertToRaw} from 'draft-js';
import {Icon} from 'letrus-ui';
import {createEditorState} from 'medium-draft';
import mediumDraftExporter from 'medium-draft/lib/exporter';
import mediumDraftImporter from 'medium-draft/lib/importer';
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {InjectedFormProps, WrappedFieldProps} from 'redux-form';
import {Field, reduxForm} from 'redux-form/immutable';
import {getCompositionGrade} from 'store/reducers/compositionReviews';
import {
  createGradeGeneralAnnotationRequest,
  deleteGradeGeneralAnnotationRequest,
  updateGradeGeneralAnnotationRequest,
} from 'store/reducers/generalAnnotations';
import {ApplicationState} from 'store/rootReducer';
import {validateLink} from 'utils/validateLink';
import SimpleMediumEditor from '../../components/SimpleMediumEditor';
import styles from './AddPlagiarismForm.module.scss';

export interface StateProps {
  compositionGrade: ImmutableMap<LetrusApi.CompositionReview>;
}

export interface DispatchProps {
  deleteGradeGeneralAnnotationRequest: typeof deleteGradeGeneralAnnotationRequest;
  updateGradeGeneralAnnotationRequest: typeof updateGradeGeneralAnnotationRequest;
  createGradeGeneralAnnotationRequest: typeof createGradeGeneralAnnotationRequest;
}

export interface OwnProps {
  generalAnnotation?: ImmutableMap<LetrusApi.GeneralAnnotation>;
  onClickSave: () => void;
  onClickCancel: () => void;
  editable?: boolean;
  gradeEditorState: any;
}

export type Props = StateProps & DispatchProps & OwnProps;
export type InjectedProps = Props & InjectedFormProps<{}, Props>;

export interface IPropsCustomInput {
  type: string;
  onError: Function;
  placeholder: string;
  editable: boolean;
}

const renderTextArea: React.FC<WrappedFieldProps & IPropsCustomInput> = ({
  meta: {touched, error, active},
  input,
  onError,
  placeholder,
  editable,
}) => {
  onError(error);
  return (
    <div>
      <textarea
        {...input}
        placeholder={placeholder}
        className={`${editable ? styles.editable : ''}`}
        disabled={!editable}
      />
      {error && touched && !active && (
        <div className={styles['error-link']}>
          <Icon icon="exclamation-triangle" />
          {error}
        </div>
      )}
    </div>
  );
};

const AddPlagiarismForm: React.FC<InjectedProps> = ({
  generalAnnotation,
  onClickSave,
  handleSubmit,
  onClickCancel,
  pristine,
  editable = true,
  gradeEditorState,
  compositionGrade,
  updateGradeGeneralAnnotationRequest,
  createGradeGeneralAnnotationRequest,
  change,
}) => {
  const [editorComment, setEditorComment] = useState(
    createEditorState(convertToRaw(mediumDraftImporter(''))),
  );
  const [canSave, setCanSave] = useState(false);

  useEffect(() => {
    if (pristine) {
      setEditorComment(createEditorState());
    }
  }, [pristine]);

  useEffect(() => {
    if (generalAnnotation) {
      setEditorComment(
        createEditorState(
          convertToRaw(
            mediumDraftImporter(generalAnnotation.get('comment', '')),
          ),
        ),
      );

      change('link', generalAnnotation.get('suggested_text'));
    }
  }, [change, generalAnnotation]);

  const handleChangeComment = (commentEditorState: any) => {
    setEditorComment(commentEditorState);
  };

  const handleAddPlagiarismFormSubmit = (values) => {
    const comment = mediumDraftExporter(editorComment.getCurrentContent());
    const suggested_text = values.get('link');

    const selection = GradeEditor.getSelection(gradeEditorState);
    const selected_text = GradeEditor.getTextSelection(
      gradeEditorState,
      selection,
    );
    const annotation_type = 'plagio';
    const review = compositionGrade.get('id');

    generalAnnotation
      ? updateGradeGeneralAnnotationRequest({
          ...generalAnnotation.toJS(),
          comment,
          suggested_text,
        })
      : createGradeGeneralAnnotationRequest({
          selected_text,
          annotation_type,
          comment,
          suggested_text,
          review,
        });

    onClickSave();
  };

  const handleError = (error) => {
    const canSave = !pristine && !error;

    setCanSave(canSave);
  };

  const selection = GradeEditor.getSelection(gradeEditorState);

  const selected_text = GradeEditor.getTextSelection(
    gradeEditorState,
    selection,
  );

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h1>VOCÊ IDENTIFICOU:</h1>"{selected_text}"
      </div>
      <hr />
      <div className={styles.body}>
        <form onSubmit={handleSubmit(handleAddPlagiarismFormSubmit)}>
          <div className={styles.content}>
            <label htmlFor="plagiarism-form-link">
              <span>
                Link<b>*</b>
              </span>
              <Field
                component={renderTextArea}
                type="text"
                name="link"
                placeholder="Copie e cole aqui o site de onde o texto foi plagiado."
                id="plagiarism-form-link"
                props={{onError: handleError}}
                editable={editable}
              />
            </label>
            <label htmlFor="plagiarism-form-comment">
              <span>Comentário</span>
              <div className={styles.editor}>
                {editable ? (
                  <Field
                    component={SimpleMediumEditor}
                    type="text"
                    name="comment"
                    editorState={editorComment}
                    onChange={handleChangeComment}
                    placeholder="Escreva aqui um comentário, caso julgue necessário."
                    id="plagiarism-form-comment"
                  />
                ) : (
                  <div
                    className={styles['raw-comment']}
                    dangerouslySetInnerHTML={{
                      __html: mediumDraftExporter(
                        editorComment.getCurrentContent(),
                      ),
                    }}
                  />
                )}
              </div>
            </label>
          </div>
          <div className={styles.footer}>
            <button
              className={styles.cancel}
              type="button"
              onClick={() => onClickCancel()}
            >
              Cancelar identificação
            </button>
            <button
              disabled={!canSave}
              className={`${styles.save} ${canSave ? '' : styles.disabled}`}
              type="submit"
            >
              <Icon icon="check" />
              Salvar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default connect<StateProps, DispatchProps, OwnProps, ApplicationState>(
  (state: ApplicationState) => ({compositionGrade: getCompositionGrade(state)}),
  {
    deleteGradeGeneralAnnotationRequest,
    updateGradeGeneralAnnotationRequest,
    createGradeGeneralAnnotationRequest,
  },
)(
  reduxForm<{}, Props>({
    form: 'AddPlagiarismForm',
    validate: validateLink,
  })(AddPlagiarismForm),
);
