import {Utils} from '@letrustech/letrus-api-interfaces';
import {fromJS} from 'immutable';
import {Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {getOrCreateSubAccountService} from 'store/services/iuguGetOrCreateSubAccountService';
import {action} from 'typesafe-actions';

//Action types
export enum IuguGetOrCreateSubAccountTypes {
  GET_OR_CREATE_REQUEST = '@iuguSubAccounts/GET_OR_CREATE_REQUEST',
  GET_OR_CREATE_SUCCESS = '@iuguSubAccounts/GET_OR_CREATE_SUCCESS',
  GET_OR_CREATE_FAILURE = '@iuguSubAccounts/GET_OR_CREATE_FAILURE',
}

//Data types

//State type
export interface IuguGetOrCreateSubAccountsState
  extends ImmutableMap<{
    data: any;
    loading: boolean;
    error: boolean;
    dataCount: number;
  }> {}

//Update actions
export const getOrCreateSubAccountRequest = (params?: Utils.GetParams) =>
  action(IuguGetOrCreateSubAccountTypes.GET_OR_CREATE_REQUEST, params);

export const getOrCreateSubAccountSuccess = (data: any) =>
  action(IuguGetOrCreateSubAccountTypes.GET_OR_CREATE_SUCCESS, {data});

export const getOrCreateSubAccountFailure = () =>
  action(IuguGetOrCreateSubAccountTypes.GET_OR_CREATE_FAILURE);

//Sagas
export function* getOrCreateSubAccount() {
  try {
    const response = yield call(getOrCreateSubAccountService);
    yield put(getOrCreateSubAccountSuccess(response.data));
  } catch (err) {
    yield put(getOrCreateSubAccountFailure());
  }
}

//Selectors
const iuguSubAccountsSelector = (state: ApplicationState) =>
  state.get('iuguGetOrCreateSubAccounts');

export const getIuguInformation = createSelector(
  iuguSubAccountsSelector,
  (iuguSubAccounts) => iuguSubAccounts.getIn(['data']),
);

//Initial state
export const INITIAL_STATE: IuguGetOrCreateSubAccountsState = fromJS({
  data: fromJS({}),
  error: false,
  loading: false,
  dataCount: 0,
});

//Reducer
export const reducer: Reducer<IuguGetOrCreateSubAccountsState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case IuguGetOrCreateSubAccountTypes.GET_OR_CREATE_REQUEST:
      return state.withMutations((prevState) => prevState.set('loading', true));

    case IuguGetOrCreateSubAccountTypes.GET_OR_CREATE_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .set('data', action.payload.data)
          .set('dataCount', action.payload.data),
      );

    case IuguGetOrCreateSubAccountTypes.GET_OR_CREATE_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );

    default:
      return state;
  }
};

export default reducer;
