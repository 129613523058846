import React from 'react';
import styles from './StudentInfo.module.scss';

export interface AboutStudent {
  grade: string;
  age: number;
  accessibility: string;
  skip_genre_review: boolean;
  skip_theme_review: boolean;
  skip_words_number_review: boolean;
}

export interface PropsStudentInfo {
  aboutStudent: ImmutableMap<AboutStudent>;
}

const StudentInfo: React.FC<PropsStudentInfo> = ({aboutStudent}) => {
  const skipSomeReview =
    aboutStudent.get('skip_genre_review') ||
    aboutStudent.get('skip_theme_review') ||
    aboutStudent.get('skip_words_number_review');

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3>Sobre o aluno:</h3>
      </div>
      <div className={styles.body}>
        <div className={styles.body__main}>
          <p>
            <strong>Série:</strong> {aboutStudent.get('grade', '--')}
          </p>
        </div>
        <hr className={`${!skipSomeReview ? styles.hide : ''}`} />
        <ul
          className={`${styles.body__observations} ${
            !skipSomeReview ? styles.hide : ''
          }`}
        >
          <p>Os motivos abaixo não podem ser usados para zerar essa redação:</p>
          {aboutStudent.get('skip_words_number_review') && (
            <li>Tamanho do texto.</li>
          )}
          {aboutStudent.get('skip_genre_review') && <li>Fuga ao gênero.</li>}
          {aboutStudent.get('skip_theme_review') && <li>Fuga ao tema.</li>}
        </ul>
      </div>
    </div>
  );
};

export default StudentInfo;
