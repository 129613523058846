import {Map} from 'immutable';
export interface Validate extends Map<String, String | Number | Validate> {}

export interface Errors extends Object {
  cep?: string;
  user_id?: string;
  password?: string;
  new_password_re?: string;
  number?: string;
  access_code?: string;
  email?: string;
  mobil?: string;
  cpf?: string;
  cnpj?: string;
  telephone?: string;
  bank?: string;
  account_type?: string;
  name?: string;
  school_group_id?: string;
  theme_id?: string;
  start?: string;
  end?: string;
  time_hours?: string;
  time_minutes?: string;
  phone_number?: string;
  parent_phone_number?: string;
  zip_code?: string;
  confim_password?: string;
  bank_ag?: string;
  bank_cc?: string;
  bank_dig?: string;
  address?: string;
  city?: string;
  state?: string;
  parent_name?: string;
  link?: string;
  _error?: string;
}

export const validateBankInformationForm = (values: Validate) => {
  const isCPF = values.get('person_type') === 'Pessoa Física';

  const errors: Errors = {};

  // cpf
  if (isCPF && !values.get('cpf')) {
    errors.cpf = 'Campo obrigatório';
  } else {
    const cpf = String(values.get('cpf'))
      .replace(/\./gi, '')
      .replace(/_/gi, '')
      .replace(/-/gi, '');
    if (isCPF && cpf.length !== 11) {
      errors.cpf = 'CPF inválido, obrigatório 11 digitos';
    }
  }
  // cnpj
  if (!isCPF && !values.get('cnpj')) {
    errors.cnpj = 'Campo obrigatório';
  } else {
    const cnpj = String(values.get('cnpj'))
      .replace(/_/gi, '')
      .replace(/\./gi, '')
      .replace(/\//gi, '')
      .replace(/-/gi, '');
    if (!isCPF && cnpj.length !== 14) {
      errors.cnpj = 'CNPJ inválido, obrigatório 14 digitos';
    }
  }
  // agencia
  if (!values.get('bank_ag')) {
    errors.bank_ag = 'Campo obrigatório';
  }
  // conta
  if (!values.get('bank_cc')) {
    errors.bank_cc = 'Campo obrigatório';
  }
  // digito
  if (!values.get('bank_dig')) {
    errors.bank_dig = 'Campo obrigatório';
  }
  // endereco
  if (!values.get('address')) {
    errors.address = 'Campo obrigatório';
  }
  // cidade
  if (!values.get('city')) {
    errors.city = 'Campo obrigatório';
  }
  // estado
  if (!values.get('state')) {
    errors.state = 'Campo obrigatório';
  }
  // CEP
  if (!values.get('cep')) {
    errors.cep = 'Campo obrigatório';
  } else {
    const cep = String(values.get('cep')).replace(/_/gi, '').replace('-', '');
    if (cep.length !== 8) {
      errors.cep = 'CEP inválido, obrigatório 8 digitos';
    }
  }

  return errors;
};
