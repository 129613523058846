import {fromJS} from 'immutable';
import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {
  sendSurveyReview,
  SendSurveyReviewData,
} from 'store/services/surveyService';
import {action} from 'typesafe-actions';

//Action types
export enum SurveysTypes {
  SEND_USER_OPINION_FOR_SURVEYS_REQUEST = '@surveys/SEND_USER_OPINION_FOR_SURVEYS_REQUEST',
  SEND_USER_OPINION_FOR_SURVEYS_SUCCESS = '@surveys/SEND_USER_OPINION_FOR_SURVEYS_SUCCESS',
  SEND_USER_OPINION_FOR_SURVEYS_FAILURE = '@surveys/SEND_USER_OPINION_FOR_SURVEYS_FAILURE ',
}

//State type
export interface SurveysState
  extends ImmutableMap<{
    data: SendSurveyReviewData;
    loading: boolean;
    error: boolean;
  }> {}

//Create actions
export const sendUserOpinionForSurveysRequest = (data: SendSurveyReviewData) =>
  action(SurveysTypes.SEND_USER_OPINION_FOR_SURVEYS_REQUEST, data);

export const sendUserOpinionForSurveysSuccess = (data: SendSurveyReviewData) =>
  action(SurveysTypes.SEND_USER_OPINION_FOR_SURVEYS_SUCCESS, data);

export const sendUserOpinionForSurveysFailure = () =>
  action(SurveysTypes.SEND_USER_OPINION_FOR_SURVEYS_FAILURE);

//Sagas
export function* sendUserOpinionForSurveys(action: AnyAction) {
  try {
    const response = yield call(sendSurveyReview, action.payload);
    yield put(sendUserOpinionForSurveysSuccess(response.data));
  } catch (err) {
    yield put(sendUserOpinionForSurveysFailure());
  }
}

//Initial state
export const INITIAL_STATE: SurveysState = fromJS({
  data: fromJS({}),
  error: false,
  loading: false,
});

//Reducer
export const reducer: Reducer<SurveysState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case SurveysTypes.SEND_USER_OPINION_FOR_SURVEYS_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('loading', true).set('error', false),
      );

    case SurveysTypes.SEND_USER_OPINION_FOR_SURVEYS_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .set('data', action.payload.data),
      );

    case SurveysTypes.SEND_USER_OPINION_FOR_SURVEYS_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );

    default:
      return state;
  }
};

export default reducer;
