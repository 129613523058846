import api from 'store/services/api';

export interface LoginRequest {
  username: string;
  password: string;
  platform?: string;
}

export const loginService = (data: LoginRequest) =>
  api('/api/v1/auth/jwt/', {method: 'post', data});

export const logoutService = () =>
  api(`/api/v1/authlogout/`, {
    method: 'post',
  });

export const restorePasswordService = (data: {email: string}) =>
  api('/forgot_password/', {method: 'post', data});

export const fetchCurrentUserService = () => api('/api/v1/me/');

export const updateCurrentUserService = (user: any) =>
  api(`/api/v1/users/${user.id}/`, {
    method: 'patch',
    data: user,
  });

export const resetPasswordService = (user: any) =>
  api(`/api/v1/users/${user.id}/reset_password/`, {
    method: 'post',
    data: user,
  });
