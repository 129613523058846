import api from 'store/services/api';

export const fetchBankInformationService = (data: any) =>
  api(`/api/v1/iugu_subaccounts/${data.id}/`);

export const getOrCreateSubAccountService = () =>
  api(`/api/v1/iugu_get_or_create_subaccount/`, {
    method: 'post',
    data: {},
  });

export const disableSubAccountService = (subAccountId: string | number) =>
  api(`/api/v1/iugu_subaccounts/${subAccountId}/`, {
    method: 'patch',
    data: {
      active: 0,
    },
  });

export const verifySubAccountService = (data: any) =>
  api(`/api/v1/iugu_subaccounts/${data.sub_account}/request_verification/`, {
    method: 'post',
    data,
  });
