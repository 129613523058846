import React from 'react';
import './TabItem.scss';

export type Size = 'default' | 'small';

interface TabItemProps {
  active: boolean;
  handleClick: (event: any) => void;
  name: string;
  size: Size;
}

const TabItem: React.FC<TabItemProps> = ({
  active = false,
  handleClick,
  name,
  size = 'default',
}) => {
  return (
    <button
      className={`tab-item reviewer ${
        active ? 'active' : ''
      } tab-item--${size}`}
      type="button"
      onClick={handleClick}
    >
      {name}
    </button>
  );
};

export default TabItem;
