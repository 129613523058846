//TODO: replace redux-form for react-hook-f
import {LetrusApi} from '@letrustech/letrus-api-interfaces';
import {User} from '@letrustech/letrus-api-interfaces/dist/interfaces/letrus_api';
import SvgIcons from 'containers/Markings/SvgIcons';
import {AboutStudent} from 'containers/ReviewerEditorWrapper/StudentInfo';
import {fromJS, List} from 'immutable';
import {Icon} from 'letrus-ui';
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import ReactTooltip from 'react-tooltip';
import {InjectedFormProps} from 'redux-form';
import {Field, reduxForm} from 'redux-form/immutable';
import {
  getCompositionGradePlagiarismAnnotations,
  getReviewCompositionStudent,
} from 'store/reducers/compositionReviews';
import {getZeroGradeReasons} from 'store/reducers/zeroGradeComment';
import {ApplicationState} from 'store/rootReducer';
import {extractTextFromHtml} from '../../utils/functions/html';
import styles from './ZeroGradeSelectForm.module.scss';

export interface IZeroReason {
  comment: string;
  created: string;
  description: string;
  id: number;
  modified: string;
  title: string;
}

interface StateProps {
  zeroReasons: List<ImmutableMap<IZeroReason>>;
  student: ImmutableMap<User>;
  plagiarismAnnotations: List<ImmutableMap<LetrusApi.GeneralAnnotation>>;
}

interface ZeroGradeSelectFormProps {
  handleSubmit: (zeroGradeReasonId: number, comments: string) => void;
}

type Props = StateProps & ZeroGradeSelectFormProps;

type MergeReduxFormProps = Props & InjectedFormProps<{}, Props>;

const ZeroGradeSelectForm: React.FC<MergeReduxFormProps> = ({
  zeroReasons,
  handleSubmit,
  student,
  plagiarismAnnotations,
}) => {
  const [aboutStudent, setAboutStudent] = useState<ImmutableMap<AboutStudent>>(
    fromJS({}),
  );

  const [hasSelectedZeroGradeReason, setHasSelectedZeroGradeReason] = useState<
    boolean
  >(false);

  const [zeroGradeReason, setZeroGradeReason] = useState({
    description: '',
    id: 0,
    title: '',
    comment: '',
  });

  useEffect(() => {
    if (student.count()) {
      setAboutStudent(
        fromJS({
          skip_genre_review: student.getIn(['user_profile, skip_genre_review']),
          skip_theme_review: student.getIn(['user_profile, skip_theme_review']),
          skip_words_number_review: student.getIn([
            'user_profile, skip_words_number_review',
          ]),
        }),
      );
    }
  }, [student]);

  const handleSelectZeroGradeReason = (reason) => {
    setZeroGradeReason({
      description: reason.get('description'),
      id: reason.get('id'),
      title: reason.get('title'),
      comment: reason.get('comment'),
    });

    setHasSelectedZeroGradeReason(true);
  };

  const handleSubmitConfirmZeroGrade = (commentConfirmed) => {
    handleSubmit(zeroGradeReason.id, commentConfirmed);
  };

  const skipSomeReview =
    aboutStudent.get('skip_genre_review') ||
    aboutStudent.get('skip_theme_review') ||
    aboutStudent.get('skip_words_number_review');

  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmitConfirmZeroGrade}>
        <fieldset className={styles.form}>
          <div className={styles.header}>
            <h3>Zerar redação</h3>
            <legend>Selecione uma justificativa</legend>
          </div>
          <div className={styles.body}>
            {zeroReasons.map((reason) => {
              return (
                <div key={reason?.get('id')} className={styles.input}>
                  <label>
                    <Field
                      name="zeroGradeReason"
                      component="input"
                      type="radio"
                      value={reason?.get('id')}
                      parse={Number}
                      onClick={() => handleSelectZeroGradeReason(reason)}
                    />
                    {reason?.get('title')}
                  </label>
                  {extractTextFromHtml(reason?.get('description'), true)}
                </div>
              );
            })}
          </div>
          <div className={styles.footer}>
            {zeroGradeReason.title === 'Cópia literal de site da Internet' &&
              plagiarismAnnotations.size < 1 && (
                <div className={styles.warningPlagiarism}>
                  <Icon icon="exclamation-circle" />
                  Para zerar a redação por “Cópia literal de site da internet”,
                  você deve fazer, obrigatoriamente, ao menos uma marcação de
                  plágio no texto do aluno.
                </div>
              )}

            {skipSomeReview && (
              <figure className={styles.warningSpecial}>
                <figcaption className={styles.title}>
                  <SvgIcons iconName="resetWriting" />
                  Aviso do professor
                </figcaption>

                <ul className={styles.description}>
                  <p>
                    De acordo com as indicações do(a) professor(a), esta redação
                    não deve ser zerada por:
                  </p>

                  {aboutStudent.get('skip_words_number_review') && (
                    <li>Tamanho do texto.</li>
                  )}
                  {aboutStudent.get('skip_genre_review') && (
                    <li>Fuga ao gênero.</li>
                  )}
                  {aboutStudent.get('skip_theme_review') && (
                    <li>Fuga ao tema.</li>
                  )}
                </ul>
              </figure>
            )}

            <span
              data-tip="É obrigatório no mínimo uma marcação de plágio no texto para zerar por Cópia literal de site da Internet."
              data-for="resetReview"
            >
              <button
                disabled={
                  !hasSelectedZeroGradeReason ||
                  (zeroGradeReason.title ===
                    'Cópia literal de site da Internet' &&
                    plagiarismAnnotations.size < 1)
                }
                type="submit"
              >
                Confirmar
              </button>
            </span>
            <ReactTooltip
              disable={
                zeroGradeReason.title !== 'Cópia literal de site da Internet' ||
                plagiarismAnnotations.size > 0
              }
              className={styles.tooltipPlagiarism}
              id="resetReview"
              type="dark"
              effect="solid"
              place="top"
              multiline={true}
            />
          </div>
        </fieldset>
      </form>
    </div>
  );
};

export default connect((state: ApplicationState) => ({
  zeroReasons: getZeroGradeReasons(state),
  student: getReviewCompositionStudent(state),
  plagiarismAnnotations: getCompositionGradePlagiarismAnnotations(state),
}))(
  reduxForm<{}, any>({
    form: 'zeroGradeReduxForm',
  })(ZeroGradeSelectForm),
);
