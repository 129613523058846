/**
 * Get all text words.
 * @param {string} text - Input text.
 * @return {number} Text words.
 */
export function getWords(text?: string): RegExpMatchArray | null {
  if (text) {
    const fText = text.replace(/(<([^>]+)>)/gi, ''); // Strip HTML
    const cmd =
      '[a-zA-Z0-9_\\.\\-]+@[a-zA-Z0-9-]+(\\.[a-z]+)+|' + // email
      '(?:https?:\\/\\/)?\\w+(?:\\.[a-z]+)+(?:\\/[a-z]+)*|' + // URLs
      '(?:[\\#@]\\w+)|' + // hashtags and Twitter user names
      '(?:[aA-zZ]\\.[aZ-zZ]?)|' + // one letter abbreviations, e.g. U.S
      '(?:[DSds][Rr][Aa]?)\\.|' + // common abbreviations such as dr., sr.
      '[a-zA-ZÇÁÉÍÓÚÂÊÎÔÛÀÈÌÒÙÃÕÜçáéíóúâêîôûàèìòùãõü]+' + // any words
      '(-[a-zA-ZÇÁÉÍÓÚÂÊÎÔÛÀÈÌÒÙÃÕÜçáéíóúâêîôûàèìòùãõü]+)+|' + // with dashes
      '[a-zA-ZÇÁÉÍÓÚÂÊÎÔÛÀÈÌÒÙÃÕÜçáéíóúâêîôûàèìòùãõü]+|' + // BR-PT words
      '\\d+(([\\.,]\\d+)+)?|' + // Integer or floats (decimals)
      '-+';
    const regex = new RegExp(cmd, 'gm');
    return regex[Symbol.match](fText);
  }
  return null;
}

/**
 * Get total number of text words.
 * @param {string} text - Input text.
 * @return {number} Total text words.
 */
export default function countWords(text: string) {
  const result = getWords(text);

  return result ? result.length : 0;
}
