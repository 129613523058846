import api from './api';

export const createOrUpdateSubCompetenceGradeService = (data: any) =>
  api(
    `/api/v1/composition_reviews/${data.reviewId}/create_or_update_review_sub_competence_grade/`,
    {
      method: 'post',
      data,
    },
  );

export const createOrUpdateCompetenceGradeService = (data: any) =>
  api(
    `/api/v1/composition_reviews/${data.review_id}/create_or_update_review_competence_grade/`,
    {
      method: 'post',
      data,
    },
  );

export const abandonReviewService = (data: any) =>
  api(`/api/v1/composition_reviews/${data.compositionReviewId}/`, {
    method: 'delete',
    data: {
      reason_id: data.abandonReasonId,
    },
  });

export const fetchCompositionReviewByCompositionIdService = (data: any) =>
  api(`/api/v1/composition_reviews/`, {
    params: {
      composition_id: data.compositionId,
    },
  });

export const fetchFinishedGradesService = (params: any) =>
  api(`/api/v1/composition_reviews/`, {
    params: {
      ...params,
      limit: 10,
      ordering: '-finished',
    },
  });

export const fetchInProgressGradeService = (params: any) =>
  api(`/api/v1/composition_reviews/`, {
    params: {
      ...params,
      limit: 10,
      ordering: '-modified',
      flag_finished: false,
      flag_pre_finished: false,
    },
  });

export const updateCompositionGradeService = (data: any) =>
  api(`/api/v1/composition_reviews/${data.id}/`, {
    method: 'patch',
    data,
  });

export const zeroReviewService = (data: any) =>
  api(`/api/v1/composition_reviews/${data.reviewId}/zero_review/`, {
    method: 'put',
    data: {
      zero_grade_reason_id: data.zeroGradeReasonId,
    },
  });
