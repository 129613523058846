import jwtSign from 'jwt-encode';
import React from 'react';
import styles from './ReviewsForecast.module.scss';

const METABASE_SITE_URL = process.env.REACT_APP_METABASE_SITE_URL;
const METABASE_SECRET_KEY = process.env.REACT_APP_METABASE_SECRET_KEY ?? '';
const METABASE_FORECAST_QUESTION_ID = 59;

function ReviewerFinishGradesTable(): JSX.Element {
  const metabasePayload = {
    resource: {
      question: METABASE_FORECAST_QUESTION_ID,
    },
    params: {},
  };
  const metabaseToken = jwtSign(metabasePayload, METABASE_SECRET_KEY);
  const metabaseForecastGraphURL =
    METABASE_SITE_URL +
    '/embed/question/' +
    metabaseToken +
    '#bordered=true&titled=false';

  return (
    <div className={styles.container}>
      <h1>Previsão de redações nos próximos trinta dias</h1>

      <p>
        Esses números são previsões, e representam a data de início das
        atividades abertas pelas escolas e a quantidade de alunos. Não
        necessariamente todos os alunos fazem a atividade, e eles às vezes têm
        prazos de dias entre a abertura e a entrega
      </p>

      <iframe src={metabaseForecastGraphURL} title="Previsão" />
    </div>
  );
}

export default ReviewerFinishGradesTable;
