/* eslint-disable react-hooks/exhaustive-deps */
import {LetrusApi} from '@letrustech/letrus-api-interfaces';
import AlertModal from 'components/AlertModal';
import EditGradeSidebar from 'components/EditGradeSidebar';
import Loading from 'components/Loading';
import ModalFinishReview from 'components/ModalFinishReview';
import ModalLeaveMarking from 'components/ModalLeaveMarking';
import {push} from 'connected-react-router';
import HeaderRigthComponent from 'containers/Dashboard/HeaderRigthComponent';
import SvgIcons from 'containers/Markings/SvgIcons';
import ReviewerEditorWrapper from 'containers/ReviewerEditorWrapper';
import dayjs from 'dayjs';
import LogoLetrus from 'images/newLogo.png';
import {List, Map} from 'immutable';
import {Modal, Navbar} from 'letrus-ui';
import find from 'lodash/find';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps, useParams} from 'react-router';
import GradeEditor from '../../components/GradeEditor';
import {
  getCurrentUser,
  getIsSuperuser,
  getIsTeacher,
} from '../../store/reducers/authentication';
import {
  fetchCompositionReviewByCompositionIdRequest,
  finishReviewRequest,
  getCompositionFromCompositionGrade,
  getCompositionGrade,
  getCompositionGradeCompetences,
  getGenre,
  isFundII,
  isLoadingCompositionReview,
  reviewFinished,
  updateCompositionGradeRequest,
} from '../../store/reducers/compositionReviews';
import {
  DataStatus as GeneralAnnotationStatus,
  getGeneralAnnotation,
  getGeneralAnnotationStatus,
  isLoadingGeneralAnnotation,
  resetGeneralAnnotationStateRequest,
} from '../../store/reducers/generalAnnotations';
import {
  DataStatus as AnnotationStatus,
  getReviewAnnotation,
  getReviewAnnotationStatus,
  isLoadingReviewAnnotation,
  resetReviewAnnotationRequest,
} from '../../store/reducers/reviewAnnotations';
import {fetchZeroGradeCommentsRequest} from '../../store/reducers/zeroGradeComment';
import {ApplicationState} from '../../store/rootReducer';
import styles from './EditGrade.module.scss';

export interface StateProps {
  annotation: ImmutableMap<any>;
  annotationStatus: AnnotationStatus;
  generalAnnotation: ImmutableMap<any>;
  generalAnnotationStatus: GeneralAnnotationStatus;
  compositionGrade: ImmutableMap<LetrusApi.CompositionReview>;
  competences: List<ImmutableMap<LetrusApi.CompositionCompetence>>;
  isLoadingReview: boolean;
  isLoadingReviewAnnotation: boolean;
  isLoadingGeneralAnnotation: boolean;
  isSuperuser: boolean;
  isTeacher: boolean;
  userData: ImmutableMap<any>;
  reviewFinished: boolean;
  isFundII: boolean;
  composition: ImmutableMap<LetrusApi.CompositionEmbed>;
  genre: ImmutableMap<LetrusApi.Genre>;
}

export interface DispatchProps {
  fetchCompositionReviewByCompositionIdRequest: typeof fetchCompositionReviewByCompositionIdRequest;
  updateCompositionGradeRequest: typeof updateCompositionGradeRequest;
  fetchZeroGradeCommentsRequest: typeof fetchZeroGradeCommentsRequest;
  finishReviewRequest: typeof finishReviewRequest;
  resetReviewAnnotationRequest: typeof resetReviewAnnotationRequest;
  resetGeneralAnnotationStateRequest: typeof resetGeneralAnnotationStateRequest;
  push: typeof push;
}

export type Props = StateProps & DispatchProps & RouteComponentProps;

const EditGrade: React.FC<Props> = ({
  history,
  competences,
  compositionGrade,
  fetchCompositionReviewByCompositionIdRequest,
  fetchZeroGradeCommentsRequest,
  generalAnnotation,
  generalAnnotationStatus,
  isLoadingReview,
  isSuperuser,
  isTeacher,
  updateCompositionGradeRequest,
  userData,
  annotation,
  annotationStatus,
  isFundII,
  finishReviewRequest,
  reviewFinished,
  resetReviewAnnotationRequest,
  resetGeneralAnnotationStateRequest,
  isLoadingGeneralAnnotation,
  isLoadingReviewAnnotation,
  push,
  composition,
  genre,
}) => {
  const [isLoading, setIsLoading] = useState(
    isLoadingReview || isLoadingGeneralAnnotation || isLoadingReviewAnnotation,
  );
  const [redirectAfterFinish, setRedirectAfterFinish] = useState<string>();
  const [isNewSelection, setIsNewSelection] = useState(false);
  const [isPlagiarismSelection, setIsPlagiarismSelection] = useState(false);
  const [lastSelection, setLastSelection] = useState<any>({});
  const [activeTab, setActiveTab] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [suggestionMsg, setSuggestionMsg] = useState('');
  const [canEditReview, setcanEditReview] = useState(false);
  const [activeEditGradeStep, setActiveEditGradeStep] = useState(0);
  const [reviewerSidebarFull, setReviewerSidebarFull] = useState(false);
  const [isFinishReviewModalOpen, setIsFinishReviewModalOpen] = useState(false);
  const [gradeEditorState, setGradeEditorState] = useState();
  const {id} = useParams<{id: string}>();
  const [selectedCompetence, setSelectedCompetence] = useState<number>(0);
  const [isMarkingsCreation, setIsMarkingsCreation] = useState(false);
  const [
    isConfirmLeaveMarkingModalOpen,
    setIsConfirmLeaveMarkingModalOpen,
  ] = useState(false);
  const [openProposal, setOpenProposal] = useState(false);
  const [selectedTextEditorPosition, setSelectedTextEditorPosition] = useState(
    null,
  );

  const onResizeWindow = useCallback(
    (event) => {
      const editGradeSidebarWidth = event.currentTarget.outerWidth;

      if (editGradeSidebarWidth < 768 && !reviewerSidebarFull) {
        setReviewerSidebarFull(true);
      } else if (editGradeSidebarWidth >= 768 && reviewerSidebarFull) {
        setReviewerSidebarFull(false);
      }
    },
    [reviewerSidebarFull],
  );

  useEffect(() => {
    setIsLoading(
      isLoadingReview ||
        isLoadingGeneralAnnotation ||
        isLoadingReviewAnnotation,
    );
  }, [isLoadingReview, isLoadingGeneralAnnotation, isLoadingReviewAnnotation]);

  useEffect(() => {
    if (genre.size) {
      fetchZeroGradeCommentsRequest(genre?.get('id'), 'reviewer');
    }
  }, [genre]);

  useEffect(() => {
    if (redirectAfterFinish && reviewFinished) {
      history.replace(redirectAfterFinish);
    }
  }, [redirectAfterFinish, reviewFinished]);

  useEffect(() => {
    window.onresize = onResizeWindow;
  }, [onResizeWindow]);

  useEffect(() => {
    const flagFinished = compositionGrade?.get('flag_finished');
    const newcanEditReview = !flagFinished || isSuperuser || isTeacher;

    if (newcanEditReview !== canEditReview) {
      setcanEditReview(newcanEditReview);
    }
  }, [canEditReview, compositionGrade, isSuperuser, isTeacher]);

  const fetchCompositionReview = useCallback(() => {
    fetchCompositionReviewByCompositionIdRequest({
      compositionId: id,
    });
  }, [id]);

  useEffect(() => {
    fetchCompositionReview();
  }, [fetchCompositionReview]);

  useEffect(() => {
    if (annotationStatus) {
      fetchCompositionReview();
    }
  }, [annotationStatus]);

  useEffect(() => {
    if (
      generalAnnotation.count() &&
      generalAnnotationStatus !== GeneralAnnotationStatus.updated
    ) {
      const newGradeEditorState =
        generalAnnotationStatus === GeneralAnnotationStatus.added
          ? addGradeAnnotationToEditorState(generalAnnotation)
          : removeGradeAnnotationToEditorState(generalAnnotation);

      updateCompositionGradeRequest(
        compositionGrade
          .set('composition_markup_html', newGradeEditorState.editorStateHTML)
          .toJS(),
      );

      setGradeEditorState(newGradeEditorState);
    }
  }, [compositionGrade, generalAnnotation, generalAnnotationStatus]);

  useEffect(() => {
    if (annotation.count() && annotationStatus !== AnnotationStatus.updated) {
      const newGradeEditorState =
        annotationStatus === AnnotationStatus.added
          ? addGradeAnnotationToEditorState(annotation)
          : removeGradeAnnotationToEditorState(annotation);

      updateCompositionGradeRequest(
        compositionGrade
          .set('composition_markup_html', newGradeEditorState.editorStateHTML)
          .toJS(),
      );

      setGradeEditorState(newGradeEditorState);
    }
  }, [annotation, annotationStatus, compositionGrade]);

  useEffect(() => {
    if (annotation.count()) {
      resetReviewAnnotationRequest();
    }

    if (generalAnnotation.count()) {
      resetGeneralAnnotationStateRequest();
    }
  }, [annotation, generalAnnotation]);

  useEffect(() => {
    if (selectedCompetence === 10) {
      setIsPlagiarismSelection(true);
    } else {
      setIsPlagiarismSelection(false);
    }
  }, [selectedCompetence]);

  useEffect(() => {
    if (
      compositionGrade.get('zero_grade_reason') &&
      !compositionGrade.get('finished')
    ) {
      handleFinishReviewRequest();
      if (isFundII) {
        setRedirectAfterFinish('/?finishReview=true');
      } else {
        setIsFinishReviewModalOpen(true);
      }
    } else {
      setIsFinishReviewModalOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compositionGrade]);

  const addGradeAnnotationToEditorState = useCallback(
    (annotation) => {
      const selection = isFundII
        ? GradeEditor.getSelection(gradeEditorState)
        : selectedTextEditorPosition;

      const annotationType = annotation.get('annotation_type');

      const gradeEditorStateType = GradeEditor.applyAnnotationType(
        gradeEditorState,
        annotationType,
        selection,
      );

      return GradeEditor.applyAnnotationId(
        gradeEditorStateType,
        annotation.get('id'),
        selection,
      );
    },
    [gradeEditorState, isFundII, selectedTextEditorPosition],
  );

  const removeGradeAnnotationToEditorState = useCallback(
    (annotation) => {
      return GradeEditor.removeAnnotation(
        gradeEditorState,
        annotation.get('id'),
      );
    },
    [gradeEditorState],
  );

  //TODO: Maybe those two useEffect's bellow can call a function to abstract the similar logic

  const goTo = (location: string) => {
    push(location);
  };

  const toggleIsPlagiarismSelection = () => {
    setIsPlagiarismSelection(!isPlagiarismSelection);
    setActiveTab(1);
  };

  const closeButton = () => {
    setShowModal(false);
  };

  const hideConfirmationModal = () => {
    setShowConfirmationModal(false);
  };
  const handleCancelPlagiarismMark = () => {
    setSelectedCompetence(0);
    setIsPlagiarismSelection(false);
  };

  const onChangeEditor = (gradeEditorState) => {
    const {
      anchorOffset,
      focusOffset,
      anchorKey,
    } = gradeEditorState.editorState.getSelection();
    const isNewSelection =
      (lastSelection.anchorOffset !== anchorOffset ||
        lastSelection.focusOffset !== focusOffset ||
        lastSelection.anchorKey !== anchorKey) &&
      gradeEditorState.hasSelection;

    const shouldSwitchToCommentsTab =
      gradeEditorState.hasSelection || gradeEditorState.selectedComments.length;
    const newActiveTab = shouldSwitchToCommentsTab ? 1 : activeTab;

    setGradeEditorState(gradeEditorState);
    setActiveTab(newActiveTab);
    setIsNewSelection(isNewSelection);
    setLastSelection({
      anchorKey,
      anchorOffset,
      focusOffset,
    });
  };

  const onClickFinishReview = () => {
    if (!canEditReview) {
      history.replace('/');
    }

    if (!compositionGrade.get('zero_grade_reason')) {
      if (validateGradesForAllCompetences()) {
        return;
      }

      if (validateGradeDifferences()) {
        return;
      }
    }

    handleFinishReviewRequest();
    if (isFundII) {
      setRedirectAfterFinish('/?finishReview=true');
    } else {
      setIsFinishReviewModalOpen(true);
    }
  };

  const onClickOkButton = () => {
    handleFinishReviewRequest();
    setRedirectAfterFinish('/');
  };

  const handleFinishReviewRequest = () => {
    if (compositionGrade.get('flag_pre_finished')) {
      finishReviewRequest(
        compositionGrade
          .merge({
            flag_pre_finished: true,
          })
          .toJS(),
      );
    } else {
      finishReviewRequest(
        compositionGrade
          .merge({
            flag_pre_finished: true,
            finished: dayjs(),
          })
          .toJS(),
      );
    }
  };

  function compositionTitle() {
    if (!isLoading) {
      return compositionGrade?.getIn(['composition', 'title'], '');
    } else {
      return '';
    }
  }

  // The following "validate" are written in very bad style. They're too long
  // and hard to read and should be rewritten.
  function validateGradesForAllCompetences() {
    if (compositionGrade.get('review_grades').count() < competences.size) {
      setShowModal(true);
      setSuggestionMsg('Por favor, lance nota para todas competências!');

      return true;
    }

    return false;
  }

  /**
   * This function verifies the gender name and lists the competencies that are mandatory.
   * Also list what were the notes and comments made that are listed in 'review_grades'.
   * From this it is checked if contest contains comments are not empty,
   * if instead they return a modal displaying the mandatory message
   */

  function validateGradeDifferences() {
    if (
      compositionGrade?.getIn([
        'composition',
        'instruction_id',
        'genre',
        'name',
      ]) === 'ENEM'
    ) {
      const gradesCompetences = compositionGrade.get('review_grades').toJS();

      const comp1ID = find(competences.toJS(), {
        name: 'Competência 1',
      }).id;
      const comp4ID = find(competences.toJS(), {
        name: 'Competência 4',
      }).id;
      const gradeComp1 = find(gradesCompetences, {competence: comp1ID})
        .grade_item.points;
      const gradeComp4 = find(gradesCompetences, {competence: comp4ID})
        .grade_item.points;
      const gradeDiff2 = Math.abs(gradeComp1 - gradeComp4);
      if (gradeDiff2 > 40) {
        setShowModal(true);
        setSuggestionMsg(
          'Notamos que a diferença entre as notas da Competência 1 e Competência 4 é maior que 40 pontos, o que dificilmente acontece. Você deve corrigir.',
        );

        return true;
      }

      const comp5ID = find(competences.toJS(), {
        name: 'Competência 5',
      }).id;
      const hasZeroGrade = find(
        gradesCompetences,
        (grade) =>
          grade.grade_item.points === 0 && grade.competence !== comp5ID,
      );
      const hasGradeBigger40 = find(
        gradesCompetences,
        (grade) => grade.grade_item.points > 40 && grade.competence !== comp5ID,
      );

      if (hasZeroGrade && hasGradeBigger40) {
        setShowConfirmationModal(true);
        setSuggestionMsg(
          'Notamos que uma das notas das Competências está zerada, enquanto outras estão acima de 40 pontos, o que dificilmente acontece. Você tem certeza que deseja concluir a correção?',
        );
        return true;
      }

      const comp2ID = find(competences.toJS(), {
        name: 'Competência 2',
      }).id;
      const comp3ID = find(competences.toJS(), {
        name: 'Competência 3',
      }).id;
      const gradeComp2 = find(gradesCompetences, {competence: comp2ID})
        .grade_item.points;
      const gradeComp3 = find(gradesCompetences, {competence: comp3ID})
        .grade_item.points;
      const gradeDiff = Math.abs(gradeComp2 - gradeComp3);
      if (gradeDiff > 80) {
        setShowConfirmationModal(true);
        setSuggestionMsg(
          'Notamos que a diferença entre as notas da Competência 2 e Competência 3 é maior que 80 pontos, o que dificilmente acontece. Você tem certeza que deseja concluir a correção?',
        );
        return true;
      }
    }
    return false;
  }

  const aids = useMemo(
    () => compositionGrade?.getIn(['composition', 'aids'], List()),
    [compositionGrade],
  );

  const theme = useMemo(
    () => compositionGrade?.getIn(['composition', 'instruction_id'], Map()),
    [compositionGrade],
  );

  const handleChangeSelectedCompetence = (selectedCompetenceNumber: number) => {
    setSelectedCompetence(selectedCompetenceNumber);
  };

  const handleAddNewMarking = (hasAddingNewMarking) => {
    setIsMarkingsCreation(!isMarkingsCreation);
  };

  const handleClickLeaveMarking = () => {
    if (isMarkingsCreation) {
      setIsConfirmLeaveMarkingModalOpen(true);
    } else {
      goTo('/');
    }
  };

  const parent_id = composition.get('parent_id');
  const child_id = composition.get('child_id');
  const is_parent_reviewed = composition.get('is_parent_reviewed');

  const isWritingRewritingComposition =
    (!!parent_id && is_parent_reviewed) || !!child_id;

  const handleClickOpenProposal = () => {
    setOpenProposal(true);
  };

  return (
    <div className={styles.container}>
      <Navbar
        centerComponent={
          <button
            className={styles.logoButton}
            onClick={handleClickLeaveMarking}
          >
            <img className={styles.logo} src={LogoLetrus} alt="Logo Letrus" />
          </button>
        }
        rightComponent={<HeaderRigthComponent />}
      />
      {isWritingRewritingComposition && child_id && !isFundII ? (
        <figure className={styles.warning}>
          <p className={styles.firstWrite}>
            <SvgIcons iconName="firstWrite" />
            Você está vendo a primeira escrita
          </p>
        </figure>
      ) : null}
      <div className={styles.wrapper}>
        <div className={styles.tabs}>
          <EditGradeSidebar
            setSelectedTextEditorPosition={setSelectedTextEditorPosition}
            handleTabChangeProposal={() => setOpenProposal(false)}
            openProposal={openProposal}
            handleAddNewMarking={handleAddNewMarking}
            handleCancelPlagiarismMark={handleCancelPlagiarismMark}
            handleChangeSelectedCompetence={handleChangeSelectedCompetence}
            aids={aids}
            theme={theme}
            toggleIsPlagiarismSelection={toggleIsPlagiarismSelection}
            gradeEditorState={gradeEditorState}
            canEditReview={canEditReview}
            isNewSelection={isNewSelection}
            onFinishReview={onClickFinishReview}
            isPlagiarismSelection={isPlagiarismSelection}
            onChangeStep={(activeEditGradeStep: number) => {
              setActiveEditGradeStep(activeEditGradeStep);
            }}
            currentStep={activeEditGradeStep}
          />
        </div>
        <div className={styles.editorContent}>
          <ReviewerEditorWrapper
            handleClickOpenProposal={handleClickOpenProposal}
            compositionReviewId={
              compositionGrade && parseInt(compositionGrade.get('id'), 10)
            }
            activeTab={activeTab}
            activeEditGradeStep={activeEditGradeStep}
            canEditReview={canEditReview}
            toggleIsPlagiarismSelection={toggleIsPlagiarismSelection}
            isPlagiarismSelection={isPlagiarismSelection}
            eEditorState={gradeEditorState}
          >
            <div className={styles.editor}>
              <div className={styles.editor__title}>{compositionTitle()}</div>
              <div id="text_editor">
                <GradeEditor
                  isFundII={!isFundII}
                  selectedCompetence={selectedCompetence}
                  gradeEditorState={gradeEditorState}
                  compositionGrade={compositionGrade}
                  onChange={onChangeEditor}
                />
              </div>
            </div>
          </ReviewerEditorWrapper>
        </div>
        {showModal && (
          <AlertModal
            message={suggestionMsg}
            onClose={closeButton}
            type="warning"
          />
        )}
        {showConfirmationModal && (
          <AlertModal
            onClose={hideConfirmationModal}
            type="warning"
            message={suggestionMsg}
            buttons={[
              {
                size: 'large',
                kind: 'secondary',
                type: 'button',
                onClick: hideConfirmationModal,
                title: 'Voltar',
              },
              {
                size: 'large',
                kind: 'primary',
                type: 'button',
                onClick: onClickOkButton,
                title: 'Concluir',
              },
            ]}
          />
        )}
        {isFinishReviewModalOpen && (
          <Modal
            onClose={() => setIsFinishReviewModalOpen(false)}
            isOpen={isFinishReviewModalOpen}
            clickOutsideToClose={false}
            buttonClose={false}
            children={<ModalFinishReview />}
          />
        )}
        {isConfirmLeaveMarkingModalOpen && (
          <Modal
            onClose={() => setIsConfirmLeaveMarkingModalOpen(false)}
            clickOutsideToClose={false}
            isOpen={isConfirmLeaveMarkingModalOpen}
            buttonClose={false}
            children={
              <ModalLeaveMarking
                handleClickConfirm={() => goTo('/')}
                handleClickCancel={() => {
                  setIsConfirmLeaveMarkingModalOpen(false);
                }}
              />
            }
          />
        )}
      </div>
      {isLoading && <Loading show />}
    </div>
  );
};

export default connect<StateProps, DispatchProps, {}, ApplicationState>(
  (state: ApplicationState) => {
    return {
      annotation: getReviewAnnotation(state),
      annotationStatus: getReviewAnnotationStatus(state),
      generalAnnotation: getGeneralAnnotation(state),
      generalAnnotationStatus: getGeneralAnnotationStatus(state),
      compositionGrade: getCompositionGrade(state),
      competences: getCompositionGradeCompetences(state),
      isLoadingReview: isLoadingCompositionReview(state),
      isLoadingReviewAnnotation: isLoadingReviewAnnotation(state),
      isLoadingGeneralAnnotation: isLoadingGeneralAnnotation(state),
      isSuperuser: getIsSuperuser(state),
      isTeacher: getIsTeacher(state),
      userData: getCurrentUser(state),
      reviewFinished: reviewFinished(state),
      isFundII: isFundII(state),
      composition: getCompositionFromCompositionGrade(state),
      genre: getGenre(state),
    };
  },
  {
    fetchCompositionReviewByCompositionIdRequest,
    updateCompositionGradeRequest,
    fetchZeroGradeCommentsRequest,
    finishReviewRequest,
    resetReviewAnnotationRequest,
    resetGeneralAnnotationStateRequest,
    push,
  },
)(EditGrade);
