/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/exhaustive-deps */
import {LetrusApi} from '@letrustech/letrus-api-interfaces';
import Loading from 'components/Loading';
import {List} from 'immutable';
import React, {useEffect} from 'react';
import TagManager from 'react-gtm-module';
import {connect} from 'react-redux';
import {Route, RouteComponentProps} from 'react-router';
import {Redirect, useLocation} from 'react-router-dom';
import {AuthRoutes, PublicRoutes} from 'routes';
import {
  errorCurrentUser,
  fetchCurrentUserRequest,
  getCurrentUserId,
  getCurrentUserProfile,
  isAuthenticated,
  isLoadingCurrentUser,
  isReviewer,
} from 'store/reducers/authentication';
import {
  fetchTermsRequest,
  getTerms,
  isLoadingTerms,
} from 'store/reducers/globalConfiguration';
import {isUpdatingUserProfile} from 'store/reducers/userProfile';
import {ApplicationState} from 'store/rootReducer';
import {getCookie} from 'store/services/api';

const checkTermsVersion = (
  termType: string,
  terms: List<ImmutableMap<LetrusApi.LetrusGlobalConfiguration>>,
) => {
  const termByTermType = terms?.find(
    (term: ImmutableMap<LetrusApi.LetrusGlobalConfiguration>) =>
      term.get('parameter') === termType,
  );

  return termByTermType?.get('value');
};

export type UserRoles = 'reviewer' | 'unknown';

interface Props {
  Component: React.FC<any>;
  path: string;
  exact?: boolean;
  requiredRoles?: UserRoles[];
  redirectUnauthorized?: PublicRoutes;
}

interface StateProps {
  isAuthed?: boolean;
  userId?: number;
  userIsReviewer?: boolean;
  error: boolean;
  profile: ImmutableMap<LetrusApi.UserProfile>;
  terms: List<ImmutableMap<LetrusApi.LetrusGlobalConfiguration>>;
  isLoadingTerms: boolean;
  isLoadingCurrentUserProfile: boolean;
  isLoadingUpdateProfile: boolean;
}

interface DispatchProps {
  fetchCurrentUserRequest: typeof fetchCurrentUserRequest;
  fetchTermsRequest: typeof fetchTermsRequest;
}

type AuthRouteProps = Props & StateProps & DispatchProps;

const AuthRoute = ({
  Component,
  path,
  exact = false,
  requiredRoles,
  isAuthed,
  userId,
  userIsReviewer,
  redirectUnauthorized,
  fetchCurrentUserRequest,
  error,
  profile,
  terms,
  fetchTermsRequest,
  isLoadingCurrentUserProfile,
  isLoadingTerms,
  isLoadingUpdateProfile,
}: AuthRouteProps) => {
  const {pathname} = useLocation();
  const csrfToken = getCookie('csrftoken');

  useEffect(() => {
    if (!isAuthed && csrfToken) {
      fetchCurrentUserRequest();
    }
  }, [csrfToken, isAuthed]);

  const userRole = userIsReviewer ? 'reviewer' : 'unknown';
  const userHasRequiredRole = requiredRoles?.includes(userRole) ?? true;
  const allowAccess = isAuthed && userHasRequiredRole && csrfToken;

  const tagManagerArgs = {
    dataLayer: {
      userId: userId,
    },
  };

  const isPrivacyTermsVersionInvalid =
    profile?.get('terms_privacy_version_id') !==
    checkTermsVersion('terms_privacy_version_id', terms);

  const isReviewerTermsVersionInvalid =
    profile?.get('terms_reviewer_version_id') !==
    checkTermsVersion('terms_reviewer_version_id', terms);

  TagManager.dataLayer(tagManagerArgs);

  if (!terms?.size && !isLoadingTerms) {
    fetchTermsRequest();
    fetchCurrentUserRequest();
  }

  useEffect(() => {
    fetchCurrentUserRequest();
  }, [isLoadingUpdateProfile]);

  if (
    !pathname.includes(AuthRoutes.acceptTerms) &&
    profile?.size &&
    terms?.size &&
    (isPrivacyTermsVersionInvalid || isReviewerTermsVersionInvalid)
  ) {
    return (
      <Redirect
        to={{
          pathname: AuthRoutes.acceptTerms,
          search: `?next=${pathname}`,
        }}
      />
    );
  }

  return (!isAuthed && csrfToken && !error) ||
    isLoadingTerms ||
    isLoadingCurrentUserProfile ? (
    <Loading show />
  ) : (
    <Route
      exact={exact}
      path={path}
      render={(props: RouteComponentProps) => {
        if (!isAuthed && !csrfToken)
          return (
            <Redirect
              to={{
                pathname: PublicRoutes.login,
              }}
            />
          );

        if (allowAccess) return <Component {...props} />;

        return (
          <Redirect
            to={{
              pathname: redirectUnauthorized || PublicRoutes.unauthorized,
            }}
          />
        );
      }}
    />
  );
};

export default connect<StateProps, DispatchProps, {}, ApplicationState>(
  (state: ApplicationState) => ({
    isAuthed: isAuthenticated(state),
    userIsReviewer: isReviewer(state),
    userId: getCurrentUserId(state),
    error: errorCurrentUser(state),
    profile: getCurrentUserProfile(state),
    terms: getTerms(state),
    isLoadingTerms: isLoadingTerms(state),
    isLoadingCurrentUserProfile: isLoadingCurrentUser(state),
    isLoadingUpdateProfile: isUpdatingUserProfile(state),
  }),
  {
    fetchCurrentUserRequest,
    fetchTermsRequest,
  },
)(AuthRoute);
