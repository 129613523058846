import {ContentState, EditorState, Modifier} from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';
import Editor from 'draft-js-plugins-editor';
import 'draft-js/dist/Draft.css';
import chunk from 'lodash/chunk';
import find from 'lodash/find';
import React, {Component} from 'react';
import './BasicEditor.module.scss';
import convertGradeHTMLToEditorState from './lib/convert-grade-html-to-editor-state';
import findCommentSelectionStates from './lib/find-comment-selection-states';
import './ReviewedComposition.module.scss';

export interface GradeEditorGetTextSelection {
  (gradeEditorState: any, selection: any, blockDelimiter?: any): string;
}

const hexaDecimalTransparenceLevel = '35';

const reviewerDefaultStyle = (color) => ({
  backgroundColor: `${color}${hexaDecimalTransparenceLevel}`,
  borderTop: '1px solid #3e828b',
});

/**
 * `GradeEditor` encapsulates the Draft.js annotations editor for grades. It
 * contains no state, but is organized as a class in order to hide Draft.js
 * implementation details from its container.
 *
 * The container uses the `onChange` method to get a `gradeEditorState` object
 * with keys:
 *
 * - editorStateHTML (The serialized HTML of the grade after being edited)
 * - hasSelection (Whether the user is selecting text)
 * - selectedComments (The list of gradeAnnotations currently under the user's
 *   cursor)
 *
 * - editorState (The internal Draft.js state)
 *
 * To add/remove annotations, the container uses the static methods exposed in
 * this class:
 * - removeAnnotation
 * - applyAnnotationType
 * - applyAnnotationId
 */

export default class GradeEditor extends Component<any> {
  /**
   * When the grade HTML changes, convert it into an `EditorState`
   */

  reviewerCompetenceStyleMap = {
    default: {color: '#313131', fontWeight: 'bold'},
    red: !this.props.isFundII
      ? reviewerDefaultStyle('#FF0000')
      : {color: '#D74B9F', fontWeight: 'bold'},
    blue: !this.props.isFundII
      ? reviewerDefaultStyle('#0000FF')
      : {backgroundColor: '#CAE2F1'},
    purple: !this.props.isFundII
      ? reviewerDefaultStyle('#C300FF')
      : {backgroundColor: '#DBC5FF'},
    green: !this.props.isFundII
      ? reviewerDefaultStyle('#00FF00')
      : {backgroundColor: '#CCEBA5'},
    yellow: reviewerDefaultStyle('#FFFF00'),
    'comp-1': this.props.isFundII
      ? {color: '#D74B9F', fontWeight: 'bold'}
      : {color: '#FF3B57', fontWeight: 'bold'},
    'comp-1-grammar': this.props.isFundII
      ? {color: '#D74B9F', fontWeight: 'bold'}
      : reviewerDefaultStyle('#ff9999'),
    'comp-1-spell': this.props.isFundII
      ? {color: '#D74B9F', fontWeight: 'bold'}
      : reviewerDefaultStyle('#e02222'),
    'comp-1-orality': this.props.isFundII
      ? {color: '#D74B9F', fontWeight: 'bold'}
      : reviewerDefaultStyle('#ff6666'),
    'comp-2': this.props.isFundII
      ? {backgroundColor: '#CAE2F1'}
      : reviewerDefaultStyle('#4b8df8'),
    'comp-3': this.props.isFundII
      ? {backgroundColor: '#DBC5FF'}
      : reviewerDefaultStyle('#852b99'),
    'comp-4': this.props.isFundII
      ? {color: '#F0962C', fontWeight: 'bold'}
      : reviewerDefaultStyle('#34aa47'),
    'comp-5': this.props.isFundII
      ? {backgroundColor: '#CCEBA5'}
      : reviewerDefaultStyle('#ffb848'),
    'comp-6': {backgroundColor: '#E6AC00'},
    plagio: {
      color: '0000',
      backgroundColor: this.props.isFundII ? '#D2D2D2' : '#FFC10540',
      padding: '4px 0em',
    },
  };

  shouldComponentUpdate(nextProps) {
    if (
      (!this.props.gradeEditorState && nextProps.compositionGrade) ||
      !this.props.compositionGrade ||
      (nextProps.compositionGrade.get('composition_markup_html') &&
        nextProps.compositionGrade.get('composition_markup_html') !==
          this.props.compositionGrade.get('composition_markup_html'))
    ) {
      const markup = nextProps.compositionGrade.get('composition_markup_html');
      const editorState = markup
        ? EditorState.createWithContent(
            ContentState.createFromBlockArray(
              (convertGradeHTMLToEditorState(markup) as any) || [],
            ),
          )
        : EditorState.createEmpty();
      this.props.onChange({
        editorState,
        editorStateHTML: GradeEditor.getEditorStateHTML(editorState),
        hasSelection: false,
        selectedComments: [],
      });

      return false;
    }
    return true;
  }

  /**
   * Highlights different competences by inline-style and underlines the
   * currently selected `comment-:id` style
   *
   * @return {Object} styleMap A draft.js customStyleMap
   */

  getStyleMap() {
    const {selectedComments} = this.props.gradeEditorState || {};
    const selectedCommentsStyleMap = {};

    if (selectedComments && selectedComments.length && selectedComments[0]) {
      const comment = selectedComments[0];
      selectedCommentsStyleMap[`comment-${comment.id}`] = {
        textDecoration: 'underline',
      };
    }

    return {
      ...this.reviewerCompetenceStyleMap,
      ...selectedCommentsStyleMap,
    };
  }

  /**
   * When the draft.js `EditorState` changes, we determine if there's a
   * currently selected annotation by looking at the inline-styles at the
   * current position and notify the container about it
   *
   * We use draft.js' undo funcionality to force re-renders but disallow edits
   */

  onChange = (editorState) => {
    const selectionState = editorState.getSelection();
    const hasSelection =
      selectionState.focusOffset !== selectionState.anchorOffset;
    const currentInlineStyle = editorState
      .getCurrentInlineStyle()
      .toJS()
      .filter((s) => !!s);
    const selectedComments = chunk(currentInlineStyle, 2)
      // eslint-disable-next-line no-unused-vars
      .map(
        ([_commentType, commentId]: any) =>
          commentId &&
          find(this.props.compositionGrade.get('annotations'), {
            id: +(commentId.replace(/^comment-/, '') ?? ''),
          }),
      )
      .filter((annotation) => !!annotation)
      // The list is reversed so inner comments show up first, when there's
      // nesting
      .reverse();

    // Undo any changes made
    const patchedEditorState = EditorState.undo(
      // Force a re-render but don't change the content
      GradeEditor.forceRerender(editorState),
    );

    this.props.onChange({
      editorState: patchedEditorState,
      editorStateHTML: GradeEditor.getEditorStateHTML(editorState),
      hasSelection,
      selectedComments,
    });
  };

  render() {
    const {gradeEditorState} = this.props;
    if (!gradeEditorState) {
      return null;
    }

    return (
      <Editor
        editorState={gradeEditorState.editorState}
        customStyleMap={this.getStyleMap()}
        onChange={this.onChange}
      />
    );
  }

  /**
   * Converts back from an `EditorState` onto HTML using a forked version of
   * `draft-js-export-html` (in /vendor/draft-js-export-html)
   *
   * @return {String} editorStateHTML
   */

  static getEditorStateHTML(editorState) {
    return stateToHTML(editorState.getCurrentContent(), {
      inlineStyleFn: (styles) => {
        if (styles.size) {
          return {
            element: 'span',
            attributes: {
              class: styles.toJS().join(' '),
            },
          };
        }

        return null;
      },
    } as any);
  }

  static getSelection = (gradeEditorState) =>
    gradeEditorState.editorState.getSelection();

  /**
   * Get current selected text
   *
   * From: https://github.com/facebook/draft-js/issues/442
   *
   * @param  {GradeEditorState}
   * @param  {Draft.SelectionState}
   * @param  {String}
   * @return {String}
   */

  static getTextSelection: GradeEditorGetTextSelection = (
    gradeEditorState,
    selection,
    blockDelimiter,
  ) => {
    blockDelimiter = blockDelimiter || '\n';
    const contentState = gradeEditorState.editorState.getCurrentContent();
    const startKey = selection.getStartKey();
    const endKey = selection.getEndKey();
    const blocks = contentState.getBlockMap();

    let lastWasEnd = false;
    const selectedBlock = blocks
      .skipUntil(function (block) {
        return block.getKey() === startKey;
      })
      .takeUntil(function (block) {
        const result = lastWasEnd;

        if (block.getKey() === endKey) {
          lastWasEnd = true;
        }

        return result;
      });

    return selectedBlock
      .map(function (block) {
        const key = block.getKey();
        let text = block.getText();

        let start = 0;
        let end = text.length;

        if (key === startKey) {
          start = selection.getStartOffset();
        }
        if (key === endKey) {
          end = selection.getEndOffset();
        }

        text = text.slice(start, end);
        return text;
      })
      .join(blockDelimiter);
  };

  /**
   * Applies inline styles for annotations. The methods are split for
   * optimistic updates on the UI, while the comment-id loads from the API
   */

  static applyAnnotationType = (
    gradeEditorState,
    annotationType,
    selectionState,
  ) => {
    const editorState = EditorState.createWithContent(
      Modifier.applyInlineStyle(
        gradeEditorState.editorState.getCurrentContent(),
        selectionState,
        annotationType,
      ),
    );
    return {
      ...gradeEditorState,
      editorState,
      editorStateHTML: GradeEditor.getEditorStateHTML(editorState),
    };
  };

  static applyAnnotationId = (
    gradeEditorState,
    annotationId,
    selectionState,
  ) => {
    const editorState = EditorState.createWithContent(
      Modifier.applyInlineStyle(
        gradeEditorState.editorState.getCurrentContent(),
        selectionState,
        `comment-${annotationId}`,
      ),
    );

    return {
      ...gradeEditorState,
      editorState,
      editorStateHTML: GradeEditor.getEditorStateHTML(editorState),
    };
  };

  /**
   * Removes annotations from the editor state.
   *
   * The logic is to find selection anchor/focus offsets in the content with
   * `findCommentSelectionStates`, then clear the desired annotation's inline
   * styles in each of them
   */

  static removeAnnotation = (gradeEditorState, gradeAnnotationId) => {
    const [selectionStates, stylesToRemove]: any = findCommentSelectionStates(
      gradeEditorState.editorState,
      gradeAnnotationId,
    );

    const contentState = selectionStates.reduce(
      (currentContentState, selectionState) =>
        stylesToRemove[0]?.reduce(
          (newCurrentContentState, inlineStyle) =>
            Modifier.removeInlineStyle(
              newCurrentContentState,
              selectionState,
              inlineStyle,
            ),
          currentContentState,
        ) ?? currentContentState,
      gradeEditorState.editorState.getCurrentContent(),
    );

    const editorState = EditorState.createWithContent(contentState);

    return {
      ...gradeEditorState,
      editorState,
      editorStateHTML: GradeEditor.getEditorStateHTML(editorState),
    };
  };

  static forceRerender = (editorState) => {
    return EditorState.undo(
      EditorState.push(
        editorState,
        ContentState.createFromText(''),
        'insert-fragment',
      ),
    );
  };
}
