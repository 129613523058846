import {fromJS, List} from 'immutable';
import {push} from 'react-router-redux';
import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {deleteCookie, setCookie} from 'store/services/api';
import {
  fetchCurrentUserService,
  LoginRequest,
  loginService,
  logoutService,
  resetPasswordService,
  restorePasswordService,
  updateCurrentUserService,
} from 'store/services/authenticationService';
import {action} from 'typesafe-actions';

//Actions types
export enum AuthenticationTypes {
  LOGIN_REQUEST = '@authentication/LOGIN_REQUEST',
  LOGIN_SUCCESS = '@authentication/LOGIN_SUCCESS',
  LOGIN_FAILURE = '@authentication/LOGIN_FAILURE',

  LOGOUT_REQUEST = '@authentication/LOGOUT_REQUEST',
  LOGOUT_SUCCESS = '@authentication/LOGOUT_SUCCESS',
  LOGOUT_FAILURE = '@authentication/LOGOUT_FAILURE',

  RESTORE_PASSWORD_REQUEST = '@authentication/RESTORE_PASSWORD_REQUEST',
  RESTORE_PASSWORD_SUCCESS = '@authentication/RESTORE_PASSWORD_SUCCESS',
  RESTORE_PASSWORD_FAILURE = '@authentication/RESTORE_PASSWORD_FAILURE',

  CLEAR_RESTORE_PASSWORD = '@authentication/CLEAR_RESTORE_PASSWORD',

  FETCH_REQUEST = '@authentication/FETCH_REQUEST',
  FETCH_SUCCESS = '@authentication/FETCH_SUCCESS',
  FETCH_FAILURE = '@authentication/FETCH_FAILURE',

  UPDATE_REQUEST = '@authentication/UPDATE_REQUEST',
  UPDATE_SUCCESS = '@authentication/UPDATE_SUCCESS',
  UPDATE_FAILURE = '@authentication/UPDATE_FAILURE',

  RESET_PASSWORD_REQUEST = '@authentication/RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS = '@authentication/RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE = '@authentication/RESET_PASSWORD_FAILURE',

  RESET_STATE_REQUEST = '@authentication/RESET_STATE_REQUEST',
  RESET_STATE_SUCCESS = '@authentication/RESET_STATE_SUCCESS',
  RESET_STATE_FAILURE = '@authentication/RESET_STATE_FAILURE',
}

//Data types
export interface User {
  is_authenticated: boolean;
  finished_compositions_count: number;
  user: string;
  user_roles: string[];
  profileUpdated: boolean;
}

//State type
export type AuthenticationState = ImmutableMap<{
  data: ImmutableMap<User> | undefined;
  loading: boolean;
  isLoadingRestorePassword: boolean;
  restorePasswordError: boolean;
  error: boolean;
  errorCurrentUser: boolean;
  isLoadingCurrentUser: boolean;
  loginFailureMessage: string;
}>;

export interface LoginActionProps extends LoginRequest {
  nextRoute?: string;
}

//Login actions
export const loginUserRequest = (data: LoginActionProps) =>
  action(AuthenticationTypes.LOGIN_REQUEST, data);

export const loginSuccess = (data: List<ImmutableMap<User>>) =>
  action(AuthenticationTypes.LOGIN_SUCCESS, {data});

export const loginFailure = (data) => {
  return action(AuthenticationTypes.LOGIN_FAILURE, {data});
};

//Logout actions
export const logoutUserRequest = () =>
  action(AuthenticationTypes.LOGOUT_REQUEST);

export const logoutSuccess = () =>
  action(AuthenticationTypes.LOGOUT_SUCCESS, undefined);

export const logoutFailure = () => action(AuthenticationTypes.LOGOUT_FAILURE);

//Restore Password actions
export const restorePasswordRequest = (data: {email: string}) =>
  action(AuthenticationTypes.RESTORE_PASSWORD_REQUEST, {data});

export const restorePasswordSuccess = (data) =>
  action(AuthenticationTypes.RESTORE_PASSWORD_SUCCESS, {data});

export const restorePasswordFailure = () =>
  action(AuthenticationTypes.RESTORE_PASSWORD_FAILURE);

export const clearRestorePassword = () =>
  action(AuthenticationTypes.CLEAR_RESTORE_PASSWORD);

//Fetch user actions
export const fetchCurrentUserRequest = () =>
  action(AuthenticationTypes.FETCH_REQUEST);

export const fetchCurrentUserSuccess = (data: List<ImmutableMap<User>>) =>
  action(AuthenticationTypes.FETCH_SUCCESS, {data});

export const fetchCurrentUserFailure = () =>
  action(AuthenticationTypes.FETCH_FAILURE);

//Update user actions
export const updateCurrentUserRequest = (data: any) =>
  action(AuthenticationTypes.UPDATE_REQUEST, data);

export const updateCurrentUserSuccess = (data: any) =>
  action(AuthenticationTypes.UPDATE_SUCCESS, data);

export const updateCurrentUserFailure = () =>
  action(AuthenticationTypes.UPDATE_FAILURE);

export const resetPasswordRequest = () =>
  action(AuthenticationTypes.RESET_PASSWORD_REQUEST);

export const resetPasswordSuccess = (data: any) =>
  action(AuthenticationTypes.RESET_PASSWORD_SUCCESS, {data});

export const resetPasswordFailure = () =>
  action(AuthenticationTypes.RESET_PASSWORD_FAILURE);

//Reset state actions

export const resetStateRequest = () =>
  action(AuthenticationTypes.RESET_STATE_REQUEST);

export const resetStateSuccess = () =>
  action(AuthenticationTypes.RESET_STATE_SUCCESS);

export const resetStateFailure = () =>
  action(AuthenticationTypes.RESET_STATE_FAILURE);

//Sagas
export function* loginUser(action: AnyAction) {
  try {
    const response = yield call(loginService, action.payload);
    // const isReviewer = response.data.user_roles.find((role) => {
    //   return role.role_type.includes('reviewer');
    // });

    // if (!isReviewer) {
    //   throw new Error('Not allowed');
    // }

    if (process.env.NODE_ENV === 'development') {
      yield call(setCookie, {
        name: 'csrftoken',
        value: response.data.token,
      });
    }
    yield put(loginSuccess(response.data));
    yield put(push(action.payload.nextRoute));
  } catch (err) {
    yield put(loginFailure(err?.response?.data));
  }
}

export function* restorePassword(action: AnyAction) {
  try {
    const response = yield call(restorePasswordService, action.payload.data);
    yield put(restorePasswordSuccess(response.data));
  } catch (err) {
    yield put(restorePasswordFailure());
  }
}

export function* logoutUser() {
  try {
    yield call(deleteCookie, {
      name: 'csrftoken',
    });
    yield call(logoutService);
    yield put(logoutSuccess());
    yield put(push('/login'));
  } catch (err) {
    yield put(logoutFailure());
  }
}

export function* fetchCurrentUser() {
  try {
    const response = yield call(fetchCurrentUserService);
    yield put(fetchCurrentUserSuccess(response.data));
  } catch (err) {
    yield put(fetchCurrentUserFailure());
  }
}

export function* updateCurrentUser(action: AnyAction) {
  try {
    const response = yield call(updateCurrentUserService, action.payload);
    yield put(updateCurrentUserSuccess(response.data));
  } catch (err) {
    yield put(updateCurrentUserFailure());
  }
}

export function* resetPassword(action: AnyAction) {
  try {
    const response = yield call(resetPasswordService, action.payload);
    yield put(resetPasswordSuccess(response.data));
  } catch (err) {
    yield put(resetPasswordFailure());
  }
}

export function* resetState() {
  try {
    yield put(resetStateSuccess());
  } catch (error) {
    yield put(resetStateFailure());
  }
}

//Initial state
export const INITIAL_STATE: AuthenticationState = fromJS({
  data: fromJS({
    is_authenticated: false,
    finished_compositions_count: 0,
    user: '',
    user_roles: [],
    profileUpdated: false,
  }),
  error: false,
  errorCurrentUser: false,
  loading: false,
  isLoadingRestorePassword: false,
  restorePasswordError: false,
  isLoadingCurrentUser: false,
  loginFailureMessage: '',
});

//Selectors
const authenticationSelector = (state: ApplicationState) =>
  state.get('authentication');

export const getAuthenticationError = createSelector(
  authenticationSelector,
  (authentication) => authentication.get('error'),
);

export const isAuthenticated = createSelector(
  authenticationSelector,
  (authentication) => authentication.getIn(['data', 'is_authenticated']),
);

export const getCurrentUser = createSelector(
  authenticationSelector,
  (authentication) => authentication?.getIn(['data', 'user']),
);

export const getRoleTypesSelector = createSelector(
  authenticationSelector,
  (authentication) =>
    authentication
      .getIn(['data', 'user_roles'], [])
      .map((role) => role.get('role_type')),
);

const getRoleTypes = (authentication) =>
  authentication
    .getIn(['data', 'user_roles'], [])
    .map((role) => role.get('role_type'));

export const getIsTester = createSelector(
  authenticationSelector,
  (authentication) =>
    !!getRoleTypes(authentication)
      .map((_role) => {
        const [user, role] = _role.split(':');
        return {
          user,
          role,
        };
      })
      .find(
        (userRole) => userRole.user === 'reviewer' && userRole.role === 'teste',
      ),
);

export const getIsSuperuser = createSelector(
  authenticationSelector,
  (authentication) => authentication?.getIn(['data', 'user', 'is_superuser']),
);

export const getIsTeacher = createSelector(
  authenticationSelector,
  (authentication) => {
    const user = authentication?.getIn(['data', 'user']);

    if (user?.get('is_superuser') || user?.get('is_staff')) {
      return true;
    }

    return !!getRoleTypes(authentication).filter(
      (userRoleType) =>
        userRoleType === 'teacher' ||
        userRoleType === 'director' ||
        userRoleType === 'manager' ||
        userRoleType === 'coordinator',
    ).length;
  },
);

export const getCurrentUserProfile = createSelector(
  authenticationSelector,
  (authentication) => authentication.getIn(['data', 'user', 'user_profile']),
);

export const getCurrentUserId = createSelector(
  authenticationSelector,
  (authentication) => authentication.getIn(['data', 'user', 'id']),
);

export const isReviewer = createSelector(
  authenticationSelector,
  (authentication) => {
    const roles = authentication
      .getIn(['data', 'user_roles'])
      ?.filter((item) => item.get('role_type').startsWith('reviewer'));
    return roles?.size;
  },
);

export const wasProfileUpdated = createSelector(
  authenticationSelector,
  (authentication) => authentication.getIn(['data', 'profileUpdated']),
);

export const errorUpdatingProfile = createSelector(
  authenticationSelector,
  (authentication) => authentication.get('error'),
);

export const isLoadingCurrentUser = createSelector(
  authenticationSelector,
  (authentication) => authentication.get('isLoadingCurrentUser'),
);

export const errorCurrentUser = createSelector(
  authenticationSelector,
  (authentication) => authentication.get('errorCurrentUser'),
);

export const isLoadingRestorePassword = createSelector(
  authenticationSelector,
  (auth: AuthenticationState) => auth.get('isLoadingRestorePassword'),
);

export const isRestorePasswordWithError = createSelector(
  authenticationSelector,
  (auth: AuthenticationState) => auth.get('restorePasswordError'),
);

export const getLoginFailureMessage = createSelector(
  authenticationSelector,
  (auth: AuthenticationState) => auth.get('loginFailureMessage'),
);

//Reducer
const reducer: Reducer<AuthenticationState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case AuthenticationTypes.LOGIN_REQUEST:
      return state.withMutations((prevState) => prevState.set('loading', true));

    case AuthenticationTypes.LOGIN_SUCCESS:
      return state.withMutations((prevState) => {
        return prevState
          .set('loading', false)
          .set('error', false)
          .set('data', fromJS(action.payload.data));
      });

    case AuthenticationTypes.LOGIN_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', true)
          .set('data', fromJS([]))
          .set('loginFailureMessage', action.payload.data?.non_field_errors[0]),
      );

    case AuthenticationTypes.LOGOUT_REQUEST:
      return state.withMutations((prevState) => prevState.set('loading', true));

    case AuthenticationTypes.LOGOUT_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .set('data', fromJS([])),
      );

    case AuthenticationTypes.LOGOUT_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', true)
          .set('data', fromJS([])),
      );

    case AuthenticationTypes.RESTORE_PASSWORD_REQUEST:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingRestorePassword', true)
          .set('restorePasswordError', false),
      );

    case AuthenticationTypes.RESTORE_PASSWORD_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingRestorePassword', false)
          .set('restorePasswordError', false),
      );

    case AuthenticationTypes.RESTORE_PASSWORD_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingRestorePassword', false)
          .set('restorePasswordError', true),
      );

    case AuthenticationTypes.CLEAR_RESTORE_PASSWORD:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingRestorePassword', false)
          .set('restorePasswordError', false),
      );

    case AuthenticationTypes.FETCH_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('isLoadingCurrentUser', true),
      );

    case AuthenticationTypes.FETCH_SUCCESS:
      return state.withMutations((prevState) => {
        return prevState
          .set('isLoadingCurrentUser', false)
          .set('errorCurrentUser', false)
          .set('data', fromJS(action.payload.data));
      });

    case AuthenticationTypes.FETCH_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('isLoadingCurrentUser', false)
          .set('errorCurrentUser', true)
          .set('data', fromJS([])),
      );

    case AuthenticationTypes.UPDATE_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('loading', true).setIn(['data', 'profileUpdated'], false),
      );

    case AuthenticationTypes.UPDATE_SUCCESS: {
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .setIn(['data', 'profileUpdated'], true)
          .setIn(['data', 'user'], fromJS(action.payload)),
      );
    }

    case AuthenticationTypes.UPDATE_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );

    case AuthenticationTypes.RESET_PASSWORD_REQUEST:
      return state.withMutations((prevState) => prevState.set('loading', true));

    case AuthenticationTypes.RESET_PASSWORD_SUCCESS:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', false),
      );

    case AuthenticationTypes.RESET_PASSWORD_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', true)
          .set('data', fromJS([])),
      );

    case AuthenticationTypes.RESET_STATE_REQUEST: {
      return state.withMutations((prevState) =>
        prevState.set('loading', true).set('error', false),
      );
    }

    case AuthenticationTypes.RESET_STATE_SUCCESS: {
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .setIn(['data', 'profileUpdated'], false),
      );
    }

    case AuthenticationTypes.RESET_STATE_FAILURE: {
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );
    }

    default:
      return state;
  }
};

export default reducer;
