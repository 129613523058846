export function extractTextFromHtml(htmlText: string, space: boolean) {
  const span = document.createElement('span');
  span.innerHTML = htmlText;

  if (space) {
    const children = span.querySelectorAll('*');
    for (var i = 0; i < children.length; i++) {
      if (children[i].textContent) children[i].textContent += ' ';
      else children[i].textContent += ' ';
    }
  }

  return firstParagraphLetterToUpperCase(
    [span.textContent || span.innerText].toString().replace(/ +/g, ' '),
  );
}

function firstParagraphLetterToUpperCase(paragraph: string) {
  const upperCaseParagraph = paragraph[0].toUpperCase();

  return paragraph.replace(paragraph[0], upperCaseParagraph);
}
