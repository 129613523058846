import api from 'store/services/api';

export const confirmPayReviewsService = () =>
  api(`/api/v1/iugu_iugureviewertransfer/pay_reviews/`, {
    method: 'post',
  });

export const fetchPaymentsService = (params: any) =>
  api(`/api/v1/iugu_iugureviewertransfer/`, {
    params: {
      ...params,
      limit: 10,
      ordering: '-transfer_date',
    },
  });
