/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/exhaustive-deps */
//Todo: Componente bem complexo e extenso, com uma camada enorme de dependencias diretas. Analisar a possibilidade de dividi-lo em componentes/containers menores
import Alert from 'components/AlertModal';
import BankInformationForm from 'components/BankInformationForm';
import ChangePasswordModal from 'components/ChangePasswordModal';
import Loading from 'components/Loading';
import ReceiptFileUpload from 'components/ReceiptFileUpload';
import TabList from 'components/TabList';
import HeaderRigthComponent from 'containers/Dashboard/HeaderRigthComponent';
import PaymentsTable from 'containers/Profile/ProfileForm/Table';
import dayjs from 'dayjs';
import LogoLetrus from 'images/newLogo.png';
import {fromJS, Map} from 'immutable';
import {Navbar, TitleText} from 'letrus-ui';
import React, {useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {
  errorUpdatingProfile,
  getCurrentUser,
  getCurrentUserProfile,
  wasProfileUpdated,
} from 'store/reducers/authentication';
import {
  fetchFeatureFlagRequest,
  getFeatureFlags,
} from 'store/reducers/featureFlags';
import {
  getIuguInformation,
  getOrCreateSubAccountRequest,
} from 'store/reducers/iuguGetOrCreateSubAccount';
import {
  fetchInProgressPaymentsRequest,
  getInProgressPayments,
  isLoadingInProgressPayments,
} from 'store/reducers/iuguReviewerFinancialAccounts';
import {
  confirmPayReviewsRequest,
  fetchPaymentsRequest,
  getPayments,
  getPaymentsCount,
  isLoadingPayments,
} from 'store/reducers/iuguReviewerTransfers';
import {
  fetchBankInformationRequest,
  getBankInformation,
} from 'store/reducers/iuguSubAccounts';
import {
  createPresignedUrlRequest,
  createPresignedUrlRequest1,
  deletePresignedUrlRequest,
  fetchPresignedUrlRequest,
  getPresignedUrl,
  getUploadedReceipt,
  isDeletingPresignedUrl,
  Receipt,
} from 'store/reducers/presignedUrl';
import {updatePresignedUrlContentRequest} from 'store/reducers/s3';
import {ApplicationState} from 'store/rootReducer';
import iuguBankMasks from 'utils/iuguBankMasks';
import {FeatureFlags} from 'utils/types/featureFlags';
import styles from './Profile.module.scss';
import ProfileForm from './ProfileForm';

interface StateProps {
  inProgressPayments: ImmutableMap<any>;
  user: any;
  profile: any;
  isTeacher: any;
  payments: any;
  isLoadingReviewer: any;
  isLoadingPayments: any;
  isLoadingInProgressPayments: any;
  paymentsCount: any;
  iuguSubAccountResponse: any;
  iuguInformation: any;
  bankInformationResponse: ImmutableMap<any>;
  presignedUrl: any;
  uploadedReceipt: ImmutableMap<Receipt>;
  isDeletingPresignedUrl: any;
  wasProfileUpdated: boolean;
  errorUpdatingProfile: boolean;
  featureFlags: ImmutableMap<FeatureFlags>;
}

interface DispatchProps {
  updatePresignedUrlContentRequest: typeof updatePresignedUrlContentRequest;
  getOrCreateSubAccountRequest: typeof getOrCreateSubAccountRequest;
  fetchBankInformationRequest: typeof fetchBankInformationRequest;
  fetchPaymentsRequest: typeof fetchPaymentsRequest;
  fetchInProgressPaymentsRequest: typeof fetchInProgressPaymentsRequest;
  createPresignedUrlRequest: typeof createPresignedUrlRequest;
  createPresignedUrlRequest1: typeof createPresignedUrlRequest1;
  confirmPayReviewsRequest: typeof confirmPayReviewsRequest;
  deletePresignedUrlRequest: typeof deletePresignedUrlRequest;
  fetchPresignedUrlRequest: typeof fetchPresignedUrlRequest;
  fetchFeatureFlagRequest: typeof fetchFeatureFlagRequest;
}

type ProfileProps = DispatchProps & StateProps;

const Profile: React.FC<ProfileProps> = ({
  user,
  wasProfileUpdated,
  profile,
  isTeacher,
  payments,
  inProgressPayments,
  isLoadingPayments,
  isLoadingInProgressPayments,
  paymentsCount,
  iuguInformation,
  presignedUrl,
  uploadedReceipt,
  getOrCreateSubAccountRequest,
  fetchBankInformationRequest,
  fetchPaymentsRequest,
  fetchInProgressPaymentsRequest,
  createPresignedUrlRequest,
  createPresignedUrlRequest1,
  confirmPayReviewsRequest,
  deletePresignedUrlRequest,
  fetchPresignedUrlRequest,
  isDeletingPresignedUrl,
  errorUpdatingProfile,
  featureFlags,
  fetchFeatureFlagRequest,
}) => {
  const [isLoading, setIsLoading] = useState<any>(false);
  const [alertType, setAlertType] = useState<any>('success');
  const [alertActions, setAlertActions] = useState<any>(null);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState<any>(
    false,
  );
  const [updateResponse, setUpdateResponse] = useState<any>(false);
  const [subAccount, setSubAccount] = useState<any>(undefined);
  const [userInformation, setUserInformation] = useState<any>({
    person_type: 'Pessoa Física',
  });
  const [bankInformation, setBankInformation] = useState<any>({});

  useEffect(() => {
    fetchFeatureFlagRequest({
      flagKey: 'reviewer-redeem-unlock',
      entityId: String(user?.get('id')),
      entityContext: {
        user_id: user ? user?.get('id') : undefined,
        isDev: process.env.NODE_ENV !== 'production',
      },
    });
  }, []);

  useEffect(() => {
    if (wasProfileUpdated) {
      showAlert('Perfil atualizado com sucesso!');
    } else if (errorUpdatingProfile) {
      showAlert('Ocorreu um erro! Por favor, tente novamente.', 'error');
    }
    setIsLoading(false);
  }, [errorUpdatingProfile, wasProfileUpdated]);

  useEffect(() => {
    if (uploadedReceipt.count()) {
      newNFUpload(true, uploadedReceipt.get('file').name);
    }
  }, [uploadedReceipt]);

  useEffect(() => {
    setIsLoading(isLoadingPayments || isLoadingInProgressPayments);
  }, [isLoadingPayments, isLoadingInProgressPayments]);

  useEffect(() => {
    getOrCreateSubAccountRequest();
    fetchPaymentsRequest();
    fetchInProgressPaymentsRequest();
  }, [paymentsCount]);

  useEffect(() => {
    setSubAccount(fromJS(iuguInformation));

    if (fromJS(iuguInformation).get('id') !== undefined) {
      const data = fromJS(iuguInformation).get('iugu_sub_account') || Map();
      const bank = data.get('bank', '');
      const bankAccount = data.get('bank_cc', '');
      const person_type = data.get('person_type', 'Pessoa Física');
      const account_type = data.get('account_type', '');
      const bank_cc = parseBankAccount(
        bank,
        bankAccount,
        account_type,
        person_type,
      );

      if (data.count()) {
        setUserInformation({
          user_id: data.get('id'),
          person_type,
          cpf: data.get('cpf'),
          cnpj: data.get('cnpj') ? data.get('cnpj') : null,
          cep: data.get('cep'),
          address: data.get('address'),
          city: data.get('city'),
          state: data.get('state'),
          mobil: data.get('mobil'),
        });

        setBankInformation({
          bank_id: data.get('id'),
          bank,
          bank_ag: data.get('bank_ag'),
          bank_cc,
          account_type,
          bank_status: data.get('bank_status'),
          feedback: data.get('webhook_feedback'),
        });
      }
    }
  }, [iuguInformation]);

  useEffect(() => {
    // This function turns the bank name into the correct format to apply Caixa Econômica's masks
    if (bankInformation && bankInformation.bank === 'Caixa Econômica') {
      setBankInformation({
        ...bankInformation,
        bank: `Caixa Econômica Conta de ${
          bankInformation.bank_cc?.length - 1
        } dígitos`,
      });
    }
  }, [bankInformation]);

  const userProfileInitialValues = useMemo(() => {
    return {
      image: profile.get('image'),
      id: user.get('id'),
      first_name: user.get('first_name'),
      last_name: user.get('last_name'),
      email: user.get('email'),
      profile_id: profile.get('id'),
      phone_number: profile.get('phone_number'),
      gender: profile.get('gender'),
      birth: dayjs(profile.get('birth')).format('L'),
    };
  }, [profile, user]);

  const parseBankAccount = (
    bankName,
    bankAccount,
    account_type,
    person_type,
  ) => {
    const bankAccountOnlyNumbers = bankAccount.replace('-', '');

    const existIuguBankMasks =
      iuguBankMasks[bankName] &&
      iuguBankMasks[bankName].operation &&
      iuguBankMasks[bankName][account_type] &&
      iuguBankMasks[bankName][account_type][person_type];

    if (!existIuguBankMasks) {
      return bankAccountOnlyNumbers;
    }

    const bankAccountWithoutOperation = bankAccountOnlyNumbers.replace(
      iuguBankMasks[bankName].operation[account_type][person_type],
      '',
    );

    return bankAccountWithoutOperation;
  };

  const onSubmit = () => {
    setIsLoading(true);
  };

  const showAlert = (message, type = 'success', actions = null) => {
    setAlertType(type);
    setUpdateResponse(message);
    setAlertActions(actions);
  };

  const closeAlert = () => {
    setUpdateResponse(null);
    setAlertActions(null);
    setAlertType(null);
  };

  const openMessageModal = (message) => {
    setUpdateResponse(message);
  };

  const closeChangePasswordModal = () => {
    setShowChangePasswordModal(false);
  };

  const handleFileUpload = async (event) => {
    event.persist();
    const file = event.target.files[0];

    createPresignedUrlRequest({
      body: {content: 'nf'},
      file,
    });

    createAlertToUploadNF(true);
  };

  const newNFUpload = (hasSuccess, name) => {
    if (hasSuccess) {
      setUpdateResponse(`A NF ${name} foi enviada com sucesso!`);
    } else {
      setUpdateResponse(`Erro ao enviar a NF, tente novamente!`);
    }
  };

  //Todo: Examinar a lógica para esse método e refazer usando chamadas de actions se necessário. Está bem confuso
  const createAlertToUploadNF = async (showAlertExist = false) => {
    try {
      if (!showAlertExist) {
        const redeemNFButtons = [
          {
            title: 'Cancelar',
            kind: 'primary',
            onClick: () => closeAlert(),
          },
          {
            title: 'Anexar',
            type: 'button',
            kind: 'primary',
            Component: (props) => (
              <form {...props}>
                <label htmlFor="file" style={{margin: 0}}>
                  {`Anexar`}
                  <input
                    type="file"
                    name="file"
                    id="file"
                    onChange={handleFileUpload}
                    style={{display: 'none'}}
                  />
                </label>
              </form>
            ),
            onClick: () => {},
          },
        ];

        showAlert(
          'Anexe sua nota fiscal atual para prosseguir com o resgate de pagamento.',
          'warning',
          redeemNFButtons as any,
        );
      } else {
        const redeemNFButtons = [
          {
            title: 'Cancelar',
            kind: 'primary',
            onClick: () => closeAlert(),
          },
          {
            title: 'Confirmar resgate',
            type: 'button',
            kind: 'primary',
            onClick: async () => {
              try {
                confirmPayReviewsRequest();

                showAlert('Resgate realizado com sucesso.');

                setTimeout(() => window.location.reload(), 6000);
              } catch (error) {
                showAlert('Erro ao fazer resgate tente novamente.', 'error');
              }
            },
          },
        ];
        setAlertActions(redeemNFButtons);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error({error});

      showAlert('Erro ao enviar a NF', 'error');
    }
  };

  const paymentsTableColumns: any = useMemo(
    () => [
      {
        Header: 'Data',
        accessor: 'transfer_date',
      },
      {
        Header: 'Correções',
        accessor: 'reviews_number',
      },
      {
        Header: 'Valor',
        accessor: 'amount_cents',
      },
      {
        Header: 'Status',
        accessor: 'withdraw_status',
      },
    ],
    [],
  );

  const inProgressPaymentsTableColumns: any = useMemo(
    () => [
      {
        Header: 'Correções',
        accessor: 'reviews_number',
      },
      {
        Header: 'Valor',
        accessor: 'amount_localized',
      },
      {
        Header: 'Ação',
        accessor: 'action',
      },
    ],
    [],
  );

  const tabData = [
    {
      name: 'Cadastro de certificado',
      tabIndex: 0,
      children: (
        <div className={styles['tabList--receiptFileUpload']}>
          <p>
            Por favor, anexe aqui o seu comprovante de Micro Empreendedor
            Individual ou Pessoa Jurídica.
          </p>
          <p>
            <b>1) Para os que possuem MEI</b>
            <br />
            Você deverá solicitar o PDF do CCMEI, o Certificado MEI.
          </p>
          <p>
            <b>2) Para os que possuem uma empresa que não é MEI</b>
            <br />
            Você deverá solicitar o cartão CNPJ.
          </p>

          <ReceiptFileUpload
            showAlert={showAlert}
            closeAlert={closeAlert}
            setIsLoading={setIsLoading}
            createPresignedUrl1={createPresignedUrlRequest1}
            deletePresignedUrl={deletePresignedUrlRequest}
            fetchPresignedUrl={fetchPresignedUrlRequest}
            isDeletingPresignedUrl={isDeletingPresignedUrl}
            presignedUrl={presignedUrl}
          />
        </div>
      ),
    },
    {
      name: 'Informações bancárias',
      tabIndex: 1,
      children: (
        <div className={styles['tabList--bankInformationForm']}>
          <p>
            Por favor, cadastrar os dados da conta que gostaria de receber os
            futuros pagamentos.
          </p>
          <BankInformationForm
            initialValues={{
              ...userInformation,
              ...bankInformation,
            }}
            subAccount={subAccount}
            user={user}
            showAlert={showAlert}
            setLoading={setIsLoading}
            iuguInformation={iuguInformation}
          />
        </div>
      ),
    },
    {
      name: 'Pagamentos',
      tabIndex: 2,
      children: (
        <div className={styles['tabList--payments']}>
          <h2>Pagamentos em aberto</h2>
          <div className={styles.openPayments}>
            {inProgressPayments.get('reviews_number') ? (
              <>
                <p className="tab-container__infos">
                  {`Para realizar o resgate de pagamento é necessário um número
                    mínimo de ${
                      featureFlags.get('reviewer-redeem-unlock') === 'on'
                        ? '1 correção'
                        : '10 correções'
                    }.`}
                </p>
                <div>
                  <PaymentsTable
                    columns={inProgressPaymentsTableColumns}
                    inProgressPayments={inProgressPayments}
                    createAlertToUploadNF={createAlertToUploadNF}
                    hasEarlyRedeem={
                      featureFlags.get('reviewer-redeem-unlock') === 'on'
                    }
                  />
                </div>
              </>
            ) : (
              <p className="tab-container__infos">
                Nenhum resgate de pagamento em aberto.
              </p>
            )}
          </div>
          <h2>Histórico de pagamentos</h2>
          <div>
            {payments && paymentsCount > 0 ? (
              <PaymentsTable
                columns={paymentsTableColumns}
                payments={payments}
                createAlertToUploadNF={createAlertToUploadNF}
              />
            ) : (
              <p className="tab-container__infos">Nenhum pagamento.</p>
            )}
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className={styles.profile}>
      <div className={styles.topBar}>
        <Navbar
          centerComponent={
            <Link to="/">
              <img className={styles.logo} src={LogoLetrus} alt="Logo Letrus" />
            </Link>
          }
          rightComponent={<HeaderRigthComponent />}
        />
        <div className={styles.title}>
          <TitleText tag="h1" title="Minhas informações" icon="user" />
        </div>
      </div>
      <div className={styles.content}>
        <div className={`${styles.form}  ${styles.card}`}>
          <ProfileForm
            initialValues={userProfileInitialValues}
            onSubmit={onSubmit}
          />
        </div>
        <div className={`${styles.tabList}  ${styles.card}`}>
          <TabList data={tabData} />
        </div>
      </div>

      <Loading show={isLoading} />

      <Alert
        type={alertType}
        message={updateResponse}
        onClose={closeAlert}
        buttons={alertActions}
      />

      <ChangePasswordModal
        show={showChangePasswordModal}
        onHide={closeChangePasswordModal}
        isTeacher={isTeacher}
        user={user}
        openMessageModal={openMessageModal}
      />
    </div>
  );
};

export default connect(
  (state: ApplicationState) => ({
    inProgressPayments: getInProgressPayments(state),
    user: getCurrentUser(state),
    profile: getCurrentUserProfile(state),
    isTeacher: false,
    payments: getPayments(state),
    isLoadingReviewer: state.getIn(['']),
    isLoadingPayments: isLoadingPayments(state),
    isLoadingInProgressPayments: isLoadingInProgressPayments(state),
    paymentsCount: getPaymentsCount(state),
    iuguInformation: getIuguInformation(state),
    bankInformationResponse: getBankInformation(state),
    presignedUrl: getPresignedUrl(state),
    uploadedReceipt: getUploadedReceipt(state),
    isDeletingPresignedUrl: isDeletingPresignedUrl(state),
    wasProfileUpdated: wasProfileUpdated(state),
    errorUpdatingProfile: !!errorUpdatingProfile(state),
    featureFlags: getFeatureFlags(state),
  }),
  {
    getOrCreateSubAccountRequest,
    fetchBankInformationRequest,
    fetchPaymentsRequest,
    fetchInProgressPaymentsRequest,
    updatePresignedUrlContentRequest,
    createPresignedUrlRequest,
    createPresignedUrlRequest1,
    confirmPayReviewsRequest,
    fetchPresignedUrlRequest,
    deletePresignedUrlRequest,
    fetchFeatureFlagRequest,
  },
)(Profile);
