import api from 'store/services/api';

export const uploadToS3Service = (data: any) => {
  return api(`/s3/`, {
    method: 'post',
    data: JSON.stringify(data),
  });
};

export const updatePresignedUrlContentService = (url: any, file) =>
  api(url, {
    method: 'put',
    data: file,
  });
