import React from 'react';
import {Link} from 'react-router-dom';
import styles from './Restricted.module.scss';
interface DispatchProps {}

type Props = DispatchProps;

const Restricted: React.FC<Props> = () => {
  return (
    <div className={styles.wrapper}>
      <h1>Restricted Page</h1>
      <div className={styles.buttonsRow}>
        <Link to="/">Homepage</Link>
      </div>
    </div>
  );
};

export default Restricted;
