import {SelectionState} from 'draft-js';
import {getEntityRanges} from 'draft-js-utils';
import chunk from 'lodash/chunk';
import find from 'lodash/find';

export default function findCommentSelectionStates(
  editorState,
  selectedCommentId,
) {
  const blocks = editorState.getCurrentContent().getBlocksAsArray();
  const selectionStates: any = [];
  const stylesToRemove: any = [];

  blocks.forEach((block) => {
    const blockKey = block.getKey();
    const blockText = block.getText();
    const charMetaList = block.getCharacterList();
    const entityPieces = getEntityRanges(blockText, charMetaList);

    let anchorOffset = 0;

    // eslint-disable-next-line no-unused-vars
    entityPieces.forEach(([_entityKey, stylePieces]) => {
      stylePieces.forEach(([text, styleSet]) => {
        const markers = chunk(styleSet.toArray(), 2);
        const selectedCommentMarker = find(
          markers,
          // eslint-disable-next-line no-unused-vars
          ([_type, commentId]) => commentId === `comment-${selectedCommentId}`,
        );

        if (selectedCommentMarker) {
          stylesToRemove.push(selectedCommentMarker);
          selectionStates.push(
            new SelectionState({
              anchorKey: blockKey,
              focusKey: blockKey,
              focusOffset: anchorOffset + text.length,
              anchorOffset,
            }),
          );
        }

        anchorOffset += text.length;
      });
    });
  });

  return [selectionStates, stylesToRemove];
}
