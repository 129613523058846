import * as Sentry from '@sentry/react';
import {Integrations} from '@sentry/tracing';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';
import {hotjar} from 'react-hotjar';
import {Provider} from 'react-redux';
import App from './App';
import './index.module.scss';
import store, {history} from './store';

const tagManagerArgs = {
  gtmId: 'GTM-M3LKWBW',
};

if (process.env.NODE_ENV === 'production') {
  hotjar.initialize(2312604, 6);
}

dayjs.locale('pt-br');
dayjs.extend(localizedFormat);

if (process.env.NODE_ENV === 'production') {
  TagManager.initialize(tagManagerArgs);
  Sentry.init({
    dsn:
      'https://4fa166556e1b4db7a1e825f2b8f07da5@o222076.ingest.sentry.io/5473038',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],
    normalizeDepth: 10,
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <Provider store={store}>
    <App history={history} />
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
