import {LetrusApi} from '@letrustech/letrus-api-interfaces';
import {List} from 'immutable';
import {TabList} from 'letrus-ui';
import React, {useEffect, useState} from 'react';
import styles from './Markings.module.scss';
import MarkingsContent from './MarkingsContent';

// TODO: Remove this interface when the original one get fixed
interface CompositionCompetence extends LetrusApi.CompositionCompetence {
  label: string;
}

export interface MarkingsProps {
  isFundII: boolean;
  gradeEditorState: any;
  isCustomCorrectionGrid: boolean;
  competences: List<ImmutableMap<CompositionCompetence>>;
  handleChangeSelectedCompetence: (selectedCompetenceNumber: number) => void;
  competenceAdded?: number;
  canEditReview: boolean;
}

const Markings: React.FC<MarkingsProps> = ({
  isFundII,
  gradeEditorState,
  competences,
  isCustomCorrectionGrid,
  canEditReview,
  competenceAdded,
  handleChangeSelectedCompetence,
}) => {
  const [tabActive, setTabActive] = useState<number>(0);

  const [tabActiveName, setTabActiveName] = useState('Geral');

  const handleTabChange = (tabId: number) => {
    setTabActive(tabId);

    const tabName = tabsData.find((tab) => tab.id === tabId);
    if (tabName) {
      setTabActiveName(tabName?.name);
    }

    handleChangeSelectedCompetence(tabId);
  };

  const gridCompetenceTabs = competences.map((competence) => {
    return {
      id: competence.get('id'),
      name: competence.get('label'),
    };
  });

  const customGridCompetenceTabs = competences
    .map((competence) => {
      return {
        id: competence.get('id'),
        name:
          competence.get('label') === 'C1'
            ? 'Marcações'
            : competence.get('label'),
      };
    })
    .filter((competence) => competence.name === 'Marcações');

  const tabsData = [
    {
      id: 0,
      name: 'Geral',
    },
    ...(isCustomCorrectionGrid
      ? customGridCompetenceTabs.toJS()
      : gridCompetenceTabs.toJS()),
    {
      id: 10,
      name: 'Plágio',
    },
  ];

  useEffect(() => {
    if (competenceAdded) {
      const tabName = tabsData.find((tab) => tab.id === competenceAdded);
      if (tabName) {
        setTabActiveName(tabName?.name);
      }
      setTabActive(competenceAdded);
    }
  }, [competenceAdded, isFundII, tabsData]);

  return (
    <div className={styles.Markings}>
      <div className={styles.tabListContainer}>
        <TabList
          data={tabsData}
          handleTabChange={handleTabChange}
          tabActive={tabActive}
          zeroheight
        />
      </div>
      <hr />
      <MarkingsContent
        canEditReview={canEditReview}
        gradeEditorState={gradeEditorState}
        tabActiveName={tabActiveName}
      />
    </div>
  );
};

export default Markings;
