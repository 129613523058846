import {LetrusApi} from '@letrustech/letrus-api-interfaces';
import GradeEditor from 'components/GradeEditor';
import ModalInfo from 'components/ModalInfo';
import ZeroGradeSelectForm from 'containers/ZeroGradeSelectForm';
import {SelectionState} from 'draft-js';
import {List} from 'immutable';
import {Icon} from 'letrus-ui';
import React, {useState} from 'react';
import {connect} from 'react-redux';
import ReactTooltip from 'react-tooltip';
import {
  getCompositionGrade,
  getCompositionGradeAnnotations,
  getCompositionGradeCompetences,
  isCustomCorrectionGrid,
  isFundII,
  updateCompositionGradeRequest,
  zeroReviewRequest,
} from 'store/reducers/compositionReviews';
import {
  deleteGradeReviewAnnotationRequest,
  updateGradeReviewAnnotationRequest,
} from 'store/reducers/reviewAnnotations';
import {ApplicationState} from 'store/rootReducer';
import welcomeGeneralMarks from '../../../images/welcomeGeneralMarks.png';
import {default as SvgIcons} from '../SvgIcons';
import styles from './MarkingsContent.module.scss';
import MarkingsTable from './MarkingsTable';
import PlagiarismTable from './PlagiarismTable';

export interface MarkingsContentProps {
  tabActiveName: string;
  gradeEditorState: any;
  canEditReview: boolean;
}

export interface StateProps {
  isFundII: boolean;
  competences: List<ImmutableMap<LetrusApi.CompositionCompetence>>;
  annotations: List<ImmutableMap<LetrusApi.ReviewAnnotation>>;
  compositionGrade: ImmutableMap<LetrusApi.CompositionReview>;
  isCustomCorrectionGrid: boolean;
}

export type ModalTypes = 'ConfirmZeroGrade' | 'ZeroGradeSelectForm' | '';

export interface DispatchProps {
  updateGradeReviewAnnotationRequest: typeof updateGradeReviewAnnotationRequest;
  deleteGradeReviewAnnotationRequest: typeof deleteGradeReviewAnnotationRequest;
  zeroReviewRequest: typeof zeroReviewRequest;
  updateCompositionGradeRequest: typeof updateCompositionGradeRequest;
}

export type Props = DispatchProps & MarkingsContentProps & StateProps;

const MarkingsContent: React.FC<Props> = ({
  tabActiveName,
  competences,
  annotations,
  compositionGrade,
  zeroReviewRequest,
  canEditReview,
  isFundII,
  isCustomCorrectionGrid,
  gradeEditorState,
  updateCompositionGradeRequest,
  updateGradeReviewAnnotationRequest,
  deleteGradeReviewAnnotationRequest,
}) => {
  const [
    isZeroGradeSelectFormModalOpen,
    setIsZeroGradeSelectFormModalOpen,
  ] = useState<boolean>(false);

  const [competenceWarning, setCompetenceWarning] = useState(true);

  const currentContent = gradeEditorState.editorState.getCurrentContent();
  const firstBlock = currentContent.getBlockMap().first();
  const lastBlock = currentContent.getBlockMap().last();
  const firstBlockKey = firstBlock.getKey();
  const lastBlockKey = lastBlock.getKey();
  const lengthOfLastBlock = lastBlock.getLength();

  const selection = new SelectionState({
    anchorKey: firstBlockKey,
    anchorOffset: 0,
    focusKey: lastBlockKey,
    focusOffset: lengthOfLastBlock,
  });

  const fullTextSelected = GradeEditor.getTextSelection(
    gradeEditorState,
    selection,
  );

  const selectedCompetence = competences.find((competence) =>
    isFundII
      ? competence
          .get('name')
          .replace('Critério ', 'C')
          .slice(0, 2)
          .includes(tabActiveName)
      : competence
          .get('name')
          .replace('Competência ', 'C')
          .includes(tabActiveName),
  );

  const orthographicCompetences = competences.filter((competence) => {
    return !!competence
      .get('marker_categories')
      .find(
        (markerCategory) =>
          markerCategory.get('varname') === 'erros_ortograficos',
      );
  });

  const orthographicErrorsList = annotations
    ? annotations?.filter((annotation) => {
        return orthographicCompetences.find(
          (competence) =>
            !!(
              competence.get('id') === annotation.get('competence') &&
              annotation.get('rule')
            ),
        );
      })
    : List();

  const alreadyApprovedOrtographicErrors = orthographicErrorsList
    ?.filter((annotation) => annotation.get('reported_by'))
    .toJS();

  const alreadyApprovedOrtographicErrorsId = alreadyApprovedOrtographicErrors?.map(
    (annotation) => annotation.id,
  );

  const [approvedOrtographicErrors, setApprovedOrtographicErrors] = useState<
    number[]
  >(
    alreadyApprovedOrtographicErrorsId.length > 0
      ? alreadyApprovedOrtographicErrorsId
      : [],
  );

  const annotationsOfSelectedCompetence = annotations.filter(
    (annotation) =>
      annotation.get('competence') === selectedCompetence?.get('id') &&
      !annotation.get('rule') &&
      annotation.get('selected_text') !== fullTextSelected,
  );

  const handleClickConfirmDelete = (annotation) => {
    deleteGradeReviewAnnotationRequest(annotation.toJS());
  };

  const handleCloseWarning = () => {
    setCompetenceWarning(false);
  };

  const hadleUpdateSuggestion = (annotation, suggestedText) => {
    updateGradeReviewAnnotationRequest({
      ...(annotation?.toJS() ?? {}),
      suggested_text: suggestedText,
      reported_by: compositionGrade.get('reviewer_user'),
    });
  };

  const handleClickApprove = (orthographicError) => {
    const alreadyApproved = approvedOrtographicErrors.find(
      (comment) => comment === orthographicError.get('id'),
    );

    if (!alreadyApproved) {
      updateGradeReviewAnnotationRequest({
        ...(orthographicError?.toJS() ?? {}),
        reported_by: compositionGrade.get('reviewer_user'),
      });

      setApprovedOrtographicErrors(
        approvedOrtographicErrors.concat(orthographicError.get('id')),
      );
    } else {
      updateGradeReviewAnnotationRequest({
        ...(orthographicError?.toJS() ?? {}),
        reported_by: '',
      });

      const approvedOrtographicErrorsWithout = approvedOrtographicErrors;

      approvedOrtographicErrorsWithout.splice(
        approvedOrtographicErrors.indexOf(orthographicError.get('id')),
        1,
      );

      setApprovedOrtographicErrors(approvedOrtographicErrorsWithout);
    }
  };

  const handleZeroGradeSelectFormSubmit = (zeroGradeReasonId, comments) => {
    const reviewId = compositionGrade.get('id');

    zeroReviewRequest({zeroGradeReasonId, reviewId: parseInt(reviewId, 10)});

    setIsZeroGradeSelectFormModalOpen(!isZeroGradeSelectFormModalOpen);
  };

  const handleCloseModalZeroGradeSelectForm = () => {
    setIsZeroGradeSelectFormModalOpen(!isZeroGradeSelectFormModalOpen);
  };

  const handleOpenModal = () => {
    setIsZeroGradeSelectFormModalOpen(!isZeroGradeSelectFormModalOpen);
  };

  const renderTabActive = () => {
    if (tabActiveName.includes('Geral')) {
      return (
        <div className={styles.welcomeContainer}>
          <div>
            <h1 className={styles.welcomeTitle}>
              Bem-vindo(a) à área de correção!
            </h1>
            <span className={styles.description}>
              {isCustomCorrectionGrid
                ? `Para iniciar sua correção, leia o texto do(a) aluno(a), navegue
              nas abas acima, verifique as marcações que já foram feitas pela
              nossa inteligência artificial e atribuir níveis aos seis
              critérios. Também é possível apontar trechos de plágio no texto,
              ou validá-los caso tenham sido identificados automaticamente.`
                : `Para iniciar sua correção, selecione um trecho do texto à esquerda
              e faça uma marcação nele, ou navegue nas abas acima e verifique as
              marcações que já foram feitas pela nossa inteligência artificial.`}
            </span>
          </div>
          <img
            src={welcomeGeneralMarks}
            className={styles.welcomeImage}
            alt="Bem-vindo(a)"
          />
        </div>
      );
    }

    if (tabActiveName.includes('C1') || tabActiveName.includes('Marcações')) {
      return (
        <div>
          {competenceWarning && (
            <div className={styles.warning}>
              <div className={styles.texts}>
                <span className={styles.description}>
                  Para finalizar uma correção, você deve excluir as marcações
                  automáticas equivocadas e aprovar as corretas, adicionando
                  e/ou removendo as sugestões e os comentários se necessário.
                  Essa etapa é obrigatória.
                </span>
              </div>
              <button
                onClick={handleCloseWarning}
                className={styles.closeWarning}
              >
                <Icon icon={['fal', 'times']} />
              </button>
            </div>
          )}
          <h3 className={styles.titleAutoMarkings}>
            MARCAÇÕES AUTOMÁTICAS
            <span
              data-tip={
                'As marcações automáticas são as marcações feitas pela inteligência artificial da Letrus.'
              }
              data-for="autoMarkings"
              className={styles.iconQuestion}
            >
              <Icon icon="question" />
            </span>
            <ReactTooltip
              className={styles.tooltipQuestion}
              id="autoMarkings"
              type="dark"
              effect="solid"
              place="right"
              multiline={true}
            />
          </h3>
          <MarkingsTable
            approvedOrtographicErrors={approvedOrtographicErrors}
            markingType="automatic"
            handleClickApprove={handleClickApprove}
            annotations={orthographicErrorsList}
            competences={competences}
            canEditReview={canEditReview}
            hadleUpdateSuggestion={hadleUpdateSuggestion}
            handleClickConfirmDelete={handleClickConfirmDelete}
          />
          {!isCustomCorrectionGrid && (
            <>
              <h3 className={styles.titleAutoMarkings}>
                SUAS MARCAÇÕES
                <span
                  data-tip={
                    'São as marcações feitas por você ao longo da correção.'
                  }
                  data-for="autoMarkings"
                  className={styles.iconQuestion}
                >
                  <Icon icon="question" />
                </span>
                <ReactTooltip
                  className={styles.tooltipQuestion}
                  id="autoMarkings"
                  type="dark"
                  effect="solid"
                  place="right"
                  multiline={true}
                />
              </h3>
              <MarkingsTable
                markingType="suggestion"
                handleClickApprove={handleClickApprove}
                annotations={annotationsOfSelectedCompetence}
                competences={competences}
                hadleUpdateSuggestion={hadleUpdateSuggestion}
                canEditReview={canEditReview}
                handleClickConfirmDelete={handleClickConfirmDelete}
              />
              <footer className={styles.footerText}>
                AS EDIÇÕES são salvas automaticamente :)
              </footer>
            </>
          )}
        </div>
      );
    }
    if (tabActiveName.includes('C2')) {
      return (
        <div>
          <h3 className={styles.titleAutoMarkings}>
            SUAS MARCAÇÕES
            <span
              data-tip="Marcações que você identificou manualmente."
              data-for="autoMarkings"
              className={styles.iconQuestion}
            >
              <Icon icon="question" />
            </span>
            <ReactTooltip
              className={styles.tooltipQuestion}
              id="autoMarkings"
              type="dark"
              effect="solid"
              place="right"
              multiline={true}
            />
          </h3>
          <MarkingsTable
            markingType="normal"
            handleClickApprove={handleClickApprove}
            annotations={annotationsOfSelectedCompetence}
            competences={competences}
            hadleUpdateSuggestion={hadleUpdateSuggestion}
            canEditReview={canEditReview}
            handleClickConfirmDelete={handleClickConfirmDelete}
          />
          <footer className={styles.footerText}>
            AS EDIÇÕES são salvas automaticamente :)
          </footer>
        </div>
      );
    }
    if (tabActiveName.includes('C3')) {
      return (
        <div>
          <h3 className={styles.titleAutoMarkings}>
            SUAS MARCAÇÕES
            <span
              data-tip="Marcações que você identificou manualmente."
              data-for="autoMarkings"
              className={styles.iconQuestion}
            >
              <Icon icon="question" />
            </span>
            <ReactTooltip
              className={styles.tooltipQuestion}
              id="autoMarkings"
              type="dark"
              effect="solid"
              place="right"
              multiline={true}
            />
          </h3>
          <MarkingsTable
            markingType="normal"
            handleClickApprove={handleClickApprove}
            annotations={annotationsOfSelectedCompetence}
            competences={competences}
            hadleUpdateSuggestion={hadleUpdateSuggestion}
            canEditReview={canEditReview}
            handleClickConfirmDelete={handleClickConfirmDelete}
          />
          <footer className={styles.footerText}>
            AS EDIÇÕES são salvas automaticamente :)
          </footer>
        </div>
      );
    }
    if (tabActiveName.includes('C4')) {
      return (
        <div>
          <h3 className={styles.titleAutoMarkings}>
            SUAS MARCAÇÕES
            <span
              data-tip="Marcações que você identificou manualmente."
              data-for="autoMarkings"
              className={styles.iconQuestion}
            >
              <Icon icon="question" />
            </span>
            <ReactTooltip
              className={styles.tooltipQuestion}
              id="autoMarkings"
              type="dark"
              effect="solid"
              place="right"
              multiline={true}
            />
          </h3>
          <MarkingsTable
            canEditReview={canEditReview}
            markingType="suggestion"
            handleClickApprove={handleClickApprove}
            annotations={annotationsOfSelectedCompetence}
            competences={competences}
            hadleUpdateSuggestion={hadleUpdateSuggestion}
            handleClickConfirmDelete={handleClickConfirmDelete}
          />
          <footer className={styles.footerText}>
            AS EDIÇÕES são salvas automaticamente :)
          </footer>
        </div>
      );
    }
    if (tabActiveName.includes('C5')) {
      return (
        <div>
          <h3 className={styles.titleAutoMarkings}>
            SUAS MARCAÇÕES
            <span
              data-tip="Marcações que você identificou manualmente."
              data-for="autoMarkings"
              className={styles.iconQuestion}
            >
              <Icon icon="question" />
            </span>
            <ReactTooltip
              className={styles.tooltipQuestion}
              id="autoMarkings"
              type="dark"
              effect="solid"
              place="right"
              multiline={true}
            />
          </h3>
          <MarkingsTable
            canEditReview={canEditReview}
            markingType="normal"
            handleClickApprove={handleClickApprove}
            annotations={annotationsOfSelectedCompetence}
            competences={competences}
            hadleUpdateSuggestion={hadleUpdateSuggestion}
            handleClickConfirmDelete={handleClickConfirmDelete}
          />
          <footer className={styles.footerText}>
            AS EDIÇÕES são salvas automaticamente :)
          </footer>
        </div>
      );
    }
    if (tabActiveName.includes('Plágio')) {
      return (
        <div>
          <PlagiarismTable canEditReview={canEditReview} />
          <div className={styles.resetWriting}>
            {canEditReview && !isFundII && (
              <button onClick={handleOpenModal} className={styles.buttonReset}>
                <SvgIcons iconName="resetWriting" /> Zerar redação
              </button>
            )}
          </div>
          <footer className={styles.footerText}>
            AS EDIÇÕES são salvas automaticamente :)
          </footer>
        </div>
      );
    }

    return (
      <div>
        <h3 className={styles.titleAutoMarkings}>
          SUAS MARCAÇÕES
          <span
            data-tip="Marcações que você identificou manualmente."
            data-for="autoMarkings"
            className={styles.iconQuestion}
          >
            <Icon icon="question" />
          </span>
          <ReactTooltip
            className={styles.tooltipQuestion}
            id="autoMarkings"
            type="dark"
            effect="solid"
            place="right"
            multiline={true}
          />
        </h3>
        <MarkingsTable
          markingType="normal"
          handleClickApprove={handleClickApprove}
          annotations={annotationsOfSelectedCompetence}
          competences={competences}
          hadleUpdateSuggestion={hadleUpdateSuggestion}
          canEditReview={canEditReview}
          handleClickConfirmDelete={handleClickConfirmDelete}
        />
        <footer className={styles.footerText}>
          AS EDIÇÕES são salvas automaticamente :)
        </footer>
      </div>
    );
  };

  return (
    <div className={styles.tabs}>
      {renderTabActive()}
      {isZeroGradeSelectFormModalOpen && (
        <ModalInfo
          onCloseClick={handleCloseModalZeroGradeSelectForm}
          visible={true}
          component={
            <ZeroGradeSelectForm
              handleSubmit={handleZeroGradeSelectFormSubmit}
            />
          }
        />
      )}
    </div>
  );
};

export default connect<
  StateProps,
  DispatchProps,
  MarkingsContentProps,
  ApplicationState
>(
  (state: ApplicationState) => ({
    annotations: getCompositionGradeAnnotations(state),
    competences: getCompositionGradeCompetences(state),
    compositionGrade: getCompositionGrade(state),
    isCustomCorrectionGrid: isCustomCorrectionGrid(state),
    isFundII: isFundII(state),
  }),
  {
    updateCompositionGradeRequest,
    deleteGradeReviewAnnotationRequest,
    updateGradeReviewAnnotationRequest,
    zeroReviewRequest,
  },
)(MarkingsContent);
