import api from 'store/services/api';

export const addLikeInGeneralAnnotationService = (data: any) =>
  api(`/api/v1/general_annotations/${data.annotationId}/like_info/`, {
    method: 'patch',
    data: {
      like_info: data.likeInfo,
    },
  });

export const createGradeGeneralAnnotationService = (data: any) =>
  api(`/api/v1/general_annotations/`, {
    method: 'post',
    data,
  });

export const updateGradeGeneralAnnotationService = (data: any) =>
  api(`/api/v1/general_annotations/${data.id}/`, {
    method: 'patch',
    data,
  });

export const deleteGradeGeneralAnnotationService = (data: any) =>
  api(`/api/v1/general_annotations/${data.id}/`, {
    method: 'delete',
  });
