/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Dropzone from 'react-dropzone';

interface DropZoneFieldProps {
  children: any;
  handleOnDrop: any;
  input: any;
  className: any;
}

const DropZoneField: React.FC<DropZoneFieldProps> = ({
  children,
  handleOnDrop,
  input: {onChange},
  className: InputClassName,
}) => {
  return (
    <Dropzone
      // accept="image/jpeg, image/png, image/gif, image/bmp"
      onDrop={(file) => handleOnDrop(file, onChange)}
      multiple={false}
    >
      {({getRootProps, getInputProps}) => (
        <section>
          <div className={InputClassName} {...getRootProps()}>
            <input {...getInputProps()} />
            {children}
          </div>
        </section>
      )}
    </Dropzone>
  );
};

export default DropZoneField;
