import {fromJS, List} from 'immutable';
import {Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {fetchAbandonReviewReasonsService} from 'store/services/abandonReviewReasonsService';
import {action} from 'typesafe-actions';

//Action types
export enum AbandonReviewReasonsTypes {
  FETCH_ABANDON_REVIEW_REASONS_REQUEST = '@abandon_review_reasons/FETCH_ABANDON_REVIEW_REASONS_REQUEST',
  FETCH_ABANDON_REVIEW_REASONS_SUCCESS = '@abandon_review_reasons/FETCH_ABANDON_REVIEW_REASONS_SUCCESS',
  FETCH_ABANDON_REVIEW_REASONS_FAILURE = '@abandon_review_reasons/FETCH_ABANDON_REVIEW_REASONS_FAILURE',
}

//Data types

//State type
export interface AbandonReviewReasonsState
  extends ImmutableMap<{
    data: List<ImmutableMap<any>>;
    loading: boolean;
    error: boolean;
    dataCount: number;
  }> {}

//Fetch abandonReviewReasons Actions
export const fetchAbandonReviewReasonsRequest = () =>
  action(AbandonReviewReasonsTypes.FETCH_ABANDON_REVIEW_REASONS_REQUEST);

export const fetchAbandonReviewReasonsSuccess = (data: any) =>
  action(AbandonReviewReasonsTypes.FETCH_ABANDON_REVIEW_REASONS_SUCCESS, data);

export const fetchAbandonReviewReasonsFailure = () =>
  action(AbandonReviewReasonsTypes.FETCH_ABANDON_REVIEW_REASONS_FAILURE);

//Sagas
export function* fetchAbandonReviewReasons() {
  try {
    const response = yield call(fetchAbandonReviewReasonsService);
    yield put(fetchAbandonReviewReasonsSuccess(response));
  } catch (error) {
    yield put(fetchAbandonReviewReasonsFailure());
  }
}

//Initial State
export const INITIAL_STATE: AbandonReviewReasonsState = fromJS({
  data: [{}],
  loading: false,
  error: false,
  dataCount: 0,
});

//Selectors
const abandonReviewReasonsSelector = (state: ApplicationState) =>
  state.get('abandonReviewReasons');

export const getAbandonReviewReasons = createSelector(
  abandonReviewReasonsSelector,
  (abandonReviewReasons) => abandonReviewReasons.get('data'),
);

//Reducer
const reducer: Reducer<AbandonReviewReasonsState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case AbandonReviewReasonsTypes.FETCH_ABANDON_REVIEW_REASONS_REQUEST: {
      return state.withMutations((prevState) =>
        prevState.set('loading', true).set('error', false),
      );
    }

    case AbandonReviewReasonsTypes.FETCH_ABANDON_REVIEW_REASONS_SUCCESS: {
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .set('data', fromJS(action.payload.data.results))
          .set('dataCount', action.payload.count),
      );
    }

    case AbandonReviewReasonsTypes.FETCH_ABANDON_REVIEW_REASONS_FAILURE: {
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );
    }

    default:
      return state;
  }
};

export default reducer;
