/* eslint-disable consistent-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {LetrusApi} from '@letrustech/letrus-api-interfaces';
import {ReactComponent as Logo} from 'images/logoLetrus.svg';
import {List} from 'immutable';
import {NewButton} from 'letrus-ui';
import React, {FormEvent, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps, useLocation} from 'react-router';
import {Link, useHistory} from 'react-router-dom';
import {
  getCurrentUser,
  getCurrentUserProfile,
  logoutUserRequest,
} from 'store/reducers/authentication';
import {
  fetchTermsRequest,
  getHasLoadedTerms,
  getTerms,
} from 'store/reducers/globalConfiguration';
import {
  isUpdatingUserProfile,
  updateProfileRequest,
} from 'store/reducers/userProfile';
import {ApplicationState} from 'store/rootReducer';
import {usePrevious, useQueryParam} from 'utils/hooks';
import styles from './Terms.module.scss';
import TermsSection from './termsSection';

interface DispatchProps {
  updateProfileRequest: typeof updateProfileRequest;
  fetchTermsRequest: typeof fetchTermsRequest;
  logoutUserRequest: typeof logoutUserRequest;
}

interface StateProps {
  terms: List<ImmutableMap<LetrusApi.LetrusGlobalConfiguration>>;
  profile: ImmutableMap<LetrusApi.UserProfile>;
  hasLoadedTerms: boolean;
  isLoadingTerms: boolean;
  termsError: boolean;
  isLoadingUpdateProfile: boolean;
  user: ImmutableMap<LetrusApi.User>;
  schoolGroup: number;
}

export type TermsProps = DispatchProps & StateProps & RouteComponentProps;

const Terms: React.FC<TermsProps> = ({
  terms,
  profile,
  hasLoadedTerms,
  isLoadingUpdateProfile,
  user,
  fetchTermsRequest,
  updateProfileRequest,
  logoutUserRequest,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [termsToUpdate, setTermsToUpdate] = useState({});
  const location = useLocation();
  const previousIsLoadingUpdateProfile = usePrevious<boolean>(
    isLoadingUpdateProfile,
  );
  const history = useHistory();
  const params = useQueryParam();
  const isFirstAccess = !user.get('last_login');

  useEffect(() => {
    if (!terms?.size) {
      fetchTermsRequest();
    }
  }, [fetchTermsRequest, terms]);

  useEffect(() => {
    if (previousIsLoadingUpdateProfile && !isLoadingUpdateProfile) {
      const goTo = params.get('next') || '/';
      history.push(goTo);

      setIsLoading(false);
    }
  }, [previousIsLoadingUpdateProfile, isLoadingUpdateProfile, params, history]);

  const checkNewTermsVersion = (userTermsVersion, apiTermsVersion) => {
    const {
      terms_privacy_version_id: userPrivacyVersion,
      terms_reviewer_version_id: userReviewerVersion,
    } = userTermsVersion;

    const {
      terms_privacy_version_id: apiPrivacyVersion,
      terms_reviewer_version_id: apiReviewerVersion,
    } = apiTermsVersion;

    const newTerms = {
      ...(userPrivacyVersion !== apiPrivacyVersion && {
        terms_privacy_version_id: apiPrivacyVersion,
      }),
      ...(userReviewerVersion !== apiReviewerVersion && {
        terms_reviewer_version_id: apiReviewerVersion,
      }),
    };

    if (hasLoadedTerms) {
      if (Object.values(newTerms).length === 0) {
        return history.push('/');
      }

      return setTermsToUpdate(newTerms);
    }
  };

  const getUserTermsVersion = () => {
    const terms_privacy_version_id = profile.get('terms_privacy_version_id');
    const terms_reviewer_version_id = profile.get('terms_reviewer_version_id');
    const terms_student_version_id = profile.get('terms_use_version_id');

    return {
      terms_privacy_version_id,
      terms_reviewer_version_id,
      terms_student_version_id,
    };
  };

  const getApiTermsVersion = () =>
    terms
      .filter(
        (term) =>
          term.get('parameter') === 'terms_student_version_id' ||
          term.get('parameter') === 'terms_reviewer_version_id' ||
          term.get('parameter') === 'terms_privacy_version_id',
      )
      .reduce((prev, curr) => {
        return {...prev, [curr.get('parameter')]: curr.get('value')};
      }, {});

  useEffect(() => {
    if (terms.size) {
      if (location.pathname === '/aceite_termos') {
        checkNewTermsVersion(getUserTermsVersion(), getApiTermsVersion());
      }
    }
  }, [terms, user]);
  const getDataByUrl = (url) => {
    const useTerm = {
      name: 'terms_use_text',
      isReadonly: true,
      title: 'TERMOS E CONDIÇÕES DE USO DA PLATAFORMA LETRUS',
      id: 'terms_use_version_id',
    };

    const acceptTerm = {
      name: 'terms_use_text',
      isReadonly: false,
      title: 'TERMOS E CONDIÇÕES DE USO DA PLATAFORMA LETRUS',
      id: 'terms_use_version_id',
    };

    const privacyTerm = {
      name: 'terms_privacy_text',
      isReadonly: true,
      title: 'POLÍTICA DE PRIVACIDADE',
      id: 'terms_privacy_version_id',
    };

    const commitmentTerm = {
      name: 'terms_reviewer_text',
      isReadonly: true,
      title:
        'CONTRATO DE PRESTAÇÃO DE SERVIÇOS DE CORREÇÃO DE TEXTOS E OUTRAS AVENÇAS',
      id: 'terms_reviewer_version_id',
    };

    switch (url) {
      case '/termos':
        return [useTerm];

      case '/aceite_termos':
        return [acceptTerm, privacyTerm, commitmentTerm].filter(
          (term) => termsToUpdate[term.id],
        );

      case '/termo_compromisso':
        return [commitmentTerm];

      default:
        return [];
    }
  };

  const data = getDataByUrl(location.pathname);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const id = profile.get('id');
    if (!id) return;

    const termsId = data.map((term) => term.id);

    const termsVersionToUpdate = termsId.reduce((acc, next) => {
      const updatedTermsToSend = terms.find(
        (term) => term.get('parameter') === next,
      );
      return {
        ...acc,
        [next]: updatedTermsToSend && updatedTermsToSend.get('value'),
      };
    }, {});
    if (!termsVersionToUpdate) return;

    updateProfileRequest({...termsVersionToUpdate, id});
  };

  return (
    <div className={styles.wrapper}>
      <header className={styles.header}>
        <Link to="/">
          <Logo className={styles.logo} />
        </Link>
        <p className={styles.description}>
          Boas vindas!{isFirstAccess ? 'Abaixo estão' : 'Atualizamos'} nossos
          Termos e Condições e nossa Política de Privacidade. Por favor, leia e
          aceite para continuar.
        </p>
      </header>
      <form onSubmit={handleSubmit}>
        <div className={styles.terms}>
          <TermsSection data={data} terms={terms} />
        </div>
        <div className={styles.formButtons}>
          <NewButton
            userRole="student"
            kind="secondary"
            type="button"
            size="small"
            onClick={logoutUserRequest}
          >
            Cancelar
          </NewButton>
          <NewButton
            userRole="student"
            kind="primary"
            type="submit"
            size="small"
            isLoading={isLoading}
          >
            Aceitar e continuar
          </NewButton>
        </div>
      </form>
    </div>
  );
};

export default connect(
  (state: ApplicationState) => ({
    profile: getCurrentUserProfile(state),
    terms: getTerms(state),
    hasLoadedTerms: getHasLoadedTerms(state),
    isLoadingUpdateProfile: isUpdatingUserProfile(state),
    user: getCurrentUser(state),
  }),
  {
    updateProfileRequest,
    fetchTermsRequest,
    logoutUserRequest,
  },
)(Terms);
