import {Modal} from 'letrus-ui';
import moment from 'moment';
import React from 'react';
import styles from './PrivacyModal.module.scss';

export interface PrivacyModalProps {
  visible: boolean;
  onClose: () => void;
  privacyText: string;
  privacyDate: string;
}

const PrivacyModal: React.FC<PrivacyModalProps> = ({
  privacyText,
  privacyDate,
  visible,
  onClose,
}) => {
  return (
    <Modal
      isOpen={visible}
      vw={40}
      maxHeight={800}
      onClose={onClose}
      clickOutsideToClose
      buttonClose
    >
      <div className={styles.privacyWrapper}>
        <h3>POLÍTICA DE PRIVACIDADE</h3>
        <div className={styles.privacyBody}>
          <p>
            Fonte:{' '}
            <a href="/privacidade" target="_blank">
              www.corretor.letrus.com.br/privacidade/
            </a>
          </p>
          <p>Última atualização: {moment(privacyDate).format('DD/MM/YYYY')}</p>
          <div
            className={styles.privacyHTML}
            dangerouslySetInnerHTML={{__html: privacyText}}
          />
        </div>
      </div>
    </Modal>
  );
};

export default PrivacyModal;
