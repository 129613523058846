import Dashboard from 'containers/Dashboard';
import EditGrade from 'containers/EditGrade';
import Login from 'containers/Login';
import PageNotFound from 'containers/PageNotFound';
import Privacy from 'containers/Privacy';
import Profile from 'containers/Profile';
import Restricted from 'containers/Restricted';
import Terms from 'containers/Terms';
import Unauthorized from 'containers/Unauthorized';
import React from 'react';
import {Route, Switch} from 'react-router-dom';
import AuthRoute from 'utils/AuthRoute';
import NoAuthRoute from 'utils/NoAuthRoute';
import ScrollToTop from 'utils/ScrollToTop';

export enum AuthRoutes {
  dashboard = '/',
  restrictedPage = '/restricted',
  profile = '/perfil',
  correcao = '/correcao/:id',
  terms = '/termos',
  acceptTerms = '/aceite_termos',
  commitmentTerms = '/termo_compromisso',
}

export enum PublicRoutes {
  unauthorized = '/unauthorized',
  notFound = '/not-found',
  login = '/login',
  privacy = '/privacidade',
}

const routes = (
  <>
    <ScrollToTop />
    <Switch>
      <AuthRoute
        path={AuthRoutes.terms}
        Component={Terms}
        requiredRoles={['reviewer']}
      />
      <AuthRoute
        exact
        path={AuthRoutes.acceptTerms}
        Component={Terms}
        requiredRoles={['reviewer']}
      />
      <AuthRoute
        exact
        path={AuthRoutes.commitmentTerms}
        Component={Terms}
        requiredRoles={['reviewer']}
      />
      <AuthRoute
        exact
        path={AuthRoutes.dashboard}
        Component={Dashboard}
        requiredRoles={['reviewer']}
        redirectUnauthorized={PublicRoutes.login}
      />
      <AuthRoute
        exact
        path={AuthRoutes.profile}
        Component={Profile}
        requiredRoles={['reviewer']}
      />
      <AuthRoute
        exact
        path={AuthRoutes.correcao}
        Component={EditGrade}
        requiredRoles={['reviewer']}
      />
      <AuthRoute
        exact
        path={AuthRoutes.restrictedPage}
        Component={Restricted}
        requiredRoles={['reviewer']}
      />
      <Route exact path={PublicRoutes.privacy} component={Privacy} />
      <Route path={PublicRoutes.unauthorized} component={Unauthorized} />
      <Route path={PublicRoutes.notFound} component={PageNotFound} />
      <Route path={PublicRoutes.login} component={Login} />
      <NoAuthRoute />
    </Switch>
  </>
);

export default routes;
