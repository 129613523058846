import Tooltip from 'rc-tooltip';
import React from 'react';
import styles from './DisplayTextInTooltip.module.scss';

export interface Props {
  text: string;
}

export const DisplayTextInTooltip: React.FC<Props> = ({text}) => {
  return (
    <Tooltip
      placement="topLeft"
      overlayClassName={styles.container}
      trigger={['hover']}
      overlay={<p>{text}</p>}
      destroyTooltipOnHide
    >
      <span>{text}</span>
    </Tooltip>
  );
};

export default DisplayTextInTooltip;
