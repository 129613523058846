import {LetrusApi} from '@letrustech/letrus-api-interfaces';
import AddCommentSelectForm from 'containers/AddCommentSelectForm';
import {List} from 'immutable';
import {Icon} from 'letrus-ui';
import React, {useState} from 'react';
import ReactTooltip from 'react-tooltip';
import {default as AddCommentIcon} from '../../SvgIcons';
import styles from './MarkingsTable.module.scss';

type MarkingsTypes = 'automatic' | 'suggestion' | 'normal';

interface MarkingsTableProps {
  annotations: List<ImmutableMap<LetrusApi.ReviewAnnotation>>;
  hadleUpdateSuggestion: (
    annotation: LetrusApi.ReviewAnnotation,
    value: string,
  ) => void;
  handleClickConfirmDelete: (annotation: LetrusApi.ReviewAnnotation) => void;
  competences: List<ImmutableMap<LetrusApi.CompositionCompetence>>;
  markingType?: MarkingsTypes;
  approvedOrtographicErrors?: number[];
  canEditReview: boolean;
  handleClickApprove: (annotation: LetrusApi.ReviewAnnotation) => void;
}

const MarkingsTable: React.FC<MarkingsTableProps> = ({
  annotations,
  hadleUpdateSuggestion,
  handleClickConfirmDelete,
  handleClickApprove,
  approvedOrtographicErrors,
  canEditReview,
  markingType,
  competences,
}) => {
  const [openedComments, setOpenedComments] = useState<number[]>([]);

  const handleClickAddComment = (annotation: ImmutableMap<any>) => {
    const isCommentOpen = openedComments.find(
      (comment) => comment === annotation.get('id'),
    );

    if (isCommentOpen) {
      const newOpenComments = openedComments.filter(
        (comment) => comment !== annotation.get('id'),
      );

      setOpenedComments(newOpenComments);
    } else {
      setOpenedComments(openedComments.concat(annotation.get('id')));
    }
  };

  const handleClickCancelAddComment = (annotationId) => {
    const isCommentOpen = openedComments.find(
      (comment) => comment === annotationId,
    );

    if (isCommentOpen) {
      const newOpenComments = openedComments.filter(
        (comment) => comment !== annotationId,
      );

      setOpenedComments(newOpenComments);
    } else {
      setOpenedComments(openedComments.concat(annotationId));
    }
  };

  const handleClickSaveComment = (annotation) => {
    const annotationId = annotation?.get('id');
    const isCommentOpen = openedComments.find(
      (comment) => comment === annotationId,
    );

    if (isCommentOpen) {
      const newOpenComments = openedComments.filter(
        (comment) => comment !== annotationId,
      );

      setOpenedComments(newOpenComments);
    } else {
      setOpenedComments(openedComments.concat(annotationId));
    }
  };

  return (
    <div>
      {annotations.size === 0 && (
        <div className={`${styles.warning} ${styles.withoutMarks}`}>
          <div className={styles.texts}>
            <span className={styles.description}>
              {markingType === 'automatic'
                ? `Nossa inteligência artificial não fez marcações relacionadas a
                  esta competência.`
                : `Você ainda não fez marcações relacionadas a esta competência. Para
              fazê-las, selecione os trechos desejados no texto à esquerda.`}
            </span>
          </div>
        </div>
      )}
      {annotations.size > 0 && (
        <div>
          <div className={styles.title}>
            <div>
              <h3>Desvios</h3>
            </div>
            {markingType !== 'normal' && (
              <div>
                <h3>Sugestões</h3>
              </div>
            )}
            <div className={styles.spacer}></div>
          </div>
          {annotations.map((annotation: any) => {
            const isApproved = approvedOrtographicErrors?.includes(
              annotation.get('id'),
            );

            const suggested_text = annotation?.get('suggested_text', '');

            const elemMiddleware = document.createElement('div');

            elemMiddleware.innerHTML = suggested_text || '';
            const suggestionText =
              elemMiddleware.textContent || elemMiddleware.innerText || '';

            return (
              <div key={annotation.get('id')}>
                <div
                  className={`${styles.container} ${
                    openedComments.includes(annotation.get('id')) &&
                    styles.commentOpen
                  }`}
                >
                  <div
                    className={`${
                      markingType !== 'normal'
                        ? styles.deviation
                        : styles.onlyDeviation
                    }`}
                  >
                    {markingType !== 'normal'
                      ? annotation.get('selected_text')
                      : `"${annotation.get('selected_text')}"`}
                  </div>
                  {markingType !== 'normal' && (
                    <input
                      type="text"
                      name="suggestionText"
                      defaultValue={suggestionText}
                      className={styles.suggestion}
                      autoComplete="off"
                      placeholder=""
                      onChange={() => {}}
                      onBlur={(e) =>
                        hadleUpdateSuggestion(annotation, e.target.value)
                      }
                      disabled={!canEditReview}
                    />
                  )}
                  <div className={styles.buttons}>
                    {markingType === 'automatic' && (
                      <button
                        data-testid={`approveButton-${annotation.get('id')}`}
                        className={`${styles.approve} ${styles.buttons} ${
                          isApproved ? styles.checkApprove : ''
                        }`}
                        onClick={() =>
                          canEditReview && handleClickApprove(annotation)
                        }
                      >
                        <ReactTooltip place="top" type="dark" effect="solid" />
                        <span data-tip="Aprovar marcação">
                          <Icon size="lg" icon="thumbs-up" />
                        </span>
                      </button>
                    )}
                    <button
                      data-testid={`addCommentButton-${annotation.get('id')}`}
                      className={`${styles.addComment} ${styles.buttons} ${
                        annotation.get('comment') ? styles.check : ''
                      }`}
                      onClick={() => handleClickAddComment(annotation)}
                    >
                      <ReactTooltip place="top" type="dark" effect="solid" />
                      <span data-tip="Editar comentário">
                        <AddCommentIcon iconName="addCommentIcon" />
                      </span>
                    </button>
                    <button
                      data-testid={`deleteButton-${annotation.get('id')}`}
                      className={`${styles.delete}  ${styles.buttons}`}
                      onClick={() =>
                        canEditReview && handleClickConfirmDelete(annotation)
                      }
                    >
                      <ReactTooltip place="top" type="dark" effect="solid" />
                      <span data-tip="Excluir marcação">
                        <Icon size="lg" icon="trash-alt" />
                      </span>
                    </button>
                  </div>
                </div>
                {openedComments.includes(annotation.get('id')) && (
                  <div className={styles.comments}>
                    <AddCommentSelectForm
                      canEditReview={canEditReview}
                      hasClickedToAddComment
                      competences={competences}
                      automaticMarking={markingType === 'automatic'}
                      competenceMarking={annotation.get('competence')}
                      onClickCancel={() =>
                        handleClickCancelAddComment(annotation.get('id'))
                      }
                      textSelected={annotation.get('selected_text')}
                      annotation={annotation}
                      onSubmitSuccess={() => handleClickSaveComment(annotation)}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MarkingsTable;
