/* eslint-disable consistent-return */
export const phoneNumberValidation = (value: string) => {
  if (!value) return;

  // Regex validate examples:
  // (16) 91231-6165
  // (16) 3231-6165
  // 16 91231-6165
  // 16 3231-6162
  // 3231-6165
  // 91231-6165
  // 21332-4654
  //You can test and see the explanation of both regex in here:
  // First Regex: https://regex101.com/r/RItqQT/2
  // Second Reges: https://regex101.com/r/ZLHG3d/5

  const regPhoneNumber = /9\d{4}-\d{4}/;
  const regBrazilNumber = /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})-?(\d{4}))$/;

  if (!regBrazilNumber.test(value) || !regPhoneNumber.test(value))
    return 'Inserir os dígitos do celular corretamente, incluindo o DDD';

  return;
};

export default phoneNumberValidation;
