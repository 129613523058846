import {List} from 'immutable';
import {Button} from 'letrus-ui';
import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import {push} from 'react-router-redux';
import {InjectedFormProps} from 'redux-form';
import {Field, reduxForm} from 'redux-form/immutable';
import {getAbandonReviewReasons} from 'store/reducers/abandonReviewReasons';
import {
  abandonRequest,
  reviewAbandoned,
} from 'store/reducers/compositionReviews';
import {ApplicationState} from 'store/rootReducer';
import NewRadioInput from '../../components/NewInputRadio';
import styles from './FormAbandonReview.module.scss';

export interface AbandonReason {
  id: number;
  title: string;
  description: string;
}

export interface StateProps {
  abandonReasons: List<ImmutableMap<AbandonReason>>;
  reviewAbandoned: boolean;
}

export interface DispatchProps {
  push: typeof push;
  abandonRequest: typeof abandonRequest;
}

export interface OwnProps {
  onCancelClick: () => void;
  compositionReviewId: number;
}

type Props = StateProps & DispatchProps & OwnProps;
type InjectedProps = Props & InjectedFormProps<{}, Props>;

const FormAbandonReview: React.FC<InjectedProps> = ({
  abandonReasons,
  onCancelClick,
  pristine,
  handleSubmit,
  compositionReviewId,
  abandonRequest,
  push,
  reviewAbandoned,
}) => {
  useEffect(() => {
    if (reviewAbandoned) {
      push('/');
    }
  }, [push, reviewAbandoned]);

  const handleSumbitAbandonReviewForm = async (values) => {
    const abandonReasonId = values.get('abandonReason');
    abandonRequest({compositionReviewId, abandonReasonId});
  };

  return (
    <div className={styles.container}>
      <form
        className={styles.form}
        onSubmit={handleSubmit(handleSumbitAbandonReviewForm)}
      >
        <div className={styles.form__header}>
          <legend>Por favor, nos informe o motivo da sua desistência:</legend>
        </div>
        <div className={styles.form__content}>
          {abandonReasons.map((reason) => (
            <label key={reason.get('id')}>
              <Field
                component={NewRadioInput}
                type="radio"
                name="abandonReason"
                value={reason.get('id')}
                parse={Number}
                userRole="reviewer"
              />
              {reason.get('description')}
            </label>
          ))}
        </div>
        <div className={styles.form__footer}>
          <Button
            kind="secondary"
            userRole="reviewer"
            size="large"
            type="button"
            onClick={onCancelClick}
          >
            Cancelar
          </Button>
          <Button
            kind="accentPrimary"
            userRole="reviewer"
            size="large"
            type="submit"
            disabled={pristine}
          >
            Desistir
          </Button>
        </div>
      </form>
    </div>
  );
};

export default connect(
  (state: ApplicationState) => ({
    abandonReasons: getAbandonReviewReasons(state),
    reviewAbandoned: reviewAbandoned(state),
  }),
  {push, abandonRequest},
)(
  reduxForm<{}, any>({
    form: 'form-abandon-review',
  })(FormAbandonReview),
);
