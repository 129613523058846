import {LetrusApi} from '@letrustech/letrus-api-interfaces';
import {List} from 'immutable';
import {Icon, Modal, NewButton} from 'letrus-ui';
import React, {useState} from 'react';
import {connect} from 'react-redux';
import ReactTooltip from 'react-tooltip';
import {
  getCompositionGrade,
  getCompositionGradeAutomaticPlagiarismAnnotations,
  getCompositionGradePlagiarismAnnotations,
  isFundII,
} from 'store/reducers/compositionReviews';
import {
  deleteGradeGeneralAnnotationRequest,
  updateGradeGeneralAnnotationRequest,
} from 'store/reducers/generalAnnotations';
import {ApplicationState} from 'store/rootReducer';
import styles from './PlagiarismTable.module.scss';

export interface annotation extends LetrusApi.GeneralAnnotation {
  reported_by: number;
}

export interface StateProps {
  compositionGrade: ImmutableMap<LetrusApi.CompositionReviewDetail>;
  automaticPlagiarismAnnotations: List<ImmutableMap<annotation>>;
  plagiarismAnnotations: List<ImmutableMap<LetrusApi.GeneralAnnotation>>;
  isFundII: boolean;
}

interface PlagiarismTableProps {
  canEditReview: boolean;
}

export interface DispatchProps {
  deleteGradeGeneralAnnotationRequest: typeof deleteGradeGeneralAnnotationRequest;
  updateGradeGeneralAnnotationRequest: typeof updateGradeGeneralAnnotationRequest;
}

export type Props = DispatchProps & PlagiarismTableProps & StateProps;

const PlagiarismTable: React.FC<Props> = ({
  canEditReview,
  isFundII,
  compositionGrade,
  automaticPlagiarismAnnotations,
  plagiarismAnnotations,
  deleteGradeGeneralAnnotationRequest,
  updateGradeGeneralAnnotationRequest,
}) => {
  const [openConfirmDeletePlagiarm, setOpenConfirmDeletePlagiarm] = useState<
    number | null
  >(null);

  const [openedConfirmDelete, setOpenedConfirmDelete] = useState<number | null>(
    null,
  );

  const alreadyApprovedAutomaticPlagiarismAnnotations = automaticPlagiarismAnnotations
    ?.filter((annotation) => annotation.get('reported_by'))
    .toJS();

  const alreadyApprovedAutomaticPlagiarismAnnotationsId = alreadyApprovedAutomaticPlagiarismAnnotations?.map(
    (annotation) => annotation.id,
  );

  const [
    approvedAutomaticPlagiarismAnnotations,
    setApprovedAutomaticPlagiarismAnnotations,
  ] = useState(
    alreadyApprovedAutomaticPlagiarismAnnotationsId.length > 0
      ? alreadyApprovedAutomaticPlagiarismAnnotationsId
      : [],
  );

  const handleClickApprovePlagiarism = (plagiarism) => {
    const alreadyApproved = approvedAutomaticPlagiarismAnnotations.find(
      (comment) => comment === plagiarism.get('id'),
    );

    if (!alreadyApproved) {
      updateGradeGeneralAnnotationRequest({
        ...plagiarism.toJS(),
        reported_by: compositionGrade.get('reviewer_user'),
      });

      setApprovedAutomaticPlagiarismAnnotations(
        approvedAutomaticPlagiarismAnnotations.concat(plagiarism.get('id')),
      );
    }
  };

  const handleClickConfirmDeletePlagiarism = (
    annotation: ImmutableMap<any>,
  ) => {
    deleteGradeGeneralAnnotationRequest(annotation.toJS());
  };

  const handleClickCancelDeletePlagiarsm = () => {
    setOpenConfirmDeletePlagiarm(null);
  };

  const handleClickDeleteItem = (annotation: ImmutableMap<any>) => {
    setOpenedConfirmDelete(annotation.get('id'));
  };

  const handleClickDeletePlagiarim = (annotation: ImmutableMap<any>) => {
    setOpenConfirmDeletePlagiarm(annotation.get('id'));
  };

  return (
    <div>
      <div className={`${styles.warning} ${styles.plagiarismWarning}`}>
        <div className={styles.texts}>
          <div>
            <Icon icon="bullhorn" />
          </div>
          <span className={styles.description}>
            Revise os possíveis trechos plagiados na redação. Para isso, aprove
            ou exclua as marcações automáticas de plágio, ou, se preferir, faça
            novas marcações no texto à esquerda.
          </span>
        </div>
      </div>
      {!isFundII && (
        <>
          <h3 className={styles.titleAutoMarkings}>
            IDENTIFICAÇÕES AUTOMÁTICAS
            <span
              data-tip={
                'Plágio identificado pela inteligência artificial da Letrus.'
              }
              data-for="autoMarkings"
              className={styles.iconQuestion}
            >
              <Icon icon="question" size="1x" />
            </span>
            <ReactTooltip
              className={styles.tooltipQuestion}
              id="autoMarkings"
              type="dark"
              effect="solid"
              place="right"
              multiline={true}
            />
          </h3>
          {automaticPlagiarismAnnotations.size === 0 && (
            <div className={`${styles.warning} ${styles.withoutMarks}`}>
              <div className={styles.texts}>
                <span className={styles.description}>
                  Nossa inteligência artificial não identificou plágio nesta
                  redação.
                </span>
              </div>
            </div>
          )}
          {automaticPlagiarismAnnotations.size && (
            <div>
              <div className={styles.title}>
                <div>
                  <h3>Marcação</h3>
                </div>
              </div>
              {automaticPlagiarismAnnotations.map((annotation) => (
                <div key={annotation.get('id')}>
                  <div className={styles.container}>
                    <div className={styles.plagiarism}>
                      "{annotation.get('selected_text')}"
                    </div>
                    <div className={styles.buttons}>
                      <button
                        data-testid={`addCommentButton-${annotation.get('id')}`}
                        className={`${styles.approve} ${styles.buttons} ${
                          approvedAutomaticPlagiarismAnnotations.includes(
                            annotation.get('id'),
                          )
                            ? styles.checkApprove
                            : ''
                        }`}
                        onClick={() =>
                          canEditReview &&
                          handleClickApprovePlagiarism(annotation)
                        }
                      >
                        <Icon size="2x" icon="check-circle" />
                      </button>
                      <button
                        data-testid={`deleteButton-${annotation.get('id')}`}
                        className={`${styles.delete}  ${styles.buttons}`}
                        onClick={() =>
                          canEditReview &&
                          handleClickDeletePlagiarim(annotation)
                        }
                      >
                        <ReactTooltip place="top" type="dark" effect="solid" />
                        <span data-tip="Excluir marcação">
                          <Icon size="2x" icon="times-circle" />
                        </span>
                      </button>
                    </div>
                    <div className={styles.modalDeletePlagiarism}>
                      <Modal
                        onClose={handleClickCancelDeletePlagiarsm}
                        buttonClose={false}
                        clickOutsideToClose
                        isOpen={
                          openConfirmDeletePlagiarm === annotation.get('id')
                        }
                      >
                        <div className={styles.modalContent}>
                          <h3>Excluir marcação de plágio</h3>
                          <label>
                            <input type="checkbox" id="quote" name="quote" />
                            Essa marcação é uma citação, não um plágio
                          </label>
                          <footer>
                            <NewButton
                              onClick={handleClickCancelDeletePlagiarsm}
                            >
                              Cancelar
                            </NewButton>
                            <NewButton
                              kind="primary"
                              userRole="student"
                              onClick={() =>
                                handleClickConfirmDeletePlagiarism(annotation)
                              }
                            >
                              Excluir marcação
                            </NewButton>
                          </footer>
                        </div>
                      </Modal>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      )}
      <h3 className={styles.titleAutoMarkings}>
        SUAS IDENTIFICAÇÕES
        <span
          data-tip="Plágio identificado por você ao longo da correção."
          data-for="autoMarkings"
          className={styles.iconQuestion}
        >
          <Icon icon="question" size="1x" />
        </span>
        <ReactTooltip
          className={styles.tooltipQuestion}
          id="autoMarkings"
          type="dark"
          effect="solid"
          place="right"
          multiline={true}
        />
      </h3>
      {plagiarismAnnotations.size === 0 && (
        <div className={`${styles.warning} ${styles.withoutMarks}`}>
          <div className={styles.texts}>
            <span className={styles.description}>
              Você ainda não identificou plágio(s) nesta redação. Caso precise
              identificá-lo(s), selecione o(s) trecho(s) plagiado(s) no texto à
              esquerda e inicie o processo de marcação.
            </span>
          </div>
        </div>
      )}
      {plagiarismAnnotations.size > 0 && (
        <div>
          <div className={styles.title}>
            <div>
              <h3>Marcação</h3>
            </div>
          </div>
          {plagiarismAnnotations.map((plagiarism) => (
            <div key={plagiarism.get('id')}>
              <div className={styles.container}>
                <div className={styles.onlyDeviation}>
                  "{plagiarism.get('selected_text')}"
                </div>
                <div className={styles.buttons}>
                  <button
                    data-testid={`deleteButton-${plagiarism.get('id')}`}
                    className={`${styles.delete} ${styles.deletePlagiarism}  ${styles.buttons}`}
                    onClick={() =>
                      canEditReview && handleClickDeleteItem(plagiarism)
                    }
                  >
                    <ReactTooltip place="top" type="dark" effect="solid" />
                    <span data-tip="Excluir marcação">
                      <Icon size="lg" icon="trash-alt" />
                    </span>
                  </button>
                </div>
                {openedConfirmDelete === plagiarism.get('id') && (
                  <div className={styles.deleteAnnotation}>
                    <NewButton
                      onClick={() => {
                        setOpenedConfirmDelete(null);
                      }}
                    >
                      Cancelar
                    </NewButton>
                    <button
                      onClick={() =>
                        handleClickConfirmDeletePlagiarism(plagiarism)
                      }
                      className={styles.deleteButton}
                    >
                      Excluir
                    </button>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default connect<
  StateProps,
  DispatchProps,
  PlagiarismTableProps,
  ApplicationState
>(
  (state: ApplicationState) => ({
    compositionGrade: getCompositionGrade(state),
    automaticPlagiarismAnnotations: getCompositionGradeAutomaticPlagiarismAnnotations(
      state,
    ),
    plagiarismAnnotations: getCompositionGradePlagiarismAnnotations(state),
    isFundII: isFundII(state),
  }),
  {
    deleteGradeGeneralAnnotationRequest,
    updateGradeGeneralAnnotationRequest,
  },
)(PlagiarismTable);
