import {LetrusApi} from '@letrustech/letrus-api-interfaces';
import EditGradeFinishReview from 'components/EditGradeFinishReview';
import ModalLeaveMarking from 'components/ModalLeaveMarking';
import ThemeSidebar from 'components/ThemeSidebar';
import EditGradeSidebarComments from 'containers/EditGradeSidebarComments';
import EditGradeSideBarGrades from 'containers/EditGradeSideBarGrades';
import {List} from 'immutable';
import {Input, Modal, TabsContainer} from 'letrus-ui';
import {ButtonProps} from 'letrus-ui/dist/components/infra/Button';
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {
  getCompositionGrade,
  getCompositionGradeAnnotations,
  getCompositionGradeCompetences,
  getCompositionGradeGeneralAnnotations,
  isCustomCorrectionGrid,
  isFundII,
} from 'store/reducers/compositionReviews';
import {ApplicationState} from 'store/rootReducer';
import {getCompetencesWithTextCategoriesOnly} from '../../utils/functions/competenceFilters';
import AlertModal from '../AlertModal';
import styles from './EditGradeSidebar.module.scss';

interface AlertButton extends ButtonProps {
  title: string;
}
interface StateAlert {
  message: string;
  type: string;
  hasComponent?: boolean;
  buttons: Array<AlertButton>;
}

export interface StateProps {
  generalAnnotations: List<ImmutableMap<LetrusApi.GeneralAnnotation>>;
  compositionGrade: ImmutableMap<LetrusApi.CompositionReview>;
  isFundII: boolean;
  isCustomCorrectionGrid: boolean;
  competences: List<ImmutableMap<LetrusApi.CompositionCompetence>>;
  annotations: List<ImmutableMap<LetrusApi.ReviewAnnotation>>;
}

export interface OwnProps {
  isNewSelection: boolean;
  gradeEditorState: any;
  onFinishReview: () => void;
  canEditReview: boolean;
  isPlagiarismSelection: boolean;
  onChangeStep: (nextStep: number) => void;
  currentStep: number;
  toggleIsPlagiarismSelection: () => void;
  theme: ImmutableMap<LetrusApi.CompositionInstruction>;
  aids: List<ImmutableMap<LetrusApi.CompositionAid>>;
  handleChangeSelectedCompetence: (selectedCompetenceNumber: number) => void;
  handleCancelPlagiarismMark: () => void;
  handleAddNewMarking: (boolean) => void;
  openProposal?: boolean;
  handleTabChangeProposal: () => void;
  setSelectedTextEditorPosition: (any) => void;
}

export type Props = StateProps & OwnProps;

const EditGradeSidebar: React.FC<Props> = ({
  isNewSelection,
  compositionGrade,
  gradeEditorState,
  handleAddNewMarking,
  onFinishReview,
  canEditReview,
  isPlagiarismSelection,
  handleCancelPlagiarismMark,
  onChangeStep,
  currentStep,
  isCustomCorrectionGrid,
  generalAnnotations,
  isFundII,
  handleTabChangeProposal,
  competences,
  annotations,
  toggleIsPlagiarismSelection,
  handleChangeSelectedCompetence,
  setSelectedTextEditorPosition,
  theme,
  aids,
  openProposal,
}) => {
  const [annotationCompetencesId, setAnnotationCompetencesId] = useState<
    List<number>
  >(List());
  const [
    makeAnnotationsForTextCategories,
    setMakeAnnotationsForTextCategories,
  ] = useState(false);
  const [alert, setAlert] = useState<StateAlert>({
    message: '',
    type: '',
    buttons: [],
    hasComponent: false,
  });

  const [isValidated, setIsValidated] = useState({
    annotations: true,
    competences: false,
    orthographicErrors: false,
  });
  const [hasTextSelected, setHasTextSelected] = useState(false);
  const [
    isConfirmLeaveMarkingModalOpen,
    setIsConfirmLeaveMarkingModalOpen,
  ] = useState(false);

  const [tabActive, setTabActive] = useState<number>(0);
  const [tabSelected, setTabSelected] = useState<number>(0);

  useEffect(() => {
    const competencesWithTextCategoriesOnly = getCompetencesWithTextCategoriesOnly(
      competences,
      annotations,
    );
    if (!competencesWithTextCategoriesOnly.count()) {
      if (isValidated.annotations !== true) {
        setIsValidated({...isValidated, annotations: true});
      }
    }
  }, [annotations, competences, isValidated]);

  useEffect(() => {
    if (openProposal) {
      setTabActive(0);
    }
  }, [openProposal]);

  useEffect(() => {
    const competencesWithTextCategoriesOnly = getCompetencesWithTextCategoriesOnly(
      competences,
      annotations,
    );
    if (
      makeAnnotationsForTextCategories &&
      !competencesWithTextCategoriesOnly.count()
    ) {
      setIsValidated({...isValidated, annotations: true});
      setMakeAnnotationsForTextCategories(false);
    } else if (
      competencesWithTextCategoriesOnly.count() &&
      isValidated.annotations
    ) {
      setIsValidated({...isValidated, annotations: false});
    }
    const newAnnotationCompetencesId = annotations.map((annotation) => {
      return annotation.get('competence');
    });
    if (
      newAnnotationCompetencesId.count() !== annotationCompetencesId.count()
    ) {
      setAnnotationCompetencesId(newAnnotationCompetencesId);
    }
  }, [
    annotationCompetencesId,
    annotations,
    competences,
    isValidated,
    makeAnnotationsForTextCategories,
  ]);

  useEffect(() => {
    if (gradeEditorState?.hasSelection) {
      setTabActive(1);
    }
  }, [gradeEditorState]);

  const onCloseAlert = () => {
    setAlert({
      message: '',
      type: '',
      buttons: [],
      hasComponent: false,
    });
  };

  const handleTabChange = (id) => {
    if (id !== 0) {
      handleTabChangeProposal();
    }
    if (hasTextSelected) {
      setIsConfirmLeaveMarkingModalOpen(true);
      setTabSelected(id);
    } else {
      setTabActive(id);
    }
  };

  const handleClickConfirmLeaveMarking = () => {
    setTabActive(tabSelected);
    setIsConfirmLeaveMarkingModalOpen(false);
    setHasTextSelected(false);
    setSelectedTextEditorPosition(null);
  };

  const handleSelectedText = (isSelected) => {
    setHasTextSelected(isSelected);
    handleAddNewMarking(isSelected);
  };

  const tabs = [
    {
      title: 'Proposta',
      id: 0,
      children: (
        <div className={styles.tabsWrapper}>
          <ThemeSidebar aids={aids} theme={theme} />
        </div>
      ),
    },
    {
      title: 'Marcações',
      id: 1,
      children: (
        <div className={styles.tabsWrapper}>
          <EditGradeSidebarComments
            setSelectedTextEditorPosition={setSelectedTextEditorPosition}
            handleSelectedText={handleSelectedText}
            handleCancelPlagiarismMark={handleCancelPlagiarismMark}
            handleChangeSelectedCompetence={(
              selectedCompetenceNumber: number,
            ) => {
              handleChangeSelectedCompetence(selectedCompetenceNumber);
            }}
            canEditReview={canEditReview}
            isNewSelection={isNewSelection}
            toggleIsPlagiarismSelection={toggleIsPlagiarismSelection}
            annotationsValidated={isValidated.annotations}
            isPlagiarismSelection={isPlagiarismSelection}
            gradeEditorState={gradeEditorState}
            makeAnnotationsForTextCategories={makeAnnotationsForTextCategories}
            onCancelMakeAnnotationsForTextCategories={() =>
              setMakeAnnotationsForTextCategories(false)
            }
          />
        </div>
      ),
    },
    {
      title: isFundII ? 'Níveis' : 'Notas',
      id: 2,
      children: (
        <div className={styles.tabsWrapper}>
          <EditGradeSideBarGrades canEditReview={canEditReview} />
        </div>
      ),
    },
    {
      title: 'Finalização',
      id: 3,
      children: (
        <div className={styles.tabsWrapper}>
          <EditGradeFinishReview
            isFundII={isFundII}
            isCustomCorrectionGrid={isCustomCorrectionGrid}
            compositionGrade={compositionGrade}
            competences={competences}
            annotations={annotations}
            generalAnnotations={generalAnnotations}
            onFinishReview={onFinishReview}
          />
        </div>
      ),
    },
  ];

  const tabsWithoutFinish = [
    {
      title: 'Proposta',
      id: 0,
      children: (
        <div className={styles.tabsWrapper}>
          <ThemeSidebar aids={aids} theme={theme} />
        </div>
      ),
    },
    {
      title: 'Marcações',
      id: 1,
      children: (
        <div className={styles.tabsWrapper}>
          <EditGradeSidebarComments
            setSelectedTextEditorPosition={setSelectedTextEditorPosition}
            handleSelectedText={handleSelectedText}
            handleCancelPlagiarismMark={handleCancelPlagiarismMark}
            handleChangeSelectedCompetence={(
              selectedCompetenceNumber: number,
            ) => {
              handleChangeSelectedCompetence(selectedCompetenceNumber);
            }}
            canEditReview={canEditReview}
            isNewSelection={isNewSelection}
            toggleIsPlagiarismSelection={toggleIsPlagiarismSelection}
            annotationsValidated={isValidated.annotations}
            isPlagiarismSelection={isPlagiarismSelection}
            gradeEditorState={gradeEditorState}
            makeAnnotationsForTextCategories={makeAnnotationsForTextCategories}
            onCancelMakeAnnotationsForTextCategories={() =>
              setMakeAnnotationsForTextCategories(false)
            }
          />
        </div>
      ),
    },
    {
      title: isFundII ? 'Níveis' : 'Notas',
      id: 2,
      children: (
        <div className={styles.tabsWrapper}>
          <EditGradeSideBarGrades canEditReview={canEditReview} />
        </div>
      ),
    },
  ];

  return (
    <div className={styles.EditGradeSidebar}>
      <TabsContainer
        handleTabChange={handleTabChange}
        tabActive={tabActive}
        tabs={!canEditReview && !isFundII ? tabsWithoutFinish : tabs}
      />

      <AlertModal
        message={alert.message}
        type={alert.type}
        onClose={onCloseAlert}
        buttons={alert.buttons}
      >
        {alert.hasComponent && (
          <label htmlFor="reviewed">
            <Input
              id="reviewed"
              input={{
                name: 'OrthographicErrorValidate',
                onChange: () => {
                  const buttons = alert.buttons;
                  buttons[1].disabled = false;
                  setAlert({
                    ...alert,
                    buttons,
                    hasComponent: true,
                  });
                },
                value: !alert.buttons[1].disabled ? 'reviewed' : '',
              }}
              inputProps={{
                disabled: false,
                inputTag: 'input',
                placeholder: '',
                type: 'radio',
              }}
              label=""
              meta={{
                error: 'Necessário revisar',
                touched: false,
              }}
            />
            Sim, revisei
          </label>
        )}
      </AlertModal>
      {isConfirmLeaveMarkingModalOpen && (
        <div className={styles.modalLeaveMarking}>
          <Modal
            onClose={() => setIsConfirmLeaveMarkingModalOpen(false)}
            clickOutsideToClose={false}
            isOpen={isConfirmLeaveMarkingModalOpen}
            buttonClose={false}
            children={
              <ModalLeaveMarking
                handleClickConfirm={handleClickConfirmLeaveMarking}
                handleClickCancel={() => {
                  setIsConfirmLeaveMarkingModalOpen(false);
                }}
              />
            }
          />
        </div>
      )}
    </div>
  );
};

export default connect<StateProps, {}, OwnProps, ApplicationState>(
  (state: ApplicationState) => ({
    compositionGrade: getCompositionGrade(state),
    isFundII: isFundII(state),
    competences: getCompositionGradeCompetences(state),
    generalAnnotations: getCompositionGradeGeneralAnnotations(state),
    annotations: getCompositionGradeAnnotations(state),
    isCustomCorrectionGrid: isCustomCorrectionGrid(state),
  }),
)(EditGradeSidebar);
