/* eslint-disable consistent-return */
/* eslint-disable react/jsx-props-no-spreading */
import NewButton from 'components/NewButton';
import React, {ReactNode, useEffect} from 'react';
import styles from './AlertModal.module.scss';

const titleMessage = {
  success: 'Sucesso',
  error: 'Erro',
  warning: 'Atenção',
};

export interface Props {
  type?: string;
  message?: string;
  buttons?: Array<any>;
  onClose?: () => void;
  children?: ReactNode;
}

const AlertModal: React.FC<Props> = ({
  type = 'warning',
  message,
  buttons,
  onClose,
  children,
}) => {
  useEffect(() => {
    if ((!buttons || !buttons.length) && onClose && message) {
      const timeout = setTimeout(() => {
        onClose();
      }, 6000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [buttons, message, onClose]);

  return message ? (
    <div className={styles.alert}>
      <div className={styles.background} onClick={onClose} />
      <div className={styles.box}>
        <div className={`${styles.title} ${styles[type]}`}>
          {titleMessage[type]}
        </div>
        <div className={styles.content}>
          <div className={styles.message}>{message}</div>
          <div className={styles.children}>{children}</div>
        </div>
        {buttons && (
          <div className={styles.buttons}>
            {buttons.map((button, index) => (
              <NewButton key={index} {...button}>
                {button.title}
              </NewButton>
            ))}
          </div>
        )}
      </div>
    </div>
  ) : null;
};

export default AlertModal;
