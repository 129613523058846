import {Icon} from 'letrus-ui';
import React from 'react';
import styles from './ModalInfo.module.scss';

interface Props {
  component: React.ReactNode;
  visible?: boolean;
  onCloseClick: React.MouseEventHandler;
}

const ModalInfo: React.FC<Props> = ({
  component,
  visible = false,
  onCloseClick,
}) => {
  return (
    <div className={`${styles.container} ${visible ? styles.visible : ''}`}>
      <div className={styles.background} onClick={onCloseClick}></div>
      <div className={styles.content}>
        {component}
        <div className={styles['close-icon']}>
          <div onClick={onCloseClick} title="Fechar">
            <Icon icon="times" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalInfo;
