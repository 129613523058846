import {Utils} from '@letrustech/letrus-api-interfaces';
import {fromJS} from 'immutable';
import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {
  createPresignedUrlService,
  deletePresignedUrlService,
  fetchPresignedUrlService,
  updatePresignedUrlService,
} from 'store/services/presignedUrlService';
import {action} from 'typesafe-actions';

//Action types
export enum PresignedUrlTypes {
  FETCH_PRESIGNED_URL_REQUEST = '@presigned_url/FETCH_PRESIGNED_URL_REQUEST',
  FETCH_PRESIGNED_URL_SUCCESS = '@presigned_url/FETCH_PRESIGNED_URL_SUCCESS',
  FETCH_PRESIGNED_URL_FAILURE = '@presigned_url/FETCH_PRESIGNED_URL_FAIURE',

  CREATE_PRESIGNED_URL_REQUEST = '@presigned_url/CREATE_PRESIGNED_URL_REQUEST',
  CREATE_PRESIGNED_URL_SUCCESS = '@presigned_url/CREATE_PRESIGNED_URL_SUCCESS',
  CREATE_PRESIGNED_URL_FAILURE = '@presigned_url/CREATE_PRESIGNED_URL_FAILURE',

  CREATE_PRESIGNED_URL_REQUEST1 = '@presigned_url/CREATE_PRESIGNED_URL_REQUEST1',
  CREATE_PRESIGNED_URL_SUCCESS1 = '@presigned_url/CREATE_PRESIGNED_URL_SUCCESS1',
  CREATE_PRESIGNED_URL_FAILURE1 = '@presigned_url/CREATE_PRESIGNED_URL_FAILURE1',

  UPDATE_PRESIGNED_URL_REQUEST = '@presigned_url/UPDATE_PRESIGNED_URL_REQUEST',
  UPDATE_PRESIGNED_URL_SUCCESS = '@presigned_url/UPDATE_PRESIGNED_URL_SUCCESS',
  UPDATE_PRESIGNED_URL_FAILURE = '@presigned_url/UPDATE_PRESIGNED_URL_FAILURE',

  DELETE_PRESIGNED_URL_REQUEST = '@presigned_url/DELETE_PRESIGNED_URL_REQUEST',
  DELETE_PRESIGNED_URL_SUCCESS = '@presigned_url/DELETE_PRESIGNED_URL_SUCCESS',
  DELETE_PRESIGNED_URL_FAILURE = '@presigned_url/DELETE_PRESIGNED_URL_FAILURE',
}

//Data types
export interface Receipt {
  url: string;
  file: any;
}
export interface PresignedUrl {
  presignedUrl: any;
  uploadedReceipt: Receipt;
}

//State type
export interface PresignedUrlState
  extends ImmutableMap<{
    data: ImmutableMap<PresignedUrl>;
    loading: boolean;
    error: boolean;
  }> {}

//Fetch PresignedUrl Actions
export const fetchPresignedUrlRequest = (
  data: Utils.GetParams & {content: string},
) => action(PresignedUrlTypes.FETCH_PRESIGNED_URL_REQUEST, data);

export const fetchPresignedUrlSuccess = (data: Array<any>) =>
  action(PresignedUrlTypes.FETCH_PRESIGNED_URL_SUCCESS, data);

export const fetchPresignedUrlFailure = () =>
  action(PresignedUrlTypes.FETCH_PRESIGNED_URL_FAILURE);

//Create PresignedUrl Actions
export const createPresignedUrlRequest = (data: any) =>
  action(PresignedUrlTypes.CREATE_PRESIGNED_URL_REQUEST, data);

export const createPresignedUrlSuccess = (data: Receipt) =>
  action(PresignedUrlTypes.CREATE_PRESIGNED_URL_SUCCESS, data);

export const createPresignedUrlFailure = () =>
  action(PresignedUrlTypes.CREATE_PRESIGNED_URL_FAILURE);

export const createPresignedUrlRequest1 = (
  requestBody: any,
  file,
  fetchParams,
) =>
  action(PresignedUrlTypes.CREATE_PRESIGNED_URL_REQUEST1, {
    requestBody,
    file,
    fetchParams,
  });

export const createPresignedUrlSuccess1 = (data: any) => {
  return action(PresignedUrlTypes.CREATE_PRESIGNED_URL_SUCCESS1, data);
};

export const createPresignedUrlFailure1 = () =>
  action(PresignedUrlTypes.CREATE_PRESIGNED_URL_FAILURE1);

//Update PresignedUrl Actions
export const updatePresignedUrlRequest = (request: {id: string; body: any}) =>
  action(PresignedUrlTypes.UPDATE_PRESIGNED_URL_REQUEST, request);

export const updatePresignedUrlSuccess = (data: any) =>
  action(PresignedUrlTypes.UPDATE_PRESIGNED_URL_SUCCESS, data);

export const updatePresignedUrlFailure = () =>
  action(PresignedUrlTypes.UPDATE_PRESIGNED_URL_FAILURE);

//Delete PresignedUrl Actions
export const deletePresignedUrlRequest = (id: string) =>
  action(PresignedUrlTypes.DELETE_PRESIGNED_URL_REQUEST, id);

export const deletePresignedUrlSuccess = () =>
  action(PresignedUrlTypes.DELETE_PRESIGNED_URL_SUCCESS);

export const deletePresignedUrlFailure = () =>
  action(PresignedUrlTypes.DELETE_PRESIGNED_URL_FAILURE);

//Sagas
export function* fetchPresignedUrl(action: AnyAction) {
  try {
    const response = yield call(fetchPresignedUrlService, action.payload);
    yield put(fetchPresignedUrlSuccess(response));
  } catch (error) {
    yield put(fetchPresignedUrlFailure());
  }
}

export function* createPresignedUrl(action: AnyAction) {
  try {
    const response = yield call(createPresignedUrlService, action.payload.body);
    const url = response.data.post;
    const file = action.payload.file;

    yield fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': '',
      },
      body: file,
    });

    yield put(createPresignedUrlSuccess({url, file}));
  } catch (error) {
    yield put(createPresignedUrlFailure());
  }
}

//TODO:refazer esse cara. Olhar createPresignedUrl
export function* createPresignedUrl1(action: AnyAction) {
  try {
    const response = yield call(
      createPresignedUrlService,
      action.payload.requestBody,
    );

    const url = response.data.post;

    yield fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': '',
      },
      body: action.payload.file,
    });

    const presignedUrl = yield call(
      fetchPresignedUrlService,
      action.payload.fetchParams,
    );

    yield put(createPresignedUrlSuccess1(presignedUrl));
  } catch (error) {
    yield put(createPresignedUrlFailure1());
  }
}

export function* updatePresignedUrl(action: AnyAction) {
  try {
    const response = yield call(updatePresignedUrlService, action.payload);
    yield put(updatePresignedUrlSuccess(response));
  } catch (error) {
    yield put(updatePresignedUrlFailure());
  }
}

export function* deletePresignedUrl(action: AnyAction) {
  try {
    yield call(deletePresignedUrlService, action.payload);
    yield put(deletePresignedUrlSuccess());
  } catch (error) {
    yield put(deletePresignedUrlFailure());
  }
}

//Initial state
export const INITIAL_STATE = fromJS({
  data: fromJS({
    presignedUrl: {},
    uploadedReceipt: {},
  }),
  loading: false,
  error: false,
});

//Selectors
const presignedUrlSelector = (state: ApplicationState) =>
  state.get('presignedUrl');

export const isDeletingPresignedUrl = createSelector(
  presignedUrlSelector,
  (presignedUrl) => presignedUrl.get('loading'),
);

export const getPresignedUrl = createSelector(
  presignedUrlSelector,
  (presignedUrl) => presignedUrl.getIn(['data', 'presignedUrl']),
);

export const getUploadedReceipt = createSelector(
  presignedUrlSelector,
  (presignedUrl) => presignedUrl.getIn(['data', 'uploadedReceipt']),
);

//Reducer
const reducer: Reducer<PresignedUrlState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PresignedUrlTypes.FETCH_PRESIGNED_URL_REQUEST: {
      return state.withMutations((prevstate) =>
        prevstate.set('loading', true).set('error', false),
      );
    }

    case PresignedUrlTypes.FETCH_PRESIGNED_URL_SUCCESS: {
      return state.withMutations((prevstate) =>
        prevstate
          .set('loading', false)
          .set('error', false)
          .setIn(
            ['data', 'presignedUrl'],
            fromJS(action.payload.data.results[0] || {}),
          ),
      );
    }

    case PresignedUrlTypes.FETCH_PRESIGNED_URL_FAILURE: {
      return state.withMutations((prevstate) =>
        prevstate.set('loading', false).set('error', true),
      );
    }

    case PresignedUrlTypes.CREATE_PRESIGNED_URL_REQUEST: {
      return state.withMutations((prevstate) =>
        prevstate
          .set('loading', true)
          .set('error', false)
          .setIn(['data', 'uploadedReceipt'], fromJS({})),
      );
    }

    case PresignedUrlTypes.CREATE_PRESIGNED_URL_SUCCESS: {
      return state.withMutations((prevstate) =>
        prevstate
          .set('loading', false)
          .set('error', false)
          .setIn(['data', 'uploadedReceipt'], fromJS(action.payload)),
      );
    }

    case PresignedUrlTypes.CREATE_PRESIGNED_URL_FAILURE: {
      return state.withMutations((prevstate) =>
        prevstate.set('loading', false).set('error', true),
      );
    }

    case PresignedUrlTypes.CREATE_PRESIGNED_URL_REQUEST1: {
      return state.withMutations((prevstate) =>
        prevstate.set('loading', true).set('error', false),
      );
    }

    case PresignedUrlTypes.CREATE_PRESIGNED_URL_SUCCESS1: {
      return state.withMutations((prevstate) =>
        prevstate
          .set('loading', false)
          .set('error', false)
          .setIn(
            ['data', 'presignedUrl'],
            fromJS(action.payload.data.results[0]),
          ),
      );
    }

    case PresignedUrlTypes.CREATE_PRESIGNED_URL_FAILURE1: {
      return state.withMutations((prevstate) =>
        prevstate.set('loading', false).set('error', true),
      );
    }

    case PresignedUrlTypes.UPDATE_PRESIGNED_URL_REQUEST: {
      return state.withMutations((prevstate) =>
        prevstate.set('loading', true).set('error', false),
      );
    }

    case PresignedUrlTypes.UPDATE_PRESIGNED_URL_SUCCESS: {
      return state.withMutations((prevstate) =>
        prevstate.set('loading', false).set('error', false),
      );
    }

    case PresignedUrlTypes.UPDATE_PRESIGNED_URL_FAILURE: {
      return state.withMutations((prevstate) =>
        prevstate.set('loading', false).set('error', true),
      );
    }

    case PresignedUrlTypes.DELETE_PRESIGNED_URL_REQUEST: {
      return state.withMutations((prevstate) =>
        prevstate.set('loading', true).set('error', false),
      );
    }

    case PresignedUrlTypes.DELETE_PRESIGNED_URL_SUCCESS: {
      return state.withMutations((prevstate) =>
        prevstate.set('loading', false).set('error', false),
      );
    }

    case PresignedUrlTypes.DELETE_PRESIGNED_URL_FAILURE: {
      return state.withMutations((prevstate) =>
        prevstate.set('loading', false).set('error', true),
      );
    }

    default: {
      return state;
    }
  }
};

export default reducer;
