import React, {useEffect, useState} from 'react';
import {WrappedFieldProps} from 'redux-form';
import styles from './NewInputRadio.module.scss';

export interface IRadioInputProps {
  id: string;
  name: string;
  selectedId: string;
  value: any;
  handleChange?: React.ChangeEventHandler;
}

const RadioInput: React.ForwardRefRenderFunction<
  HTMLInputElement,
  IRadioInputProps & Partial<WrappedFieldProps>
> = ({id, name, selectedId, value, input, handleChange}, ref) => {
  const _name = input ? input.name : name;
  const _value = input ? input.value : value;
  const _onChange = input ? input.onChange : handleChange;

  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    const _isChecked = input && input ? !!input.checked : id === selectedId;
    setIsChecked(_isChecked);
  }, [id, input, selectedId]);

  const renderCheckMark = () => {
    return (
      isChecked && (
        <div className={`${styles['input-radio']} ${styles.checkmark}`}></div>
      )
    );
  };

  return (
    <div className={styles['input-radio__container']}>
      <input
        ref={ref}
        id={id}
        name={_name}
        type="radio"
        className={`${styles['input-radio']} ${styles.input}`}
        value={_value}
        defaultChecked={isChecked}
        onChange={_onChange}
      />
      {renderCheckMark()}
    </div>
  );
};

export default React.forwardRef(RadioInput);
