/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import './InputSelectField.scss';

interface InputSelectFieldProps {
  label: any;
  autocomplete: any;
  required: any;
  input: any;
  type: string;
  disabled: boolean;
  id: string;
  className: string;
  meta: any;
}

const InputSelectField: React.FC<InputSelectFieldProps> = ({
  label,
  autocomplete,
  required,
  input,
  type = 'text',
  disabled,
  id,
  children,
  className,
  meta,
}) => {
  return (
    <div
      className={`form-group InputField ${
        meta.error && meta.touched ? 'has-error' : ''
      } ${
        className && className.indexOf('ComplementItem') !== -1
          ? 'ComplementItem'
          : ''
      }`}
    >
      {
        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        <label htmlFor={id} className="control-label col-xs-12">
          {label}
          {required ? (
            <span className="InputField__label--required">*</span>
          ) : (
            ''
          )}
        </label>
      }
      <div>
        <select
          autoComplete={autocomplete}
          className="form-control"
          {...input}
          type={type}
          disabled={disabled}
          id={id}
        >
          {children}
        </select>
        {meta.touched && meta.error && (
          <div className="help-block">{meta.error}</div>
        )}
      </div>
    </div>
  );
};

export default InputSelectField;
