import DisplayTextInTooltip from 'components/DisplayTextInTooltip';
import Loading from 'components/Loading';
import {push} from 'connected-react-router/immutable';
import dayjs from 'dayjs';
import {List} from 'immutable';
import {Button, Icon, Table} from 'letrus-ui';
import React, {useCallback, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {Column, usePagination, useSortBy, useTable} from 'react-table';
import {
  createCompositionReviewRequest,
  hasCreatedCompositionReview,
  isCreatingCompositionReview,
  resetCompositionReviewStateRequest,
} from 'store/reducers/compositions';
import {
  getAvailableCompositions,
  getAvailableCompositionsCount,
  getAvailableCompositionsCountLastUpdated,
  hasInProgressGradesCompositions,
  isLoadingReviewerCompositions,
  verifyProgressGradesLimitRequest,
} from 'store/reducers/reviewerCompositions';
import {ApplicationState} from 'store/rootReducer';
import styles from './ReviewerAvailableCompositionsTable.module.scss';

interface StateProps {
  availableCompositions: List<any>;
  isLoadingCompositions: boolean;
  isCreatingCompositionReview: boolean;
  hasInProgressGrades: boolean;
  hasCreatedCompositionReview: boolean;
  availableCompositionsCount: number;
  lastUpdated: string;
}

interface AvailableComposition {
  id: number;
  instruction_id__title: string;
  is_writing_reviewer: boolean;
  parent_id?: number;
  revision_deadline?: string;
  title?: string;
  genre__name: string;
}

interface DispatchProps {
  verifyProgressGradesLimitRequest: typeof verifyProgressGradesLimitRequest;
  createCompositionReviewRequest: typeof createCompositionReviewRequest;
  resetCompositionReviewStateRequest: typeof resetCompositionReviewStateRequest;
  push: typeof push;
}

type Props = StateProps & DispatchProps;

const AvailableCompositionsTable: React.FC<Props> = ({
  availableCompositions,
  isLoadingCompositions,
  isCreatingCompositionReview,
  verifyProgressGradesLimitRequest,
  hasInProgressGrades,
  push,
  lastUpdated,
  createCompositionReviewRequest,
  availableCompositionsCount,
  hasCreatedCompositionReview,
  resetCompositionReviewStateRequest,
}) => {
  const [openCompositionId, setOpenCompositionId] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    verifyProgressGradesLimitRequest();
  }, [verifyProgressGradesLimitRequest]);

  useEffect(() => {
    if (hasCreatedCompositionReview) {
      push(`/correcao/${openCompositionId}`);
    }

    return () => {
      resetCompositionReviewStateRequest();
    };
  }, [
    hasCreatedCompositionReview,
    openCompositionId,
    push,
    resetCompositionReviewStateRequest,
  ]);

  const openReview = useCallback(
    (id: number) => {
      if (!hasInProgressGrades) {
        setIsLoading(true);
        createCompositionReviewRequest(id);
        setOpenCompositionId(id);
      }
    },
    [createCompositionReviewRequest, hasInProgressGrades],
  );

  const columns: Array<Column<AvailableComposition>> = React.useMemo(() => {
    return [
      {
        accessor: 'id',
        Header: 'Número',
        Cell: ({value}) => <div style={{minWidth: '100px'}}>{value}</div>,
      },
      {
        id: 'title',
        Header: 'Nome',
        accessor: ({title}): any => {
          return title ? title : 'Sem título';
        },
        Cell: ({row: {original}, value}) => {
          return (
            <div style={{minWidth: '150px'}}>
              <div className={styles['table-title']}>
                <span className={styles['table-title__icons']}>
                  {original.parent_id ? (
                    <span className={styles['table-title__icons--clone']}>
                      <Icon icon="clone" />
                    </span>
                  ) : null}
                  {original.is_writing_reviewer ? (
                    <div className={styles['table-title__icons--star']}>
                      <Icon icon="star" color="yellow" />
                      <Icon icon={['fal', 'star']} color="black" />
                    </div>
                  ) : null}
                </span>
                <span
                  style={{
                    fontStyle: 'normal',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    display: 'block',
                  }}
                >
                  <DisplayTextInTooltip text={value} />
                </span>
              </div>
            </div>
          );
        },
      },
      {
        accessor: 'instruction_id__title',
        Header: 'Tema',
        Cell: ({value}) => (
          <div
            style={{
              width: '300px',
              fontStyle: 'normal',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            <DisplayTextInTooltip text={value.replace(/ *\[[^)]*\] */g, '')} />
          </div>
        ),
      },
      {
        accessor: 'genre__name',
        Header: 'Gênero',
        Cell: ({value}) => (
          <div
            style={{
              minWidth: '100px',
              fontStyle: 'italic',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {value}
          </div>
        ),
      },
      {
        Header: 'Prazo de entrega',
        accessor: 'revision_deadline',
        Cell: ({value}) => {
          const revisionDeadline = value
            ? dayjs(value).format('DD/MM/YYYY')
            : '';

          return (
            <div style={{minWidth: '150px'}}>
              {revisionDeadline.indexOf('atrás') !== -1 ? (
                <strong>
                  {revisionDeadline} / {value}
                </strong>
              ) : (
                revisionDeadline
              )}
            </div>
          );
        },
      },
      {
        Header: 'Ação',
        Cell: ({
          row: {
            original: {id},
          },
        }) => (
          <Button
            kind="secondary"
            onClick={() => openReview(id)}
            disabled={isLoadingCompositions || hasInProgressGrades || isLoading}
          >
            Corrigir
          </Button>
        ),
      },
    ];
  }, [hasInProgressGrades, isLoading, isLoadingCompositions, openReview]);

  const data = React.useMemo(() => {
    return availableCompositions.count() ? availableCompositions.toJS() : [];
  }, [availableCompositions]);

  const {
    getTableBodyProps,
    headerGroups,
    getTableProps,
    prepareRow,
    state,
    page,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
    usePagination,
  ) as any;

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Lista de redações</h1>
      <h2 className={styles.timer}>
        {availableCompositionsCount} redações disponíveis - última atualização{' '}
        {dayjs(lastUpdated).format('LT')}
      </h2>
      {data.length ? (
        <Table
          dataCount={data.length}
          tableData={{
            getTableBodyProps,
            getTableProps,
            headerGroups,
            prepareRow,
            state,
            page,
          }}
          isLoading={isLoadingCompositions}
        />
      ) : (
        <div className={styles.placeholder}>Nenhuma redação disponivel!</div>
      )}
      <Loading show={isLoading} />
    </div>
  );
};

export default connect(
  (state: ApplicationState) => {
    return {
      availableCompositions: getAvailableCompositions(state),
      availableCompositionsCount: getAvailableCompositionsCount(state),
      lastUpdated: getAvailableCompositionsCountLastUpdated(state),
      isLoadingCompositions: isLoadingReviewerCompositions(state),
      hasInProgressGrades: hasInProgressGradesCompositions(state),
      hasCreatedCompositionReview: hasCreatedCompositionReview(state),
      isCreatingCompositionReview: isCreatingCompositionReview(state),
    };
  },
  {
    verifyProgressGradesLimitRequest,
    push,
    createCompositionReviewRequest,
    resetCompositionReviewStateRequest,
  },
)(AvailableCompositionsTable);
