/* eslint-disable no-nested-ternary */
import {LetrusApi} from '@letrustech/letrus-api-interfaces';
import {List} from 'immutable';
import {Icon} from 'letrus-ui';
import React, {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {connect} from 'react-redux';
import Select from 'react-select';
import ReactTooltip from 'react-tooltip';
import {
  getCompositionGrade,
  getReviewGridName,
  isFundII,
} from 'store/reducers/compositionReviews';
import {
  createGradeReviewAnnotationRequest,
  deleteGradeReviewAnnotationRequest,
  updateGradeReviewAnnotationRequest,
} from 'store/reducers/reviewAnnotations';
import {ApplicationState} from 'store/rootReducer';
import styles from './AddCommentSelectForm.module.scss';

interface InputsCompetenceSelectForm {
  competence?: number;
  category?: {
    label: string;
    value: number;
  };
  subcategory?: {
    label: string;
    value: number;
  };
  suggestionText?: string;
  commentText?: string;
}

interface AddCommentSelectFormProps {
  competences: List<ImmutableMap<LetrusApi.CompositionCompetence>>;
  textSelected: string;
  onClickCancel: () => void;
  hasClickedToAddComment?: boolean;
  competenceMarking?: number;
  onSubmitSuccess?: (selectedCompetenceID?: number) => void;
  annotation?: ImmutableMap<LetrusApi.ReviewAnnotation>;
  tabCompetenceSelected?: number;
  automaticMarking?: boolean;
  canEditReview?: boolean;
}

export interface StateProps {
  isFundII: boolean;
  compositionGradeGenre: string;
  compositionGrade: ImmutableMap<LetrusApi.CompositionReview>;
}

export interface DispatchProps {
  createGradeReviewAnnotationRequest: typeof createGradeReviewAnnotationRequest;
  updateGradeReviewAnnotationRequest: typeof updateGradeReviewAnnotationRequest;
  deleteGradeReviewAnnotationRequest: typeof deleteGradeReviewAnnotationRequest;
}

export type Props = StateProps & DispatchProps & AddCommentSelectFormProps;

const AddCommentSelectForm: React.FC<Props> = ({
  competences,
  textSelected,
  hasClickedToAddComment,
  competenceMarking,
  onClickCancel,
  compositionGrade,
  createGradeReviewAnnotationRequest,
  updateGradeReviewAnnotationRequest,
  onSubmitSuccess,
  compositionGradeGenre,
  automaticMarking,
  tabCompetenceSelected,
  annotation,
  isFundII,
  canEditReview,
}) => {
  const [categories, setCategories] = useState<List<ImmutableMap<any>>>(List());
  const [subcategories, setSubcategories] = useState<List<ImmutableMap<any>>>(
    List(),
  );

  const [hasCompetenceSelected, setHasCompetenceSelected] = useState(
    hasClickedToAddComment || tabCompetenceSelected,
  );
  const [hasCategorySelected, setHasCategorySelected] = useState(
    hasClickedToAddComment,
  );
  const [hasSubCategorySelected, setHasSubCategorySelected] = useState(
    hasClickedToAddComment,
  );
  const [categoryOptions, setCategoryOptions] = useState(List());
  const [subCategoryOptions, setSubCategoryOptions] = useState(List());

  const comment = annotation?.get('comment', '');
  const suggested_text = annotation?.get('suggested_text', '');

  const elemMiddleware = document.createElement('div');

  elemMiddleware.innerHTML = comment || '';
  const commentTextFormated =
    elemMiddleware.textContent || elemMiddleware.innerText || '';

  elemMiddleware.innerHTML = suggested_text || '';
  const suggestionTextFormated =
    elemMiddleware.textContent || elemMiddleware.innerText || '';

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState,
    watch,
  } = useForm<InputsCompetenceSelectForm>({
    defaultValues: {
      competence: hasClickedToAddComment
        ? competenceMarking
        : tabCompetenceSelected,
      category: {label: ''},
      subcategory: {
        label: '',
      },
      commentText: commentTextFormated,
      suggestionText: suggestionTextFormated,
    },
  });

  const {competence: competenceField} = watch();

  useEffect(() => {
    if (formState.isSubmitSuccessful && onSubmitSuccess) {
      onSubmitSuccess();
    }
  }, [formState, onSubmitSuccess]);

  useEffect(() => {
    const competence = competences.find(
      (competence) => competence.get('id') === competenceField,
    );

    const categories =
      competence && competence.get('review_categories')
        ? competence.get('review_categories')
        : List();

    setCategoryOptions(
      categories.map((category) => ({
        label: category.get('name'),
        value: category.get('id'),
      })),
    );
    setCategories(categories);

    if (annotation?.get('review_subcategory')) {
      const categorySelected = categories.find((category) =>
        category
          .get('subcategories')
          .find(
            (subcategory) =>
              subcategory.get('id') === annotation?.get('review_subcategory'),
          ),
      );

      const category = categories.find(
        (category) => category.get('id') === categorySelected.get('id'),
      );
      const subcategories = category ? category.get('subcategories') : List();

      setSubcategories(subcategories);

      setSubCategoryOptions(
        subcategories.map((subcategory) => ({
          label: subcategory.get('name'),
          value: subcategory.get('id'),
        })),
      );

      setValue('category', categorySelected?.get('id'));

      setValue('subcategory', annotation.get('review_subcategory'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasClickedToAddComment, competences]);

  const handleCompetenceChange = (competenceId) => {
    setValue('competence', competenceId);

    setValue('category', null);

    setValue('subcategory', null);

    setHasSubCategorySelected(false);

    setHasCategorySelected(false);

    const competenceSelected = competences.find(
      (competence) => competence.get('id') === competenceId,
    );
    setHasCompetenceSelected(true);
    const hasCategories = competenceSelected?.get('review_categories').count();

    if (hasCategories) {
      setCategories(List());
    }
    const competence = competences.find(
      (competence) =>
        competence.get('id').toString() === (getValues('competence') || ''),
    );
    const categories =
      competence && competence.get('review_categories')
        ? competence.get('review_categories')
        : List();

    const categoriesWithoutTextCategory = categories.filter(
      (categories) => !categories.get('is_text_category'),
    );

    if (!compositionGradeGenre.includes('ENEM')) {
      setHasSubCategorySelected(true);

      setHasCategorySelected(true);
    }

    setCategoryOptions(
      categoriesWithoutTextCategory.map((category) => ({
        label: category.get('name'),
        value: category.get('id'),
      })),
    );
    setCategories(categoriesWithoutTextCategory);
  };

  const {commentText} = getValues();

  const handleSubmitForm = (data) => {
    const competenceID = hasClickedToAddComment
      ? competenceMarking?.toString()
      : getValues('competence');
    const competenceSelected = competences.find(
      (competence) => competence.get('id').toString() === competenceID,
    );

    const subcategorySelected = subcategories.find(
      (subcategory) => subcategory.get('id') === data.subcategory,
    );

    const comment = data.commentText;
    const suggestion = data.suggestionText;

    if (competenceSelected) {
      const annotationType = `${competenceSelected.get('style')}`;

      hasClickedToAddComment
        ? updateGradeReviewAnnotationRequest({
            ...annotation?.toJS(),
            comment,
            suggested_text: suggestion,
            review_subcategory: subcategorySelected?.get('id'),
            reported_by: compositionGrade.get('reviewer_user'),
          })
        : createGradeReviewAnnotationRequest({
            comment,
            suggested_text: suggestion,
            annotation_type: annotationType,
            competence: competenceSelected.get('id'),
            selected_text: textSelected,
            review_subcategory: subcategorySelected?.get('id'),
            review: parseInt(compositionGrade.get('id'), 10),
            reported_by: compositionGrade.get('reviewer_user'),
          });

      if (onSubmitSuccess) onSubmitSuccess(selectedCompetence?.get('id'));
    }
  };

  const onChangeSubCategory = ({value}) => {
    setValue('subcategory', value);
    setHasSubCategorySelected(true);
  };

  const onChangeCategory = ({value}) => {
    setValue('category', value);

    if (!hasClickedToAddComment) {
      setHasSubCategorySelected(false);

      setHasCategorySelected(true);
      setValue('subcategory', null);
    }

    const category = categories.find(
      (category) => category.get('id') === value,
    );
    const subcategories = category ? category.get('subcategories') : List();

    setSubcategories(subcategories);
    setSubCategoryOptions(
      subcategories.map((subcategory) => ({
        label: subcategory.get('name'),
        value: subcategory.get('id'),
      })),
    );
  };

  const compareCompetenceID = hasClickedToAddComment
    ? competenceMarking?.toString()
    : getValues('competence');

  const selectedCompetence = competences.find(
    (competence) => competence.get('id').toString() === compareCompetenceID,
  );

  return (
    <form
      className={styles.container}
      onSubmit={handleSubmit(handleSubmitForm)}
    >
      <header>
        <h3
          className={`${styles.title} ${
            hasClickedToAddComment ? '' : styles.deviationMark
          }`}
        >
          {hasClickedToAddComment ? (
            <span className={styles.titleDeviation}>Desvio</span>
          ) : (
            <span className={styles.titleMark}>VOCÊ MARCOU:</span>
          )}
          <p
            className={`${styles.textSelected} ${
              hasClickedToAddComment ? styles.hasClickedToAddComment : ''
            } ${
              styles[
                isFundII
                  ? selectedCompetence
                      ?.get('name')
                      .replace('Critério ', 'C')
                      .slice(0, 2) || ''
                  : compositionGradeGenre.includes('ENEM')
                  ? selectedCompetence
                      ?.get('name')
                      .replace('Competência ', 'C') || ''
                  : selectedCompetence
                      ?.get('name')
                      .replace('Competência ', 'C')
                      .slice(0, 2) || ''
              ]
            }
            `}
          >
            "{textSelected}"
          </p>
        </h3>
      </header>
      {!hasClickedToAddComment && <hr />}
      <div className={styles.body}>
        {!hasClickedToAddComment && (
          <div className={styles.competencesWrap}>
            <label className={styles.titlteLabel}>
              Competências{' '}
              <span
                data-tip="Conjunto de conhecimentos utilizados pelo Enem para avaliar textos do tipo dissertativo-argumentativo."
                data-for="autoMarkings"
                className={styles.iconQuestion}
              >
                <Icon icon="question" size="1x" />
              </span>
              <ReactTooltip
                className={styles.tooltipQuestion}
                id="autoMarkings"
                type="dark"
                effect="solid"
                place="right"
                multiline={true}
              />
            </label>
            <div className={styles.container}>
              {competences.map((competence) => (
                <div className={styles.competence} key={competence.get('id')}>
                  <span
                    data-tip={competence?.get('description')}
                    data-for={`competence-${competence.get('id')}`}
                  >
                    <button
                      type="button"
                      name="competence"
                      className={`${styles.button}  ${
                        (getValues('competence') || '') ===
                        competence.get('id').toString()
                          ? styles[
                              `selected--${
                                isFundII
                                  ? selectedCompetence
                                      ?.get('name')
                                      .replace('Critério ', 'C')
                                      .slice(0, 2)
                                  : compositionGradeGenre.includes('ENEM')
                                  ? competence
                                      .get('name')
                                      .replace('Competência ', 'C')
                                  : competence
                                      .get('name')
                                      .replace('Competência ', 'C')
                                      .slice(0, 2)
                              }`
                            ]
                          : styles.buttonWithHover
                      }`}
                      onClick={() =>
                        handleCompetenceChange(competence.get('id'))
                      }
                      ref={register}
                    >
                      {isFundII
                        ? competence
                            .get('name')
                            .replace('Critério ', 'C')
                            .slice(0, 2)
                        : compositionGradeGenre.includes('ENEM')
                        ? competence.get('name').replace('Competência ', 'C')
                        : competence
                            .get('name')
                            .replace('Competência ', 'C')
                            .slice(0, 3)}
                    </button>
                  </span>
                  <ReactTooltip
                    id={`competence-${competence.get('id')}`}
                    className={styles.tooltip}
                    type="dark"
                    effect="solid"
                    place="bottom"
                    multiline={true}
                  />
                </div>
              ))}
            </div>
            {hasCompetenceSelected &&
              competences.map((competence) =>
                (getValues('competence') || '') ===
                competence.get('id').toString() ? (
                  <span
                    className={styles.description}
                    key={competence.get('id')}
                  >
                    {competence?.get('description')}
                  </span>
                ) : (
                  ''
                ),
              )}
          </div>
        )}
        {!automaticMarking &&
        hasCompetenceSelected &&
        compositionGradeGenre.includes('ENEM') ? (
          <div className={styles.categoriesContainer}>
            <div className={styles.categoriesWrap}>
              <label className={styles.titlteLabel}>
                Categoria
                <span
                  data-tip={
                    'Grupo de elementos associados a uma determinada competência.'
                  }
                  data-for="autoMarkings"
                  className={styles.iconQuestion}
                >
                  <Icon icon="question" size="1x" />
                </span>
                <ReactTooltip
                  className={styles.tooltipQuestion}
                  id="autoMarkings"
                  type="dark"
                  effect="solid"
                  place="right"
                  multiline={true}
                />
              </label>
              <Select
                className="reactSelect"
                name="category"
                onChange={onChangeCategory}
                options={categoryOptions.toJS()}
                placeholder="Selecione"
                value={
                  hasCategorySelected
                    ? categoryOptions.find(
                        (option) => option.value === getValues('category'),
                      )
                    : null
                }
                ref={() => register({name: 'category', required: true})}
              />
            </div>
            <div className={styles.subCategoriesWrap}>
              <label className={styles.titlteLabel}>
                Subcategoria
                <span
                  data-tip="Divisão dos elementos que compõem uma categoria"
                  data-for="autoMarkings"
                  className={styles.iconQuestion}
                >
                  <Icon icon="question" size="1x" />
                </span>
                <ReactTooltip
                  className={styles.tooltipQuestion}
                  id="autoMarkings"
                  type="dark"
                  effect="solid"
                  place="right"
                  multiline={true}
                />
              </label>
              <Select
                className="reactSelect"
                name="subcategory"
                onChange={onChangeSubCategory}
                options={subCategoryOptions.toJS()}
                placeholder="Selecione"
                value={
                  hasSubCategorySelected
                    ? subCategoryOptions.find(
                        (option) => option.value === getValues('subcategory'),
                      )
                    : null
                }
                ref={() => register({name: 'subcategory', required: true})}
              />
            </div>
          </div>
        ) : null}
        {hasCategorySelected && hasSubCategorySelected && (
          <div>
            <div className={styles.comment}>
              <label className={styles.title}>Comentário</label>
              <textarea
                className={styles.input}
                autoComplete="off"
                name="commentText"
                id="commentText"
                placeholder="Adicionar comentário"
                ref={register()}
                spellCheck
              />
            </div>
            <div className={styles.suggestion}>
              <label className={styles.title}>Sugestão</label>
              <textarea
                className={styles.input}
                autoComplete="off"
                id="suggestionText"
                name="suggestionText"
                placeholder="Sugerir uma correção para o aluno"
                ref={register()}
                spellCheck
              />
            </div>
          </div>
        )}
        <footer className={styles.footerButtons}>
          <button
            className={styles.cancel}
            type="button"
            onClick={onClickCancel}
          >
            {hasClickedToAddComment ? 'Cancelar edição' : 'Cancelar marcação'}
          </button>
          {canEditReview && (
            <button
              disabled={!commentText}
              className={`${styles.save} ${commentText ? '' : styles.disabled}`}
              type="submit"
            >
              <Icon icon="check" />
              Salvar
            </button>
          )}
        </footer>
      </div>
    </form>
  );
};

export default connect<
  StateProps,
  DispatchProps,
  AddCommentSelectFormProps,
  ApplicationState
>(
  (state: ApplicationState) => ({
    compositionGrade: getCompositionGrade(state),
    isFundII: isFundII(state),
    compositionGradeGenre: getReviewGridName(state),
  }),
  {
    updateGradeReviewAnnotationRequest,
    createGradeReviewAnnotationRequest,
    deleteGradeReviewAnnotationRequest,
  },
)(AddCommentSelectForm);
