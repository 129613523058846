import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Input} from 'letrus-ui';
import React from 'react';
import {Modal} from 'react-bootstrap';
import {connect} from 'react-redux';
import {track} from 'react-tracking';
import {InjectedFormProps} from 'redux-form';
import {
  Field,
  Form,
  reduxForm,
  reset,
  SubmissionError,
} from 'redux-form/immutable';
import {resetPasswordRequest} from 'store/reducers/authentication';
import {validatePassword} from 'utils/validatePassword';
import './ChangePasswordModal.scss';

interface DispatchProps {
  resetPasswordRequest?: any;
  tracking?: any;
}

interface ChangePasswordModalProps {
  user?: any;
  isTeacher: boolean;
  onHide: any;
  openMessageModal: any;
  dispatch?: any;
  show: boolean;
}

type Props = DispatchProps & ChangePasswordModalProps;

const required = (value: any) => (value ? undefined : 'Campo obrigatório');

const ChangePasswordModal: React.FC<Props & InjectedFormProps<{}, Props>> = ({
  dispatch,
  form,
  handleSubmit,
  isTeacher,
  onHide,
  openMessageModal,
  resetPasswordRequest,
  show,
  tracking,
  user,
}) => {
  const onClickCancel = () => {
    onHide();
    dispatch(reset('ChangePasswordModalForm'));
  };

  const onSubmit = (values: any) => {
    if (isTeacher && user && user.get('id')) {
      return resetPasswordRequest(user.id, values.toJS())
        .then(() => {
          onHide();
          openMessageModal('Senha redefinida com sucesso!');
          dispatch(reset('ChangePasswordModalForm'));
          tracking.trackEvent({
            event: 'updatedPassword',
          });
        })
        .catch((error: any) => {
          throw new SubmissionError(error.json);
        });
    }

    return null;
  };

  return (
    <Modal
      autoFocus
      dialogClassName="ChangePasswordModal"
      onHide={onHide}
      show={show}
    >
      <div className="ChangePasswordModal__Header defaultPrimary">
        <FontAwesomeIcon
          icon="key"
          className="ChangePasswordModal__Header__Icon"
        />
        <p className="ChangePasswordModal__Header__Title">Alterar senha</p>
      </div>
      <div className="ChangePasswordModal__Body">
        <Form className="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <Field
            label="Senha atual"
            type="password"
            name="current_password"
            placeholder="Escreva sua senha atual"
            component={Input}
            validate={required}
          />
          <Field
            label="Nova senha"
            type="password"
            name="new_password"
            placeholder="Escreva a nova senha"
            component={Input}
            validate={required}
          />
          <Field
            label="Confirmação de senha"
            type="password"
            name="new_password_re"
            placeholder="Confirme a nova senha"
            component={Input}
            validate={required}
          />
          <div className="ChangePasswordModal__Button">
            <Button onClick={onClickCancel} type="button">
              Cancelar
            </Button>
            <span className="spacer" />
            <Button type="submit">Alterar</Button>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default connect(null, {
  resetPasswordRequest,
})(
  reduxForm<{}, Props>({
    form: 'ChangePasswordModalForm',
    validate: validatePassword,
  })(track({})(ChangePasswordModal)),
);
