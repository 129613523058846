import api from './api';

export interface FlagEvaluationRequest {
  flagKey: string;
  entityId: string;
  entityContext?: {};
}

export const fetchFeatureFlagService = (data: FlagEvaluationRequest) =>
  api('https://flags.letrus.com.br/api/v1/evaluation', {method: 'post', data});
