import {LetrusApi} from '@letrustech/letrus-api-interfaces';
import Loading from 'components/Loading';
import moment from 'moment';
import React, {useEffect, useMemo} from 'react';
import {connect} from 'react-redux';
import {
  fetchTermsRequest,
  getTerms,
  isLoadingGlobalConfiguration,
} from 'store/reducers/globalConfiguration';
import {ApplicationState} from 'store/rootReducer';
import styles from './Privacy.module.scss';

interface StateProps {
  terms: ImmutableList<ImmutableMap<LetrusApi.LetrusGlobalConfiguration>>;
  isLoadingGlobalConfiguration: boolean;
}

interface DispatchProps {
  fetchTermsRequest: typeof fetchTermsRequest;
}

type PrivacyProps = StateProps & DispatchProps;

function Privacy({
  terms,
  isLoadingGlobalConfiguration,
  fetchTermsRequest,
}: PrivacyProps): JSX.Element {
  useEffect(() => {
    fetchTermsRequest();
  }, [fetchTermsRequest]);

  const {privacyText, privacyDate} = useMemo(() => {
    const privacyTerm = terms
      ?.filter<ImmutableMap<LetrusApi.LetrusGlobalConfiguration>>(
        (term) => term.get('parameter') === 'terms_privacy_text',
      )
      ?.first();

    return {
      privacyText: privacyTerm ? privacyTerm.get('value') : '',
      privacyDate: privacyTerm ? privacyTerm.get('modified') : '',
    };
  }, [terms]);

  return (
    <div className={styles.privacyWrapper}>
      <h3>POLÍTICA DE PRIVACIDADE</h3>

      {isLoadingGlobalConfiguration ? (
        <Loading show />
      ) : (
        <div className={styles.privacyBody}>
          <p>
            Fonte:{' '}
            <a href="/privacidade" target="_blank">
              www.corretor.letrus.com.br/privacidade/
            </a>
          </p>
          <p>Última atualização: {moment(privacyDate).format('DD/MM/YYYY')}</p>
          <div
            className={styles.privacyHTML}
            dangerouslySetInnerHTML={{__html: privacyText}}
          />
        </div>
      )}
    </div>
  );
}

export default connect(
  (state: ApplicationState) => ({
    terms: getTerms(state),
    isLoadingGlobalConfiguration: isLoadingGlobalConfiguration(state),
  }),
  {
    fetchTermsRequest,
  },
)(Privacy);
