import {fromJS, List} from 'immutable';
import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {fetchZeroGradeCommentService} from 'store/services/zeroGradeCommentService';
import {action} from 'typesafe-actions';

//Action types
export enum ZeroGradeCommentTypes {
  FETCH_ZERO_GRADE_COMMENT_REQUEST = '@zero_grade_comments/FETCH_ZERO_GRADE_COMMENT_REQUEST',
  FETCH_ZERO_GRADE_COMMENT_SUCCESS = '@zero_grade_comments/FETCH_ZERO_GRADE_COMMENT_SUCCESS',
  FETCH_ZERO_GRADE_COMMENT_FAILURE = '@zero_grade_comments/FETCH_ZERO_GRADE_COMMENT_FAILURE',
}

//Data types

//State type
export interface ZeroGradeCommentState
  extends ImmutableMap<{
    data: List<any>;
    loading: boolean;
    error: boolean;
    dataCount: number;
  }> {}

//Fetch ZeroGradeComment Actions
export const fetchZeroGradeCommentsRequest = (
  genreId: number,
  userRole: string,
) =>
  action(ZeroGradeCommentTypes.FETCH_ZERO_GRADE_COMMENT_REQUEST, {
    genreId,
    userRole,
  });

export const fetchZeroGradeCommentSuccess = (data: any) =>
  action(ZeroGradeCommentTypes.FETCH_ZERO_GRADE_COMMENT_SUCCESS, data);

export const fetchZeroGradeCommentFailure = () =>
  action(ZeroGradeCommentTypes.FETCH_ZERO_GRADE_COMMENT_FAILURE);

//Sagas
export function* fetchZeroGradeComment(action: AnyAction) {
  try {
    const response = yield call(
      fetchZeroGradeCommentService,
      action.payload.genreId,
      action.payload.userRole,
    );
    yield put(fetchZeroGradeCommentSuccess(response.data));
  } catch (error) {
    yield put(fetchZeroGradeCommentFailure());
  }
}

//Initial State
export const INITIAL_STATE: ZeroGradeCommentState = fromJS({
  data: [],
  loading: false,
  error: false,
  dataCount: 0,
});

//Selectors
const zeroGradeCommentSelector = (state: ApplicationState) =>
  state.get('zeroGradeComment');

export const getZeroGradeReasons = createSelector(
  zeroGradeCommentSelector,
  (zeroGradeComment) => zeroGradeComment.get('data'),
);

//Reducer
const reducer: Reducer<ZeroGradeCommentState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case ZeroGradeCommentTypes.FETCH_ZERO_GRADE_COMMENT_REQUEST: {
      return state.withMutations((prevState) =>
        prevState.set('loading', true).set('error', false),
      );
    }

    case ZeroGradeCommentTypes.FETCH_ZERO_GRADE_COMMENT_SUCCESS: {
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .set('data', fromJS(action.payload.results))
          .set('dataCount', action.payload.count),
      );
    }

    case ZeroGradeCommentTypes.FETCH_ZERO_GRADE_COMMENT_FAILURE: {
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );
    }

    default:
      return state;
  }
};

export default reducer;
