import {fromJS} from 'immutable';
import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {fetchCompositionGradeService} from 'store/services/compositionGradesService';
import {action} from 'typesafe-actions';
import {immutableFactory} from 'utils/Immutable/immutableFactory';

//Action types
export enum CompositionGradesTypes {
  FETCH_COMPOSITION_GRADES_REQUEST = '@composition_grades/FETCH_COMPOSITION_GRADES_REQUEST',
  FETCH_COMPOSITION_GRADES_SUCCESS = '@composition_grades/FETCH_COMPOSITION_GRADES_SUCCESS',
  FETCH_COMPOSITION_GRADES_FAILURE = '@composition_grades/FETCH_COMPOSITION_GRADES_FAILURE',
}

//Data types

//State type
export interface CompositionGradesState
  extends ImmutableMap<{
    data: ImmutableMap<any>;
    loading: boolean;
    error: boolean;
  }> {}

//Fetch CompositionGrades Actions
export const fetchCompositionGradesRequest = (id: string) =>
  action(CompositionGradesTypes.FETCH_COMPOSITION_GRADES_REQUEST, id);

export const fetchCompositionGradesSuccess = (data: any) =>
  action(CompositionGradesTypes.FETCH_COMPOSITION_GRADES_SUCCESS, data);

export const fetchCompositionGradesFailure = () =>
  action(CompositionGradesTypes.FETCH_COMPOSITION_GRADES_FAILURE);

//Sagas
export function* fetchCompositionGrades(action: AnyAction) {
  try {
    const response = yield call(fetchCompositionGradeService, action.payload);
    yield put(fetchCompositionGradesRequest(response));
  } catch (error) {
    yield put(fetchCompositionGradesFailure());
  }
}

//Initial State
export const INITIAL_STATE: CompositionGradesState = fromJS({
  data: immutableFactory<any>({}),
  loading: false,
  error: false,
});

//Selectors

const compositionGradeSelector = (state: ApplicationState) =>
  state.get('compositionGrades');

export const getCompositionGrades = createSelector(
  compositionGradeSelector,
  (grades) => grades.get('data'),
);

//Reducer
const reducer: Reducer<CompositionGradesState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case CompositionGradesTypes.FETCH_COMPOSITION_GRADES_REQUEST: {
      return state.withMutations((prevState) =>
        prevState.set('loading', true).set('error', false),
      );
    }

    case CompositionGradesTypes.FETCH_COMPOSITION_GRADES_SUCCESS: {
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .set('data', immutableFactory<any>(action.payload)),
      );
    }

    case CompositionGradesTypes.FETCH_COMPOSITION_GRADES_FAILURE: {
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );
    }

    default:
      return state;
  }
};

export default reducer;
