import dayjs from 'dayjs';
import {List} from 'immutable';
import {NewButton, Table} from 'letrus-ui';
import React, {useMemo} from 'react';
import {usePagination, useSortBy, useTable} from 'react-table';

interface Props {
  columns: any;
  payments?: List<ImmutableMap<any>>;
  inProgressPayments?: ImmutableMap<any>;
  createAlertToUploadNF: any;
  hasEarlyRedeem?: boolean;
}

const PaymentsTable: React.FC<Props> = ({
  inProgressPayments,
  payments,
  createAlertToUploadNF,
  columns,
  hasEarlyRedeem,
}) => {
  const convertCentsInBrazilianMoney = (moneyInCents) => {
    const money = (Number(moneyInCents || 0) / 100).toFixed(2);

    return 'R$ ' + String(money).replace('.', ',');
  };

  const translateStatus = (status) => {
    const mapStatus = {
      rejected: 'Rejeitado',
      accepted: 'Aceito',
      paid: 'Pago',
    };

    const newStatus = mapStatus[status];

    return newStatus || 'Em processamento';
  };

  const rowTableData = useMemo(() => {
    return inProgressPayments
      ? [
          {
            reviews_number: inProgressPayments.get('reviews_number'),
            amount_localized: inProgressPayments.get('amount_localized'),
            action:
              inProgressPayments.get('reviews_number') <
              (hasEarlyRedeem ? 1 : 10) ? (
                <NewButton disabled>Aguarde liberação</NewButton>
              ) : (
                <NewButton
                  // className="button-accent-with-hover"
                  onClick={() => createAlertToUploadNF()}
                >
                  Resgatar
                </NewButton>
              ),
          },
        ]
      : payments
          ?.map((payment) => ({
            transfer_date: dayjs(payment.get('transfer_date')).format('L'),
            reviews_number: payment.get('reviews_number'),
            amount_cents: convertCentsInBrazilianMoney(
              payment.get('amount_cents'),
            ),
            withdraw_status: translateStatus(payment.get('withdraw_status')),
          }))
          .toJS() || [{}];
  }, [createAlertToUploadNF, hasEarlyRedeem, inProgressPayments, payments]);

  const tableData = useTable(
    {
      columns,
      data: rowTableData,
    },
    useSortBy,
    usePagination,
  );

  return (
    <Table
      tableData={tableData}
      dataCount={rowTableData.length}
      isLoading={false}
    />
  );
};

export default PaymentsTable;
