/* eslint-disable no-nested-ternary */
import {LetrusApi} from '@letrustech/letrus-api-interfaces';
import ConfirmFinishReview from 'components/ConfirmFinishReview';
import FormConfirmAbandonReview from 'containers/FormConfirmAbandonReview';
import {List} from 'immutable';
import {Icon, Modal, NewButton} from 'letrus-ui';
import React, {useState} from 'react';
import ReactTooltip from 'react-tooltip';
import styles from './EditGradeFinishReview.module.scss';

interface GeneralAnnotation extends LetrusApi.GeneralAnnotation {
  reported_by?: number;
}

export interface EditGradeFinishReviewProps {
  isFundII: boolean;
  isCustomCorrectionGrid: boolean;
  annotations?: List<ImmutableMap<LetrusApi.ReviewAnnotation>>;
  competences: List<ImmutableMap<LetrusApi.CompositionCompetence>>;
  compositionGrade: ImmutableMap<LetrusApi.CompositionReview>;
  onFinishReview: () => void;
  generalAnnotations: List<ImmutableMap<GeneralAnnotation>>;
}

const EditGradeFinishReview: React.FC<EditGradeFinishReviewProps> = ({
  compositionGrade,
  competences,
  onFinishReview,
  generalAnnotations,
  annotations,
  isFundII,
  isCustomCorrectionGrid,
}) => {
  const [isOpenWarning, setIsOpenWarning] = useState(true);

  const [isFinishReviewModalOpen, setIsFinishReviewModalOpen] = useState(false);

  const [isOpenModalAbandonReview, setIsOpenModalAbandonReview] = useState(
    false,
  );

  const orthographicCompetences = competences.filter((competence) => {
    return !!competence
      .get('marker_categories')
      .find<ImmutableMap<LetrusApi.TextMarkerCategoryNested>>(
        (markerCategory) =>
          markerCategory.get('varname') === 'erros_ortograficos',
      );
  });

  const orthographicErrorsList = annotations
    ? annotations?.filter((annotation) => {
        return orthographicCompetences.find(
          (competence) =>
            competence.get('id') === annotation.get('competence') &&
            !!annotation.get('rule'),
        );
      })
    : List();

  const autoMarkingsApproved = orthographicErrorsList.filter((annotation) =>
    annotation.get('reported_by'),
  );

  const validateAutoMarkings =
    autoMarkingsApproved.size === orthographicErrorsList.size;

  const automaticPlagiarismAnnotations = generalAnnotations.filter(
    (annotation) => {
      return (
        annotation.get('annotation_type') === 'plagio' &&
        !annotation.get('reported_by')
      );
    },
  );

  const validateCompetenceGrades =
    competences?.size === compositionGrade?.get('review_grades').size;

  const handleCloseWarning = () => {
    setIsOpenWarning(false);
  };

  const generalComments = compositionGrade?.get('general_comments');

  const hasAtLeastOneReviewerAnnotation =
    annotations &&
    annotations.filter((annotation) => !annotation.get('rule'))?.size >= 1;

  const isEnem =
    compositionGrade?.getIn([
      'composition',
      'instruction_id',
      'genre',
      'review_grid_name',
    ]) === 'ENEM';

  const isMultigenre =
    compositionGrade?.getIn([
      'composition',
      'instruction_id',
      'genre',
      'review_grid_name',
    ]) === 'Multigênero';

  const hasPreReview = compositionGrade?.getIn([
    'composition',
    'test',
    'has_pre_review',
  ]);

  const commentsIsNotRequired = (isEnem || isMultigenre) && hasPreReview;

  const validateAllSteps =
    (validateAutoMarkings &&
      validateCompetenceGrades &&
      automaticPlagiarismAnnotations.size === 0 &&
      (hasAtLeastOneReviewerAnnotation || isCustomCorrectionGrid)) ||
    (commentsIsNotRequired && validateAutoMarkings && validateCompetenceGrades);

  const validateAllStepsFund =
    validateAutoMarkings &&
    validateCompetenceGrades &&
    automaticPlagiarismAnnotations.size === 0 &&
    generalComments &&
    (hasAtLeastOneReviewerAnnotation || isCustomCorrectionGrid);

  const handleClickAbandonReview = () => {
    setIsOpenModalAbandonReview(!isOpenModalAbandonReview);
  };

  const handleClickFinishReview = () => {
    setIsFinishReviewModalOpen(!isFinishReviewModalOpen);
  };

  const handleSubmitFinishReview = () => {
    handleClickFinishReview();
    onFinishReview();
  };

  return (
    <div className={styles.container}>
      {isOpenWarning && (
        <div className={styles.warning}>
          <div className={styles.texts}>
            <span className={styles.description}>
              Para finalizar e enviar o texto corrigido, você deve{' '}
              <strong>completar todas as etapas</strong> obrigatórias do nosso
              processo de correção. Assim, utilize a lista de tarefas a seguir
              para saber o que ainda precisa ser completado por você nesta
              correção.
            </span>
          </div>
          <button onClick={handleCloseWarning} className={styles.closeWarning}>
            <Icon icon={['fal', 'times']} />
          </button>
        </div>
      )}
      <span className={styles.titleList}>LISTA DE TAREFAS</span>
      <ul className={styles.finishList}>
        {orthographicErrorsList && (
          <li className={styles.finishItem}>
            <label
              className={`${styles.titleItem} ${
                validateAutoMarkings ? styles.validated : ''
              }`}
            >
              <Icon
                icon={validateAutoMarkings ? 'check-circle' : 'times-circle'}
              />
              Avaliar todas as <b>marcações automáticas</b>
            </label>
            <p className={styles.descriptionItem}>
              {isFundII
                ? isCustomCorrectionGrid
                  ? `As marcações automáticas estão presentes na guia superior "Marcações" (na aba de mesmo nome). Para fazer a avaliação exigida, você deve aprovar ou excluir as marcações feitas pela nossa inteligência artificial.`
                  : `As marcações automáticas estão presentes na guia superior "Marcações" e associadas ao critério 1 (na aba C1). Para fazer a avaliação exigida, você deve aprovar ou excluir as marcações feitas pela nossa inteligência artificial.`
                : `As marcações automáticas estão presentes na guia superior
              "Marcações" e associadas à competência 1 (na aba C1). Para fazer a
              avaliação exigida, você deve aprovar ou excluir as marcações
              feitas pela nossa inteligência artificial.`}
            </p>
          </li>
        )}
        <li className={styles.finishItem}>
          <label
            className={`${styles.titleItem} ${
              validateCompetenceGrades ? styles.validated : ''
            }`}
          >
            <Icon
              icon={validateCompetenceGrades ? 'check-circle' : 'times-circle'}
            />
            {isFundII ? (
              <>
                Atribuir <b>nível</b> para todos os critérios.
              </>
            ) : (
              <>
                Atribuir <b>nota</b> para todas as competências
              </>
            )}
          </label>
          <p className={styles.descriptionItem}>
            {isFundII
              ? `Navegue na guia ”Níveis” e certifique-se de que você atribuiu níveis para todos os critérios.`
              : `Navegue na guia ”Notas” e certifique-se de que você atribuiu nota
            para todas as competências. Caso você precise zerar a redação,
            acesse a aba “Total” na guia “Notas”, clique no botão “Zerar
            redação” e justifique o motivo do zero.`}
          </p>
        </li>
        {automaticPlagiarismAnnotations.size > 0 && (
          <li className={styles.finishItem}>
            <label className={styles.titleItem}>
              <Icon icon="times-circle" />
              Avaliar todas as <strong>identificações automáticas</strong> de
              plágio
            </label>
            <p className={styles.descriptionItem}>
              As identificações automáticas de plágio estão presentes na aba
              ”Plágio”, em ”Marcações”. Para fazer a avaliação exigida, você
              deve aprovar ou excluir as marcações feitas pela nossa
              inteligência artificial.
            </p>
          </li>
        )}
        {isFundII && (
          <li className={styles.finishItem}>
            <label
              className={`${styles.titleItem} ${
                generalComments ? styles.validated : ''
              }`}
            >
              <Icon icon={generalComments ? 'check-circle' : 'times-circle'} />
              Escrever um <b>comentário</b> para o aluno
            </label>
            <p className={styles.descriptionItem}>
              Certifique-se de que você deixou, na seção “Totais” da guia
              "Níveis", um comentário final para o aluno, indicando pontos
              positivos e de possíveis melhorias.
            </p>
          </li>
        )}
        {!isCustomCorrectionGrid ||
          (!commentsIsNotRequired && (
            <li className={styles.finishItem}>
              <label
                className={`${styles.titleItem} ${
                  hasAtLeastOneReviewerAnnotation ? styles.validated : ''
                }`}
              >
                <Icon
                  icon={
                    hasAtLeastOneReviewerAnnotation
                      ? 'check-circle'
                      : 'times-circle'
                  }
                />
                Realizar <b>comentários</b> no corpo do texto.
              </label>
              <p className={styles.descriptionItem}>
                Escreva comentários construtivos para o estudante de acordo com
                os critérios avaliativos desta atividade, ressaltando ao menos
                três delas. Selecione pequenos trechos do texto e atente-se aos
                pontos de desenvolvimento do autor, indicando maneiras de
                evoluir.
              </p>
            </li>
          ))}
      </ul>
      <footer className={styles.footerButton}>
        {isFundII ? (
          !validateAllStepsFund ? (
            <>
              <span
                data-tip={
                  'Complete todas as tarefas pendentes na lista acima para poder enviar a correção ao aluno.'
                }
                data-for="finishReview"
              >
                <NewButton
                  disabled={!validateAllStepsFund}
                  text="Finalizar e enviar correção"
                  kind="primary"
                  userRole="teacher"
                />
              </span>
              <ReactTooltip
                className={styles.tooltipQuestion}
                id="finishReview"
                type="dark"
                effect="solid"
                place="right"
                multiline
              />
            </>
          ) : (
            <NewButton
              onClick={handleClickFinishReview}
              text="Finalizar e enviar correção"
              kind="primary"
              userRole="teacher"
            />
          )
        ) : !validateAllSteps ? (
          <>
            <span
              data-tip={
                'Complete todas as tarefas pendentes na lista acima para poder enviar a correção ao aluno.'
              }
              data-for="finishReview"
            >
              <NewButton
                disabled={!validateAllSteps}
                text="Finalizar e enviar correção"
                kind="primary"
                userRole="teacher"
              />
            </span>
            <ReactTooltip
              className={styles.tooltipQuestion}
              id="finishReview"
              type="dark"
              effect="solid"
              place="right"
              multiline={true}
            />
          </>
        ) : (
          <NewButton
            onClick={handleClickFinishReview}
            text="Finalizar e enviar correção"
            kind="primary"
            userRole="teacher"
          />
        )}
        <button
          onClick={handleClickAbandonReview}
          className={styles.buttonReset}
          type="button"
        >
          <Icon icon={['fal', 'times']} /> Desistir da correção
        </button>
      </footer>
      <div className={styles.modal}>
        <Modal
          onClose={handleClickFinishReview}
          isOpen={isFinishReviewModalOpen}
          clickOutsideToClose
          buttonClose={false}
          children={
            <ConfirmFinishReview
              isFundII={isFundII}
              onFinishReview={handleSubmitFinishReview}
              handleCLickCancel={handleClickFinishReview}
            />
          }
        />
        <Modal
          onClose={handleClickAbandonReview}
          isOpen={isOpenModalAbandonReview}
          clickOutsideToClose
          buttonClose={false}
          children={
            <FormConfirmAbandonReview
              compositionReviewId={parseInt(compositionGrade.get('id'), 10)}
              onCancelClick={handleClickAbandonReview}
            />
          }
        />
      </div>
    </div>
  );
};

export default EditGradeFinishReview;
