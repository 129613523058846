import DisplayTextInTooltip from 'components/DisplayTextInTooltip';
import {push} from 'connected-react-router';
import dayjs from 'dayjs';
import {Button, Icon, Table} from 'letrus-ui';
import React, {useCallback, useMemo} from 'react';
import {connect} from 'react-redux';
import {Column, usePagination, useSortBy, useTable} from 'react-table';
import {
  getInProgressGrades,
  isLoadingInProgressGradesSelector,
} from 'store/reducers/compositionReviews';
import {ApplicationState} from 'store/rootReducer';
import styles from './ReviewerInProgressGrades.module.scss';

interface InProgressGradesComposition {
  id: number;
  title: string;
  instruction_id: {
    title: string;
    genre: {
      name: string;
    };
  };
  revision_deadline: string;
}

export interface StateProps {
  inProgressGrades: any;
  isLoading: boolean;
}

export interface DispatchProps {
  push: typeof push;
}

type Props = StateProps & DispatchProps;

const ReviewerInProgressGradesTable: React.FC<Props> = ({
  inProgressGrades,
  push,
  isLoading,
}) => {
  const handleClickContinue = useCallback(
    (id: number) => {
      return push(`/correcao/${id}`);
    },
    [push],
  );

  const data = useMemo(() => {
    const composition = inProgressGrades?.get('composition').toJS();
    return [composition] || [];
  }, [inProgressGrades]);

  const columns: Array<Column<InProgressGradesComposition>> = useMemo(() => {
    return [
      {
        accessor: 'id',
        Header: 'Número',
        Cell: ({value}) => <div style={{minWidth: '120px'}}>{value}</div>,
      },
      {
        id: 'composition__title',
        Header: 'Nome',
        accessor: ({title}) => title || 'Sem título',
        Cell: ({row: {original}, value}) => (
          <div style={{minWidth: '150px'}}>
            <div className={styles['composition-title']}>
              {original.parent_id ? (
                <span className={styles['composition-title--icon']}>
                  <Icon icon="clone" />
                </span>
              ) : null}
              <span
                style={{
                  fontStyle: 'normal',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  display: 'block',
                }}
              >
                {<DisplayTextInTooltip text={value} />}
              </span>
            </div>
          </div>
        ),
      },
      {
        id: 'composition__instruction_id__title',
        Header: 'Tema',
        accessor: ({instruction_id}) =>
          instruction_id?.title &&
          instruction_id?.title.replace(/ *\[[^)]*\] */g, ''),
        Cell: ({value}) => (
          <div
            style={{
              width: '300px',
              fontStyle: 'normal',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {<DisplayTextInTooltip text={value} />}
          </div>
        ),
      },
      {
        id: 'composition__genre__name',
        Header: 'Gênero',
        accessor: ({instruction_id}) => instruction_id?.genre.name || null,
        Cell: ({value}) => (
          <div
            style={{
              minWidth: '100px',
              fontStyle: 'italic',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {value}
          </div>
        ),
      },
      {
        id: 'composition__revision_deadline',
        Header: 'Data de Entrega',
        accessor: ({revision_deadline}) =>
          dayjs(revision_deadline || dayjs().add(3, 'day')).format(
            'DD/MM/YYYY',
          ),
        Cell: ({value}) => <div style={{minWidth: '170px'}}>{value}</div>,
      },
      {
        id: '_controls',
        Header: 'Ação',
        accessor: (composition) => composition,
        Cell: ({row: {original}}) => (
          <div style={{minWidth: '150px'}}>
            <Button
              kind="accentPrimary"
              onClick={() => handleClickContinue(original.id)}
              userRole="reviewer"
            >
              Continuar
            </Button>
          </div>
        ),
      },
    ];
  }, [handleClickContinue]);

  const {
    getTableBodyProps,
    headerGroups,
    getTableProps,
    prepareRow,
    state,
    page,
  } = useTable({columns, data}, useSortBy, usePagination) as any;

  return (
    <div className={styles.container}>
      <h1>Correção iniciada</h1>
      <Table
        dataCount={data.length}
        tableData={{
          getTableBodyProps,
          getTableProps,
          headerGroups,
          prepareRow,
          state,
          page,
        }}
        isLoading={isLoading}
      />
    </div>
  );
};

export default connect(
  (state: ApplicationState) => ({
    inProgressGrades: getInProgressGrades(state),
    isLoading: !!isLoadingInProgressGradesSelector(state),
  }),
  {push},
)(ReviewerInProgressGradesTable);
