import {LetrusApi} from '@letrustech/letrus-api-interfaces';
import {List} from 'immutable';
import moment from 'moment';
import React from 'react';

interface TermInfo {
  name: string;
  isReadonly: boolean;
  id: string;
  title: string;
}
export interface TermsSectionProps {
  terms: List<ImmutableMap<LetrusApi.LetrusGlobalConfiguration>>;
  data: TermInfo[];
}

const TermsSection: React.FC<TermsSectionProps> = ({data, terms}) => {
  const markdown =
    data &&
    data
      .reverse()
      .map((term) => {
        const termsInfo =
          terms &&
          terms.find((termsItem) => termsItem.get('parameter') === term.name);

        return `<h4><b>${term.title}</b></h4>
          ${
            termsInfo?.get('parameter') === 'terms_privacy_text'
              ? `
            <div style="margin-top: 2rem; margin-bottom: 2rem;">
              <p>
                Fonte:
                <a href="/privacidade" target="_blank">
                  www.professor.letrus.com.br/privacidade/
                </a>
              </p>
              <p>
                Última atualização:
                ${moment(termsInfo.get('modified')).format('DD/MM/YYYY')}
            </p>
            </div>
          `
              : ``
          }
          <div>${termsInfo && termsInfo.get('value')}</div>`;
      })
      .join(`<div style="margin-top: 5rem"></div>`);

  return <div dangerouslySetInnerHTML={{__html: markdown}} />;
};

export default TermsSection;
