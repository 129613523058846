import MediumEditor from 'medium-draft';
import 'medium-draft/dist/medium-draft.css';
import React from 'react';
import {WrappedFieldProps} from 'redux-form';
import styles from './SimpleMediumEditor.module.scss';

export interface Props {
  disabled?: boolean;
  sideButtons?: Array<any>;
  blockButtons?: Array<any>;
  inlineButtons?: Array<any>;
  editorState: any;
  onChange?: Function;
  spellCheck: boolean;
  placeholder: string;
}

const defaultInlineButtons = [
  {
    label: 'B',
    style: 'BOLD',
    icon: 'bold',
    description: 'Negrito',
  },
  {
    label: 'I',
    style: 'ITALIC',
    icon: 'italic',
    description: 'Itálico',
  },
  {
    label: '#',
    style: 'hyperlink',
    icon: 'link',
    description: 'Adicione um link',
  },
];

const SimpleMediumEditor: React.FC<Props & Partial<WrappedFieldProps>> = ({
  disabled = false,
  editorState,
  placeholder,
  spellCheck = false,
  blockButtons = [],
  inlineButtons = defaultInlineButtons,
  onChange,
  sideButtons = [],
  input,
}) => {
  return (
    <div className={styles.container}>
      {!disabled && (
        <MediumEditor
          sideButtons={sideButtons}
          blockButtons={blockButtons}
          inlineButtons={inlineButtons}
          editorState={editorState}
          onChange={input?.onChange ?? onChange}
          spellCheck={spellCheck}
          placeholder={placeholder}
        />
      )}
    </div>
  );
};

export default SimpleMediumEditor;
