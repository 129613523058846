import React from 'react';
import styles from './Spinner.module.scss';

export interface Props {
  color?: string;
  message?: string;
  size?: number;
}

const Spinner: React.FC<Props> = ({color = '', message, size = 30}) => {
  return (
    <div className={styles.container}>
      <div
        className={styles.icon}
        style={{
          borderTopColor: color,
          borderLeftColor: color,
          height: size,
          width: size,
          margin: '0 auto',
        }}
      />
      {message && <small>{message}</small>}
    </div>
  );
};

export default Spinner;
