import React from 'react';
import NewLogo from '../NewLogo';
import styles from './Loading.module.scss';

interface LoadingProps {
  show: boolean;
}

const Loading: React.FC<LoadingProps> = ({show}) => {
  return show ? (
    <div data-testid="loading" className={styles.loading}>
      <NewLogo isLoading={show} />
    </div>
  ) : null;
};

export default Loading;
