import {isValidEmail} from 'utils/regex';
import {Errors, Validate} from './forms/validations/bankInformation';

export const validateEmail = (values: Validate) => {
  const errors: Errors = {};
  if (!values.get('email')) {
    errors.email = 'Campo obrigatório';
  } else if (!isValidEmail(values.get('email'))) {
    errors.email = 'Endereço email inválido';
  }
  return errors;
};
