import {fromJS} from 'immutable';
import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {createCompositionReviewService} from 'store/services/compositions';
import {action} from 'typesafe-actions';

//Action types
export enum CompositionsTypes {
  CREATE_COMPOSITION_REVIEW_REQUEST = '@compositions/CREATE_COMPOSITION_REVIEW_REQUEST',
  CREATE_COMPOSITION_REVIEW_SUCCESS = '@compositions/CREATE_COMPOSITION_REVIEW_SUCCESS',
  CREATE_COMPOSITION_REVIEW_FAILURE = '@compositions/CREATE_COMPOSITION_REVIEW_FAILURE',

  RESET_COMPOSITION_REVIEW_STATE_REQUEST = '@compositions/RESET_COMPOSITION_REVIEW_STATE_REQUEST',
  RESET_COMPOSITION_REVIEW_STATE_SUCCESS = '@compositions/RESET_COMPOSITION_REVIEW_STATE_SUCCESS',
  RESET_COMPOSITION_REVIEW_STATE_FAILURE = '@compositions/RESET_COMPOSITION_REVIEW_STATE_FAILURE',
}

//Data types
export interface Review {
  isCreated: boolean;
}

//State type
export interface CompositionsState
  extends ImmutableMap<{
    data: ImmutableMap<Review>;
    loading: boolean;
    error: boolean;
  }> {}

//Compositions Actions
export const createCompositionReviewRequest = (id: number) =>
  action(CompositionsTypes.CREATE_COMPOSITION_REVIEW_REQUEST, id);

export const createCompositionReviewSuccess = (data: any) =>
  action(CompositionsTypes.CREATE_COMPOSITION_REVIEW_SUCCESS, data);

export const createCompositionReviewFailure = () =>
  action(CompositionsTypes.CREATE_COMPOSITION_REVIEW_FAILURE);

//reset composition review
export const resetCompositionReviewStateRequest = () =>
  action(CompositionsTypes.RESET_COMPOSITION_REVIEW_STATE_REQUEST);

export const resetCompositionReviewStateSuccess = () =>
  action(CompositionsTypes.RESET_COMPOSITION_REVIEW_STATE_SUCCESS);

export const resetCompositionReviewStateFailure = () =>
  action(CompositionsTypes.RESET_COMPOSITION_REVIEW_STATE_FAILURE);

//Sagas
export function* createCompositionReview(action: AnyAction) {
  try {
    const response = yield call(createCompositionReviewService, action.payload);
    yield put(createCompositionReviewSuccess(response));
  } catch (error) {
    yield put(createCompositionReviewFailure());
  }
}

export function* resetCompositionReviewState() {
  try {
    yield put(resetCompositionReviewStateSuccess());
  } catch (error) {
    yield put(resetCompositionReviewStateFailure());
  }
}

//Initial state
export const INITIAL_STATE: CompositionsState = fromJS({
  data: fromJS({
    review: {},
    isCreated: false,
  }),
  loading: false,
  error: false,
});

//Selectors
const compositionsSelector = (state: ApplicationState) =>
  state.get('compositions');

export const hasCreatedCompositionReview = createSelector(
  compositionsSelector,
  (compositions) => compositions.getIn(['data', 'isCreated']),
);

export const isCreatingCompositionReview = createSelector(
  compositionsSelector,
  (compositions) => compositions.get('loading'),
);

//Reducer
const reducer: Reducer<CompositionsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CompositionsTypes.CREATE_COMPOSITION_REVIEW_REQUEST: {
      return state.withMutations((prevState) => prevState.set('loading', true));
    }

    case CompositionsTypes.CREATE_COMPOSITION_REVIEW_SUCCESS: {
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .setIn(['data', 'isCreated'], true),
      );
    }

    case CompositionsTypes.CREATE_COMPOSITION_REVIEW_FAILURE: {
      return state.withMutations((prevState) =>
        prevState.set('error', true).set('loading', false),
      );
    }

    case CompositionsTypes.RESET_COMPOSITION_REVIEW_STATE_REQUEST: {
      return state.withMutations((prevState) =>
        prevState.set('error', false).set('loading', true),
      );
    }

    case CompositionsTypes.RESET_COMPOSITION_REVIEW_STATE_SUCCESS: {
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set(
          'data',
          fromJS({
            review: {},
            isCreated: false,
          }),
        ),
      );
    }

    case CompositionsTypes.RESET_COMPOSITION_REVIEW_STATE_FAILURE: {
      return state.withMutations((prevState) =>
        prevState.set('error', true).set('loading', false),
      );
    }

    default: {
      return state;
    }
  }
};

export default reducer;
