import {connectRouter, RouterState} from 'connected-react-router/immutable';
import {History} from 'history';
import {reducer as formReducer} from 'redux-form/immutable';
import {combineReducers} from 'redux-immutable';
import abandonReviewReasonsReducer, {
  AbandonReviewReasonsState,
} from './reducers/abandonReviewReasons';
import authenticationReducer, {
  AuthenticationState,
} from './reducers/authentication';
import compositionGradesReducer, {
  CompositionGradesState,
} from './reducers/compositionGrades';
import compositionReviewsReducer, {
  CompositionReviewsState,
} from './reducers/compositionReviews';
import compositionsReducer, {CompositionsState} from './reducers/compositions';
import featureFlagsReducer, {FeatureFlagsState} from './reducers/featureFlags';
import generalAnnotationsReducer, {
  GeneralAnnotationsState,
} from './reducers/generalAnnotations';
import globalConfigurationReducer, {
  GlobalConfigurationState,
} from './reducers/globalConfiguration';
import iuguGetOrCreateSubAccountsReducer, {
  IuguGetOrCreateSubAccountsState,
} from './reducers/iuguGetOrCreateSubAccount';
import iuguReviewerFinancialAccountsReducer, {
  IuguReviewerFinancialAccountsState,
} from './reducers/iuguReviewerFinancialAccounts';
import iuguReviewTransfersReducer, {
  IuguReviewerTranfersState,
} from './reducers/iuguReviewerTransfers';
import iuguSubAccountsReducer, {
  IuguSubAccountsState,
} from './reducers/iuguSubAccounts';
import presignedUrlReducer, {PresignedUrlState} from './reducers/presignedUrl';
import reviewAnnotationsReducer, {
  ReviewAnnotationsState,
} from './reducers/reviewAnnotations';
import reviewerCompositionReducer, {
  ReviewerCompositionsState,
} from './reducers/reviewerCompositions';
import S3Reducer, {S3State} from './reducers/s3';
import surveysReducer, {SurveysState} from './reducers/surveys';
import userProfileReducer, {UserProfileState} from './reducers/userProfile';
import zeroGradeCommentReducer, {
  ZeroGradeCommentState,
} from './reducers/zeroGradeComment';

export type ApplicationState = ImmutableMap<{
  S3: S3State;
  abandonReviewReasons: AbandonReviewReasonsState;
  authentication: AuthenticationState;
  compositionGrades: CompositionGradesState;
  compositionReviews: CompositionReviewsState;
  compositions: CompositionsState;
  featureFlags: FeatureFlagsState;
  formReducer: typeof formReducer;
  generalAnnotations: GeneralAnnotationsState;
  iuguGetOrCreateSubAccounts: IuguGetOrCreateSubAccountsState;
  iuguReviewerFinancialAccounts: IuguReviewerFinancialAccountsState;
  iuguReviewerTransfers: IuguReviewerTranfersState;
  iuguSubAccounts: IuguSubAccountsState;
  globalConfiguration: GlobalConfigurationState;
  presignedUrl: PresignedUrlState;
  presignedUrlState: PresignedUrlState;
  reviewAnnotations: ReviewAnnotationsState;
  reviewerCompositions: ReviewerCompositionsState;
  router: RouterState;
  surveys: SurveysState;
  userProfile: UserProfileState;
  zeroGradeComment: ZeroGradeCommentState;
}>;

const rootReducer = (history: History) =>
  combineReducers({
    S3: S3Reducer,
    abandonReviewReasons: abandonReviewReasonsReducer,
    authentication: authenticationReducer,
    compositionGrades: compositionGradesReducer,
    compositionReviews: compositionReviewsReducer,
    compositions: compositionsReducer,
    featureFlags: featureFlagsReducer,
    form: formReducer,
    generalAnnotations: generalAnnotationsReducer,
    globalConfiguration: globalConfigurationReducer,
    iuguGetOrCreateSubAccounts: iuguGetOrCreateSubAccountsReducer,
    iuguReviewerFinancialAccounts: iuguReviewerFinancialAccountsReducer,
    iuguReviewerTransfers: iuguReviewTransfersReducer,
    iuguSubAccounts: iuguSubAccountsReducer,
    presignedUrl: presignedUrlReducer,
    reviewAnnotations: reviewAnnotationsReducer,
    reviewerCompositions: reviewerCompositionReducer,
    router: connectRouter(history),
    surveys: surveysReducer,
    userProfile: userProfileReducer,
    zeroGradeComment: zeroGradeCommentReducer,
  });

export default rootReducer;
