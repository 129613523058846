import {NewButton} from 'letrus-ui';
import React from 'react';
import {Link} from 'react-router-dom';
import styles from './ModalFinishReview.module.scss';

const ModalFinishReview: React.FC = () => (
  <div className={styles.container}>
    <h3 className={styles.title}>Correção enviada!</h3>
    <legend className={styles.description}>
      Você ainda pode rever essa correção nos próximos 60 minutos.
    </legend>
    <div className={styles.link}>
      <Link to="/">
        <NewButton
          userRole="student"
          kind="primary"
          text="Ir para Página Inicial"
        />
      </Link>
    </div>
  </div>
);

export default ModalFinishReview;
