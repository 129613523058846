import {fromJS} from 'immutable';
import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {updateProfileService} from 'store/services/userProfile';
import {action} from 'typesafe-actions';

//Action types
export enum UserProfileTypes {
  UPDATE_PROFILE_REQUEST = '@reviewAnnotations/UPDATE_PROFILE_REQUEST',
  UPDATE_PROFILE_SUCCESS = '@reviewAnnotations/UPDATE_PROFILE_SUCCESS',
  UPDATE_PROFILE_FAILURE = '@reviewAnnotations/UPDATE_PROFILE_FAILURE',
}

//Data types

//State type
export interface UserProfileState
  extends ImmutableMap<{
    data: any;
    loading: boolean;
    error: boolean;
    dataCount: number;
    isUpdatingUserProfile: boolean;
  }> {}

//Create actions
export const updateProfileRequest = (data: any) =>
  action(UserProfileTypes.UPDATE_PROFILE_REQUEST, data);

export const updateProfileSuccess = (data: any) =>
  action(UserProfileTypes.UPDATE_PROFILE_SUCCESS, {data});

export const updateProfileFailure = () =>
  action(UserProfileTypes.UPDATE_PROFILE_FAILURE);

//Sagas
export function* updateProfile(action: AnyAction) {
  try {
    const response = yield call(updateProfileService, action.payload);
    yield put(updateProfileSuccess(response.data));
  } catch (err) {
    yield put(updateProfileFailure());
  }
}

//Selectors
const userProfileSelector = (state: ApplicationState) =>
  state.get('userProfile');

export const isUpdatingUserProfile = createSelector(
  userProfileSelector,
  (userProfile) => userProfile.get('isUpdatingUserProfile'),
);

//Initial state
export const INITIAL_STATE: UserProfileState = fromJS({
  data: fromJS([]),
  error: false,
  loading: false,
  dataCount: 0,
  isUpdatingUserProfile: false,
});

//Reducer
export const reducer: Reducer<UserProfileState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case UserProfileTypes.UPDATE_PROFILE_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('isUpdatingUserProfile', true),
      );

    case UserProfileTypes.UPDATE_PROFILE_SUCCESS:
      return state.withMutations((prevState) =>
        prevState.set('isUpdatingUserProfile', false).set('error', false),
      );

    case UserProfileTypes.UPDATE_PROFILE_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('isUpdatingUserProfile', false).set('error', true),
      );

    default:
      return state;
  }
};

export default reducer;
