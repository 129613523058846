import {LetrusApi, Utils} from '@letrustech/letrus-api-interfaces';
import {fromJS, List, Map} from 'immutable';
import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {
  abandonReviewService,
  createOrUpdateCompetenceGradeService,
  createOrUpdateSubCompetenceGradeService,
  fetchCompositionReviewByCompositionIdService,
  fetchFinishedGradesService,
  fetchInProgressGradeService,
  updateCompositionGradeService,
  zeroReviewService,
} from 'store/services/compositionReviewsService';
import {action} from 'typesafe-actions';

//Action types
export enum CompositionReviewsTypes {
  CREATE_OR_UPDATE_SUB_COMPETENCE_GRADE_REQUEST = '@compositionReviews/CREATE_OR_UPDATE_SUB_COMPETENCE_GRADE_REQUEST',
  CREATE_OR_UPDATE_SUB_COMPETENCE_GRADE_SUCCESS = '@compositionReviews/CREATE_OR_UPDATE_SUB_COMPETENCE_GRADE_SUCCESS',
  CREATE_OR_UPDATE_SUB_COMPETENCE_GRADE_FAILURE = '@compositionReviews/CREATE_OR_UPDATE_SUB_COMPETENCE_GRADE_FAILURE',

  CREATE_OR_UPDATE_COMPETENCE_GRADE_REQUEST = '@compositionReviews/CREATE_OR_UPDATE_COMPETENCE_GRADE_REQUEST',
  CREATE_OR_UPDATE_COMPETENCE_GRADE_SUCCESS = '@compositionReviews/CREATE_OR_UPDATE_COMPETENCE_GRADE_SUCCESS',
  CREATE_OR_UPDATE_COMPETENCE_GRADE_FAILURE = '@compositionReviews/CREATE_OR_UPDATE_COMPETENCE_GRADE_FAILURE',

  ABANDON_REQUEST = '@compositionReviews/ABANDON_REQUEST',
  ABANDON_SUCCESS = '@compositionReviews/ABANDON_SUCCESS',
  ABANDON_FAILURE = '@compositionReviews/ABANDON_FAILURE',

  UPDATE_COMPOSITION_GRADE_REQUEST = '@compositionReviews/UPDATE_COMPOSITION_GRADE_REQUEST',
  UPDATE_COMPOSITION_GRADE_SUCCESS = '@compositionReviews/UPDATE_COMPOSITION_GRADE_SUCCESS',
  UPDATE_COMPOSITION_GRADE_FAILURE = '@compositionReviews/UPDATE_COMPOSITION_GRADE_FAILURE',

  FETCH_BY_COMPOSITION_ID_REQUEST = '@compositionReviews/FETCH_BY_COMPOSITION_ID_REQUEST',
  FETCH_BY_COMPOSITION_ID_SUCCESS = '@compositionReviews/FETCH_BY_COMPOSITION_ID_SUCCESS',
  FETCH_BY_COMPOSITION_ID_FAILURE = '@compositionReviews/FETCH_BY_COMPOSITION_ID_FAILURE',

  FETCH_FINISHED_GRADES_REQUEST = '@compositionReviews/FETCH_FINISHED_GRADES_REQUEST',
  FETCH_FINISHED_GRADES_SUCCESS = '@compositionReviews/FETCH_FINISHED_GRADES_SUCCESS',
  FETCH_FINISHED_GRADES_FAILURE = '@compositionReviews/FETCH_FINISHED_GRADES_FAILURE',

  FETCH_IN_PROGRESS_GRADES_REQUEST = '@compositionReviews/FETCH_IN_PROGRESS_GRADES_REQUEST',
  FETCH_IN_PROGRESS_GRADES_SUCCESS = '@compositionReviews/FETCH_IN_PROGRESS_GRADES_SUCCESS',
  FETCH_IN_PROGRESS_GRADES_FAILURE = '@compositionReviews/FETCH_IN_PROGRESS_GRADES_FAILURE',

  ZERO_REQUEST = '@compositionReviews/ZERO_REQUEST',
  ZERO_SUCCESS = '@compositionReviews/ZERO_SUCCESS',
  ZERO_FAILURE = '@compositionReviews/ZERO_FAILURE',

  FINISH_REVIEW_REQUEST = '@compositionReviews/FINISH_REVIEW_REQUEST',
  FINISH_REVIEW_SUCCESS = '@compositionReviews/FINISH_REVIEW_SUCCESS',
  FINISH_REVIEW_FAILURE = '@compositionReviews/FINISH_REVIEW_FAILURE',
}

//Data types
export interface CompositionReviews {
  inProgressGrades: any;
  finishedGrades: any;
  compositionGrade: ImmutableMap<LetrusApi.CompositionReview>;
  abandonReasons: List<any>;
  reviewAbandoned: boolean;
  reviewFinished: boolean;
}

//State type
export interface CompositionReviewsState
  extends ImmutableMap<{
    data: ImmutableMap<CompositionReviews>;
    loading: boolean;
    error: boolean;
  }> {}

//Fetch actions
export const fetchInProgressGradesRequest = (params?: Utils.GetParams) =>
  action(CompositionReviewsTypes.FETCH_IN_PROGRESS_GRADES_REQUEST, params);

export const fetchInProgressGradesSuccess = (data: any) =>
  action(CompositionReviewsTypes.FETCH_IN_PROGRESS_GRADES_SUCCESS, {data});

export const fetchInProgressGradesFailure = () =>
  action(CompositionReviewsTypes.FETCH_IN_PROGRESS_GRADES_FAILURE);

export const fetchFinishedGradesRequest = (params?: Utils.GetParams) =>
  action(CompositionReviewsTypes.FETCH_FINISHED_GRADES_REQUEST, {params});

export const fetchFinishedGradesSuccess = (data: any) =>
  action(CompositionReviewsTypes.FETCH_FINISHED_GRADES_SUCCESS, {data});

export const fetchFinishedGradesFailure = () =>
  action(CompositionReviewsTypes.FETCH_FINISHED_GRADES_FAILURE);

export const fetchCompositionReviewByCompositionIdRequest = (data?: any) =>
  action(CompositionReviewsTypes.FETCH_BY_COMPOSITION_ID_REQUEST, data);

export const fetchCompositionReviewByCompositionIdSuccess = (data: any) =>
  action(CompositionReviewsTypes.FETCH_BY_COMPOSITION_ID_SUCCESS, {data});

export const fetchCompositionReviewByCompositionIdFailure = () =>
  action(CompositionReviewsTypes.FETCH_BY_COMPOSITION_ID_FAILURE);

//Patch actions
export const updateCompositionGradeRequest = (compositionGrade: any) =>
  action(
    CompositionReviewsTypes.UPDATE_COMPOSITION_GRADE_REQUEST,
    compositionGrade,
  );

export const updateCompositionGradeSuccess = (data: any) =>
  action(CompositionReviewsTypes.UPDATE_COMPOSITION_GRADE_SUCCESS, {data});

export const updateCompositionGradeFailure = () =>
  action(CompositionReviewsTypes.UPDATE_COMPOSITION_GRADE_FAILURE);

//Put actions
export const zeroReviewRequest = (data: {
  zeroGradeReasonId: number;
  reviewId: number;
}) => action(CompositionReviewsTypes.ZERO_REQUEST, data);

export const zeroReviewSuccess = (data: any) =>
  action(CompositionReviewsTypes.ZERO_SUCCESS, data);

export const zeroReviewFailure = () =>
  action(CompositionReviewsTypes.ZERO_FAILURE);

//Create actions
export const createOrUpdateCompetenceGradeRequest = (data: {
  review_id: number;
  comments: string;
  competence_id: number;
  grade_item_id?: number;
}) =>
  action(
    CompositionReviewsTypes.CREATE_OR_UPDATE_COMPETENCE_GRADE_REQUEST,
    data,
  );

export const createOrUpdateCompetenceGradeSuccess = (data: any) =>
  action(
    CompositionReviewsTypes.CREATE_OR_UPDATE_COMPETENCE_GRADE_SUCCESS,
    data,
  );

export const createOrUpdateCompetenceGradeFailure = () =>
  action(CompositionReviewsTypes.CREATE_OR_UPDATE_COMPETENCE_GRADE_FAILURE);

export const createOrUpdateSubCompetenceGradeRequest = (
  params?: Utils.GetParams,
) =>
  action(
    CompositionReviewsTypes.CREATE_OR_UPDATE_SUB_COMPETENCE_GRADE_REQUEST,
    params,
  );

export const createOrUpdateSubCompetenceGradeSuccess = (data: any) =>
  action(
    CompositionReviewsTypes.CREATE_OR_UPDATE_SUB_COMPETENCE_GRADE_SUCCESS,
    {
      data,
    },
  );

export const createOrUpdateSubCompetenceGradeFailure = () =>
  action(CompositionReviewsTypes.CREATE_OR_UPDATE_SUB_COMPETENCE_GRADE_FAILURE);

//Delete actions
interface AbandonRequestParams {
  compositionReviewId: number;
  abandonReasonId: number;
}

export const abandonRequest = (params: AbandonRequestParams) =>
  action(CompositionReviewsTypes.ABANDON_REQUEST, params);

export const abandonSuccess = (data: any) =>
  action(CompositionReviewsTypes.ABANDON_SUCCESS, {data});

export const abandonFailure = () =>
  action(CompositionReviewsTypes.ABANDON_FAILURE);

//Finish review actions

export const finishReviewRequest = (data: any) =>
  action(CompositionReviewsTypes.FINISH_REVIEW_REQUEST, data);

export const finishReviewSuccess = () =>
  action(CompositionReviewsTypes.FINISH_REVIEW_SUCCESS);

export const finishReviewFailure = () =>
  action(CompositionReviewsTypes.FINISH_REVIEW_FAILURE);

//Sagas
export function* zeroReview(action: AnyAction) {
  try {
    const response = yield call(zeroReviewService, action.payload);
    yield put(zeroReviewSuccess(response.data));
  } catch (err) {
    yield put(zeroReviewFailure());
  }
}

export function* fetchInProgressGrades(action: AnyAction) {
  try {
    const response = yield call(fetchInProgressGradeService, action.payload);
    yield put(fetchInProgressGradesSuccess(response.data));
  } catch (err) {
    yield put(fetchInProgressGradesFailure());
  }
}

export function* fetchFinishedGrades(action: AnyAction) {
  try {
    const response = yield call(
      fetchFinishedGradesService,
      action.payload.params,
    );
    yield put(fetchFinishedGradesSuccess(response.data));
  } catch (err) {
    yield put(fetchFinishedGradesFailure());
  }
}

export function* fetchCompositionReviewByCompositionId(action: AnyAction) {
  try {
    const response = yield call(
      fetchCompositionReviewByCompositionIdService,
      action.payload,
    );
    yield put(fetchCompositionReviewByCompositionIdSuccess(response.data));
  } catch (err) {
    yield put(fetchCompositionReviewByCompositionIdFailure());
  }
}

export function* updateCompositionGrade(action: AnyAction) {
  try {
    const response = yield call(updateCompositionGradeService, action.payload);
    yield put(updateCompositionGradeSuccess(response.data));
  } catch (err) {
    yield put(updateCompositionGradeFailure());
  }
}

export function* abandon(action: AnyAction) {
  try {
    const response = yield call(abandonReviewService, action.payload);
    yield put(abandonSuccess(response.data));
  } catch (err) {
    yield put(abandonFailure());
  }
}

export function* createOrUpdateCompetenceGrade(action: AnyAction) {
  try {
    const response = yield call(
      createOrUpdateCompetenceGradeService,
      action.payload,
    );
    yield put(createOrUpdateCompetenceGradeSuccess(response.data));
  } catch (err) {
    yield put(createOrUpdateCompetenceGradeFailure());
  }
}

export function* createOrUpdateSubCompetenceGrade(action: AnyAction) {
  try {
    const response = yield call(
      createOrUpdateSubCompetenceGradeService,
      action.payload,
    );
    yield put(createOrUpdateSubCompetenceGradeSuccess(response.data));
  } catch (err) {
    yield put(createOrUpdateSubCompetenceGradeFailure());
  }
}

export function* finishReview(action: AnyAction) {
  try {
    yield call(updateCompositionGradeService, action.payload);
    yield put(finishReviewSuccess());
  } catch (error) {
    yield put(finishReviewFailure());
  }
}

//Selectors
const compositionReviewsSelector = (state: ApplicationState) =>
  state.get('compositionReviews');

export const isLoadingInProgressGradesSelector = createSelector(
  compositionReviewsSelector,
  (compositionReviews) => compositionReviews.get('loading'),
);

export const getInProgressGrades = createSelector(
  compositionReviewsSelector,
  (compositionReviews) =>
    compositionReviews.getIn(['data', 'inProgressGrades']),
);

export const hasInProgressGrades = createSelector(
  compositionReviewsSelector,
  (compositionReviews) => {
    return !!compositionReviews.getIn(['data', 'inProgressGrades'])?.count();
  },
);

export const getFinishedGrades = createSelector(
  compositionReviewsSelector,
  (compositionReviews) => {
    return compositionReviews.getIn(['data', 'finishedGrades']);
  },
);

export const getFinishedGradesCount = createSelector(
  compositionReviewsSelector,
  (compositionReviews) => {
    return compositionReviews.getIn(['data', 'finishedGradesCount']);
  },
);

export const isLoadingFinishedGrades = createSelector(
  compositionReviewsSelector,
  (compositionReviews) => compositionReviews.get('loading'),
);

export const getCompositionGrade = createSelector(
  compositionReviewsSelector,
  (compositionReviews) =>
    compositionReviews.getIn(['data', 'compositionGrade']),
);

export const getCompositionGradeCompetences = createSelector(
  compositionReviewsSelector,
  (compositionReviews) =>
    compositionReviews.getIn(
      ['data', 'compositionGrade', 'composition', 'genre', 'competences'],
      List(),
    ),
);

export const getCompositionGradeGeneralAnnotations = createSelector(
  compositionReviewsSelector,
  (compositionReview) =>
    compositionReview.getIn(
      ['data', 'compositionGrade', 'general_annotations'],
      List(),
    ),
);

export const getCompositionGradePlagiarismAnnotations = createSelector(
  compositionReviewsSelector,
  (compositionReview) =>
    compositionReview
      .getIn(['data', 'compositionGrade', 'general_annotations'], fromJS([]))
      .filter((annotation) => {
        return annotation.get('annotation_type') === 'plagio';
      }),
);

export const getCompositionGradeAutomaticPlagiarismAnnotations = createSelector(
  compositionReviewsSelector,
  (compositionReview) =>
    compositionReview
      .getIn(['data', 'compositionGrade', 'general_annotations'], fromJS([]))
      .filter((annotation) => {
        return (
          annotation.get('annotation_type') === 'plagio' &&
          !annotation.get('reported_by')
        );
      }),
);

export const getCompositionGradeAnnotations = createSelector(
  compositionReviewsSelector,
  (compositionReview) =>
    compositionReview.getIn(
      ['data', 'compositionGrade', 'annotations'],
      List(),
    ),
);

export const getCompositionFromCompositionGrade = createSelector(
  compositionReviewsSelector,
  (compositionReviews) =>
    compositionReviews.getIn(
      ['data', 'compositionGrade', 'composition'],
      Map(),
    ),
);

export const isFundII = createSelector(
  compositionReviewsSelector,
  (compositionReviews) =>
    compositionReviews.getIn([
      'data',
      'compositionGrade',
      'composition',
      'genre',
      'review_grid_name',
    ]) === 'Grade Única',
);

export const isMultigenre = createSelector(
  compositionReviewsSelector,
  (compositionReviews) =>
    compositionReviews.getIn([
      'data',
      'compositionGrade',
      'composition',
      'genre',
      'review_grid_name',
    ]) === 'Multigênero',
);

export const getReviewGridName = createSelector(
  compositionReviewsSelector,
  (compositionReviews) =>
    compositionReviews.getIn([
      'data',
      'compositionGrade',
      'composition',
      'genre',
      'review_grid_name',
    ]),
);

export const getGenre = createSelector(
  compositionReviewsSelector,
  (compositionReviews) =>
    compositionReviews.getIn(
      ['data', 'compositionGrade', 'composition', 'genre'],
      fromJS([]),
    ),
);

export const reviewAbandoned = createSelector(
  compositionReviewsSelector,
  (compositionReviews) => compositionReviews.getIn(['data', 'reviewAbandoned']),
);

export const getReviewCompositionStudent = createSelector(
  compositionReviewsSelector,
  (compositionReviews) =>
    compositionReviews.getIn(
      ['data', 'compositionGrade', 'composition', 'user'],
      Map(),
    ),
);

export const reviewFinished = createSelector(
  compositionReviewsSelector,
  (compositionReview) => compositionReview.getIn(['data', 'reviewFinished']),
);

export const isLoadingCompositionReview = createSelector(
  compositionReviewsSelector,
  (compositionReview) => compositionReview.get('loading'),
);

export const isCustomCorrectionGrid = createSelector(
  compositionReviewsSelector,
  (compositionReviews) => {
    const customGridIds = [85, 86, 87, 88, 89, 90];

    const isCustomCorrectionGrid = customGridIds.includes(
      compositionReviews.getIn([
        'data',
        'compositionGrade',
        'composition',
        'genre',
        'id',
      ]),
    );

    return isCustomCorrectionGrid;
  },
);

//Initial state
export const INITIAL_STATE: CompositionReviewsState = fromJS({
  data: fromJS({
    inProgressGrades: [],
    finishedGrades: [],
    compositionGrade: {},
    abandonReasons: [],
    reviewAbandoned: false,
    reviewFinished: false,
  }),
  error: false,
  loading: false,
  dataCount: 0,
});

//Reducer
export const reducer: Reducer<CompositionReviewsState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case CompositionReviewsTypes.ZERO_REQUEST:
      return state.withMutations((prevState) => prevState.set('loading', true));

    case CompositionReviewsTypes.ZERO_SUCCESS: {
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .setIn(['data', 'compositionGrade'], fromJS(action.payload)),
      );
    }

    case CompositionReviewsTypes.ZERO_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );

    case CompositionReviewsTypes.FETCH_IN_PROGRESS_GRADES_REQUEST:
      return state.withMutations((prevState) => prevState.set('loading', true));

    case CompositionReviewsTypes.FETCH_IN_PROGRESS_GRADES_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .setIn(
            ['data', 'inProgressGrades'],
            fromJS(action.payload.data.results[0] || []),
          ),
      );

    case CompositionReviewsTypes.FETCH_IN_PROGRESS_GRADES_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .set('data', fromJS({})),
      );

    case CompositionReviewsTypes.FETCH_FINISHED_GRADES_REQUEST:
      return state.withMutations((prevState) => prevState.set('loading', true));

    case CompositionReviewsTypes.FETCH_FINISHED_GRADES_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .setIn(
            ['data', 'finishedGradesCount'],
            fromJS(action.payload.data.count || []),
          )
          .setIn(
            ['data', 'finishedGrades'],
            fromJS(action.payload.data.results || []),
          ),
      );

    case CompositionReviewsTypes.FETCH_FINISHED_GRADES_FAILURE:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .set('data', fromJS([])),
      );

    case CompositionReviewsTypes.FETCH_BY_COMPOSITION_ID_REQUEST:
      return state.withMutations((prevState) => prevState.set('loading', true));

    case CompositionReviewsTypes.FETCH_BY_COMPOSITION_ID_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .setIn(['data', 'reviewFinished'], false)
          .setIn(['data', 'reviewAbandoned'], false)
          .setIn(
            ['data', 'compositionGrade'],
            fromJS(action.payload.data.results[0] || {}),
          ),
      );

    case CompositionReviewsTypes.FETCH_BY_COMPOSITION_ID_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', false),
      );

    case CompositionReviewsTypes.UPDATE_COMPOSITION_GRADE_REQUEST:
      return state.withMutations((prevState) => prevState.set('loading', true));

    case CompositionReviewsTypes.UPDATE_COMPOSITION_GRADE_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .setIn(
            ['data', 'compositionGrade'],
            fromJS(action.payload.data || {}),
          ),
      );

    case CompositionReviewsTypes.UPDATE_COMPOSITION_GRADE_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );

    case CompositionReviewsTypes.ABANDON_REQUEST:
      return state.withMutations((prevState) => prevState.set('loading', true));

    case CompositionReviewsTypes.ABANDON_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .setIn(['data', 'reviewAbandoned'], true),
      );

    case CompositionReviewsTypes.ABANDON_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );

    case CompositionReviewsTypes.CREATE_OR_UPDATE_COMPETENCE_GRADE_REQUEST:
      return state.withMutations((prevState) => prevState.set('loading', true));

    case CompositionReviewsTypes.CREATE_OR_UPDATE_COMPETENCE_GRADE_SUCCESS: {
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .setIn(['data', 'compositionGrade'], fromJS(action.payload)),
      );
    }

    case CompositionReviewsTypes.CREATE_OR_UPDATE_COMPETENCE_GRADE_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );

    case CompositionReviewsTypes.CREATE_OR_UPDATE_SUB_COMPETENCE_GRADE_REQUEST:
      return state.withMutations((prevState) => prevState.set('loading', true));

    case CompositionReviewsTypes.CREATE_OR_UPDATE_SUB_COMPETENCE_GRADE_SUCCESS:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', false),
      );

    case CompositionReviewsTypes.CREATE_OR_UPDATE_SUB_COMPETENCE_GRADE_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );

    case CompositionReviewsTypes.FINISH_REVIEW_REQUEST: {
      return state.withMutations((prevState) =>
        prevState.set('loading', true).set('error', false),
      );
    }

    case CompositionReviewsTypes.FINISH_REVIEW_SUCCESS: {
      return state.withMutations((prevState) =>
        prevState.set('loading', false).setIn(['data', 'reviewFinished'], true),
      );
    }

    case CompositionReviewsTypes.FINISH_REVIEW_FAILURE: {
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );
    }

    default:
      return state;
  }
};

export default reducer;
