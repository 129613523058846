import React from 'react';
import './NewButton.scss';

interface NewButtonProps {
  className?: string;
  isLoading?: boolean;
  disabled?: boolean;
  kind?:
    | 'primary'
    | 'secondary'
    | 'accent'
    | 'negative-primary'
    | 'negative-secondary';
  onClick?: () => void;
  size?: 'small' | 'large';
  type?: 'button' | 'submit' | 'reset';
  Component?: any;
}

const NewButton: React.FC<NewButtonProps> = ({
  className,
  isLoading,
  disabled,
  kind,
  onClick,
  size,
  type,
  Component,
  children,
}) => {
  return (
    <>
      {Component ? (
        <Component
          className={`new-button reviewer new-button--${size} ${
            isLoading || disabled
              ? 'new-button--disabled'
              : `new-button--${kind}`
          } ${className}`}
        />
      ) : (
        <button
          className={`new-button reviewer new-button--${size} ${
            isLoading || disabled
              ? 'new-button--disabled'
              : `new-button--${kind}`
          } ${className}`}
          type={type}
          onClick={onClick}
          disabled={isLoading || disabled}
        >
          {children}
        </button>
      )}
    </>
  );
};

export default NewButton;
