import {push} from 'connected-react-router';
import {DropdownMenu} from 'letrus-ui';
import React from 'react';
import {connect} from 'react-redux';
import {getCurrentUser, logoutUserRequest} from 'store/reducers/authentication';
import {ApplicationState} from 'store/rootReducer';
import styles from './HeaderRigthComponent.module.scss';

interface StateProps {
  user: Map<string, any>;
}

interface DispatchProps {
  logoutUserRequest: typeof logoutUserRequest;
  push: typeof push;
}

type Props = StateProps & DispatchProps;

const fileDomain =
  process.env.NODE_ENV === 'development'
    ? 'https://betamedia.letrus.com.br'
    : 'https://media.letrus.com.br';

const HeaderRigthComponent: React.FC<Props> = ({
  push,
  logoutUserRequest,
  user,
}) => {
  const userProfile = user.get('user_profile');
  const userImage = userProfile.get('image');

  return (
    <div className={styles.headerRightComponent}>
      <DropdownMenu
        title=""
        titleAvatar
        avatarUrl={userImage ? `${fileDomain}/${userImage}` : ''}
        items={[
          {
            icon: 'home',
            id: 1,
            onClick: () => push('/'),
            text: 'Página inicial',
          },
          {
            icon: 'user',
            id: 2,
            onClick: () => push('/perfil'),
            text: 'Minhas informações',
          },
          {
            icon: 'sign-out',
            id: 3,
            onClick: () => logoutUserRequest(),
            text: 'Sair',
          },
        ]}
      />
    </div>
  );
};

export default connect<StateProps, DispatchProps, {}, ApplicationState>(
  (state: ApplicationState) => ({
    user: getCurrentUser(state),
  }),
  {push, logoutUserRequest},
)(HeaderRigthComponent);
