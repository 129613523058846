import {Utils} from '@letrustech/letrus-api-interfaces';
import api from 'store/services/api';

export const reviewerAvailableCompositionsService = (params: Utils.GetParams) =>
  api('/api/v1/reviewer_compositions/', {
    params,
  });

export const reviewerVerifyProgressGradesLimitService = () =>
  api('/api/v1/reviewer_compositions/verify_progress_grades_limit/');
