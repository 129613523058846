/* eslint-disable react/jsx-props-no-spreading */
import AlertModal, {Props as AlertModalProps} from 'components/AlertModal';
import ReviewerAvailableCompositionsTable from 'containers/ReviewerAvailableCompositionsTable';
import ReviewerFinishGradesTable from 'containers/ReviewerFinishGradesTable';
import ReviewerInProgressGradesTable from 'containers/ReviewerInProgressGradesTable';
import dayjs from 'dayjs';
import LogoLetrus from 'images/newLogo.png';
import {Modal, Navbar, NPSInput, TabList, Title, TopBar} from 'letrus-ui';
import querystring from 'querystring';
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {
  getCurrentUser,
  getRoleTypesSelector,
} from 'store/reducers/authentication';
import {
  fetchInProgressGradesRequest,
  hasInProgressGrades,
} from 'store/reducers/compositionReviews';
import {reviewerAvailableCompositionsRequest} from 'store/reducers/reviewerCompositions';
import {sendUserOpinionForSurveysRequest} from 'store/reducers/surveys';
import {updateProfileRequest} from 'store/reducers/userProfile';
import {ApplicationState} from 'store/rootReducer';
import styles from './Dashboard.module.scss';
import HeaderRigthComponent from './HeaderRigthComponent';
import ReviewsForecast from './ReviewsForecast';

interface StateProps {
  user: ImmutableMap<any>;
  roles: ImmutableMap<any>;
  hasInProgressGrades: boolean;
}

interface DispatchProps {
  updateProfileRequest: typeof updateProfileRequest;
  reviewerAvailableCompositionsRequest: typeof reviewerAvailableCompositionsRequest;
  fetchInProgressGradesRequest: typeof fetchInProgressGradesRequest;
  sendUserOpinionForSurveysRequest: typeof sendUserOpinionForSurveysRequest;
}

type Props = StateProps & DispatchProps;

const Dashboard = ({
  user,
  roles,
  updateProfileRequest,
  hasInProgressGrades,
  sendUserOpinionForSurveysRequest,
  reviewerAvailableCompositionsRequest,
  fetchInProgressGradesRequest,
}: Props) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const [alertModal, setAlertModal] = useState<AlertModalProps>();
  const {
    location: {search},
  } = useHistory();

  const [isNPSModalOpen, setIsNPSModalOpen] = useState<boolean>(false);

  const [wasNpsSubmitted, setWasNpsSubmitted] = useState<boolean>(false);

  const [npsRating, setNpsRating] = useState<number>(0);

  useEffect(() => {
    fetchInProgressGradesRequest();

    reviewerAvailableCompositionsRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (search) {
      const {finishReview} = querystring.parse(search.slice(1));

      if (finishReview === 'true') {
        setAlertModal({
          message: 'Muito Obrigado, sua correção foi enviada!',
          type: 'success',
          onClose: () => setAlertModal(undefined),
        });

        openNPSModal();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  // TODO: renderNotifications

  const renderAvailableGrades = () => {
    return (
      <div>
        {hasInProgressGrades && (
          <div className={styles['table-inProgressGrades']}>
            <ReviewerInProgressGradesTable />
          </div>
        )}

        <ReviewerAvailableCompositionsTable />
      </div>
    );
  };
  const renderFinishedGrades = () => <ReviewerFinishGradesTable />;

  const tabData = [
    {
      name: 'Redações disponíveis',
      id: 0,
      children: renderAvailableGrades(),
    },
    {
      name: 'Correções finalizadas',
      id: 1,
      children: renderFinishedGrades(),
    },
    {
      name: 'Atividades futuras',
      id: 2,
      children: <ReviewsForecast />,
    },
  ];

  const handleTabChange = (id: number) => setActiveTab(id);

  const openNPSModal = () => {
    const answeredNPS = user.getIn(['user_profile', 'answered_nps']);

    const ignoredNPS = user.getIn(['user_profile', 'ignored_nps']);

    const currentDate = dayjs();

    if (!answeredNPS || currentDate.diff(answeredNPS, 'day') > 30) {
      if (!ignoredNPS || currentDate.diff(ignoredNPS, 'day') > 14) {
        setIsNPSModalOpen(true);
      }
    }
  };

  const onSubmitNPS = () => {
    const profile = user.get('user_profile');

    const data = {
      event_name: 'reviewer_nps',
      user: profile.get('user'),
      comment: String(npsRating),
    };

    sendUserOpinionForSurveysRequest(data);
    updateProfileRequest({
      id: profile.get('id'),
      answered_nps: dayjs(),
      ignored_nps: null,
    });

    setWasNpsSubmitted(true);

    setTimeout(() => {
      setIsNPSModalOpen(!isNPSModalOpen);
    }, 2000);
  };

  const handleClickIgnoreNPS = () => {
    const profile = user.get('user_profile');

    !wasNpsSubmitted &&
      updateProfileRequest({
        id: profile.get('id'),
        ignored_nps: dayjs(),
      });

    setIsNPSModalOpen(!isNPSModalOpen);
  };

  return (
    <div className={styles.dashboard}>
      <Navbar
        centerComponent={
          <img className={styles.logo} src={LogoLetrus} alt="Logo Letrus" />
        }
        rightComponent={<HeaderRigthComponent />}
      />
      <TopBar
        title={
          <Title icon="home" noWrap onClick={() => {}} text="Página inicial" />
        }
        tabList={
          <TabList
            data={tabData}
            handleTabChange={handleTabChange}
            tabActive={activeTab}
            userRole="reviewer"
          />
        }
        rightButton={dayjs().format('dddd, LL')}
      />
      <div className={styles.wrapper}>
        {tabData[activeTab].children || null}
      </div>
      {alertModal && <AlertModal {...alertModal} />}
      {roles.find((role) => role !== 'reviewer:interno') && (
        <Modal
          clickOutsideToClose={false}
          isOpen={isNPSModalOpen}
          onClose={() => handleClickIgnoreNPS()}
          title="Pesquisa de satisfação"
        >
          <div className={styles.modalNps}>
            <NPSInput
              buttonText={!wasNpsSubmitted ? 'Enviar' : ''}
              description="De 0 a 10, o quanto você indicaria a plataforma Letrus para outro corretor?"
              onChange={(value) => setNpsRating(value)}
              onSubmit={() => onSubmitNPS()}
              userRole="reviewer"
            />
            {wasNpsSubmitted && (
              <span className={styles.successMessage}>
                Obrigado pela sua avaliação. ;)
              </span>
            )}
          </div>
        </Modal>
      )}
    </div>
  );
};

export default connect<StateProps, DispatchProps, {}, ApplicationState>(
  (state: ApplicationState) => ({
    user: getCurrentUser(state),
    roles: getRoleTypesSelector(state),
    hasInProgressGrades: hasInProgressGrades(state),
  }),
  {
    reviewerAvailableCompositionsRequest,
    fetchInProgressGradesRequest,
    sendUserOpinionForSurveysRequest,
    updateProfileRequest,
  },
)(Dashboard);
