import {NewButton} from 'letrus-ui';
import React from 'react';
import ZeroReason from '../../images/zeroReason.svg';
import styles from './ConfirmFinishReview.module.scss';

interface ConfirmFinishReviewProps {
  onFinishReview: () => void;
  handleCLickCancel: () => void;
  isFundII: boolean;
}

const ConfirmFinishReview: React.FC<ConfirmFinishReviewProps> = ({
  onFinishReview,
  handleCLickCancel,
  isFundII,
}) => {
  return (
    <div className={styles.modalContainer}>
      <h3 className={styles.title}>Enviar correção</h3>
      <span className={styles.description}>
        O aluno receberá a sua correção com bastante expectativa. Por isso,
        tente garantir que{' '}
        {isFundII ? 'o nível foi atribuído' : 'a nota foi atribuída'} de acordo
        com a grade de correção fornecida e que os comentários foram elaborados
        com o intuito de ajudar o aluno a desenvolver sua escrita.
      </span>
      <img className={styles.image} src={ZeroReason} alt="Enviar correção" />
      <footer className={styles.footerButtons}>
        <NewButton
          onClick={handleCLickCancel}
          text="Voltar e revisar correção"
          kind="primary"
          userRole="anonymous"
        />
        <NewButton
          onClick={onFinishReview}
          text="Confirmar envio"
          kind="primary"
          userRole="teacher"
        />
      </footer>
    </div>
  );
};

export default ConfirmFinishReview;
