import {LetrusApi} from '@letrustech/letrus-api-interfaces';
import {List} from 'immutable';
import {Title} from 'letrus-ui';
import React from 'react';
import styles from './ThemeSidebar.module.scss';
import ThemeSidebarAid from './ThemeSidebarAid';

interface Props {
  theme: ImmutableMap<LetrusApi.CompositionInstruction>;
  aids: List<ImmutableMap<LetrusApi.CompositionAid>>;
}

const ThemeSidebar: React.FC<Props> = ({aids, theme}) => {
  const renderAid = (aid: ImmutableMap<any>, index: number) => {
    return <ThemeSidebarAid aid={aid} key={index} />;
  };

  const sortAids = (aids: List<ImmutableMap<LetrusApi.CompositionAid>>) => {
    return aids.sort((aidA, aidB) => aidA.get('order') - aidB.get('order'));
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.container__Header}>
          <div className={styles.container__Header__Top}>
            <Title text="Proposta" onClick={() => {}} />
          </div>
          <div className={styles.container__Header__Content}>
            <div className={styles['container__Header__Content--info']}>
              <h3>
                Tema:{' '}
                {theme?.get('title') &&
                  theme?.get('title').replace(/ *\[[^)]*\] */g, '')}
              </h3>
              <p>Gênero: {theme?.getIn(['genre', 'name'])}</p>
            </div>
          </div>
        </div>

        <div className={styles.container__Content}>
          <div className={styles.container__Content__Header}>
            {aids?.count()
              ? sortAids(aids).map((aid, i) => renderAid(aid, i))
              : null}
          </div>

          <div className={styles.container__Content}>
            <h2 className={styles.container__Content__Subtitle}>
              PROPOSTA DE REDAÇÃO
            </h2>
            <div
              className={styles.HTML}
              dangerouslySetInnerHTML={{
                __html: theme?.get('content_html'),
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ThemeSidebar;
