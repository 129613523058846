/* eslint-disable no-extra-boolean-cast */
// import {any} from '../interfaces/reviewer/competence';
// import {Annotation} from '../interfaces/reviewer/annotation';

import {List} from 'immutable';

interface FilterCompetencesByAnnotations {
  (
    competences: List<ImmutableMap<any>>,
    annotations: List<ImmutableMap<any>>,
  ): List<ImmutableMap<any>>;
}

interface FilterCompetences {
  (competences: List<ImmutableMap<any>>): List<ImmutableMap<any>>;
}

/**
 * Here we map just the competences that have text category(ies).
 * Those competences are mapped only with his text categories that has no
 * annotations yet
 *
 * *text categories are categories that evaluate the entire text of the composition
 */

export const getCompetencesWithTextCategoriesOnly: FilterCompetencesByAnnotations = (
  competences,
  annotations,
) => {
  return competences?.reduce<List<ImmutableMap<any>>>(
    (prevCompetences, competence) => {
      const categories: List<ImmutableMap<any>> = competence.get(
        'review_categories',
        List(),
      );
      const textCategories = categories.filter((category) => {
        const hasAnnotation = !!category
          .get('subcategories')
          .find((subCategory: ImmutableMap<any>) =>
            annotations.find(
              (annotation) =>
                annotation.get('review_subcategory') === subCategory.get('id'),
            ),
          );

        return category.get('is_text_category') && !hasAnnotation;
      });

      const competenceWithTextCategoriesOnly = competence.concat({
        review_categories: textCategories,
      });

      return !!textCategories.count()
        ? prevCompetences.concat([competenceWithTextCategoriesOnly])
        : prevCompetences;
    },
    List(),
  );
};

/**
 *  Here we map just the competences that have category(ies) other than text categories.
 *  Those competences are mapped only with his not text categories.
 *
 *  *text categories are categories that evaluate the entire text of the composition
 */
export const getCompetencesWithoutTextCategories: FilterCompetences = (
  competences,
) => {
  return competences?.reduce<List<ImmutableMap<any>>>(
    (prevCompetences, competence) => {
      const categories: List<ImmutableMap<any>> = competence.get(
        'review_categories',
        List(),
      );
      const noTextCategories = categories.filter(
        (category) => !category.get('is_text_category'),
      );

      const competenceWithouTextCategories = competence.concat({
        review_categories: noTextCategories,
      });

      return noTextCategories.count() || !categories.count()
        ? prevCompetences.concat([competenceWithouTextCategories])
        : prevCompetences;
    },
    List(),
  );
};
