import {LetrusApi} from '@letrustech/letrus-api-interfaces';

export function totalReviewLevelScore(review: any, competences = []) {
  if (typeof review === 'undefined') {
    return 1;
  }

  if (!review.get('composition')) {
    return 1;
  }

  const totalCompenteces =
    review.getIn(['composition', 'genre', 'competences']) || competences;

  const result = Math.ceil(totalReviewScore(review) / totalCompenteces.count());

  return result === 0 ? 1 : result;
}

export function totalReviewScore(review: any) {
  if (!review) {
    return 0;
  }

  if (review.toJS) {
    review = review.toJS();
  }

  return review.review_grades.reduce((memo: number, g: any) => {
    return memo + (g.grade_item ? g.grade_item.points : 0);
  }, 0);
}

export function maxReviewScore(review: any) {
  if (typeof review === 'undefined') {
    return 0;
  }

  if (review.toJS) {
    review = review.toJS();
  }

  if (!review.composition) {
    return 0;
  }

  return review.composition.genre.competences.reduce(
    (memo: number, competence: LetrusApi.CompositionCompetence) => {
      return memo + competence.max_value_range;
    },
    0,
  );
}
