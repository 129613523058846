/* eslint-disable no-nested-ternary */
import {LetrusApi} from '@letrustech/letrus-api-interfaces';
import AddPlagiarismForm from 'components/AddPlagiarismForm';
import GradeEditor from 'components/GradeEditor';
import AddCommentSelectForm from 'containers/AddCommentSelectForm';
import Markings from 'containers/Markings';
import {SelectionState} from 'draft-js';
import {List} from 'immutable';
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {
  getCompositionGradeAnnotations,
  getCompositionGradeCompetences,
  isCustomCorrectionGrid,
  isFundII,
} from 'store/reducers/compositionReviews';
import {ApplicationState} from 'store/rootReducer';
import {
  getCompetencesWithoutTextCategories,
  getCompetencesWithTextCategoriesOnly,
} from '../../utils/functions/competenceFilters';
import styles from './EditGradeSidebarComments.module.scss';

export interface StateProps {
  competences: List<ImmutableMap<LetrusApi.CompositionCompetence>>;
  annotations: List<ImmutableMap<LetrusApi.ReviewAnnotation>>;
  isCustomCorrectionGrid: boolean;
  isFundII: boolean;
}

export interface OwnProps {
  makeAnnotationsForTextCategories: boolean;
  canEditReview: boolean;
  handleCancelPlagiarismMark: () => void;
  toggleIsPlagiarismSelection: () => void;
  onCancelMakeAnnotationsForTextCategories: () => void;
  isPlagiarismSelection: boolean;
  gradeEditorState: any;
  annotationsValidated: boolean;
  isNewSelection: boolean;
  handleSelectedText: (boolean) => void;
  setSelectedTextEditorPosition: (any) => void;
  handleChangeSelectedCompetence: (selectedCompetenceNumber: number) => void;
}

export type Props = StateProps & OwnProps;

export type Modal =
  | 'PlagiarismForm'
  | 'CompetenceSelectForm'
  | 'OrthographicErrorList'
  | 'CompetenceCommentForm'
  | '';

export type CompetenceCommentFormGoBackTo =
  | 'OrthographicErrorList'
  | 'CompetenceSelectForm'
  | '';

const EditGradeSidebarComments: React.FC<Props> = ({
  annotations,
  canEditReview,
  competences,
  gradeEditorState,
  handleSelectedText,
  setSelectedTextEditorPosition,
  isPlagiarismSelection,
  toggleIsPlagiarismSelection,
  isCustomCorrectionGrid,
  handleChangeSelectedCompetence,
  handleCancelPlagiarismMark,
  annotationsValidated,
  isNewSelection,
  makeAnnotationsForTextCategories,
  onCancelMakeAnnotationsForTextCategories,
  isFundII,
}) => {
  const [modal, setModal] = useState<Modal>();
  const [
    competencesWithoutTextCategories,
    setCompetencesWithoutTextCategories,
  ] = useState<List<ImmutableMap<LetrusApi.CompositionCompetence>>>(List());
  const [
    competencesWithTextCategoriesOnly,
    setCompetencesWithTextCategoriesOnly,
  ] = useState<List<ImmutableMap<LetrusApi.CompositionCompetence>>>(List());
  const [annotationSelected, setAnnotationSelected] = useState<
    ImmutableMap<any>
  >();

  const [plagiarismAnnotation, setPlagiarismAnnotation] = useState<any>();
  const [textSelected, setTextSelected] = useState<string>('');

  const [tabCompetenceSelected, setTabCompetenceSelected] = useState<
    number | undefined
  >();

  const [competenceAdded, setCompetenceAdded] = useState<number>();

  useEffect(() => {
    if (!modal && makeAnnotationsForTextCategories && !annotationsValidated) {
      const currentContent = gradeEditorState.editorState.getCurrentContent();
      const firstBlock = currentContent.getBlockMap().first();
      const lastBlock = currentContent.getBlockMap().last();
      const firstBlockKey = firstBlock.getKey();
      const lastBlockKey = lastBlock.getKey();
      const lengthOfLastBlock = lastBlock.getLength();

      const selection = new SelectionState({
        anchorKey: firstBlockKey,
        anchorOffset: 0,
        focusKey: lastBlockKey,
        focusOffset: lengthOfLastBlock,
      });

      const textSelected = GradeEditor.getTextSelection(
        gradeEditorState,
        selection,
      );

      const selectionState = GradeEditor.getSelection(gradeEditorState);

      setModal('CompetenceSelectForm');
      setSelectedTextEditorPosition(selectionState);
      handleSelectedText(true);
      setTextSelected(textSelected);
    }
  }, [
    annotationsValidated,
    modal,
    gradeEditorState.editorState,
    gradeEditorState,
    handleSelectedText,
    makeAnnotationsForTextCategories,
    setSelectedTextEditorPosition,
  ]);

  useEffect(() => {
    if (!competencesWithoutTextCategories.count()) {
      const newCompetencesWithoutTextCategories = getCompetencesWithoutTextCategories(
        competences,
      );

      if (newCompetencesWithoutTextCategories.count()) {
        setCompetencesWithoutTextCategories(
          newCompetencesWithoutTextCategories,
        );
      }
    }
  }, [annotations, competences, competencesWithoutTextCategories]);

  useEffect(() => {
    const newCompetencesWithTextCategoriesOnly = getCompetencesWithTextCategoriesOnly(
      competences,
      annotations,
    );

    const newCategories = newCompetencesWithTextCategoriesOnly.flatMap(
      (competence) => competence.get('review_categories'),
    );

    const oldCategories = competencesWithTextCategoriesOnly.flatMap(
      (competence) => competence.get('review_categories'),
    );

    const updateCompetenceWithTextCategoriesOnly =
      newCategories.count() !== oldCategories.count();

    if (updateCompetenceWithTextCategoriesOnly) {
      setCompetencesWithTextCategoriesOnly(
        newCompetencesWithTextCategoriesOnly,
      );
      setModal('');
    }
  }, [annotations, competences, competencesWithTextCategoriesOnly]);

  useEffect(() => {
    if (
      gradeEditorState.hasSelection &&
      gradeEditorState.editorState.getSelection().hasFocus &&
      isNewSelection &&
      !modal &&
      canEditReview
    ) {
      const textSelected = GradeEditor.getTextSelection(
        gradeEditorState,
        gradeEditorState.editorState.getSelection(),
      );

      handleSelectedText(true);

      const selectionState = GradeEditor.getSelection(gradeEditorState);

      setSelectedTextEditorPosition(selectionState);

      setTextSelected(textSelected);
      setModal('CompetenceSelectForm');
      setAnnotationSelected(undefined);
    }
  }, [
    gradeEditorState,
    isNewSelection,
    modal,
    canEditReview,
    handleSelectedText,
    setSelectedTextEditorPosition,
  ]);

  useEffect(() => {
    if (isPlagiarismSelection && isNewSelection && modal !== 'PlagiarismForm') {
      setModal('PlagiarismForm');
    }
  }, [isNewSelection, isPlagiarismSelection, modal]);

  const handleSavePlagiarismForm = () => {
    handleCancelPlagiarismFormModal();
    if (isPlagiarismSelection) {
      toggleIsPlagiarismSelection();
    }

    setCompetenceAdded(10);

    handleCancelComment();
  };

  const handleCancelPlagiarismFormModal = () => {
    setModal('');
    setPlagiarismAnnotation(null);
  };

  const handleCancelComment = () => {
    setTextSelected('');
    handleSelectedText(false);
    setModal('');
    onCancelMakeAnnotationsForTextCategories();
    handleCancelPlagiarismMark();
  };

  const handleSubmitAddCommment = (competenceId) => {
    setCompetenceAdded(competenceId);
    handleCancelComment();
  };

  const handleChangeTabCompetenceSelected = (
    selectedCompetenceNumber: number,
  ) => {
    if (selectedCompetenceNumber === 0 || selectedCompetenceNumber === 10) {
      setTabCompetenceSelected(undefined);
    } else {
      setTabCompetenceSelected(selectedCompetenceNumber);
    }
  };

  return (
    <div className={styles.container}>
      <div>
        {textSelected ? (
          isPlagiarismSelection || isCustomCorrectionGrid ? (
            <AddPlagiarismForm
              generalAnnotation={plagiarismAnnotation}
              onClickSave={handleSavePlagiarismForm}
              onClickCancel={handleCancelComment}
              editable={canEditReview}
              gradeEditorState={gradeEditorState}
            />
          ) : (
            <AddCommentSelectForm
              canEditReview={canEditReview}
              competences={
                makeAnnotationsForTextCategories
                  ? competencesWithTextCategoriesOnly
                  : competencesWithoutTextCategories
              }
              onClickCancel={handleCancelComment}
              textSelected={textSelected}
              annotation={annotationSelected}
              onSubmitSuccess={handleSubmitAddCommment}
              tabCompetenceSelected={tabCompetenceSelected}
            />
          )
        ) : (
          <div>
            <Markings
              isFundII={isFundII}
              canEditReview={canEditReview}
              isCustomCorrectionGrid={isCustomCorrectionGrid}
              competenceAdded={competenceAdded}
              handleChangeSelectedCompetence={(
                selectedCompetenceNumber: number,
              ) => {
                handleChangeSelectedCompetence(selectedCompetenceNumber);
                handleChangeTabCompetenceSelected(selectedCompetenceNumber);
                setCompetenceAdded(undefined);
              }}
              competences={competencesWithoutTextCategories as any}
              gradeEditorState={gradeEditorState}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default connect<StateProps, {}, OwnProps, ApplicationState>(
  (state: ApplicationState) => ({
    isFundII: isFundII(state),
    annotations: getCompositionGradeAnnotations(state),
    competences: getCompositionGradeCompetences(state),
    isCustomCorrectionGrid: isCustomCorrectionGrid(state),
  }),
)(EditGradeSidebarComments);
