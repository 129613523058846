import {LetrusApi} from '@letrustech/letrus-api-interfaces';
import PrivacyModal from 'components/PrivacyModal';
import TermsModal from 'components/TermsModal';
import {ReactComponent as Illustration} from 'images/illustration_01.svg';
import {ReactComponent as Logo} from 'images/logoLetrus.svg';
import {InputPassword, InputText, NewButton} from 'letrus-ui';
import React, {useEffect, useMemo, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {connect} from 'react-redux';
import {
  getAuthenticationError,
  getLoginFailureMessage,
  LoginActionProps,
  loginUserRequest,
} from 'store/reducers/authentication';
import {
  fetchTermsRequest,
  getTerms,
  isLoadingGlobalConfiguration,
} from 'store/reducers/globalConfiguration';
import {ApplicationState} from 'store/rootReducer';
import styles from './Login.module.scss';
import RestorePassword from './RestorePassword';

interface DispatchProps {
  loginUserRequest(data: LoginActionProps): void;
  fetchTermsRequest: typeof fetchTermsRequest;
}

interface StateProps {
  terms: ImmutableList<ImmutableMap<LetrusApi.LetrusGlobalConfiguration>>;
  isLoadingGlobalConfiguration: boolean;
  showRestorePassword: boolean;
  location?: any;
  authenticationError: boolean;
  failureMessage: string;
}

interface FormProps {
  username: string;
  password: string;
}

type Props = DispatchProps & StateProps;

const Login: React.FC<Props> = ({
  loginUserRequest,
  location,
  authenticationError,
  terms,
  isLoadingGlobalConfiguration,
  fetchTermsRequest,
  failureMessage,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showRestorePassword, setShowRestorePassword] = useState(false);
  const [privacyModalOpen, setPrivacyModalOpen] = useState(false);
  const [termsModalOpen, setTermsModalOpen] = useState(false);
  const [failureWarning, setFailureWarning] = useState('');
  const {
    handleSubmit,
    control,
    errors,
    getValues,
    formState: {isSubmitted},
  } = useForm<FormProps>({
    mode: 'onBlur',
  });

  useEffect(() => {
    fetchTermsRequest();
  }, [fetchTermsRequest]);

  useEffect(() => {
    setFailureWarning(failureMessage);
  }, [failureMessage, failureWarning]);

  const termsText = useMemo(() => {
    return (
      terms.size &&
      terms
        .filter<ImmutableMap<LetrusApi.LetrusGlobalConfiguration>>(
          (term) => term.get('parameter') === 'terms_use_text',
        )
        .first()
        .get('value')
    );
  }, [terms]);

  const {privacyText, privacyDate} = useMemo(() => {
    const privacyTerms =
      terms.size &&
      terms
        .filter<ImmutableMap<LetrusApi.LetrusGlobalConfiguration>>(
          (term) => term.get('parameter') === 'terms_privacy_text',
        )
        .first();

    return {
      privacyText: privacyTerms ? privacyTerms.get('value') : '',
      privacyDate: privacyTerms ? privacyTerms.get('modified') : '',
    };
  }, [terms]);

  const onSubmit = (data: FormProps) => {
    const nextRoute = location?.state?.requestedPath || '/';

    loginUserRequest({
      username: data.username,
      password: data.password,
      nextRoute,
      platform: 'reviewer',
    });

    setFailureWarning(failureMessage);
  };

  const toggleView = () => setShowRestorePassword((prevState) => !prevState);

  const platformTextError =
    'User does not have the necessary roles to access this platform.';

  return (
    <div className={styles.container}>
      <div className={styles.breadcrumb}>
        <a href="https://www.letrus.com.br">Voltar para Início</a>
      </div>

      <main className={styles.mainContent}>
        <div className={styles.firstContent}>
          <div className={styles.card}>
            <h1 className={styles.title}>
              Somos a Letrus, a primeira plataforma de letramento do país!
            </h1>
            <figure className={styles.illustration}>
              <Illustration />
            </figure>
          </div>

          <div className={styles.lastLink}>
            <span className={styles.terms}>
              <span
                role="button"
                aria-disabled={isLoadingGlobalConfiguration}
                onClick={() => setTermsModalOpen(true)}
                className={styles.underline}
              >
                Termos de uso
              </span>{' '}
              e{' '}
              <span
                role="button"
                aria-disabled={isLoadingGlobalConfiguration}
                onClick={() => setPrivacyModalOpen(true)}
                className={styles.underline}
              >
                privacidade
              </span>
            </span>
          </div>
        </div>
        <div className={styles.secondContent}>
          <div className={styles.card}>
            <div className={styles.cardHeader}>ÁREA DO CORRETOR</div>
            {!showRestorePassword ? (
              <>
                <div className={styles.formContainer}>
                  <Logo className={styles.logo} />
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    className={styles.form}
                  >
                    <div className={styles.formBlock}>
                      <Controller
                        control={control}
                        name="username"
                        rules={{
                          required: true,
                        }}
                        render={({name, onBlur, onChange, ref, value}) => (
                          <InputText
                            id="username"
                            aria-label="username"
                            placeholder="Usuário ou e-mail"
                            name={name}
                            onBlur={onBlur}
                            onChange={onChange}
                            inputRef={ref}
                            value={value ?? ''}
                            errorMessage={
                              errors.username &&
                              'Esse campo não pode ficar em branco.'
                            }
                          />
                        )}
                      />
                    </div>
                    <div className={styles.formBlock}>
                      <Controller
                        control={control}
                        name="password"
                        rules={{
                          required: true,
                        }}
                        render={({name, onBlur, onChange, ref, value}) => (
                          <InputPassword
                            showPassword={showPassword}
                            onClickPasswordIcon={() =>
                              setShowPassword(!showPassword)
                            }
                            id="password"
                            placeholder="Senha"
                            aria-label="password"
                            name={name}
                            onBlur={onBlur}
                            onChange={onChange}
                            inputRef={ref}
                            value={value ?? ''}
                            errorMessage={
                              errors.password &&
                              'Esse campo não pode ficar em branco.'
                            }
                          />
                        )}
                      />

                      {authenticationError && isSubmitted && (
                        <span
                          data-testid="credentialsError"
                          className={styles.errorText}
                        >
                          {failureWarning === platformTextError ? (
                            <>
                              Talvez você esteja na plataforma errada!
                              <br />
                              Para fazer login como estudante ou professor,
                              clique no link abaixo.
                            </>
                          ) : (
                            <>
                              Usuário de corretor e/ou senha inválidos.
                              <br />
                              Por favor, verifique e tente novamente.
                            </>
                          )}
                        </span>
                      )}
                    </div>

                    <NewButton
                      userRole="teacher"
                      type="submit"
                      disabled={
                        !!errors.password ||
                        !!errors.username ||
                        !getValues().username ||
                        !getValues().password
                      }
                    >
                      Entrar
                    </NewButton>
                  </form>
                </div>
                <div className={styles.formFooter}>
                  <button className={styles.linkButton} onClick={toggleView}>
                    Esqueci minha senha
                  </button>
                </div>
              </>
            ) : (
              <RestorePassword
                setShowRestorePassword={setShowRestorePassword}
              />
            )}
          </div>
          <div className={styles.lastLink}>
            <p>
              Fazer login como{' '}
              <a href="https://aluno.letrus.com.br">estudante</a> ou{' '}
              <a href="https://professor.letrus.com.br">professor</a>.
            </p>
          </div>
        </div>
      </main>

      <PrivacyModal
        privacyText={privacyText}
        privacyDate={privacyDate}
        visible={privacyModalOpen}
        onClose={() => setPrivacyModalOpen(false)}
      />

      <TermsModal
        termsText={termsText}
        visible={termsModalOpen}
        onClose={() => setTermsModalOpen(false)}
      />
    </div>
  );
};

export default connect(
  (state: ApplicationState) => ({
    authenticationError: getAuthenticationError(state),
    terms: getTerms(state) as any,
    isLoadingGlobalConfiguration: isLoadingGlobalConfiguration(state),
    failureMessage: getLoginFailureMessage(state),
  }),
  {
    fetchTermsRequest,
    loginUserRequest,
  },
)(Login);
