import {LetrusApi} from '@letrustech/letrus-api-interfaces';
import {List} from 'immutable';
import React, {useEffect, useState} from 'react';
import CompetenceGrade from './CompetenceGrade';
import styles from './CompetenceGrades.module.scss';
import ConfirmGrades from './ConfirmGrades';

export interface Props {
  competences: List<ImmutableMap<LetrusApi.CompositionCompetence>>;
  isFundII: boolean;
  isMultigenre: boolean;
  tabActiveName: string;
  canEditReview: boolean;
}

const CompetenceGrades: React.FC<Props> = ({
  competences,
  isFundII,
  isMultigenre,
  canEditReview,
  tabActiveName,
}) => {
  const [selectedCompetence, setSelectedCompetence] = useState(
    competences.find((competence) =>
      isFundII || isMultigenre
        ? competence
            .get('name')
            .replace('Critério ', 'C')
            .slice(0, 2)
            .includes(tabActiveName)
        : competence
            .get('name')
            .replace('Competência ', 'C')
            .includes(tabActiveName.toString()),
    ),
  );

  useEffect(() => {
    setSelectedCompetence(
      competences.find((competence) =>
        isFundII || isMultigenre
          ? competence
              .get('name')
              .replace('Critério ', 'C')
              .slice(0, 2)
              .includes(tabActiveName)
          : competence
              .get('name')
              .replace('Competência ', 'C')
              .includes(tabActiveName),
      ),
    );
  }, [competences, isFundII, tabActiveName, isMultigenre]);

  return (
    <div className={styles.container}>
      {tabActiveName === 'Total' ? (
        <ConfirmGrades canEditReview={canEditReview} />
      ) : (
        <CompetenceGrade
          canEditReview={canEditReview}
          selectedCompetence={selectedCompetence}
          isFundII={isFundII}
          isMultigenre={isMultigenre}
          key={selectedCompetence?.get('id')}
        />
      )}
    </div>
  );
};

export default CompetenceGrades;
