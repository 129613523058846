import {ReactComponent as LetrusBrand} from 'images/logoLetrus.svg';
import React from 'react';
import './NewLogo.scss';

export interface NewLogoProps {
  isLoading?: boolean;
  className?: string;
}

const NewLogo: React.FC<NewLogoProps> = ({
  isLoading = false,
  className = '',
}) => {
  return (
    <div
      className={`NewLogo ${isLoading ? 'NewLogo--isLoading' : ''} ${
        className ?? ''
      }`}
    >
      <LetrusBrand title="Letrus" />

      <svg
        xmlns="http://www.w3.org/2000/svg"
        cursor="crosshair"
        id="svg2"
        height="130.805"
        width="428.504"
        viewBox="0 0 428.5036 130.8048"
      >
        <g transform="matrix(1.33333 0 0 -1.33333 -183.456 941.453)" id="g10">
          <g id="g20">
            <g id="g22">
              <g className="logo_bars">
                <path
                  d="m 177.782,607.986 h -20 v 8 h 20 z"
                  id="bar2"
                  fill="#fecb2e"
                />
                <path
                  d="m 157.592,607.986 h -20 v 8 h 20 z"
                  id="bar3"
                  fill="#000"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default NewLogo;
