import {Utils} from '@letrustech/letrus-api-interfaces';
import {fromJS} from 'immutable';
import {AnyAction, Reducer} from 'redux';
import {call, put} from 'redux-saga/effects';
import {createSelector} from 'reselect';
import {ApplicationState} from 'store/rootReducer';
import {
  addLikeInGeneralAnnotationService,
  createGradeGeneralAnnotationService,
  deleteGradeGeneralAnnotationService,
  updateGradeGeneralAnnotationService,
} from 'store/services/generalAnnotationService';
import {action} from 'typesafe-actions';

//Action types
export enum GeneralAnnotationTypes {
  CREATE_GRADE_REQUEST = '@generalAnnotation/CREATE_GRADE_REQUEST',
  CREATE_GRADE_SUCCESS = '@generalAnnotation/CREATE_GRADE_SUCCESS',
  CREATE_GRADE_FAILURE = '@generalAnnotation/CREATE_GRADE_FAILURE',

  UPDATE_GRADE_REQUEST = '@generalAnnotation/UPDATE_GRADE_REQUEST',
  UPDATE_GRADE_SUCCESS = '@generalAnnotation/UPDATE_GRADE_SUCCESS',
  UPDATE_GRADE_FAILURE = '@generalAnnotation/UPDATE_GRADE_FAILURE',

  DELETE_GRADE_REQUEST = '@generalAnnotation/DELETE_GRADE_REQUEST',
  DELETE_GRADE_SUCCESS = '@generalAnnotation/DELETE_GRADE_SUCCESS',
  DELETE_GRADE_FAILURE = '@generalAnnotation/DELETE_GRADE_FAILURE',

  ADD_LIKE_REQUEST = '@generalAnnotation/ADD_LIKE_REQUEST',
  ADD_LIKE_SUCCESS = '@generalAnnotation/ADD_LIKE_SUCCESS',
  ADD_LIKE_FAILURE = '@generalAnnotation/ADD_LIKE_FAILURE',

  RESET_GENERAL_ANNOTATION_STATE_REQUEST = '@generalAnnotation/RESET_GENERAL_ANNOTATION_STATE_REQUEST',
  RESET_GENERAL_ANNOTATION_STATE_SUCCESS = '@generalAnnotation/RESET_GENERAL_ANNOTATION_STATE_SUCCESS',
  RESET_GENERAL_ANNOTATION_STATE_FAILURE = '@generalAnnotation/RESET_GENERAL_ANNOTATION_STATE_FAILURE',
}

//Data types
export enum DataStatus {
  added = 'added',
  removed = 'removed',
  updated = 'updated',
}

export interface Data {
  annotation: ImmutableMap<any>;
  status?: DataStatus;
}

//State type
export interface GeneralAnnotationsState
  extends ImmutableMap<{
    data: ImmutableMap<any>;
    loading: boolean;
    error: boolean;
  }> {}

//Create actions
export const createGradeGeneralAnnotationRequest = (data: any) =>
  action(GeneralAnnotationTypes.CREATE_GRADE_REQUEST, data);

export const createGradeGeneralAnnotationSuccess = (data: any) =>
  action(GeneralAnnotationTypes.CREATE_GRADE_SUCCESS, data);

export const createGradeGeneralAnnotationFailure = () =>
  action(GeneralAnnotationTypes.CREATE_GRADE_FAILURE);

//Update actions
export const updateGradeGeneralAnnotationRequest = (data?: any) =>
  action(GeneralAnnotationTypes.UPDATE_GRADE_REQUEST, data);

export const updateGradeGeneralAnnotationSuccess = (data: any) =>
  action(GeneralAnnotationTypes.UPDATE_GRADE_SUCCESS, data);

export const updateGradeGeneralAnnotationFailure = () =>
  action(GeneralAnnotationTypes.UPDATE_GRADE_FAILURE);

export const addLikeInGeneralAnnotationRequest = (params?: Utils.GetParams) =>
  action(GeneralAnnotationTypes.ADD_LIKE_REQUEST, params);

export const addLikeInGeneralAnnotationSuccess = (data: any) =>
  action(GeneralAnnotationTypes.ADD_LIKE_SUCCESS, data);

export const addLikeInGeneralAnnotationFailure = () =>
  action(GeneralAnnotationTypes.ADD_LIKE_FAILURE);

//reset General Annotation actions
export const resetGeneralAnnotationStateRequest = () =>
  action(GeneralAnnotationTypes.RESET_GENERAL_ANNOTATION_STATE_REQUEST);

export const resetGeneralAnnotationStateSuccess = () =>
  action(GeneralAnnotationTypes.RESET_GENERAL_ANNOTATION_STATE_SUCCESS);

export const resetGeneralAnnotationStateFailure = () =>
  action(GeneralAnnotationTypes.RESET_GENERAL_ANNOTATION_STATE_FAILURE);

//Delete actions
export const deleteGradeGeneralAnnotationRequest = (data: any) =>
  action(GeneralAnnotationTypes.DELETE_GRADE_REQUEST, data);

export const deleteGradeGeneralAnnotationSuccess = (data: any) =>
  action(GeneralAnnotationTypes.DELETE_GRADE_SUCCESS, data);

export const deleteGradeGeneralAnnotationFailure = () =>
  action(GeneralAnnotationTypes.DELETE_GRADE_FAILURE);

//Sagas
export function* resetGeneralAnnotationState() {
  try {
    yield put(resetGeneralAnnotationStateSuccess());
  } catch (error) {
    yield put(resetGeneralAnnotationStateFailure());
  }
}

export function* createGeneralGradeGeneralAnnotation(action: AnyAction) {
  try {
    const response = yield call(
      createGradeGeneralAnnotationService,
      action.payload,
    );
    yield put(createGradeGeneralAnnotationSuccess(response.data));
  } catch (err) {
    yield put(createGradeGeneralAnnotationFailure());
  }
}

export function* updateGeneralGradeGeneralAnnotation(action: AnyAction) {
  try {
    const response = yield call(
      updateGradeGeneralAnnotationService,
      action.payload,
    );
    yield put(updateGradeGeneralAnnotationSuccess(response.data));
  } catch (err) {
    yield put(updateGradeGeneralAnnotationFailure());
  }
}

export function* deleteGeneralGradeGeneralAnnotation(action: AnyAction) {
  try {
    const response = yield call(
      deleteGradeGeneralAnnotationService,
      action.payload,
    );
    yield put(
      deleteGradeGeneralAnnotationSuccess({
        ...response.data,
        ...action.payload,
      }),
    );
  } catch (err) {
    yield put(deleteGradeGeneralAnnotationFailure());
  }
}

export function* addLikeInGeneralAnnotation(action: AnyAction) {
  try {
    const response = yield call(
      addLikeInGeneralAnnotationService,
      action.payload,
    );
    yield put(addLikeInGeneralAnnotationSuccess(response.data));
  } catch (err) {
    yield put(addLikeInGeneralAnnotationFailure());
  }
}

//Selectors

const generalAnnotationSelector = (state: ApplicationState) =>
  state.get('generalAnnotations');

export const getGeneralAnnotation = createSelector(
  generalAnnotationSelector,
  (generalAnnotation) => generalAnnotation.getIn(['data', 'annotation']),
);

export const getGeneralAnnotationStatus = createSelector(
  generalAnnotationSelector,
  (generalAnnotation) => generalAnnotation.getIn(['data', 'status']),
);

export const isLoadingGeneralAnnotation = createSelector(
  generalAnnotationSelector,
  (generalAnnotation) => generalAnnotation.get('loading'),
);

//Initial state
export const INITIAL_STATE: GeneralAnnotationsState = fromJS({
  data: {
    annotation: {},
    status: undefined,
  },
  error: false,
  loading: false,
});

//Reducer
export const reducer: Reducer<GeneralAnnotationsState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case GeneralAnnotationTypes.CREATE_GRADE_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('loading', true).setIn(['data', 'status'], undefined),
      );

    case GeneralAnnotationTypes.CREATE_GRADE_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .setIn(['data', 'status'], DataStatus.added)
          .setIn(['data', 'annotation'], fromJS(action.payload)),
      );

    case GeneralAnnotationTypes.CREATE_GRADE_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );

    case GeneralAnnotationTypes.UPDATE_GRADE_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('loading', true).setIn(['data', 'status'], undefined),
      );

    case GeneralAnnotationTypes.UPDATE_GRADE_SUCCESS:
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .setIn(['data', 'status'], DataStatus.updated)
          .setIn(['data', 'annotation'], fromJS(action.payload)),
      );

    case GeneralAnnotationTypes.UPDATE_GRADE_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );

    case GeneralAnnotationTypes.DELETE_GRADE_REQUEST:
      return state.withMutations((prevState) =>
        prevState.set('loading', true).setIn(['data', 'status'], undefined),
      );

    case GeneralAnnotationTypes.DELETE_GRADE_SUCCESS: {
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .set('error', false)
          .setIn(['data', 'status'], DataStatus.removed)
          .setIn(['data', 'annotation'], fromJS(action.payload)),
      );
    }

    case GeneralAnnotationTypes.DELETE_GRADE_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );

    case GeneralAnnotationTypes.ADD_LIKE_REQUEST:
      return state.withMutations((prevState) => prevState.set('loading', true));

    case GeneralAnnotationTypes.ADD_LIKE_SUCCESS:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', false),
      );

    case GeneralAnnotationTypes.ADD_LIKE_FAILURE:
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );

    case GeneralAnnotationTypes.RESET_GENERAL_ANNOTATION_STATE_REQUEST: {
      return state.withMutations((prevState) => prevState.set('loading', true));
    }

    case GeneralAnnotationTypes.RESET_GENERAL_ANNOTATION_STATE_SUCCESS: {
      return state.withMutations((prevState) =>
        prevState
          .set('loading', false)
          .setIn(['data', 'status'], undefined)
          .setIn(['data', 'annotation'], fromJS({})),
      );
    }

    case GeneralAnnotationTypes.RESET_GENERAL_ANNOTATION_STATE_FAILURE: {
      return state.withMutations((prevState) =>
        prevState.set('loading', false).set('error', true),
      );
    }

    default:
      return state;
  }
};

export default reducer;
