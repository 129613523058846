import {LetrusApi} from '@letrustech/letrus-api-interfaces';
import {IReview} from 'containers/CompetenceGradeSelectForm';
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {InjectedFormProps} from 'redux-form';
import {Field, Form, reduxForm} from 'redux-form/immutable';
import {
  createOrUpdateCompetenceGradeRequest,
  getCompositionGrade,
} from 'store/reducers/compositionReviews';
import {ApplicationState} from 'store/rootReducer';
import {extractTextFromHtml} from 'utils/functions/html';
import styles from './CompetenceGrade.module.scss';

export interface CompetenceGradeProps {
  isMultigenre: boolean;
  isFundII: boolean;
  selectedCompetence?: ImmutableMap<LetrusApi.CompositionCompetence>;
  canEditReview: boolean;
}

interface DispatchProps {
  createOrUpdateCompetenceGradeRequest: typeof createOrUpdateCompetenceGradeRequest;
}

interface StateProps {
  compositionGrade: ImmutableMap<LetrusApi.CompositionReview>;
}

type Props = CompetenceGradeProps & DispatchProps & StateProps;
type InjectedProps = Props & InjectedFormProps<{}, Props>;

const CompetenceGrade: React.FC<InjectedProps> = ({
  selectedCompetence,
  createOrUpdateCompetenceGradeRequest,
  compositionGrade,
  canEditReview,
  isFundII,
  isMultigenre,
}) => {
  const gradeItems = selectedCompetence?.get('grade_items');

  const [selectedReview, setSelectedReview] = useState<IReview>(
    compositionGrade.get('review_grades').find((grade) => {
      return grade.get('competence') === selectedCompetence?.get('id');
    }),
  );

  const selectedReviewInfo = compositionGrade
    .get('review_grades')
    .find((grade) => {
      return grade.get('competence') === selectedCompetence?.get('id');
    });

  const selectedReviewInfoGradeItemPoints = selectedReviewInfo?.getIn([
    'grade_item',
    'points',
  ]);

  const [reviewPointsSelected, setReviewPointsSelected] = useState<
    ImmutableMap<any>
  >(
    gradeItems?.find(
      (item) => item.get('points') === selectedReviewInfoGradeItemPoints,
    ),
  );

  const [reviewPoint, setReviewPoint] = useState<number>(
    reviewPointsSelected?.get('points'),
  );

  const [reviewComment, setReviewComment] = useState(
    selectedReview?.comments ? selectedReview.comments : '',
  );

  useEffect(() => {
    if (selectedCompetence) {
      const selectedReviewInfo = compositionGrade
        .get('review_grades')
        .find((grade) => {
          return grade.get('competence') === selectedCompetence?.get('id');
        });

      if (selectedReviewInfo) {
        setSelectedReview(selectedReviewInfo.toJS());
      }
    }
  }, [compositionGrade, selectedCompetence]);

  useEffect(() => {
    if (selectedReview?.comments) {
      setReviewComment(selectedReview.comments);
    } else {
      setReviewComment('');
    }
  }, [selectedReview]);

  const callOnGradeChange = (gradePoints: number) => {
    const grade = gradeItems.find((grade) => {
      return grade.get('points') === gradePoints;
    });

    createOrUpdateCompetenceGradeRequest({
      comments: reviewComment,
      competence_id: selectedCompetence?.get('id') as number,
      review_id: parseInt(compositionGrade.get('id'), 10),
      grade_item_id: grade && grade.get('id'),
    });
  };

  const handleSelectGradePoints = (e) => {
    if (canEditReview) {
      const selectedPoint = gradeItems.find(
        (item) => item.get('points').toString() === e.target.value,
      );

      const selectedReviewInfo = compositionGrade
        .get('review_grades')
        .find((grade) => {
          return grade.get('competence') === selectedCompetence?.get('id');
        });

      if (selectedReviewInfo) {
        setSelectedReview(selectedReviewInfo.toJS());
      }

      if (selectedPoint) {
        setReviewPointsSelected(selectedPoint);

        setReviewPoint(Number(e.target.value));

        callOnGradeChange(Number(e.target.value));
      }
    }
  };

  const handleChangeComment = (event) => {
    setReviewComment(event.target.value);
  };

  const handleSaveChangeComment = () => {
    callOnGradeChange(Number(reviewPoint));
  };

  const gradeItemsSorted = gradeItems?.sort((a, b) => {
    return a.get('points') - b.get('points');
  });

  return (
    <div className={styles.container}>
      <Form onSubmit={() => {}} autoComplete="off">
        <fieldset className={styles.form}>
          <div className={styles.goal}>
            <p className={styles.title}>Objetivo do aluno</p>
            <span className={styles.description}>
              {selectedCompetence?.get('description')}
            </span>
          </div>
          <div className={styles.grade}>
            <h3 className={styles.title}>
              Atribuição de {isFundII ? 'nível' : 'nota'}
            </h3>

            <div className={styles.gradeOptions}>
              {gradeItemsSorted?.map((gradeItem, index) => {
                const checkedItem = reviewPoint === gradeItem.get('points');

                if (gradeItem.get('points') === 0 && isMultigenre) return null;

                return (
                  <label key={gradeItem.get('points')}>
                    <Field
                      name="grade_item"
                      component="input"
                      type="radio"
                      parse={Number}
                      checked={checkedItem}
                      value={gradeItem.get('points')}
                      onChange={handleSelectGradePoints}
                    />
                    {gradeItem.get('display_points')}
                  </label>
                );
              })}
            </div>
          </div>
          {reviewPointsSelected?.get('description') && (
            <div className={styles.description}>
              {reviewPointsSelected?.get('description')}
            </div>
          )}
          {!isFundII && (
            <div className={styles.comment}>
              <label htmlFor="CommentGrade" className={styles.title}>
                Comentário
              </label>

              <textarea
                id="CommentGrade"
                name="CommentGrade"
                onBlur={handleSaveChangeComment}
                onChange={handleChangeComment}
                placeholder="Não há nenhum comentário automático para este nível"
                defaultValue={
                  reviewComment ? extractTextFromHtml(reviewComment, true) : ''
                }
                disabled={!canEditReview}
              />
            </div>
          )}
        </fieldset>
      </Form>
      <footer className={styles.footerText}>
        AS EDIÇÕES são salvas automaticamente :)
      </footer>
    </div>
  );
};

export default connect<
  StateProps,
  DispatchProps,
  CompetenceGradeProps,
  ApplicationState
>(
  (state: ApplicationState) => ({
    compositionGrade: getCompositionGrade(state),
  }),
  {
    createOrUpdateCompetenceGradeRequest,
  },
)(
  reduxForm<{}, Props>({
    form: 'CompetenceGradeForm',
  })(CompetenceGrade),
);
