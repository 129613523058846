/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import './InputField.scss';

interface InputFieldProps {
  onClick?: any;
  block?: any;
  modality?: any;
  type?: any;
  userInterface?: any;
  icon?: any;
  autoFocus?: any;
  disabled?: any;
  fullWidth?: any;
  meta?: any;
  className?: any;
  id?: any;
  label?: any;
  autocomplete?: any;
  input?: any;
  placeholder?: any;
  required?: any;
}

const InputField: React.FC<InputFieldProps> = ({
  type,
  disabled,
  fullWidth,
  meta,
  className,
  id,
  label,
  autocomplete,
  input,
  placeholder,
  required,
  children,
}) => {
  return (
    <div
      className={`form-group InputField ${
        meta.error && meta.touched ? 'has-error' : ''
      } ${
        className && className.indexOf('ComplementItem') !== -1
          ? 'ComplementItem'
          : ''
      }`}
    >
      {
        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        <label htmlFor={id} className="control-label col-xs-12">
          {label}
          {required ? (
            <span className="InputField__label--required">*</span>
          ) : (
            ''
          )}
        </label>
      }
      <div className={`col-xs-12 ${!fullWidth && 'col-md-6'}`}>
        <input
          required={required}
          autoComplete={autocomplete}
          className="form-control"
          {...input}
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          id={id}
        />
        {meta.touched && meta.error && (
          <small className="input-text-mask__error">{meta.error}</small>
        )}
      </div>
      {children}
    </div>
  );
};

export default InputField;
