const BANK_MASKS = {
  'Banco do Brasil': {
    agency: '9999-*',
    account: '99999999-*',
  },
  Santander: {
    agency: '9999',
    account: '99999999-*',
  },
  'Caixa Econômica Conta de 8 dígitos': {
    agency: '9999',
    account: '99999999-*',
    operation: {
      Corrente: {'Pessoa Física': '001', 'Pessoa Jurídica': '003'},
      Simples: {'Pessoa Física': '002'},
      Poupança: {'Pessoa Física': '013', 'Pessoa Jurídica': '022'},
    },
  },
  'Caixa Econômica Conta de 9 dígitos': {
    agency: '9999',
    account: '999999999-*',
    operation: {
      Corrente: {'Pessoa Física': '001', 'Pessoa Jurídica': '003'},
      Simples: {'Pessoa Física': '002'},
      Poupança: {'Pessoa Física': '013', 'Pessoa Jurídica': '022'},
    },
  },
  'Caixa Econômica Conta de 13 dígitos': {
    agency: '9999',
    account: '9999999999999-*',
    operation: {
      Corrente: {'Pessoa Física': '001', 'Pessoa Jurídica': '003'},
      Simples: {'Pessoa Física': '002'},
      Poupança: {'Pessoa Física': '013', 'Pessoa Jurídica': '022'},
    },
  },
  Bradesco: {
    agency: '9999-9',
    account: '9999999-*',
  },
  Itaú: {
    agency: '9999',
    account: '99999-*',
  },
  Banrisul: {
    agency: '9999',
    account: '9999999999',
  },
  Sicredi: {
    agency: '9999',
    account: '999999',
  },
  Sicoob: {
    agency: '9999',
    account: '999999999-*',
  },
  Inter: {
    agency: '9999',
    account: '999999999-*',
  },
  BRB: {
    agency: '9999',
    account: '999999999-*',
  },
  'Via Credi': {
    agency: '9999',
    account: '99999999999-*',
  },
  Neon: {
    agency: '9999',
    account: '9999999-*',
  },
  Nubank: {
    agency: '9999',
    account: '9999999999-*',
  },
  PagSeguro: {
    agency: '9999',
    account: '99999999-*',
  },
  'Banco Original': {
    agency: '9999',
    account: '99999-*',
  },
  Safra: {
    agency: '9999',
    account: '99999999-*',
  },
};

export default BANK_MASKS;
