import {Errors, Validate} from './forms/validations/bankInformation';

export const validatePassword = (values: Validate) => {
  const errors: Errors = {};

  if (
    values.get('new_password') &&
    values.get('new_password_re') &&
    values.get('new_password') !== values.get('new_password_re')
  ) {
    errors.new_password_re = 'As senhas não conferem';
  }
  return errors;
};
