export const denormalizePhone = (value) => {
  if (!value) {
    return null;
  }

  if (typeof value === 'string') {
    return value.replace(/[^\d]/g, '');
  } else {
    return value.toString().replace(/[^\d]/g, '');
  }
};

export default denormalizePhone;
