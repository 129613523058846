/* eslint-disable react-hooks/exhaustive-deps */
import DropZoneField from 'components/DropZoneField';
import {Icon} from 'letrus-ui';
import React, {useEffect, useState} from 'react';
import {InjectedFormProps} from 'redux-form';
import {Field, Form, reduxForm} from 'redux-form/immutable';
import './ReceiptFileUpload.scss';

interface ReceiptFileUploadProps {
  showAlert?: any;
  closeAlert?: any;
  setIsLoading?: any;
  createPresignedUrl1?: any;
  fetchPresignedUrl?: any;
  deletePresignedUrl?: any;
  isDeletingPresignedUrl?: any;
  presignedUrl: any;
}

const ReceiptFileUpload: React.FC<
  ReceiptFileUploadProps & InjectedFormProps<{}, ReceiptFileUploadProps>
> = ({
  showAlert,
  closeAlert,
  setIsLoading,
  createPresignedUrl1,
  presignedUrl,
  fetchPresignedUrl,
  deletePresignedUrl,
  isDeletingPresignedUrl,
}) => {
  const [file, setFile] = useState<any>(null);

  useEffect(() => {
    fetchPresignedUrl({
      content: 'certificado',
      ordering: '-id',
      limit: 1,
    });
  }, []);

  useEffect(() => {
    if (presignedUrl.count()) {
      setFile({
        name: String(presignedUrl.get('url')).split('/').slice(-1).pop(),
        url: presignedUrl.get('url'),
        id: presignedUrl.get('id'),
      });
    }
  }, [presignedUrl]);

  useEffect(() => {
    if (isDeletingPresignedUrl) {
      setIsLoading(true);
      closeAlert();
    } else {
      setIsLoading(false);
    }
  }, [closeAlert, isDeletingPresignedUrl, setIsLoading]);

  const handleOnDrop = async (files, onChange) => {
    createPresignedUrl1({content: 'certificado'}, files[0], {
      content: 'certificado',
      ordering: '-id',
      limit: 1,
    });

    showAlert('Seu comprovante já foi anexado!', 'success');
    onChange(files[0]);
  };

  const handleOpenReceipt = () => {
    const a = document.createElement('a');
    a.href = file.url;
    a.target = '_blank';
    a.click();
  };

  const handleDeleteReceipt = () => {
    showAlert(
      `Você tem certeza que deseja excluir o arquivo Comprovante${fileType}?`,
      'warning',
      [
        {
          title: 'Cancelar',
          onClick: closeAlert,
          type: 'button',
          kind: 'secondary',
          size: 'large',
        },
        {
          title: 'Excluir',
          type: 'button',
          kind: 'negativePrimary',
          onClick: () => {
            deletePresignedUrl(file.id);

            setFile(null);
          },
          size: 'large',
        },
      ],
    );
  };

  const fileNameSplit = file && file.name ? file.name.split('.') : undefined;
  const fileType = fileNameSplit
    ? `.${fileNameSplit[fileNameSplit.length - 1]}`
    : '';

  //TODO: Put onSubmit handler as a prop on ReduxForm Form component
  return (
    <Form className="receipt" onSubmit={() => {}}>
      Comprovante
      {file ? (
        <div className="receipt__file">
          <div>
            <Icon icon="file-pdf" />
            <span className="receipt__file__name">
              {`Comprovante${fileType}`}{' '}
            </span>
          </div>
          <div className="receipt__file__buttons">
            <button
              type="button"
              className="receipt__file__buttons__button receipt__file__buttons__button--primary"
              onClick={handleOpenReceipt}
            >
              <Icon icon="eye" /> Visualizar
            </button>{' '}
            <button
              type="button"
              className="receipt__file__buttons__button receipt__file__buttons__button--danger"
              onClick={handleDeleteReceipt}
            >
              <Icon icon="times" /> Excluir
            </button>
          </div>
        </div>
      ) : (
        <Field
          name="imageToUpload"
          component={DropZoneField}
          type="file"
          handleOnDrop={handleOnDrop}
          className="receipt__send"
        >
          <Icon icon="cloud-upload-alt" />
          Clique aqui para enviar seu arquivo
        </Field>
      )}
    </Form>
  );
};

export default reduxForm<{}, ReceiptFileUploadProps>({
  form: 'ReceiptFileUpload',
})(ReceiptFileUpload);
